var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"reportForm"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"label":"Tip dokumenta","dense":"","items":_vm.documentTypes,"item-text":"name","item-value":"value"},model:{value:(_vm.documentType),callback:function ($$v) {_vm.documentType=$$v},expression:"documentType"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDateFrom,"label":"Od datuma","dense":"","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.reportFromMenu),callback:function ($$v) {_vm.reportFromMenu=$$v},expression:"reportFromMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","max":_vm.dateTo},on:{"input":function($event){_vm.reportFromMenu = false}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"transition":"scale-transition","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Vrijeme od","dense":"","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.formatTimeFrom),callback:function ($$v) {_vm.formatTimeFrom=$$v},expression:"formatTimeFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.reportTimeFromMenu),callback:function ($$v) {_vm.reportTimeFromMenu=$$v},expression:"reportTimeFromMenu"}},[(_vm.reportTimeFromMenu)?_c('v-time-picker',{attrs:{"max":_vm.dateFrom === _vm.dateTo ? _vm.timeTo : undefined,"full-width":"","format":"24hr"},model:{value:(_vm.timeFrom),callback:function ($$v) {_vm.timeFrom=$$v},expression:"timeFrom"}}):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDateTo,"label":"Do datuma","dense":"","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.reportToMenu),callback:function ($$v) {_vm.reportToMenu=$$v},expression:"reportToMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","min":_vm.dateFrom,"scrollable":""},on:{"input":function($event){_vm.reportToMenu = false}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"transition":"scale-transition","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Vrijeme do","dense":"","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.formatTimeTo),callback:function ($$v) {_vm.formatTimeTo=$$v},expression:"formatTimeTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.reportTimeToMenu),callback:function ($$v) {_vm.reportTimeToMenu=$$v},expression:"reportTimeToMenu"}},[(_vm.reportTimeToMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},model:{value:(_vm.timeTo),callback:function ($$v) {_vm.timeTo=$$v},expression:"timeTo"}}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }