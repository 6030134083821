<template>
  <v-dialog
    v-model="visible"
    v-if="visible"
    @keydown.esc="close"
    persistent
    :max-width="tab == 'ClosingBalanceList' ? '900px' : '900px'"
    content-class="my-custom-dialog"
  >
    <v-form
      ref="createReportForm"
      style="border-radius: 25px !important"
      @submit.prevent="submit"
    >
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-dialog-card': $vuetify.breakpoint.smAndDown }"
        style="border-radius: 25px"
      >
        <v-card-title
          class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;

            height: 58px;
            color: white;
          "
        >
          <span class="text-h5" style="font-weight: bold"
            >Zaključak blagajne</span
          >
        </v-card-title>

        <!-- <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Zaključak blagajne</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title> -->
        <v-card-text class="pl-0 ml-0 pt-5">
          <v-row>
            <v-col :cols="tab == 'ClosingBalanceList' ? 3 : 3">
              <v-tabs vertical v-model="tab">
                <v-tab class="justify-start" href="#ClosingBalance"
                  >Zaključak blagajne</v-tab
                >
                <v-tab class="justify-start" href="#ClosingBalanceList"
                  >Lista zaključaka</v-tab
                >
              </v-tabs>
            </v-col>
            <v-divider vertical inset></v-divider>
            <v-col
              style="min-width: 100px; max-width: 100%"
              :class="[densityPadding, 'flex-grow-1']"
            >
              <div>
                <v-tabs-items v-model="tab">
                  <v-tab-item value="ClosingBalance">
                    <closing-balance
                      ref="closingBalance"
                      @receiptPrint="setReceiptPrint"
                    ></closing-balance>
                  </v-tab-item>
                  <v-tab-item value="ClosingBalanceList">
                    <closing-balance-list
                      @setPrintState="setPrintState"
                    ></closing-balance-list>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5 pr-5">
          <v-spacer></v-spacer>
          <v-btn
            v-if="tab == 'ClosingBalance'"
            class="btn infoButton"
            @click="openBalancePreview"
            >Ispis totala</v-btn
          >
          <v-btn id="danger" class="btn dangerButton" @click="close">
            {{ $t("$vuetify.close") }}
          </v-btn>
          <v-btn
            v-if="tab == 'ClosingBalance'"
            id="success"
            class="btn successButton"
            @click="openWithdrawDialog"
          >
            Zaključi blagajnu
          </v-btn>
        </v-card-actions>
        <v-dialog v-model="showWithdrawDialog" width="25%">
          <v-card>
            <v-card-title class="d-flex" style="vertical-align: middle">
              <v-row class="pa-3 mb-2">
                <h3 class="text--secondary">Zaključak</h3>
                <v-spacer></v-spacer>
                <v-icon large @click="showWithdrawDialog = !showWithdrawDialog"
                  >mdi-close</v-icon
                >
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row class="pb-3">
                <div
                  style="
                    padding-left: 10px;
                    padding-top: 7px;
                    padding-right: 5px;
                  "
                >
                  Iznos novca za polaganje u banku:
                </div>
                <div style="padding-top: -5px; max-width: 24% !important">
                  <quantity-input
                    dense
                    hide-details
                    v-model="withdrawAmount"
                    suffix="€"
                  ></quantity-input>
                </div>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="success" class="btn successButton" @click="submit()">
                Zaključi blagajnu
              </v-btn>

              <v-btn
                id="danger"
                class="btn dangerButton"
                @click="showWithdrawDialog = false"
              >
                Zatvori
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-overlay v-if="loading" absolute z-index="10">
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import ClosingBalance from '@/modules/cash-register/components/closeBalance/components/ClosingBalance'
import ClosingBalanceList from '@/modules/cash-register/components/closeBalance/components/ClosingBalanceList'
import state from '@/state'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import { v4 as uuidv4 } from 'uuid'
import { df, auth, functions } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import EventBus from '@/plugins/event-bus'
import { closingBalance } from '@/libs/closingBalance/closingBalance.js'
import { printBalancePreview } from '@/libs/bixolon/printBalancePreview'
import LogData from '@/model/LogData'
import log from '@/mixins/log'
import QuantityInput from '@/components/QuantityInput.vue'

export default {
  components: { ClosingBalance, ClosingBalanceList, QuantityInput },
  inject: ['showMsgBox', 'openPdfPrint', 'confirm', 'confirmClose'],
  mixins: [applicationSettings, log],
  data: function () {
    return {
      visible: false,
      tab: 'ClosingBalance',
      loading: false,
      lastBalanceId: '',
      receiptPrint: true,
      selectedPrinter: {},
      showWithdrawDialog: false,
      withdrawAmount: 0
    }
  },
  mounted () {
    EventBus.$on('set-selected-printer', (printer) => {
      this.selectedPrinter = printer
    })
  },
  methods: {
    prepareLogCall () {
      var req = new LogData()

      let userId = auth.currentUser.uid
      if (state.getCashRegisterUser().id && state.getCashRegisterUser().id !== '') {
        userId = state.getCashRegisterUser().id
      }

      req.action_name = 'Ispis totala'
      req.action_id = '8'
      req.user_id = userId

      this.setLog(req)
    },
    async openBalancePreview () {
      if (state.getCurrentCompany().save_log) {
        this.prepareLogCall()
      }
      this.loading = true
      var balancePreview = functions.httpsCallable('balancepreview')

      balancePreview({
        company_id: state.getCurrentCompany().id,
        location_id: state.getPointOfSale().id,
        register_id: state.getCashRegister().id
      })
        .then(async (result) => {
          this.loading = false

          if (result && result.data.code === 200 && result.data.balance_report) {
            printBalancePreview(result.data.balance_report, this.selectedPrinter)
          }
        })
    },
    async open () {
      this.visible = true
      await this.setLastBalanceId()
      this.setPrintListener()
    },
    close () {
      this.visible = false
      this.$refs.closingBalance.resetReceiptPrint()
      this.detachListeners()
    },
    setPrintState (state) {
      this.loading = state
    },
    setReceiptPrint (state) {
      this.receiptPrint = state
    },
    setPrintListener () {
      const docRef = df.doc(`user_cash_registers/${auth.currentUser.uid}.${state.getCurrentCompany().id}.${state.getPointOfSale().id}`)
      this.listeners.push(docRef.onSnapshot((doc) => {
        const data = doc.data()
        if (data?.cash_registers) {
          const register = data.cash_registers[state.getCashRegister().id]
          if (!register) return
          if (register.last_balance_id && register.last_balance_id !== this.lastBalanceId) {
            if (['T58', 'T80', 'A4'].includes(this.selectedPrinter.width)) {
              this.printDocument(register.last_balance_id)
            } else {
              this.printLocal(register.last_balance_id)
            }
            this.lastBalanceId = clone(register.last_balance_id)
          }
        }
      })
      )
    },
    openWithdrawDialog () {
      var amounts = this.$refs.closingBalance.getBalanceAmounts()
      this.withdrawAmount = amounts.withdrawAmount
      this.showWithdrawDialog = true
    },
    submit () {
      this.showWithdrawDialog = false
      this.loading = true

      var amounts = this.$refs.closingBalance.getBalanceAmounts()
      var withdrawAmount = this.formatNumber(this.withdrawAmount, 100)
      var availableFunds = this.formatNumber(amounts.availableFunds, 1)
      var previousAmount = this.formatNumber(amounts.previousAmount, 1)
      var maxWithdraw = (availableFunds + previousAmount)

      if (withdrawAmount > maxWithdraw) {
        this.showMsgBox({
          text: `Iznos za polaganje u banku ne može biti veći od ${maxWithdraw / 100} €. Molimo ispravite iznos kako bi kreirali zaključak blagajne.`,
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
        this.loading = false
        return
      }

      const payload = {
        action: {
          operation: 'set',
          entity: 'balance',
          params: {
            company_id: state.getCurrentCompany().id,
            location_id: state.getPointOfSale().id,
            register_id: state.getCashRegister().id,
            withdraw_amount: withdrawAmount
          }
        }
      }

      let userId = auth.currentUser.uid
      if (state.getCashRegisterUser().id && state.getCashRegisterUser().id !== '') {
        userId = state.getCashRegisterUser().id
      }

      payload.action.params.user_id = userId

      try {
        // const reqId = uuidv4()
        this.$emit('setPrintState', true)

        var cashregisterApi = functions.httpsCallable('cashregisterapi')

        cashregisterApi({
          action: {
            operation: 'set',
            entity: 'balance',
            params: payload.action.params
          }
        })
          .then((result) => {
            if (result.data.code === 200) {
              this.getCashRegister()
            }
          })

        // df.doc(`request/${reqId}`)
        //   .set({
        //     user_id: `${userId}`,
        //     device_id: 'web',
        //     created: `${new Date().getTime()}`,
        //     type: 'cashregister',
        //     payload: btoa(
        //       unescape(encodeURIComponent(JSON.stringify(payload)))
        //     )
        //   })
        //   .catch((err) => {
        //     this.showMsgBox({
        //       text: err && err !== '' ? err : 'An error has occurred',
        //       actions: ['cancel'],
        //       cancelBtnText: this.$t('$vuetify.close'),
        //       color: 'error'
        //     })
        //   })
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      }

      setTimeout(() => { this.getCashRegister() }, 5000)
    },
    async getCashRegister () {
      if (!state.getCashRegister()?.id) { return }
      const response = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      if (!response.data()) {
        throw new Error('Nije moguće dohvatiti podatke o blagajni')
      } else {
        state.setCashRegister(response.data())
        EventBus.$emit('refreshCashRegister')
      }
    },
    formatNumber (num, multiplier = 100) {
      if (!num || num === '' || num === 0) return 0

      var bla = num.toString().trim()
      if (bla === '') return 0

      var number = bla.replace(/,/g, '.')

      number = parseFloat(number)
      return parseInt(number * multiplier)
    },
    async printLocal (balanceId) {
      this.confirm({
        title: 'Zaključak blagajne',
        message: 'Da li želite isprintati zaključak blagajne?',
        options: {
          toolbar: true,
          width: 410,
          confirmText: 'DA',
          cancelText: 'NE'
        }
      }).then(async (resp) => {
        if (resp) {
          this.confirmClose()
          const response = await df.doc(`cash_registers/${state.getCashRegister().id}/balances/${balanceId}`).get()
          if (response && response.data()) {
            var closeBalance = response.data()
            closingBalance(closeBalance, this.selectedPrinter)
            this.loading = false
          }
        } else {
          this.confirmClose()
          this.loading = false
        }
      })
    },

    printDocument (balanceId) {
      const payload = {
        action: {
          entity: 'cashier_balance',
          operation: 'get',
          resource_id: balanceId,
          params: {
            company_id: state.getCurrentCompany().id,
            print_size: state.getPrinter(),
            print_receipts: this.receiptPrint,
            cash_register_id: state.getCashRegister().id
          }
        }
      }

      const reqId = uuidv4()
      df.doc(`request/${reqId}`)
        .set({
          user_id: `${auth.currentUser.uid}`,
          device_id: 'web',
          created: `${new Date().getTime()}`,
          type: 'document',
          payload: btoa(unescape(encodeURIComponent(JSON.stringify(payload))))
        })
        .catch((err) => {
          this.showMsgBox({
            text: err && err !== '' ? err : 'An error has occurred',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
        })
      const docRef = df.doc(`response/${reqId}`)
      const unsubscribe = docRef.onSnapshot((doc) => {
        const data = doc.data()
        if (data) {
          unsubscribe()
          this.loading = false

          if (!data.body || !data.body.document) {
            this.showMsgBox({
              text: this.$t('$vuetify.errors.nullClosingBalance'),
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })
            return
          }
          this.openPdfPrint(data.body.document)
        }
      })
    },
    async setLastBalanceId () {
      const registersDoc = await df
        .doc(
          `user_cash_registers/${auth.currentUser.uid}.${
            state.getCurrentCompany().id
          }.${state.getPointOfSale().id}`
        )
        .get()
      const registerBalanceId =
        registersDoc.data()?.cash_registers?.[state.getCashRegister().id]
          ?.last_balance_id
      this.lastBalanceId = registerBalanceId ? clone(registerBalanceId) : ''
    }
  }
}
</script>
<style scoped>
.v-dialog__content >>> .my-custom-dialog {
  border-radius: 25px !important;
  z-index: 6;
}
</style>
