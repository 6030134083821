<template>
  <v-dialog
    v-model="dialogOpen"
    v-if="dialogOpen"
    content-class="my-custom-dialog"
    persistent
    scrollable
    max-width="800"
    @keydown.esc="close"
  >
    <v-card tile color="white">
      <v-card-title
        class="pb-4"
        style="position: sticky; border-bottom: 1px solid lightgrey"
      >
        <h2>Napomene</h2>
        <v-spacer></v-spacer>
        <v-icon class="mt-1" large @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-actions>
        <v-tabs v-model="tab">
          <v-tab>Napomena</v-tab>
          <v-tab>Povijest</v-tab>
          <v-tab-item
            ><v-container>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    autofocus
                    auto-grow
                    rows="1"
                    label="Unesi napomenu"
                    v-model="note"
                    @keypress.enter="close()"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <!-- <v-textarea label="Sve napomene" readonly></v-textarea> -->
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <v-row>
                <v-card class="mt-2" outlined width="100%">
                  <v-col cols="12">
                    <v-data-table
                      :items="previousNotes"
                      :headers="headers"
                      hide-default-footer
                    ></v-data-table>
                  </v-col>
                </v-card>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-card-actions> </v-card
  ></v-dialog>
</template>

<script>
import { df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import state from '@/state'

export default {
  data () {
    return {
      previousNotes: [],
      orgUnits: [],
      dialogOpen: false,
      tab: null,
      note: '',
      itemId: '',
      invoice: {},
      headers: []
    }
  },
  methods: {
    open (item, invoice, table) {
      this.note = ''

      this.getHeaders()
      this.getOrganizationalUnits()
      this.dialogOpen = true
      this.itemId = item.id
      this.invoice = invoice
      this.getPreviousNotes(item, table.id)
    },
    close () {
      this.saveNote()
      this.dialogOpen = false
    },

    getHeaders () {
      this.headers = []
      this.headers.push(
        {
          text: 'Napomena',
          align: 'start',
          value: 'note.message'
        },
        {
          text: 'Ustrojstvena jedinica',
          align: 'start',
          value: 'orgUnit.name'
        },
        {
          text: 'Dodao',
          align: 'start',
          value: 'order.added_by_name'
        },
        {
          text: 'Status',
          align: 'start',
          value: 'order.order_status'
        }
      )
    },
    async getPreviousNotes (item, tableId) {
      const me = this
      this.previousNotes = []
      if (tableId) {
        const tbl = await df.doc(`location_orders/${state.getPointOfSale().id}/tables/${tableId}`).get()
        if (tbl && tbl.data()) {
          const table = tbl.data()
          const items = clone(table.items)
          items.forEach(i => {
            if (i.notes && i.notes.length > 0) {
              i.notes.forEach(note => {
                const organizationalUnit = me.getOrgUnitById(item.organizational_unit_id)

                this.previousNotes.push({ note: note, item: i, orgUnit: organizationalUnit })
              })
            }
          })
        }
      }
    },
    async getOrganizationalUnits () {
      this.orgUnits = []
      await df.collection(`location_units/${state.getPointOfSale().id}/units`).get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((unit) => {
              this.orgUnits.push(unit.data())
            })
          }
        })
    },
    async saveNote () {
      if (this.note.length > 0) {
        const noteItemIdx = this.invoice.items.findIndex(item => item.id === this.itemId)
        if (noteItemIdx > -1) {
          if (!this.invoice.items[noteItemIdx].notes) {
            this.invoice.items[noteItemIdx].notes = []
          }
          this.invoice.items[noteItemIdx].notes.push({ orderId: '', message: this.note.replace('\n', ''), currentNote: true })
        }
      }
    },
    getOrgUnitById (id) {
      return this.orgUnits.find(unit => unit.id === id)
    }
  }
}
</script>

<style scoped>
.v-dialog__content >>> .my-custom-dialog {
  position: absolute;
  top: 50px;
}
</style>
