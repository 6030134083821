<template>
  <v-container fluid class="d-flex justify-center align-center fill-height">
    <v-row class="d-flex justify-center">
      <v-col sm="8" md="6" lg="4" xl="3">
        <v-alert
          v-model="error.show"
          type="error"
          color="error"
          dismissible
          @input="dismissError"
          >{{ error.message }}</v-alert
        >
        <v-row class="d-flex justify-center align-center">
          <v-img
            v-bind:src="logo"
            class="ma-10 d-flex justify-center align-center align-center"
            contain
          ></v-img>
        </v-row>
        <v-card elevation="0" class="card pa-5">
          <v-card-text>
            <v-form ref="loginFrm" @submit.prevent="submit">
              <v-text-field
                label="Email"
                outlined
                rounded
                class="rounded-card"
                :rules="[rules.email, rules.req]"
                v-model="email"
                prepend-inner-icon="mdi-email"
                autofocus
              ></v-text-field>
              <v-text-field
                outlined
                :label="$t('$vuetify.formDetails.password')"
                rounded
                class="rounded-card"
                v-model="password"
                :rules="[rules.req]"
                prepend-inner-icon="mdi-lock"
                :type="passVisible ? 'text' : 'password'"
                :append-icon="passVisible ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (passVisible = !passVisible)"
              ></v-text-field>
              <v-btn
                class="white--text okButton rounded-card"
                dark
                max-width="100%"
                width="100%"
                x-large
                :loading="submitting"
                :disabled="submitting"
                color="loginButton"
                type="submit"
                id="loginBtn"
              >
                {{ $t("$vuetify.formDetails.logIn") }}
              </v-btn>
            </v-form>

            <p v-if="aikasa === true" class="d-flex justify-center mt-5 mb-5">
              <a href="" @click.prevent="forgotPasswordProcess">{{
                $t("$vuetify.formDetails.forgotPassword")
              }}</a>
            </p>
            <v-divider class="mb-5 mr-3 ml-3"></v-divider>
            <v-row
              v-if="aikasa === true"
              width="100%"
              class="d-flex justify-center"
            >
              <v-btn
                class="white--text okButton rounded-card"
                width="70%"
                color="loginButton"
                height="45"
                x-large
                id="registerBtn"
                @click.prevent="registerUser"
                @keydown.enter.prevent="registerUser"
              >
                {{ $t("$vuetify.formDetails.register") }}
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row
          v-if="aikasa === true && platform !== 'ios'"
          class="d-flex justify-center ma-7 align-center"
        >
          <a :href="androidUrl">
            <v-img
              height="80"
              width="160"
              src="@/assets/android-app-icon.jpg"
              contain
            ></v-img>
          </a>
          <!-- <a :href="iOSUrl">
            <v-img
              height="80"
              width="160"
              src="@/assets/apple-app-store-icon.png"
              contain
            ></v-img>
          </a> -->
        </v-row>

        <v-row
          v-if="aikasa === true && platform === 'ios'"
          class="d-flex justify-center ma-7 align-center"
        >
          <a :href="iOSUrl">
            <v-img
              height="80"
              width="160"
              src="@/assets/apple-app-store-icon.png"
              contain
            ></v-img>
          </a>
        </v-row>
      </v-col>
    </v-row>
    <password-reset ref="passwordReset"></password-reset>
  </v-container>
</template>
<script>
import { auth, df, functions, firebase } from '@/plugins/firebase'
import rules from '@/plugins/rules'
import state from '@/state'
import defaults from '@/mixins/defaults'
import PasswordReset from './PasswordReset.vue'

export default {
  name: 'Login',
  inject: ['registerUserForm', 'confirm', 'confirmClose', 'showLoader', 'hideLoader'],
  components: { PasswordReset },
  mixins: [defaults],
  data: () => ({
    email: '',
    password: '',
    submitting: false,
    passVisible: false,
    url: '',
    error: {
      show: false,
      message: undefined,
      code: undefined
    },
    rules: {
      req: rules.req(),
      email: rules.email()
    },
    cashRegisters: []
  }),
  mounted () {
    if (this.aikasa === true) { document.title = 'aiKasa' } else { document.title = 'Kasa' }
  },
  computed: {
    platform () {
      var platform = 'default'
      if ([
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)) { platform = 'ios' }

      if ((navigator.userAgent.includes('Android') && 'ontouchend' in document)) { platform = 'android' }
      return platform
    },
    aikasa () {
      try {
        require('@/assets/' + this.imageSrc)
        return false
      } catch (err) {
        return true
      }
    },
    logo () {
      let ret = 'logo-AI-kasa.svg'
      try {
        ret = require('@/assets/' + this.imageSrc)
      } catch (err) {
        if (this.$vuetify.theme.dark === true) { ret = require('@/assets/' + 'logo-AI-kasa-negativ.svg') } else { ret = require('@/assets/' + 'logo-AI-kasa.svg') }
        return ret
      }
      return ret
    },
    imageSrc () {
      return 'logos/' + window.location.hostname + '.png'
    },
    androidUrl () {
      return process.env.VUE_APP_ANDROID_URL
    },
    iOSUrl () {
      return process.env.VUE_APP_iOS_URL
    }
  },
  methods: {
    callReservation (register, user) {
      const that = this

      this.showLoader()
      const reserving = user.name + ' ' + user.surname

      if (register.reserved_by !== undefined) {
        df.doc(`cash_registers/${register.id}`).update({ reserved_by: reserving })
          .then(() => {
          })
          .catch(() => {
            that.hideLoader()
            that.showMsgBox({ text: 'Pogreška prilikom zapisa podataka', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
            that.$router.push({
              name: 'pointOfSale.cashRegisters',
              params: {
                companyId: state.getCurrentCompany().id,
                locationId: state.getPointOfSale().id
              }
            })
          })
          .finally(() => {
            that.hideLoader()
            state.setCashRegister(register)
            that.$router.push({
              name: 'cashRegister',
              params: {
                companyId: state.getCurrentCompany().id,
                locationId: state.getPointOfSale().id,
                registerId: state.getCashRegister().id,
                redirectedThruIssue: true
              }
            })
              .catch(() => {})
            this.submitting = false
          })
      }
    },
    async getUser () {
      const user = await df.doc(`users/${auth.currentUser.uid}`).get()
      return user
    },
    async getCashRegister () {
      const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      return register
    },
    submit (event) {
      if (!this.$refs.loginFrm || !this.$refs.loginFrm.validate()) return

      if (document.activeElement.id.includes('registerBtn')) {
        event.preventDefault()
        return
      }
      this.submitting = true
      auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then(async (authResp) => {
          if (authResp.user) {
            if (authResp.user.emailVerified) {
              var CheckSessionStatus =
                functions.httpsCallable('CheckSessionStatus')
              const res = await CheckSessionStatus({})
              if (res?.data?.header?.code !== 200) {
                this.error.message =
                  res?.data?.header?.message ||
                  'Dogodila se greška prilikom login-a.'
                this.error.show = true
                this.error.code = res?.data?.header?.code || 400
                this.submitting = false
                return
              }

              if (res.data.session) {
                this.confirm({
                  title: 'Prijava',
                  message: `Trenutno ste prijavljeni na uređaju ${
                    res.data.session?.device_name || 'nepoznati uređaj'
                  }.<br>Da li se želite odjaviti s tog i prijaviti ovdje?`,
                  options: {
                    toolbar: true,
                    width: 410,
                    confirmText: 'DA',
                    cancelText: this.$t('$vuetify.close')
                  }
                }).then(async (resp) => {
                  if (resp) {
                    this.confirmClose()
                    const sessionResp = await this.startSession()
                    if (!sessionResp) {
                      this.submitting = false
                      return
                    }
                    this.proceedToHome(authResp)
                  } else {
                    this.submitting = false
                  }
                })
              } else {
                const sessionResp = await this.startSession()
                if (!sessionResp) {
                  this.submitting = false
                  return
                }
                this.proceedToHome(authResp)
              }
            } else {
              this.error.message = 'Još niste potvrdili e-mail adresu!'
              this.error.show = true
              this.submitting = false
            }
          }
        })
        .catch((error) => {
          this.error.message = this.getMessageByKey(error.code) || error
          this.error.show = true
          this.error.code = error.code
          this.submitting = false
        })
    },
    async getDirectToRegisterData () {
      const companies = await df
        .doc(`user_companies/${auth.currentUser.uid}`)
        .get()

      if (
        !companies ||
        !companies.data() ||
        !companies.data().companies
      ) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ status: 0 })
      }

      const allCompanies = Object.keys(companies.data().companies).map((key) => {
        return companies.data().companies[key]
      })

      if (
        allCompanies.filter(
          (company) =>
            company.status === 'OK' && company.resource_status === 'ACTIVE'
        ).length !== 1
      ) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ status: 0 })
      }

      const company = allCompanies.find(
        (company) =>
          company.status === 'OK' && company.resource_status === 'ACTIVE'
      )

      const pointsOfSale = await df
        .doc(`user_locations/${auth.currentUser.uid}.${company.id}`)
        .get()

      if (
        !pointsOfSale ||
        !pointsOfSale.data() ||
        !pointsOfSale.data().locations
      ) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          status: 1,
          company: company
        })
      }
      const locations = Object.keys(pointsOfSale.data().locations).map(
        (key) => {
          return pointsOfSale.data().locations[key]
        }
      )

      if (
        locations.filter(
          (location) =>
            location.status === 'OK' && location.resource_status === 'ACTIVE'
        ).length !== 1
      ) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          status: 1,
          company: company
        })
      }

      const pointOfSale = locations.find(
        (location) =>
          location.status === 'OK' && location.resource_status === 'ACTIVE'
      )
      const registers = await df
        .doc(
          `user_cash_registers/${auth.currentUser.uid}.${company.id}.${pointOfSale.id}`
        )
        .get()

      this.cashRegisters = registers.data().cash_registers

      if (
        !registers ||
        !registers.data() ||
        !registers.data().cash_registers ||
        Object.keys(registers.data().cash_registers).length !== 1 ||
        Object.keys(registers.data().cash_registers).map((key) => {
          return registers.data().cash_registers[key]
        })[0].status !== 'OK'
      ) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          status: 2,
          company: company,
          pSale: pointOfSale
        })
      }

      const register = Object.keys(registers.data().cash_registers).map(
        (key) => {
          return registers.data().cash_registers[key]
        }
      )[0]

      return {
        company: company,
        pSale: pointOfSale,
        register: register
      }
    },
    async reauthenticateUser () {
      const credential = firebase.auth.EmailAuthProvider.credential(
        this.email,
        this.password
      )
      try {
        return await auth.currentUser.reauthenticateWithCredential(credential)
      } catch (err) {
        return null
      }
    },
    async startSession () {
      const StartNewSession = functions.httpsCallable('StartNewSession')
      const startSessionResp = await StartNewSession({})
      if (startSessionResp?.data?.header?.code !== 200) {
        this.error.message =
          startSessionResp?.data?.header?.message ||
          'Dogodila se greška prilikom prijavljivanja u aplikaciju.'
        this.error.show = true
        this.error.code = startSessionResp?.data?.header?.code || 400
        return null
      }
      return this.reauthenticateUser()
    },
    proceedToHome (resp) {
      state.isAuthenticated = true
      df.doc(`users/${resp.user.uid}`)
        .get()
        .then((user) => {
          if (user && user.exists) {
            state.setUser(user.data())
          }
        })
      this.getDirectToRegisterData()
        .then((resp) => {
          const that = this
          state.setCurrentCompany(resp.company)
          state.setAppTitle(resp.company.name)
          state.setPointOfSale(resp.pSale)
          state.setCashRegister(resp.register)

          this.getCashRegister()
            .then(cashRegResp => {
              const cashReg = cashRegResp.data()

              this.getUser()
                .then(userResp => {
                  const user = userResp.data()
                  let reservedRegister = false

                  if (cashReg.reserved_by !== undefined && cashReg.reserved_by !== '' && cashReg.reserved_by !== user.name + ' ' + user.surname) {
                    reservedRegister = true
                    this.confirm({
                      title: 'Rezervacija blagajne',
                      message: `Trenutno je na blagajnu prijavljen ${
                   cashReg.reserved_by
                  }.<br>Da li želite odjaviti tog korisnika i prijaviti sebe?`,
                      options: {
                        toolbar: true,
                        width: 410,
                        confirmText: 'DA',
                        cancelText: 'NE'
                      }
                    }).then(async (resp) => {
                      if (resp) {
                        this.confirmClose()
                        this.callReservation(cashReg, user)
                      } else {
                        this.confirmClose()
                        that.$router.push({
                          name: 'pointOfSale.cashRegisters',
                          params: {
                            companyId: state.getCurrentCompany().id,
                            locationId: state.getPointOfSale().id,
                            redirectedThruIssue: true
                          }
                        }).catch(() => {})
                        that.submitting = false
                      }
                    })
                  }

                  if (cashReg.reserved_by !== undefined && cashReg.reserved_by === '') {
                    reservedRegister = true
                    this.callReservation(cashReg, user)
                  }

                  if (!reservedRegister) {
                    this.$router
                      .push({
                        name: 'cashRegister',
                        params: {
                          companyId: resp.company.id,
                          locationId: resp.pSale.id,
                          registerId: resp.register.id,
                          redirectedThruIssue: true
                        }
                      })
                      .catch(() => {})
                    this.submitting = false
                  }
                })
            })
        })
        .catch((resp) => {
          let routeFound = false
          let routeName = ''
          let registerReserved = false

          if (state.getCurrentCompany().id !== undefined && state.getCurrentCompany().id !== '') {
            routeFound = true
            routeName = 'company'
            const params = {
              companyId: state.getCurrentCompany().id,
              redirectedThruIssue: true
            }

            if (state.getPointOfSale().id !== undefined && state.getPointOfSale().id !== '') {
              routeName = 'pointOfSale'
              params.locationId = state.getPointOfSale().id

              if (state.getCashRegister().id !== undefined && state.getCashRegister().id !== '') {
                routeName = 'pointOfSale.cashRegisters'

                this.getCashRegister()
                  .then(cashRegResp => {
                    const cashReg = cashRegResp.data()

                    this.getUser()
                      .then(userResp => {
                        const user = userResp.data()

                        if (cashReg.reserved_by !== undefined && cashReg.reserved_by !== '' && cashReg.reserved_by === user.name + ' ' + user.surname) {
                          registerReserved = true
                          this.$router.push({
                            name: 'cashRegister',
                            params: {
                              companyId: state.getCurrentCompany().id,
                              locationId: state.getPointOfSale().id,
                              registerId: state.getCashRegister().id,
                              redirectedThruIssue: true
                            }
                          })
                            .catch(() => {})
                          this.submitting = false
                        } else if (cashReg.reserved_by !== undefined && cashReg.reserved_by !== '' && cashReg.reserved_by !== user.name + ' ' + user.surname) {
                          routeName = 'cashRegister'
                          params.registerId = state.getCashRegister().id
                          routeFound = true
                          registerReserved = true

                          this.confirm({
                            title: 'Rezervacija blagajne',
                            message: `Trenutno je na blagajnu prijavljen ${
                         cashReg.reserved_by
                        }.<br>Da li želite odjaviti tog korisnika i prijaviti sebe?`,
                            options: {
                              toolbar: true,
                              width: 410,
                              confirmText: 'DA',
                              cancelText: 'NE'
                            }
                          }).then(async (resp) => {
                            if (resp) {
                              this.confirmClose()
                              this.callReservation(cashReg, user)
                            } else {
                              this.confirmClose()
                              this.$router.push({
                                name: 'pointOfSale.cashRegisters',
                                params: {
                                  companyId: state.getCurrentCompany().id,
                                  locationId: state.getPointOfSale().id,
                                  redirectedThruIssue: true
                                }
                              }).catch(() => {})
                              this.submitting = false
                            }
                          })
                        } else if (cashReg.reserved_by !== undefined && cashReg.reserved_by === '') {
                          registerReserved = true
                          this.callReservation(cashReg, user)
                        }
                      })
                  })
              }
            }

            if (!registerReserved) {
              this.$router
                .push({
                  name: routeName,
                  params: params
                })
                .catch(() => {})
              this.submitting = false
            }
          }

          if (!routeFound) {
            this.submitting = false
            switch (resp.status) {
              case 0: {
                this.$router.push({
                  path: '/companies',
                  params: {
                    redirectedThruIssue: true
                  }
                }).catch(() => {})
                break
              }

              case 1: {
                state.setCurrentCompany(resp.company)
                state.setAppTitle(resp.company.name)
                this.$router
                  .push({
                    name: 'company',
                    params: {
                      companyId: resp.company.id,
                      redirectedThruIssue: true
                    }
                  })
                  .catch(() => {})
                break
              }

              case 2: {
                state.setCurrentCompany(resp.company)
                state.setAppTitle(resp.company.name)
                state.setPointOfSale(resp.pSale)

                this.$router
                  .push({
                    name: 'pointOfSale',
                    params: {
                      companyId: resp.company.id,
                      locationId: resp.pSale.id,
                      redirectedThruIssue: true
                    }
                  })
                  .catch(() => {})
                break
              }

              default: {
                this.$router.push({
                  path: '/companies',
                  params: {
                    redirectedThruIssue: true
                  }
                }).catch(() => {})
              }
            }
          }
        })
    },
    dismissError () {
      if (this.error.code === 'auth/user-not-found') {
        this.$refs.loginFrm.reset()
      }

      this.error.show = false
      this.error.message = undefined
      this.error.code = undefined
    },
    registerUser () {
      this.registerUserForm()
    },
    forgotPasswordProcess () {
      this.$refs.passwordReset.open()
      // this.$router.push({
      //   name: 'PasswordReset'
      // })
    }
  }
}
</script>
<style scoped>
.rounded-card {
  border-radius: 10px;
}

#loginBtn.v-btn--disabled {
  background-color: #2597c5 !important;
}

.app-welcome {
  height: 300px;
  widows: 300px;
  position: relative;
}
</style>
