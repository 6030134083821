<template>
  <div>
    <v-progress-linear v-if="!loaded" indeterminate></v-progress-linear>
    <v-row v-for="(row, idxr) in rows" :key="idxr">
      <v-col
        v-for="(item, idxc) in row.cols"
        :key="idxc"
        :cols="colspan"
        class="panel-cell"
        align="center"
      >
        <v-btn
          style="display: inline-block"
          :disabled="!editMode && item.id === ''"
          block
          tile
          depressed
          min-height="3.5rem"
          :color="item.color"
          @click="onTileClick(item)"
        >
          <span class="text-wrap label">{{ item.label }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <!-- DIALOGS -->
    <add-item-to-panel
      :addItemDialogOpen="addItemDialogOpen"
      :categories="categories"
      :selectedTile="selectedTile"
      @addCategoryToPanel="onAddCategoryToPanel"
      @addProductToPanel="onAddProductToPanel"
      @addItemDialogClose="onAddItemDialogClose"
    ></add-item-to-panel>
    <edit-panel-item
      ref="editPanelItem"
      v-if="editItemDialogOpen"
      :categories="categories"
      :editItem="editItem"
      :editItemDialogOpen="editItemDialogOpen"
      @deleteItem="onDeleteItem"
      @changeCategoryOnPanel="onChangeCategoryOnPanel"
      @changeProductOnPanel="onChangeProductOnPanel"
      @editItemDialogClose="onEditItemDialogClose"
    ></edit-panel-item>

    <select-product-from-category
      :selectProductDialogOpen="selectProductDialogOpen"
      :selectedCategoryId="selectedCategoryId"
      @addProduct="addProduct"
      @addItemDialogClose="onAddProductDialogClose"
    ></select-product-from-category>
  </div>
</template>

<script>
import state from '@/state'
import { df } from '@/plugins/firebase'
import EventBus from '@/plugins/event-bus'
import AddItemToPanel from '@/modules/cash-register/components/dialogs/AddItemToPanel'
import EditPanelItem from '@/modules/cash-register/components/dialogs/EditPanelItem'
import SelectProductFromCategory from '@/modules/cash-register/components/dialogs/SelectProductFromCategory.vue'

export default {
  components: {
    EditPanelItem,
    AddItemToPanel,
    SelectProductFromCategory
  },

  data () {
    return {
      loaded: false,
      rowCount: null,
      colCount: null,
      colspan: null,
      editMode: false,
      selectedTile: null,
      addItemDialogOpen: false,
      editItemDialogOpen: false,
      rows: [],
      listeners: [],
      categories: [],
      selectProductDialogOpen: false,
      selectedCategoryId: undefined,
      selectProductDialogClose: false
    }
  },

  methods: {
    onAddProductDialogClose () {
      this.selectProductDialogOpen = false
    },
    setupTiles () {
      for (let i = 0; i < this.rowCount; i++) {
        const row = {
          rowIndex: i,
          cols: []
        }

        for (let j = 0; j < this.colCount; j++) {
          row.cols.push({
            active: false,
            row_index: i,
            col_index: j,
            color: 'primary',
            colspan: 1,
            id: '',
            parent_id: '',
            label: '',
            type: ''
          })
        }

        this.rows.push(row)
      }
    },

    async fillTiles () {
      const query = df.collection(`cash_registers/${state.getCashRegister().id}/gui_configuration_rows`)
      const listener = query.onSnapshot((doc) => {
        for (let i = 0; i < this.rowCount; i++) {
          if (doc.docs[i]) {
            const row = doc.docs[i].data()
            for (let j = 0; j < this.colCount; j++) {
              if (row.cols && row.cols[j]) {
                const tile = row.cols[j]
                if (tile.active && this.rows[tile.row_index]) {
                  this.rows[tile.row_index].cols[tile.col_index] = tile
                } else {
                  this.rows[i].cols[j] = {
                    active: false,
                    row_index: tile.row_index,
                    col_index: tile.col_index,
                    color: 'primary',
                    colspan: 1,
                    id: '',
                    parent_id: '',
                    label: '',
                    type: ''
                  }
                }
              }
            }
          }
        }
        this.$forceUpdate()
      })
      this.loaded = true
      this.listeners.push(listener)
    },

    getCategories () {
      const listener = df.doc(`company_categories/${state.getCurrentCompany().id}`)
        .onSnapshot(doc => {
          if (doc && doc.data() && doc.data().categories && Object.keys(doc.data().categories).length > 0) {
            this.categories = Object.keys(doc.data().categories).map(key => {
              return { ...doc.data().categories[key], [`hover-${doc.data().categories[key].id}`]: false }
            })
            this.categories.push({ id: '5692439e-4269-4fd9-875e-71e0e08a4e99', name: 'Meni', picture: '', status: 'OK' })
            this.categories.sort((a, b) => a.name > b.name ? 1 : -1)
          }
        })

      this.listeners.push(listener)
    },

    async updateTileData (tile, rows) {
      const docRef = df.doc(`cash_registers/${state.getCashRegister().id}/gui_configuration_rows/row${tile.row_index}`)
      try {
        await docRef.set(
          {
            cols: rows[tile.row_index].cols
          }
        )
        console.log('Tile successfully updated.')
      } catch (err) {
        console.error('Error updating tile: ', err)
      }
    },

    onTileClick (item) {
      this.selectedTile = item
      if (this.editMode) {
        if (item.active) {
          this.editPanelItem(item)
        } else {
          this.addPanelItem()
        }
      } else {
        if (item.type === 'category') {
          this.selectedCategoryId = item.id
          this.selectProductDialogOpen = true
        } else {
          this.addProduct(item)
        }
      }
    },

    editPanelItem (item) {
      this.editItem = item
      this.editItemDialogOpen = true
    },

    addPanelItem () {
      this.addItemDialogOpen = true
    },

    addProduct (item) {
      EventBus.$emit('add-product-to-invoice', item)
    },

    async onDeleteItem (item) {
      this.editItemDialogOpen = false
      try {
        this.loaded = false
        const colsRef = df.doc(`cash_registers/${state.getCashRegister().id}/gui_configuration_rows/row${item.row_index}`)
        const document = await colsRef.get()
        const cols = document.data().cols
        const tileIndex = cols.findIndex(c => c.col_index === item.col_index)
        this.updateTile(item.row_index, item.col_index, { active: false, color: 'primary', colspan: 1, id: '', label: '', type: '' })
        this.markDeleted(cols, tileIndex)
        colsRef.update({ cols: cols })
        this.loaded = true
        console.info('Tile succefully deleted')
      } catch (err) {
        console.error(`Error deleting tile: ${err}`)
      }
    },

    async onChangeCategoryOnPanel (item, colspan, color) {
      this.addItemToPanel(item.id, '', colspan, item.label, color, 'category')
      await this.updateTileData(item, this.rows)
      this.editItemDialogOpen = false
    },

    async onChangeProductOnPanel (item, colspan, color) {
      this.addItemToPanel(item.id, item.parent_id, colspan, item.label, color, 'product')
      await this.updateTileData(item, this.rows)
      this.editItemDialogOpen = false
    },

    addItemToPanel (id, categoryId, colspan, name, backgroundColor, type) {
      this.selectedTile.colspan = colspan
      this.selectedTile.id = id
      this.selectedTile.parent_id = categoryId
      this.selectedTile.label = name
      this.selectedTile.type = type
      this.selectedTile.active = true
      this.selectedTile.color = backgroundColor
    },

    async onAddCategoryToPanel (category, colspan, backgroundColor) {
      this.addItemToPanel(category.id, '', colspan, category.name, backgroundColor, 'category')
      await this.updateTileData(this.selectedTile, this.rows)
    },

    async onAddProductToPanel (product, category, colspan, backgroundColor) {
      this.addItemToPanel(product.id, category.id, colspan, product.name, backgroundColor, 'product')
      if (product.products) {
        this.selectedTile.type = 'menu-product'
      }
      await this.updateTileData(this.selectedTile, this.rows)
    },

    onAddItemDialogClose () {
      this.addItemDialogOpen = false
    },

    onEditItemDialogClose () {
      this.editItemDialogOpen = false
    },

    updateTile (rowIndex, colIndex, props) {
      this.rows[rowIndex].cols[colIndex] = {
        active: props.active,
        row_index: rowIndex,
        col_index: colIndex,
        color: 'primary',
        colspan: props.colspan,
        id: props.id,
        label: props.label,
        type: props.type
      }
      this.$forceUpdate()
    },

    markDeleted (cols, tileIndex) {
      const item = cols[tileIndex]
      item.active = false
      item.color = 'primary'
      item.colspan = 1
      item.id = ''
      item.label = ''
      item.type = ''
      item.parent_id = ''
    },

    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },

    async getPanelParams () {
      const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      this.rowCount = register.data().panel_rows ? register.data().panel_rows : 6
      this.colCount = register.data().panel_cols ? register.data().panel_cols : 6
      this.colspan = register.data().panel_colspan ? register.data().panel_colspan : 12 / this.colCount

      state.setPanelRows(this.rowCount)
      state.setPanelCols(this.colCount)
      state.setPanelColspan(this.colspan)
    }
  },

  async created () {
    this.getCategories()
  },

  async mounted () {
    await this.getPanelParams()
    this.setupTiles()
    await this.fillTiles()

    EventBus.$on('set-mode', (editMode) => {
      this.editMode = editMode
    })

    EventBus.$on('adjust-template', () => {
      this.rowCount = state.getPanelRows()
      this.colCount = state.getPanelCols()
      this.colspan = state.getPanelColspan()
      this.rows = []
      this.setupTiles()
      this.fillTiles()
    })
  },

  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  }
}
</script>

<style scoped>
.panel-cell {
  padding: 0;
  border: 1px solid lightgray;
}
.label {
  color: white;
}
</style>
