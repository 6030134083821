var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mb-1"},[_c('v-col',{staticStyle:{"max-height":"3.5rem","margin":"0","padding":"0 1px 1px 0"},attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltipDelay,"open-on-hover":true,"open-on-click":false,"open-on-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticStyle:{"border-radius":"12px"},attrs:{"block":"","tile":"","depressed":"","color":"purple","width":"100%","height":"3.4rem"},on:{"click":function($event){return _vm.$emit('openSearchItemsDialog')}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-magnify")])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("$vuetify.tooltips.searchItems")))])])],1),_c('v-col',{staticStyle:{"max-height":"3.5rem","margin":"0","padding":"0 1px 1px 0"},attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltipDelay,"open-on-hover":true,"open-on-click":false,"open-on-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('div',_vm._g({},Object.assign({}, onMenu, onTooltip)),[_c('v-btn',_vm._b({class:['white--text', { purple: true }],staticStyle:{"border-radius":"12px"},style:(_vm.items && _vm.items.length > 0
                    ? { 'font-weight': 'bold' }
                    : {
                        'background-color': '#E0E0E0 !important',
                        'font-weight': 'bold',
                      }),attrs:{"block":"","tile":"","depressed":"","color":"white","width":"100%","height":"3.4rem","icon":"","disabled":_vm.items && _vm.items.length > 0 ? false : true}},'v-btn',attrs,false),[_vm._v(" Wolt ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("$vuetify.tooltips.wolt")))])])]}}])},[_c('v-list',_vm._l((_vm.paymentMenu),function(menu,idx){return _c('v-list-item',{key:idx,on:{"click":function($event){return _vm.menuPaymentClick(menu, 'wolt')}}},[(menu.type === 'G' || menu.type === 'V')?_c('v-list-item-title',[_vm._v(_vm._s(menu.text))]):_c('v-list-item-title',[_vm._v(_vm._s(menu.text)+" ")])],1)}),1)],1)],1),_c('v-col',{staticStyle:{"max-height":"3.5rem","margin":"0","padding":"0 1px 1px 0"},attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var onMenu = ref.on;
                      var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltipDelay,"open-on-hover":true,"open-on-click":false,"open-on-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var onTooltip = ref.on;
return [_c('div',_vm._g({},Object.assign({}, onMenu, onTooltip)),[_c('v-btn',_vm._b({class:['white--text', { purple: true }],staticStyle:{"border-radius":"12px"},style:(_vm.items && _vm.items.length > 0
                    ? { 'font-weight': 'bold' }
                    : {
                        'font-weight': 'bold',
                        'background-color': '#E0E0E0 !important',
                      }),attrs:{"block":"","tile":"","depressed":"","color":"white","width":"100%","height":"3.4rem","icon":"","disabled":_vm.items && _vm.items.length > 0 ? false : true}},'v-btn',attrs,false),[_vm._v(" Bolt ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("$vuetify.tooltips.bolt")))])])]}}])},[_c('v-list',_vm._l((_vm.paymentMenu),function(menu,idx){return _c('v-list-item',{key:idx,on:{"click":function($event){return _vm.menuPaymentClick(menu, 'bolt')}}},[(menu.type === 'G' || menu.type === 'V')?_c('v-list-item-title',[_vm._v(_vm._s(menu.text))]):_c('v-list-item-title',[_vm._v(_vm._s(menu.text)+" ")])],1)}),1)],1)],1),_c('v-col',{staticStyle:{"max-height":"3.5rem","margin":"0","padding":"0 1px 1px 0"},attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var onMenu = ref.on;
                      var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltipDelay,"open-on-hover":true,"open-on-click":false,"open-on-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var onTooltip = ref.on;
return [_c('div',_vm._g({},Object.assign({}, onMenu, onTooltip)),[_c('v-btn',_vm._b({class:['white--text', { purple: true }],staticStyle:{"border-radius":"12px"},style:(_vm.items && _vm.items.length > 0
                    ? { 'font-weight': 'bold' }
                    : {
                        'font-weight': 'bold',
                        'background-color': '#E0E0E0 !important',
                      }),attrs:{"block":"","tile":"","depressed":"","color":"white","width":"100%","height":"3.4rem","icon":"","disabled":_vm.items && _vm.items.length > 0 ? false : true}},'v-btn',attrs,false),[_vm._v(" Glovo ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("$vuetify.tooltips.glovo")))])])]}}])},[_c('v-list',_vm._l((_vm.paymentMenu),function(menu,idx){return _c('v-list-item',{key:idx,on:{"click":function($event){return _vm.menuPaymentClick(menu, 'glovo')}}},[(menu.type === 'G' || menu.type === 'V')?_c('v-list-item-title',[_vm._v(_vm._s(menu.text))]):_c('v-list-item-title',[_vm._v(_vm._s(menu.text)+" ")])],1)}),1)],1)],1),_c('v-col',{staticStyle:{"max-height":"3.5rem","margin":"0","padding":"0 1px 1px 0"},attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltipDelay,"open-on-hover":true,"open-on-click":false,"open-on-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticStyle:{"border-radius":"12px"},attrs:{"block":"","tile":"","depressed":"","color":"purple","width":"100%","height":"3.4rem"},on:{"click":function($event){return _vm.$emit('openReceiptsDialog')}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-receipt-text")])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("$vuetify.tooltips.receiptsDialog")))])])],1),_c('v-col',{staticStyle:{"max-height":"3.5rem","margin":"0","padding":"0 1px 1px 0"},attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltipDelay,"open-on-hover":true,"open-on-click":false,"open-on-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticStyle:{"border-radius":"12px"},attrs:{"block":"","tile":"","depressed":"","color":"purple","width":"100%","height":"3.4rem"},on:{"click":function($event){return _vm.$emit('openCloseBalanceDialog')}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-cash-register")])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("$vuetify.tooltips.closeBalance")))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }