<template>
  <div>
    <v-row>
      <v-col align="start" cols="5">
        <h2 style="margin-left: 1rem; text-transform: uppercase">
          {{ `${selectedTable.name}` }}
        </h2>
      </v-col>
      <v-col class="pt-0" cols="7">
        <h4 style="text-transform: uppercase">
          Popust:&nbsp;
          {{ $options.filters.money(totalDiscount, 100, currency) }}
        </h4>
        <h3 style="text-transform: uppercase">
          {{ $t("$vuetify.receipts.receiptPrice") }}:
          {{ $options.filters.money(total, 100, currency) }}&nbsp;&nbsp;
          {{ $options.filters.money(hrkTotalDiscount, 100, "HRK") }}
        </h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EventBus from '@/plugins/event-bus'
import { clone } from '@/plugins/utils'

export default {
  data () {
    return {
      data: { total: 0 },
      selectedTable: { name: '' },
      currency: 'EUR'
    }
  },
  computed: {
    hrkTotalDiscount () {
      const m = 100.00
      const temp = this.data.total > 0 ? this.data.total * 100 : this.data.total

      const inFloat = temp.toFixed(2)

      const trueVal = inFloat / 100
      let tConv = 0
      if (this.currency === 'HRK') {
        tConv = trueVal.toFixed(2) * 7.53450
      } else if (this.currency === 'EUR') {
        tConv = trueVal.toFixed(2) * 7.53450
      }

      const tOut = Math.round(tConv * m) / 100
      return tOut.toFixed(2)
    },
    total () {
      return this.data.total
    },
    totalDiscount () {
      return this.data.fullTotal - this.data.total
    }
  },

  mounted () {
    EventBus.$on('adjust-template', () => {
      // const rowCount = state.getPanelRows()
    })

    EventBus.$on('cart-has-changed', (data) => {
      this.data = clone(data)
    })

    EventBus.$on('update-table-selection', (table) => {
      this.selectedTable = table
    })
  }
}
</script>
