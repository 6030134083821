var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.ordersView)?_c('v-data-table',{staticClass:"invoice-table",attrs:{"height":_vm.tableHeight,"fixed-header":"","headers":_vm.headers,"items":_vm.invoice.items,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"border-left":"0","height":"3.4rem"},attrs:{"dense":"","flat":"","outlined":""}},[_c('span',{staticStyle:{"font-weight":"bold","text-transform":"capitalize","font-size":"16px","padding":"0 20px 0 0"}},[_vm._v(_vm._s(_vm.selectedTable.user_name ? _vm.selectedTable.user_name : ""))]),_c('v-spacer'),_c('v-toolbar-title',[_c('div',{staticStyle:{"padding-top":"1.5rem","padding-right":"2rem","padding-left":"2rem"}},[_c('v-switch',{on:{"change":_vm.onOrdersChange},model:{value:(_vm.switchOnOrdersTable),callback:function ($$v) {_vm.switchOnOrdersTable=$$v},expression:"switchOnOrdersTable"}})],1)])],1)]},proxy:true},{key:"header.controls",fn:function(ref){return [_c('v-row',{staticClass:"justify-end pr-1"},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_vm._l((_vm.generalMenu),function(menu,idx){return _c('v-list-item',{key:idx,on:{"click":function($event){return _vm.generalMenuClick(menu.method)}}},[_c('v-list-item-title',[_vm._v(_vm._s(menu.text))])],1)}),(_vm.lastInsertBy && _vm.lastInsertBy !== '' ? true : false)?_c('v-list-item',[_c('v-list-item-title',[_vm._v("Zadnje dodao "+_vm._s(_vm.$options.filters.capitalize(_vm.lastInsertBy)))])],1):_vm._e()],2)],1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(!_vm.showEdit(item))?_c('div',{staticClass:"invoice-row",staticStyle:{"min-width":"18rem","max-width":"22rem"},style:(_vm.showPencil(item)
                ? { 'text-decoration': 'underline', cursor: 'pointer' }
                : { 'text-decoration': 'none' }),on:{"mouseover":function($event){return _vm.onNameMouseover(item)},"mouseleave":function($event){return _vm.onNameMouseover(item)},"click":function($event){return _vm.onNameClick(item)}}},[_vm._v(" "+_vm._s(item.name)+" "),(_vm.showPencil(item))?_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-pencil")]):_vm._e()],1):_c('div',[_c('v-text-field',{staticClass:"invoice-row",attrs:{"autofocus":"","append-icon":"mdi-check"},on:{"click:append":function($event){return _vm.finishEdit(item)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.finishEdit(item)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)]}},{key:"item.selling_unit",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"invoice-row"},[_vm._v(" "+_vm._s(item.selling_unit)+" ")])]}},{key:"item.unitPrice",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"invoice-row"},[_vm._v(" "+_vm._s(_vm.$options.filters.money(item.unitPrice, 100, _vm.currency))+" ")])]}},{key:"item.discountedUnitPrice",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"invoice-row"},[_vm._v(" "+_vm._s(_vm.$options.filters.money(item.discountedUnitPrice, 100, _vm.currency))+" ")])]}},{key:"item.total",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"invoice-row"},[_vm._v(" "+_vm._s(_vm.$options.filters.money(item.total, 100, _vm.currency))+" ")])]}},{key:"item.quantity",fn:function(ref){
                var item = ref.item;
return [_c('v-text-field',{staticClass:"invoice-row",staticStyle:{"max-width":"2rem","height":"24px !important","margin-top":"-28px"},attrs:{"disabled":item.type && item.type === 'menu-product'},on:{"change":function($event){return _vm.recalculate()}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.controls",fn:function(props){return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"icon":"","disabled":!props.item || props.item.type === 'menu-product',"color":"red"},on:{"click":function($event){return _vm.onRowBtnClick(props.item, 'decrement')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-minus")])],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"icon":"","disabled":!props.item || props.item.type === 'menu-product',"color":"red"},on:{"click":function($event){return _vm.onRowBtnClick(props.item, 'increment')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"mr-1",attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"bottom":"","right":"","transition":"scale-transition","max-width":"170px","min-width":"170px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((props.item.menu),function(menu,idx){return _c('v-list-item',{key:idx,on:{"click":function($event){return _vm.itemMenuClick(menu.method, props.item)}}},[_c('v-list-item-content',[_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(menu.text)+" ")])],1)],1)],1)}),1)],1)],1)],1)],1)]}}],null,true)}):_vm._e(),(_vm.selectedTable && _vm.ordersView)?_c('v-data-table',{staticClass:"invoice-table",attrs:{"headers":_vm.orderHeaders,"items":_vm.selectedTable.orders,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"hide-default-footer":"","fixed-header":"","items-per-page":-1,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","flat":"","outlined":"","rounded":"","shaped":""}},[_c('span',{staticStyle:{"font-weight":"bold","text-transform":"capitalize","font-size":"16px","padding":"0 20px 0 0"}},[_vm._v(_vm._s(_vm.selectedTable.user_name ? _vm.selectedTable.user_name : ""))]),_c('v-spacer'),_c('v-toolbar-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"padding-top":"1.5rem","padding-right":"2rem","padding-left":"2rem"}},on),[_c('v-switch',{on:{"change":_vm.onOrdersChange},model:{value:(_vm.switchOnOrdersTable),callback:function ($$v) {_vm.switchOnOrdersTable=$$v},expression:"switchOnOrdersTable"}})],1)]}}],null,false,1496363068)},[_c('span',[_vm._v(_vm._s(_vm.switchOnOrdersTable ? "Narudžbe" : "Blagajna"))])])],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
                var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.orderHeaders.length}},[_c('v-row',{staticClass:"d-flex",attrs:{"flat":""}},[_c('v-col',{staticClass:"d-flex justify-center pt-5 pl-5",attrs:{"cols":"4"}},[_vm._v("Ime artikla")]),_c('v-col',{staticClass:"d-flex justify-center pt-5 pr-5 pl-5"},[_vm._v("Količina artikla")]),_c('v-col',{staticClass:"d-flex justify-center pt-5",attrs:{"cols":"4"}},[_vm._v("Jedinična cijena")])],1),_vm._l((item.order_items),function(it,index){return _c('v-row',{key:item.id + it.id + index,staticClass:"d-flex",attrs:{"flat":""}},[_c('v-col',{staticClass:"d-flex justify-center pt-5 pl-5",attrs:{"cols":"4"}},[_vm._v(_vm._s(it.name))]),_c('v-col',{staticClass:"d-flex justify-center pt-5 pr-5 pl-5",attrs:{"cols":"4"}},[_vm._v(_vm._s(it.quantity))]),_c('v-col',{staticClass:"d-flex justify-center pt-5",attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.$options.filters.money(it.discountedUnitPrice, 100, _vm.currency)))])],1)})],2)]}}],null,false,1059668447)}):_vm._e()],1)],1),_c('add-discount-form',{ref:"addDiscountForm",on:{"add":_vm.onAddDiscount}}),_c('add-note-dialog',{ref:"addNoteDialog"}),_c('add-note-article-dialog',{ref:"addNoteArticleDialog"}),_c('show-error',{ref:"showError"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }