<template>
  <v-row class="mb-1">
    <v-col cols="2" style="max-height: 3.5rem; margin: 0; padding: 0 1px 1px 0">
      <v-tooltip
        bottom
        :open-delay="tooltipDelay"
        :open-on-hover="true"
        :open-on-click="false"
        :open-on-focus="false"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <v-btn
              style="border-radius: 12px"
              block
              tile
              depressed
              color="purple"
              width="100%"
              height="3.4rem"
              @click="$emit('openSearchItemsDialog')"
            >
              <v-icon color="white">mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
        <span>{{ $t("$vuetify.tooltips.searchItems") }}</span>
      </v-tooltip>
    </v-col>
    <v-col cols="2" style="max-height: 3.5rem; margin: 0; padding: 0 1px 1px 0">
      <v-menu bottom right>
        <template v-slot:activator="{ on: onMenu, attrs }">
          <v-tooltip
            bottom
            :open-delay="tooltipDelay"
            :open-on-hover="true"
            :open-on-click="false"
            :open-on-focus="false"
          >
            <template #activator="{ on: onTooltip }">
              <div v-on="{ ...onMenu, ...onTooltip }">
                <v-btn
                  style="border-radius: 12px"
                  block
                  tile
                  depressed
                  color="white"
                  width="100%"
                  height="3.4rem"
                  icon
                  v-bind="attrs"
                  :style="
                    items && items.length > 0
                      ? { 'font-weight': 'bold' }
                      : {
                          'background-color': '#E0E0E0 !important',
                          'font-weight': 'bold',
                        }
                  "
                  :disabled="items && items.length > 0 ? false : true"
                  :class="['white--text', { purple: true }]"
                >
                  Wolt
                </v-btn>
              </div>
            </template>
            <span>{{ $t("$vuetify.tooltips.wolt") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item
            v-for="(menu, idx) in paymentMenu"
            :key="idx"
            @click="menuPaymentClick(menu, 'wolt')"
          >
            <v-list-item-title v-if="menu.type === 'G' || menu.type === 'V'">{{
              menu.text
            }}</v-list-item-title>
            <v-list-item-title v-else>{{ menu.text }} </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="2" style="max-height: 3.5rem; margin: 0; padding: 0 1px 1px 0">
      <v-menu bottom right>
        <template v-slot:activator="{ on: onMenu, attrs }">
          <v-tooltip
            bottom
            :open-delay="tooltipDelay"
            :open-on-hover="true"
            :open-on-click="false"
            :open-on-focus="false"
          >
            <template #activator="{ on: onTooltip }">
              <div v-on="{ ...onMenu, ...onTooltip }">
                <v-btn
                  style="border-radius: 12px"
                  block
                  tile
                  depressed
                  color="white"
                  width="100%"
                  height="3.4rem"
                  icon
                  v-bind="attrs"
                  :style="
                    items && items.length > 0
                      ? { 'font-weight': 'bold' }
                      : {
                          'font-weight': 'bold',
                          'background-color': '#E0E0E0 !important',
                        }
                  "
                  :class="['white--text', { purple: true }]"
                  :disabled="items && items.length > 0 ? false : true"
                >
                  Bolt
                </v-btn>
              </div>
            </template>
            <span>{{ $t("$vuetify.tooltips.bolt") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item
            v-for="(menu, idx) in paymentMenu"
            :key="idx"
            @click="menuPaymentClick(menu, 'bolt')"
          >
            <v-list-item-title v-if="menu.type === 'G' || menu.type === 'V'">{{
              menu.text
            }}</v-list-item-title>
            <v-list-item-title v-else>{{ menu.text }} </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="2" style="max-height: 3.5rem; margin: 0; padding: 0 1px 1px 0">
      <v-menu bottom right>
        <template v-slot:activator="{ on: onMenu, attrs }">
          <v-tooltip
            bottom
            :open-delay="tooltipDelay"
            :open-on-hover="true"
            :open-on-click="false"
            :open-on-focus="false"
          >
            <template #activator="{ on: onTooltip }">
              <div v-on="{ ...onMenu, ...onTooltip }">
                <v-btn
                  style="border-radius: 12px"
                  block
                  tile
                  depressed
                  color="white"
                  width="100%"
                  height="3.4rem"
                  icon
                  v-bind="attrs"
                  :style="
                    items && items.length > 0
                      ? { 'font-weight': 'bold' }
                      : {
                          'font-weight': 'bold',
                          'background-color': '#E0E0E0 !important',
                        }
                  "
                  :disabled="items && items.length > 0 ? false : true"
                  :class="['white--text', { purple: true }]"
                >
                  Glovo
                </v-btn>
              </div>
            </template>
            <span>{{ $t("$vuetify.tooltips.glovo") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item
            v-for="(menu, idx) in paymentMenu"
            :key="idx"
            @click="menuPaymentClick(menu, 'glovo')"
          >
            <v-list-item-title v-if="menu.type === 'G' || menu.type === 'V'">{{
              menu.text
            }}</v-list-item-title>
            <v-list-item-title v-else>{{ menu.text }} </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

    <v-col cols="2" style="max-height: 3.5rem; margin: 0; padding: 0 1px 1px 0">
      <v-tooltip
        bottom
        :open-delay="tooltipDelay"
        :open-on-hover="true"
        :open-on-click="false"
        :open-on-focus="false"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <v-btn
              style="border-radius: 12px"
              block
              tile
              depressed
              color="purple"
              width="100%"
              height="3.4rem"
              @click="$emit('openReceiptsDialog')"
            >
              <v-icon color="white">mdi-receipt-text</v-icon>
            </v-btn>
          </div>
        </template>
        <span>{{ $t("$vuetify.tooltips.receiptsDialog") }}</span>
      </v-tooltip>
    </v-col>
    <v-col cols="2" style="max-height: 3.5rem; margin: 0; padding: 0 1px 1px 0">
      <v-tooltip
        bottom
        :open-delay="tooltipDelay"
        :open-on-hover="true"
        :open-on-click="false"
        :open-on-focus="false"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <v-btn
              style="border-radius: 12px"
              block
              tile
              depressed
              color="purple"
              width="100%"
              height="3.4rem"
              @click="$emit('openCloseBalanceDialog')"
            >
              <v-icon color="white">mdi-cash-register</v-icon>
            </v-btn>
          </div>
        </template>
        <span>{{ $t("$vuetify.tooltips.closeBalance") }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import state from '@/state'
import EventBus from '@/plugins/event-bus'

export default {
  data: function () {
    return {
      deliveries: [{
        name: 'Wolt', value: 'wolt'
      },
      {
        name: 'Glovo', value: 'glovo'
      },
      {
        name: 'Bolt Food', value: 'bolt'
      }],
      paymentMenu: [
        { text: this.$t('$vuetify.paymentMethods.cashMethod'), type: 'G', icon: 'mdi-cash' },
        { text: this.$t('$vuetify.paymentMethods.cardMethod'), type: 'K', icon: 'mdi-card' }
      ],
      tooltipDelay: 500
      // paymentMenu: [
      //   { text: this.$t('$vuetify.paymentMethods.cash'), type: 'G', icon: 'mdi-cash' },
      //   { text: this.$t('$vuetify.paymentMethods.card'), type: 'K', icon: 'mdi-card' }
      // ],
      // availableCards: [
      //   { text: 'Visa', type: 'VISA', icon: 'mdi-cash' },
      //   { text: 'Maestro', type: 'MAESTRO', icon: 'mdi-card' },
      //   { text: 'Master Card', type: 'MASTER', icon: 'mdi-card' },
      //   { text: 'American Express', type: 'AMERICAN', icon: 'mdi-card' }
      // ]
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    receiptNote: {
      type: Object,
      default: () => undefined
    }
  },
  methods: {
    openReceiptNoteDialog () {
      this.$refs.addReceiptNoteDialog.open(this.receiptNote)
    },
    menuPaymentClick (menu, deliveryService) {
      switch (menu.type) {
        case 'G':
          this.$emit('deliveryFinishSimplePayment', deliveryService, 'G')
          break
        case 'K':
          this.$emit('deliveryFinishSimplePayment', deliveryService, 'O')
          break
      }
    }
  },
  mounted () {
    EventBus.$off('open_receipts')
    EventBus.$on('open_receipts', () => {
      this.$emit('openReceiptsDialog')
    })
  },

  created () {
    this.tooltipDelay = state.getTooltipDelay()
  }
}
</script>
