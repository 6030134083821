<template>
  <div>
    <div>
      <v-container>
        <v-col
          class="d-flex justify-end"
          height="30px"
          style="margin-top: 10px"
        >
          <v-btn
            v-if="data.registerType != 'MP'"
            style="margin-right: 10px"
            class="white--text loginButton"
            :disabled="nextDisabled"
            @click="advanceStep('P')"
          >
            {{ $t("$vuetify.offer").split("|")[0] }}
          </v-btn>
          <v-btn
            class="white--text loginButton"
            style="margin-right: 10px"
            :disabled="nextDisabled"
            @click="advanceStep('R')"
          >
            {{ $t("$vuetify.receipt").split("|")[0] }}
          </v-btn>
          <!-- ZA PREDUJAM SAKRIVENO -->
          <!-- <v-menu
            bottom
            left
            flat
            rounded="false"
            nudge-bottom="60px"
            min-width="200px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="white--text loginButton"
                width="10%"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list style="min-width: 100%">
              <v-list-item :disabled="!nextDisabled" @click="openAvans">
                <v-list-item-title> Račun za predujam </v-list-item-title>
              </v-list-item>
              <v-list-item :disabled="nextDisabled" @click="openAvansSummary">
                <v-list-item-title>
                  Poveži račun za predujam
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
        </v-col>
        <v-row v-if="data.registerType != 'MP'">
          <v-col>
            <v-select
              tabindex="-1"
              :label="$t('$vuetify.currency')"
              :items="currencies"
              v-model="currency"
              @change="setCurrency"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="totalWithDiscount === 0" class="pb-0 mb-0">
          <v-col class="pb-0 mb-0">
            <p class="font-weight-bold pb-0 mb-0">{{ $t("$vuetify.total") }}</p>
          </v-col>
          <v-col class="pb-0 mb-0">
            <p class="font-weight-bold pb-0 mb-0">
              {{ total | money(100, currency) }}
            </p>
          </v-col>
        </v-row>

        <!-- ISPIS KONVERZIJE U EURO -->
        <v-row
          class="mt-0 pt-0 pb-0 mb-0"
          v-if="totalWithDiscount === 0 && currency === 'EUR'"
        >
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <p class="font-weight-bold mt-0 pt-0"></p>
          </v-col>
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <p class="font-weight-bold mt-0 pb-0 mb-0">
              {{ hrkTotal | money(100, "HRK") }}
            </p>
          </v-col>
        </v-row>
        <v-row
          class="mt-0 pt-0 pb-0 mb-0"
          v-if="totalWithDiscount === 0 && currency === 'HRK'"
        >
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <p class="font-weight-bold mt-0 pt-0"></p>
          </v-col>
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <p class="font-weight-bold mt-0 pb-0 mb-0">
              {{ eurTotal | money(100, "EUR") }}
            </p>
          </v-col>
        </v-row>
        <!--  -->

        <v-row class="pb-0 mb-0" v-if="totalWithDiscount > 0">
          <v-col class="pb-0 mb-0">
            <p class="font-weight-bold pt-3 pb-0 mb-0">
              {{ $t("$vuetify.total") }}
            </p>
          </v-col>
          <v-row class="pb-0 mb-0">
            <v-col class="pb-0 mb-0">
              <p class="font-weight-bold pb-0 mb-0">
                <s>{{ total | money(100, currency) }}</s
                ><br />
                {{ totalWithDiscount | money(100, currency) }}
              </p>
            </v-col>
          </v-row>
        </v-row>

        <!-- ISPIS KONVERZIJE U EURO -->
        <v-row
          class="mt-0 pt-0 mb-0 pb-0"
          v-if="totalWithDiscount > 0 && currency === 'EUR'"
        >
          <v-col class="mt-0 pt-0 mb-0 pb-0">
            <p class="font-weight-bold mt-0 pt-0 mb-0 pb-0"></p>
          </v-col>
          <v-row class="pb-0 mb-0">
            <v-col class="mt-0 pt-1 pl-1 mb-0 pb-0">
              <p class="font-weight-bold mt-0 mb-0 pb-0">
                {{ hrkTotalDiscount | money(100, "HRK") }}
              </p>
            </v-col>
          </v-row>
        </v-row>
        <v-row
          class="mt-0 pt-0 mb-0 pb-0"
          v-if="totalWithDiscount > 0 && currency === 'HRK'"
        >
          <v-col class="mt-0 pt-0 mb-0 pb-0">
            <p class="font-weight-bold mt-0 pt-0 mb-0 pb-0"></p>
          </v-col>
          <v-row class="pb-0 mb-0">
            <v-col class="mt-0 pt-1 pl-1 mb-0 pb-0">
              <p class="font-weight-bold mt-0 mb-0 pb-0">
                {{ eurTotalDiscount | money(100, "EUR") }}
              </p>
            </v-col>
          </v-row>
        </v-row>
        <!--  -->

        <v-row v-if="totalWithDiscount > 0" class="d-flex">
          <p class="font-weight-bold pl-3 pr-4">
            {{ capitalizeFirst(basketDiscount.name) }}
          </p>
          <v-btn
            style="width: 50%; font-size: 11px"
            small
            plain
            outlined
            color="primary"
            @click="removeDiscount(true)"
          >
            <v-icon>mdi-minus</v-icon>
            Ukloni popust
          </v-btn>
        </v-row>
        <v-row
          class="justify-start pl-2"
          v-if="total > 0 && totalWithDiscount === 0"
        >
          <!-- Hardkodirano samo da se ne mice previse, potrebno ispraviti -->
          <p class="font-weight-bold pl-3 pr-4" style="color: white">
            ovo je sam
          </p>
          <v-btn
            small
            plain
            outlined
            color="primary"
            v-if="cashReg.type != 'VP'"
            style="width: 50%; font-size: 11px"
            @click="addDiscount(true)"
          >
            <v-icon>mdi-percent</v-icon>
            Dodaj popust
          </v-btn>
        </v-row>
        <v-row class="justify-start" v-if="total > 0 && totalWithDiscount > 0">
        </v-row>
      </v-container>
    </div>
    <v-simple-table fixed-header class="dynamicTable" height="350px">
      <template v-slot:default>
        <tbody>
          <v-list v-if="items.length > 0" dense class="py-0">
            <template v-for="(item, i) in items.slice().reverse()">
              <div :key="i" :class="{ 'deletable-div ': hover === i }">
                <v-divider v-if="i > 0"></v-divider>
                <v-list-item dense>
                  <v-avatar :size="$vuetify.breakpoint.mdAndUp ? '55' : '45'">
                    <v-img
                      v-if="item.picture"
                      :src="item.picture"
                      contain
                    ></v-img>
                    <v-img v-else src="@/assets/no-item.jpg"></v-img>
                  </v-avatar>
                  <v-list-item-content
                    class="item-details align-start pl-3 d-flex justify-center"
                    style="max-width: 60% !important"
                  >
                    <div class="font-weight-bold pl-1 text-subtitle mt-1">
                      {{ capitalizeFirst(item.name) }}
                    </div>
                    <v-list-item-subtitle
                      class="pl-1"
                      v-if="
                        item.discounted_price === undefined ||
                        item.discounted_price === 0
                      "
                    >
                      {{ item.prices[currency].price | money(100, currency) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="pl-1"
                      v-if="item.discounted_price > 0"
                    >
                      <s>{{
                        item.prices[currency].price | money(100, currency)
                      }}</s>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="pl-1"
                      v-if="item.discounted_price > 0"
                    >
                      {{ item.discounted_price | money(100, currency) }}
                    </v-list-item-subtitle>
                    <div :style="`width: ${priceInputWidth}`">
                      <quantity-input
                        dense
                        v-model="item.quantity"
                        autocomplete="off"
                        class="centered-input"
                        :val="item.quantity"
                        ref="quantityInput"
                      >
                        <template v-slot:prepend>
                          <v-icon
                            color="red"
                            hover
                            dense
                            @click="reduceQuantity(item)"
                          >
                            mdi-minus
                          </v-icon>
                        </template>
                        <template v-slot:append-outer>
                          <v-icon
                            color="green"
                            hover
                            dense
                            @click="increaseQuantity(item)"
                          >
                            mdi-plus
                          </v-icon>
                        </template>
                      </quantity-input>
                    </div>
                  </v-list-item-content>
                  <v-col cols="1" class="pa-0">
                    <v-btn
                      v-if="
                        total > 0 &&
                        (item.discounted_price === undefined ||
                          item.discounted_price === 0)
                      "
                      tabindex="-1"
                      icon
                      text
                      @click="addDiscount(false, item.id)"
                      class="disBtn pa-1"
                      @mouseover="hover = i"
                      @mouseleave="hover = -1"
                    >
                      <v-icon class="disBtn" size="25">mdi-percent</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="total > 0 && item.discounted_price > 0"
                      tabindex="-1"
                      icon
                      text
                      @click="removeDiscount(false, item.id)"
                      class="minBtn"
                      @mouseover="hover = i"
                      @mouseleave="hover = -1"
                    >
                      <v-icon class="minBtn" size="25">mdi-minus</v-icon>
                    </v-btn>
                    <v-btn
                      tabindex="-1"
                      icon
                      text
                      @click="removeItemFromBasket(item)"
                      class="delBtn"
                      @mouseover="hover = i"
                      @mouseleave="hover = -1"
                    >
                      <v-icon class="delBtn" size="25">mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-list-item>
                <v-divider v-if="i == items.length - 1"></v-divider>
              </div>
            </template>
          </v-list>

          <v-container v-else class="d-flex justify-center align-center">
            <span class="text-title">Dodajte artikle u košaricu</span>
          </v-container>
        </tbody>
        <add-discount-form
          @add="onAddDiscount"
          ref="addDiscountForm"
        ></add-discount-form>
      </template>
    </v-simple-table>
    <avans-dialog ref="avans"></avans-dialog>
    <avans-summary ref="avansSummary"></avans-summary>
  </div>
</template>
<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import QuantityInput from '@/components/QuantityInput'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import AddDiscountForm from './AddDiscountForm.vue'
import AvansDialog from '@/modules/cash-register/components/dialogs/avans/AvansDialog.vue'
import AvansSummary from '@/modules/cash-register/components/dialogs/avans/AvansSummary.vue'

export default {
  props: ['data'],
  mixins: [applicationSettings],
  components: {
    QuantityInput,
    AddDiscountForm,
    AvansDialog,
    AvansSummary
  },
  inject: ['showMsgBox'],
  data: function () {
    return {
      hover: -1,
      items: [],
      currencies: [],
      currency: undefined,
      totalWithDiscount: 0,
      basketDiscount: undefined,
      listener: [],
      totalShoppingCart: undefined,
      totalWithoutDiscounts: undefined

    }
  },
  computed: {
    cashReg () {
      return state.getCashRegister()
    },
    total () {
      // console.log(this.data.registerType !== 'MP')
      // console.log(this.data.registerType)
      // console.log(this.data)
      // console.log(this.items.reduce((acc, val) => acc + parseInt((this.truncateDecimals(parseFloat(val?.quantity?.toString()?.replace(',', '.')), 3)) * val.prices[this.currency].price) || 0, 0))
      // return this.items.reduce((acc, val) => acc + ((this.truncateDecimals(parseFloat(val?.quantity?.toString()?.replace(',', '.')), 3)) * val.prices[this.currency].price).toFixed(2) || 0, 0)

      // last compute
      // return this.items.reduce((acc, val) => (acc + parseFloat(val?.quantity?.toString()?.replace(',', '.')) * val.prices[this.currency].price) || 0, 0)
      this.calculateAllDiscounts()
      return this.totalShoppingCart
    },
    priceInputWidth () {
      return this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.smAndDown ? '50%' : '100%'
    },
    nextDisabled () {
      return !this.total || !this.allQuantitiesSet()
    },
    hrkTotal () {
      const m = 100.00
      const inFloat = this.total.toFixed(2)
      const trueVal = inFloat / 100
      const tConv = trueVal.toFixed(2) * 7.53450
      const tOut = Math.round(tConv * m)
      return tOut.toFixed(2)
    },
    hrkTotalDiscount () {
      const m = 100.00
      const inFloat = this.totalWithDiscount.toFixed(2)
      const trueVal = inFloat / 100
      const tConv = trueVal.toFixed(2) * 7.53450
      const tOut = Math.round(tConv * m)
      return tOut.toFixed(2)
    },
    eurTotal () {
      const m = 100.00
      const inFloat = this.total.toFixed(2)
      const trueVal = inFloat / 100
      const tConv = trueVal.toFixed(2) / 7.53450
      const tOut = Math.round(tConv * m)
      return tOut.toFixed(2)
    },
    eurTotalDiscount () {
      const m = 100.00
      const inFloat = this.totalWithDiscount.toFixed(2)
      const trueVal = inFloat / 100
      const tConv = trueVal.toFixed(2) / 7.53450
      const tOut = Math.round(tConv * m)
      return tOut.toFixed(2)
    }
  },
  watch: {
    data (val) {
      if (!val || !val.items) {
        this.items = []
      }
    },
    totalWithDiscount (val) {
    }
  },
  mounted () {
    this.getMultiplier()
    this.currencies = [
      'HRK', 'EUR', 'USD', 'GBP'
    ]
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }

    EventBus.$on('add-basket-item', item => this.addItem(item))
    EventBus.$on('add-write-in-item', item => this.addWriteInItem(item))
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  },
  methods: {
    openAvansSummary () {
      this.$refs.avansSummary.open(null, null, this.items, this.basketDiscount)
    },
    openAvans () {
      this.$refs.avans.open()
    },
    resetDiscountTotal () {
      this.totalWithDiscount = 0
    },
    removeDiscount (basketDiscount = false, itemID) {
      if (basketDiscount) {
        this.totalWithDiscount = 0
        this.basketDiscount = undefined
      } else {
        this.items.forEach((item, key) => {
          if (item.id === itemID) {
            this.items[key].discount = null
            this.items[key].discounted_price = 0
            this.items[key].showDiscounted = false
          }
        })

        const tempItems = this.items
        this.items = []
        this.items = tempItems
      }
    },
    onAddDiscount (discount) {
      if (discount !== undefined) {
        if (discount.basketDiscount === true) {
          switch (discount.type) {
            case 'RATE': {
              const temp = ((this.total / 100) * (discount.rate / 100))
              // temp = Math.round(temp * 100)

              this.totalWithDiscount = this.total - temp
              this.basketDiscount = discount
              this.basketDiscount.amount = temp

              if (this.totalWithDiscount <= 0) {
                this.basketDiscount = undefined
                this.totalWithDiscount = undefined
                this.showMsgBox({ text: 'Popust je veći od ukupne cijene, te se ne može primjeniti.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
              }
              break
            }
            case 'AMOUNT':
              this.totalWithDiscount = this.total - discount.amount
              this.basketDiscount = discount
              this.basketDiscount.rate = (discount.amount / this.total) * 100

              if (this.totalWithDiscount <= 0) {
                this.basketDiscount = undefined
                this.totalWithDiscount = undefined
                this.showMsgBox({ text: 'Popust je veći od ukupne cijene, te se ne može primjeniti.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
              }
              break
          }
        } else {
          this.items.forEach((item, key) => {
            if (item.id === discount.itemID) {
              this.items[key].discount = discount

              switch (discount.type) {
                case 'RATE': {
                  // const temp = ((this.items[key].prices[this.currency].price / 100) * (discount.rate / 100))
                  const temp = Math.round((this.items[key].prices[this.currency].price) - ((this.items[key].prices[this.currency].price * (discount.rate / 10000))))

                  this.items[key].discounted_price = temp
                  this.items[key].discount.amount = this.items[key].prices[this.currency].price - this.items[key].discounted_price

                  // this.items[key].discount.amount = temp
                  // this.items[key].discounted_price = this.items[key].prices[this.currency].price - temp

                  if (this.items[key].discounted_price <= 0) {
                    this.items[key].discount = undefined
                    this.items[key].discounted_price = 0
                    this.showMsgBox({ text: 'Popust je veći od cijene artikla, te se ne može primjeniti.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
                  }
                  break
                }
                case 'AMOUNT':
                  this.items[key].discounted_price = this.items[key].prices[this.currency].price - discount.amount
                  this.items[key].discount.rate = ((discount.amount / this.items[key].prices[this.currency].price) * 100) * 100
                  if (this.items[key].discounted_price <= 0) {
                    this.items[key].discount = undefined
                    this.items[key].discounted_price = 0
                    this.showMsgBox({ text: 'Popust je veći od cijene artikla, te se ne može primjeniti.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
                  }
                  break
              }
            }
          })

          this.calculateAllDiscounts(true)
        }
      }
    },
    calculateAllDiscounts (dd = false) {
      this.totalShoppingCart = 0
      this.totalWithoutDiscounts = 0
      this.totalWithDiscount = 0
      let dodatna = 0

      this.items.forEach((item, key) => {
        if (item.quantity !== undefined && parseFloat(item?.quantity?.toString()?.replace(',', '.')) > 0) {
          const tmpd = parseFloat(item?.quantity?.toString()?.replace(',', '.'))
          var ad = 0

          if (item.discount !== undefined && item.discounted_price !== undefined && item.discounted_price > 0) {
            ad = item.discounted_price * tmpd
          } else {
            ad = item.prices[this.currency].price * tmpd
          }

          var tempPriceWithoutDiscount = item.prices[this.currency].price * tmpd
          this.totalWithoutDiscounts += tempPriceWithoutDiscount
          this.totalShoppingCart += ad
        }
      })

      if (this.basketDiscount !== undefined) {
        if (this.basketDiscount.type === 'RATE') {
          var temp = (this.totalShoppingCart / 100) * ((this.basketDiscount.rate / 100) / 100)
          this.basketDiscount.amount = Math.round(temp * 100)
          this.totalWithDiscount = this.totalShoppingCart - Math.round(temp * 100)
          dodatna = this.totalShoppingCart - Math.round(temp * 100)
        } else {
          if ((this.basketDiscount.amount) > this.totalShoppingCart) {
            this.basketDiscount.rate = 1000
            this.basketDiscount.amount = this.totalShoppingCart
          } else {
            const temp = (this.totalShoppingCart / 100) / (this.basketDiscount.amount / 100)
            this.basketDiscount.rate = Math.round(temp * 100)
            this.totalWithDiscount = this.totalShoppingCart - this.basketDiscount.amount
            dodatna = this.totalShoppingCart - this.basketDiscount.amount
          }
        }
      }

      if (this.totalWithDiscount <= 0) {
        if (dd && this.basketDiscount !== undefined) {
          this.showMsgBox({ text: 'Uklonjen je popust na košaricu jer prelazi iznos ukupnog računa, ukoliko želite možete dodati popust na košaricu.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        }
        this.basketDiscount = undefined
        this.totalWithDiscount = 0
        if (dodatna > 0) {
          this.totalWithDiscount = dodatna
        }
      }

      const tempItems = this.items
      this.items = []
      this.items = tempItems
    },
    addDiscount (mainBasket = false, itemID) {
      this.$refs.addDiscountForm.open(mainBasket, itemID, this.currency)
    },
    capitalizeFirst (name) {
      if (name !== undefined && name !== null && name.length > 2) {
        return name.charAt(0).toUpperCase() + name.slice(1)
      }
    },
    truncateDecimals (num, digits) {
      var numS = num.toString()
      var decPos = numS.indexOf('.')
      var substrLength = decPos === -1 ? numS.length : 1 + decPos + digits
      var trimmedResult = numS.substr(0, substrLength)
      var finalResult = isNaN(trimmedResult) ? 0 : trimmedResult

      return parseFloat(finalResult)
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    advanceStep (type) {
      this.items = this.items.map(item => {
        const ret = item
        ret.quantity = parseFloat(item?.quantity?.toString()?.replace(',', '.')) || 0
        return ret
      })

      // if ((this.total <= 125 && this.basketDiscount === undefined) || (this.totalWithDiscount <= 125 && this.basketDiscount !== undefined)) {
      //   this.showMsgBox({ text: 'Minimalan iznos košarice je 1,25 kn. Molimo promijenite ukupni iznos košarice.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
      //   return
      // }
      this.$emit('advanceStep', { items: this.items, currency: this.currency, total: this.total, type: type, title: type === 'P' ? this.$t('$vuetify.offer').split('|')[0] : this.$t('$vuetify.receipt').split('|')[0], totalWithDiscount: this.totalWithDiscount, fullTotal: this.totalWithoutDiscounts, totalShoppingCart: this.totalShoppingCart, discount: this.basketDiscount })
    },
    setCurrency () {
      this.$emit('currencyChanged', this.currency)
      this.items = []
    },
    addItem (item) {
      // console.log(item)
      if (item.manual_price_input) {
        if (this.items.some(it => it.name === item.name && it.prices[this.currency].price === item.prices[this.currency].price)) {
          this.items.map(it => {
            if (it.name === item.name && it.prices[this.currency].price === item.prices[this.currency].price) {
              it.quantity = parseFloat(it.quantity.toString().replace(',', '.')) + 1.000
              it.quantity = it.quantity.toFixed(3).replace('.', ',')
            }
            return it
          })
        } else {
          this.items.push({ ...item, ...{ quantity: 1.000.toFixed(3).replace('.', ',') } })
        }
      } else {
        if (this.items.some(it => it.name === item.name)) {
          this.items.map(it => {
            if (it.name === item.name) {
              it.quantity = parseFloat(it.quantity.toString().replace(',', '.')) + 1.000
              it.quantity = it.quantity.toFixed(3).replace('.', ',')

              // TODO dodati ubacivanje ordera u item
              // it.choose
            }
            return it
          })
        } else {
          this.items.push({ ...item, ...{ quantity: 1.000.toFixed(3).replace('.', ',') } })
        }
      }
    },

    addWriteInItem (item) {
      if (this.items.some(i => i.name === item.name)) {
        this.items.map(i => {
          if (i.name === item.name) {
            i.quantity = parseFloat(i.quantity.toString().replace(',', '.')) + 1.000
            i.quantity = i.quantity.toFixed(3).replace('.', ',')
          }
          return i
        })
      } else {
        this.items.push(item)
      }
    },
    reduceQuantity (item) {
      this.items = this.items.map(it => {
        if (it.name === item.name && it.prices[this.currency].price === item.prices[this.currency].price) {
          // const decimals = it.quantity.toString().split(/[.|,]/)
          // let decimalPlaces = 0
          // if (decimals[1]) decimalPlaces = decimals[1].length
          it.quantity = it.quantity.toString().replace(',', '.') - 1 >= 0 ? it.quantity.toString().replace(',', '.') - 1 : 0
          // if (decimalPlaces) { it.quantity = it.quantity.toString().substr(0, decimals[0].length + decimals[1].length + 1) }
          if (it.quantity > 0) {
            it.quantity = it.quantity.toFixed(3).replace('.', ',')
          }
        }
        return it
      })
      this.items = this.items.filter(it => it.quantity)
    },

    increaseQuantity (item) {
      this.items = this.items.map(it => {
        if (it.name === item.name && it.prices[this.currency].price === item.prices[this.currency].price) {
          it.quantity = parseFloat(it.quantity.toString().replace(',', '.')) + 1.000
          it.quantity = it.quantity.toFixed(3).replace('.', ',')
        }
        return it
      })
    },
    removeItemFromBasket (item) {
      this.items = this.items.filter(it => it !== item)
      this.hover = -1
    },
    allQuantitiesSet () {
      if (!this.items || !Array.isArray(this.items)) return false
      return this.items.every(item => this.isCorrectQuantity(item.quantity))
    },
    isCorrectQuantity (quantity) {
      const priceRx = /^([0-9]+)$|^(([0-9]+)[,|.]{1}([0-9]+))$/
      return quantity.toString().match(priceRx)
    }
  }
}
</script>
<style scoped>
.deletable-div {
  opacity: 0.6;
}
.deletable-div .delBtn {
  font-size: 38px !important;
}
.item-details {
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.dynamicTable {
  height: auto;
  overflow: auto;
}

.centered-input >>> input {
  text-align: center;
}

.dynamicTable {
  height: auto;
  overflow: auto;
}

.deletable-div .minBtn {
  font-size: 38px !important;
}

.deletable-div .disBtn {
  font-size: 38px !important;
}
</style>
