<template>
  <v-dialog
    v-model="dialogOpen"
    v-if="dialogOpen"
    persistent
    max-width="770"
    @keydown.esc="close"
  >
    <v-card tile color="white">
      <v-card-title>
        <h2 class="text--secondary">Uredi kategoriju ili proizvod</h2>
        <v-spacer></v-spacer>
        <v-icon large @click="close">mdi-close</v-icon>
      </v-card-title>
    </v-card>
    <v-card-text class="white">
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="selectedCategory"
              :items="filteredCategories"
              placeholder="Kategorija"
              item-text="name"
              item-value="id"
              return-object
              @change="onSelectionChange('category')"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="selectedProduct"
              :items="filteredProducts"
              placeholder="Proizvod"
              item-text="name"
              item-value="id"
              return-object
              @change="onSelectionChange('product')"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3"></v-col>
          <v-col cols="6" class="pa-2">
            <v-btn
              style="display: inline-block; border-radius: 12px;"
              block
              tile
              depressed
              min-height="3.5rem"
              :color="backgroundColor"
            >
              <span class="text-wrap label" :style="`color: ${foregroundColor}`">{{ previewBtnText }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <h4 style="margin-bottom: 1rem">Boja pozadine</h4>
            <v-color-picker
              show-swatches
              :swatches="swatches"
              canvas-height="100"
              mode="hexa"
              v-model="backgroundColor"
            ></v-color-picker>
          </v-col>
          <v-col cols="6">
            <h4 style="margin-bottom: 1rem">Boja teksta</h4>
            <v-color-picker
              show-swatches
              :swatches="swatches"
              canvas-height="100"
              mode="hexa"
              v-model="foregroundColor"
            ></v-color-picker>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="white">
      <v-btn
        class="white--text okButton"
        height="45"
        @click="onAddItem('category')"
        :disabled="!categoryIsSelected"
      >
        <!-- :disabled="!categoryIsSelected" -->
        Spremi kategoriju
      </v-btn>
      <v-btn
        class="white--text okButton"
        height="45"
        @click="onAddItem('product')"
        :disabled="!productIsSelected"
      >
        <!-- :disabled="!productIsSelected" -->
        Spremi proizvod
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn class="white--text okButton" height="45" @click="onDeleteItem">
        Ukloni
      </v-btn>
    </v-card-actions>
  </v-dialog>
</template>

<script>
import { df } from '@/plugins/firebase'
import state from '@/state'

export default {
  data () {
    return {
      dialogOpen: false,
      products: [],
      selectedCategory: undefined,
      selectedProduct: undefined,
      categoryIsSelected: false,
      productIsSelected: false,
      colspan: 1,
      backgroundColor: {},
      foregroundColor: {},
      swatches: [
        ['#797979', '#494949', '#000000'],
        ['#C02DD8', '#9C21B0', '#721881'],
        ['#4996F3', '#3B78C3', '#2C5A90'],
        ['#D50000', '#BEC600', '#00BB0C'],
        ['#FF7301', '#00D5BD', '#E400B9']
      ],
      updatedItem: {},
      previewBtnText: ''
    }
  },
  props: {
    editItem: {
      type: Object,
      default: () => {}
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    filteredCategories () {
      return this.categories.filter(cat => cat.status === 'OK')
    },
    filteredProducts () {
      return this.products.filter(prod => prod.status === 'OK')
    }
  },
  watch: {
    color (newValue) {
    }
  },
  methods: {
    close () {
      this.$emit('editItemDialogClose')
    },
    onSelectionChange (type) {
      if (type === 'category') {
        // this.categoryIsSelected = true
        this.previewBtnText = this.selectedCategory.name
        this.getProductsByCategory(this.selectedCategory.id)
      } else {
        this.previewBtnText = this.selectedProduct.name
        // this.productIsSelected = true
      }
    },
    onAddItem (type) {
      // console.log(this.backgroundColor, this.foregroundColor)
      // console.log(this.selectedProduct)
      if (type === 'category') {
        this.updatedItem = {
          active: true,
          col_index: this.editItem.col_index,
          color: this.backgroundColor,
          foregroundColor: this.foregroundColor,
          colspan: this.colspan,
          id: this.selectedCategory.id,
          label: this.selectedCategory.name,
          row_index: this.editItem.row_index,
          type: 'category',
          parent_id: ''
        }

        this.$emit('changeCategoryOnPanel', this.updatedItem, this.colspan, this.backgroundColor, this.foregroundColor)
      } else {
        this.updatedItem = {
          active: true,
          col_index: this.editItem.col_index,
          color: this.backgroundColor,
          foregroundColor: this.foregroundColor,
          colspan: this.colspan,
          id: this.selectedProduct.id,
          label: this.selectedProduct.name,
          row_index: this.editItem.row_index,
          type: 'product',
          parent_id: this.selectedCategory.id
        }

        this.$emit('changeProductOnPanel', this.updatedItem, this.colspan, this.backgroundColor, this.foregroundColor)
      }
      // this.reset()
      this.$emit('addItemDialogClose')
    },
    onDeleteItem () {
      this.$emit('deleteItem', this.editItem)
    },
    // reset () {
    //   this.selectedCategory = {}
    //   this.selectedProduct = {}
    //   this.categoryIsSelected = false
    //   this.productIsSelected = false
    // },
    async getProductsByCategory (categoryId) {
      if (categoryId === '5692439e-4269-4fd9-875e-71e0e08a4e99') {
        const query = await df.collection(`location_menu_pricelist/${state.getPointOfSale().id}/items`)
          .where('status', '==', 'OK')
          .where('active', '==', true)
          // .where(`prices.${this.currency}.active`, '==', true)
          .get()

        const documents = query.docs.map(doc => doc.data())
        this.products = [...documents]
      } else {
        const query = await df.collection(`location_pricelist/${state.getPointOfSale().id}/items`)
          .where('category_ids', 'array-contains', `${categoryId}`)
          .where('status', '==', 'OK')
          .where('active', '==', true)
          // .where(`prices.${this.currency}.active`, '==', true)
          .get()

        const documents = query.docs.map(doc => doc.data())
        this.products = [...documents]
      }
    }
  },
  async mounted () {
    if (this.editItem.type === 'category') {
      this.selectedCategory = { name: this.editItem.label, id: this.editItem.id }
      await this.getProductsByCategory(this.selectedCategory.id)
    } else if (this.editItem.type === 'product') {
      this.selectedCategory = this.categories.find((cat) => cat.id === this.editItem.parent_id)
      await this.getProductsByCategory(this.editItem.parent_id)
      this.selectedProduct = { name: this.editItem.label, id: this.editItem.id }
    }
    this.backgroundColor = this.editItem.color
    this.foregroundColor = this.editItem.foregroundColor ?? '#ffffff'

    this.categoryIsSelected = this.selectedCategory !== undefined
    this.productIsSelected = this.selectedProduct !== undefined

    this.previewBtnText = this.editItem.label

    this.dialogOpen = true
  }
}
</script>

<style scoped>
.v-card >>> .v-card__actions {
  background-color: white !important;
}

.label {
  font-weight: 700;
}
</style>
