<template>
  <v-card class="justify-center overflow-auto" height="595px">
    <v-form ref="accountForm" @submit.prevent="finishPayment">
      <v-card-title class="h6 mx-0">
        {{ $t("$vuetify.accountDetailsTitle") }}
      </v-card-title>
      <v-card-text class="pb-0 px-0">
        <v-container class="fill-height">
          <v-row>
            <v-col>
              <v-combobox
                autofocus
                :label="$t('$vuetify.formDetails.name')"
                :placeholder="$t('$vuetify.filterPlaceholderLabel')"
                v-model="payer"
                :loading="loading"
                :items="associates"
                return-object
                item-text="name"
                item-value="name"
                @change="setForm(true)"
                :search-input.sync="payerName"
                @update:search-input="querySelections"
                hide-no-data
                clearable
                @click:clear="setForm(false)"
                :rules="[rules.req]"
                @keydown.enter="finishPayment"
                class="uppercased-input"
              >
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item
                    class="uppercased-input"
                    @keydown.enter.stop=""
                    v-on="on"
                    v-bind="attrs"
                    >{{ item.name }}</v-list-item
                  >
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                class="uppercased-input"
                v-model="payerAddress"
                :label="$t('$vuetify.formDetails.address')"
                :rules="
                  payer && payer.name === 'Kupac građanin' ? [] : [rules.req]
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="payerCity"
                :label="$t('$vuetify.formDetails.city')"
                :rules="
                  payer && payer.name === 'Kupac građanin' ? [] : [rules.req]
                "
                class="uppercased-input"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :label="$t('$vuetify.formDetails.zipCode')"
                v-model="payerZip"
                :rules="
                  payer && payer.name === 'Kupac građanin' ? [] : [rules.req]
                "
                class="uppercased-input"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="countries"
                :label="$t('$vuetify.formDetails.country')"
                v-model="payerCountry"
                item-text="country"
                item-value="country"
                :rules="
                  payer && payer.name === 'Kupac građanin' ? [] : [rules.req]
                "
                class="uppercased-input"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-combobox
                :label="$t('$vuetify.formDetails.oib')"
                v-model="payerOib"
                :search-input.sync="payerOib"
                :items="payer ? payer.oibs : []"
                validate-on-blur
                @keydown.enter="finishPayment"
                class="uppercased-input"
              >
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item
                    @keydown.enter.stop=""
                    v-on="on"
                    v-bind="attrs"
                    >{{ item }}</v-list-item
                  >
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row v-if="data.paymentMethod === 'T'">
            <v-col class="py-0">
              <v-text-field
                tabindex="-1"
                v-model="referenceNumber"
                :label="$t('$vuetify.referenceNumber')"
                autocomplete="off"
                validate-on-blur
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="
              inEu === true &&
              payerCountry !== 'Hrvatska' &&
              payerCountry !== undefined
            "
          >
            <v-col class="py-0">
              <v-radio-group
                v-model="selectedVies"
                :rules="[(v) => !!v || 'Molimo odaberite']"
                required
              >
                <v-radio
                  v-for="vies in vieses"
                  :key="vies.value"
                  :value="vies.value"
                  :label="vies.name"
                  :rules="[rules.req]"
                ></v-radio>
              </v-radio-group>
              <v-btn
                href="https://ec.europa.eu/taxation_customs/vies/?locale=hr"
                target="_blank"
              >
                Provjera VIES baze
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="
              inEu === false &&
              payerCountry !== 'Hrvatska' &&
              payerCountry !== undefined
            "
          >
            <v-col class="py-0">
              <v-radio-group
                v-model="selectedCustomerType"
                :rules="[(v) => !!v || 'Molimo odaberite']"
                required
              >
                <v-radio
                  v-for="type in customerTypes"
                  :key="type.value"
                  :value="type.value"
                  :label="type.name"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-spacer></v-spacer>
      <v-card-actions class="mx-5">
        <v-spacer></v-spacer>
        <v-row style="height: 75px" class="d-flex justify-end align-end">
          <v-btn outlined color="primary" @click="backwardStep" class="mr-2">
            {{ $t("$vuetify.backward") }}
          </v-btn>
          <v-btn class="white--text okButton" id="finishPayment" type="submit">
            {{ $t("$vuetify.finishPayment") }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import rules from '@/plugins/rules'
import { clone, DAY, toISOLocal } from '@/plugins/utils'
import countries from '@/mixins/countries'
import state from '@/state'
import { v4 as uuidv4 } from 'uuid'
import { df, auth, functions } from '@/plugins/firebase'
import { printerInit } from '@/libs/bixolon/printer'
import { reprint } from '@/libs/bixolon/reprint'
import { print58 } from '@/libs/bixolon/print58'
import { prepareReceiptLogCall } from '@/libs/receiptIssuing/payment'

export default {
  mixins: [countries],
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'openPdfPrint'],
  props: ['data', 'selectedPrinter'],
  data: function () {
    return {
      loading: false,
      multiplier: 0,
      defaultAssociates: [],
      associates: [],
      paymentModels: [],
      payerName: undefined,
      payer: undefined,
      payerAddress: undefined,
      payerCity: undefined,
      payerZip: undefined,
      payerCountry: undefined,
      payerOib: undefined,
      referenceNumber: '',
      selectedVies: undefined,
      selectedCustomerType: undefined,
      euCountries: [],
      wholeSaleRcptTypes: undefined,
      inEu: false,
      rules: {
        req: rules.req(),
        oib: rules.oib()
      },
      listeners: []
    }
  },
  mounted () {
    const issueID = 1
    printerInit(issueID)
    state.setPosPrinterSettings(2)

    this.getMultiplier()
    this.getWholesaleReceiptTypes()
    this.getEuCountries()
    df.doc(`company_associates/${state.getCurrentCompany().id}`)
      .get()
      .then((resp) => {
        if (resp && resp.data()) {
          this.defaultAssociates = Object.keys(resp.data().associates)
            .map((key) => {
              return resp.data().associates[key]
            })
            .filter((associate) => associate.status === 'OK')

          this.defaultAssociates.push({ name: 'Kupac građanin', id: 'kupac-gradanin' })
          this.associates = clone(this.defaultAssociates.sort((a, b) => a.name.localeCompare(b.name)))
        }
      })
  },
  computed: {
    vieses () {
      return [
        { name: 'Kupac unutar VIES baze', value: 'inside' },
        { name: 'Kupac izvan VIES baze', value: 'outside' }
      ]
    },
    customerTypes () {
      return [
        { name: 'Kupac - pravna osoba', value: 'legal' },
        { name: 'Kupac - fizička osoba', value: 'person' }
      ]
    }
  },
  watch: {
    payerCountry (nv) {
      this.inEu = false
      this.euCountries.forEach(country => {
        if (country === nv) {
          this.inEu = true
        }
      })
    },
    payerOib (nv) {
      var regExp = /[a-zA-Z]/g

      if (nv && nv.length === 11 && !regExp.test(nv)) {
        // const valid = this.oibCheck(nv)
        // if (!valid) {
        //   this.payerOib = []
        //   return
        // }
        Object.keys(this.paymentModels).map(
          (key) => {
            if (key === 'HR01' && this.paymentModels.HR01.enabled === true && this.data.paymentMethod === 'T') {
              const randomKey = Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000

              const forSecurity = this.payerOib.toString() + randomKey.toString()
              const securityNum = this.kontrolniBroj(forSecurity)
              this.referenceNumber = key + ' ' + this.payerOib + '-' + randomKey + '-' + securityNum
            }
          }
        )
      }
    }
  },
  methods: {
    getWholesaleReceiptTypes () {
      const listener = df.doc('project_constants/wholesale_receipt_types').onSnapshot(doc => {
        this.wholeSaleRcptTypes = doc.data()
      })
      this.listeners.push(listener)
    },
    kontrolniBroj (s) {
      var i = 0; var v = 0; var p = 2; var c = ' '
      for (i = s.length; i >= 1; i--) {
        c = s.substr(i - 1, 1)
        if (c >= '0' && c <= '9' && v >= 0) { v = v + p * c; p = p + 1 } else { v = -1 }
      };
      if (v >= 0) { v = 11 - (v % 11); if (v > 9) { v = 0 }; }
      return (v)
    },
    oibCheck (oibCode) {
      var checkDigit, num

      var code = oibCode.toString()

      if (code.length === 13 && code.substr(0, 2).toUpperCase() === 'HR') {
        code = code.substr(2, 11)
      } else if (code.length !== 11) {
        return false
      }

      var numCheck = parseInt(code, 10)
      if (isNaN(numCheck)) {
        return false
      }

      num = 10
      for (var i = 0; i < 10; i++) {
        num = num + parseInt(code.substr(i, 1), 10)
        num = num % 10
        if (num === 0) {
          num = 10
        }
        num *= 2
        num = num % 11
      }

      checkDigit = 11 - num
      if (checkDigit === 10) {
        checkDigit = 0
      }

      return parseInt(code.substr(10, 1), 10) === checkDigit
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.paymentModels = doc.data().payment_models
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    getEuCountries () {
      const listener = df.doc('project_constants/countries').onSnapshot(doc => {
        this.euCountries = doc.data().eu_countries
      })
      this.listeners.push(listener)
    },
    setForm (data = true) {
      if (data) {
        if (this.payer && typeof this.payer === 'object') {
          this.payerCountry = this.payer.country
          this.payerOib = this.payer.oibs ? this.payer.oibs[0] : ''
          this.payerAddress = this.payer.address
          this.payerCity = this.payer.city
          this.payerZip = this.payer.zip_code
        }
      } else {
        this.referenceNumber = ''
        this.associates = clone(this.defaultAssociates)
        this.$nextTick(() => {
          // this.$refs.accountForm.reset()
        })
      }
    },
    querySelections () {
      if (this.payerName) {
        this.associates = this.defaultAssociates.filter((associate) =>
          associate.name.toLowerCase().includes(this.payerName.toLowerCase())
        )
      } else {
        this.associates = clone(this.defaultAssociates)
      }
    },
    finishPayment () {
      if (state.getCashRegister().type === 'ALL' && this.data.paymentMethod === 'T' && (this.data.receiptType === 'R' || this.data.receiptType === 'P')) {
        if (this.data.discount && this.data.discount.amount > 0) {
          var title = 'Transakcijski račun'
          if (this.data.receiptType === 'P') {
            title = 'Ponuda'
          }
          this.showMsgBox({
            title: title,
            text: title + ' se ne može izdati sa popustom na račun. Ukoliko želite nastaviti zamijenite popust na iznos sa popustom na pojedinačne artikle.',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })

          return
        }

        this.data.items.forEach((item, key) => {
          this.data.items[key] = this.getWholesaleItem(item)
        })
      }

      let wholeSaleRcptType = ''

      // POTREBNO ZBOG ISPISA ČLANKA NA RAČUNU
      if (this.data.paymentMethod === 'T' && this.payerCountry !== undefined && this.payerCountry !== 'Hrvatska') {
        if (state.getCurrentCompany().is_taxed === true) {
          if (this.inEu) {
            if (this.selectedVies === 'inside') {
              wholeSaleRcptType = 'TAX_EU_VIES_TRUE'
            } else {
              wholeSaleRcptType = 'TAX_EU_VIES_FALSE'
            }
          } else {
            if (this.selectedCustomerType === 'person') {
              wholeSaleRcptType = 'TAX_OTHER_PERSON'
            } else {
              wholeSaleRcptType = 'TAX_OTHER_LEGAL_ENTITY'
            }
          }
        } else {
          if (this.inEu) {
            if (this.selectedVies === 'inside') {
              wholeSaleRcptType = 'EU_VIES_TRUE'
            } else {
              wholeSaleRcptType = 'EU_VIES_FALSE'
            }
          } else {
            if (this.selectedCustomerType === 'person') {
              wholeSaleRcptType = 'OTHER_PERSON'
            } else {
              wholeSaleRcptType = 'OTHER_LEGAL_ENTITY'
            }
          }
        }
      }

      if (!this.$refs.accountForm.validate()) return

      const type = {
        flag: this.data.receiptType,
        payer_name: this.payerName,
        payer_address: this.payerAddress,
        payer_city: this.payerCity,
        payer_zip_code: this.payerZip,
        payer_country: this.payerCountry,
        payer_oib: this.payerOib
      }

      if (this.data.issueYear && this.data.paymentMethod === 'T') {
        type.issue_year = this.data.issueYear
      }
      try {
        // let removeGoods = false
        // let removeService = false
        if (this.wholeSaleRcptTypes !== undefined && this.wholeSaleRcptTypes.receipt_types !== undefined && this.wholeSaleRcptTypes.receipt_types[wholeSaleRcptType] !== undefined && this.payerCountry !== 'Hrvatska') {
          if (this.wholeSaleRcptTypes.receipt_types[wholeSaleRcptType].pdv_on_goods === false) {
            // removeGoods = true
          }
          if (this.wholeSaleRcptTypes.receipt_types[wholeSaleRcptType].pdv_on_service === false) {
            // removeService = true
          }
        }

        if (this.data.fullTotal !== undefined && this.data.fullTotal > 0) {
          this.data.fullTotal = 0
        }

        if (this.data.total !== undefined && this.data.total > 0) {
          this.data.total = 0
        }

        const items = this.data.items.map((item) => {
          const it = {
            name: item.name,
            amt: item.quantity * this.multiplier,
            id: item.id,
            selling_unit: item.selling_unit,
            price: Math.round(item.quantity * item.prices[this.data.currency].price),
            single_item_price: item.prices[this.data.currency].price,
            type: item.type,
            pdv: {
              rate: item.prices[this.data.currency].taxes[0].rate
            },
            pnp: null
          }

          if (item.prices[this.data.currency].taxes.length > 0) {
            item.prices[this.data.currency].taxes.forEach(tax => {
              if (tax.name === 'PNP') {
                it.pnp = {
                  name: 'PNP',
                  rate: tax.rate
                }
              }
            })
          }

          if (item.choosenProductOrder) {
            it.menu_products = []
            Object.keys(item.choosenProductOrder).forEach(key => {
              if (item.choosenProductOrder[key] && item.choosenProductOrder[key].length > 0) {
                item.choosenProductOrder[key].forEach(product => {
                  if (product && product.productItems && product.productItems.length > 0) {
                    product.productItems.forEach(pi => {
                      if (pi && pi.id !== '') {
                        it.menu_products.push(pi)
                      }
                    })
                  }
                })
              }
            })
          }

          if (item.discount && item.discounted_price > 0) {
            it.discount = item.discount

            if (item.discount.type === 'RATE') {
              const temp = ((it.single_item_price / 100) * (item.discount.rate / 100))
              it.discounted_price = it.single_item_price - temp
              it.discount.amount = temp
            } else {
              it.discount.amount = Math.round(it.discount.amount, 2)
              it.discounted_price = it.single_item_price - it.discount.amount
              it.discount.rate = (it.discount.amount / it.single_item_price) * 100
            }

            it.price = it.discounted_price * item.quantity
            this.data.fullTotal += (it.single_item_price * item.quantity)
            this.data.total += (it.discounted_price * item.quantity)
          } else {
            this.data.total += (it.single_item_price * item.quantity)
            this.data.fullTotal = this.data.total
          }

          return it
        })

        const reqId = uuidv4()
        this.showLoader()
        let deliveryDate
        let paymentDueDate
        let entity = 'receipt'
        if (this.data.paymentMethod === 'T' && this.data.receiptType !== 'P') {
          deliveryDate = this.data.deliveryDate
            ? parseInt(
              (
                new Date(`${this.data.deliveryDate}T23:59:59`).getTime() /
                  1000
              ).toFixed(0)
            )
            : parseInt((new Date().getTime() / 1000).toFixed(0))
          paymentDueDate = this.data.paymentDueDate
            ? parseInt(
              (
                new Date(`${this.data.paymentDueDate}T23:59:59`).getTime() /
                  1000
              ).toFixed(0)
            )
            : this.data.paymentDueDefault
        }
        if (this.data.receiptType === 'P') {
          paymentDueDate = this.data.paymentDueDate
            ? parseInt(
              (
                new Date(`${this.data.paymentDueDate}T23:59:59`).getTime() /
                  1000
              ).toFixed(0)
            )
            : parseInt(
              (
                new Date(
                  toISOLocal(new Date(new Date().getTime() + 8 * DAY))
                ).getTime() / 1000
              ).toFixed(0)
            )
          entity = 'offer'
        }
        const issueDateTime =
          this.data.vpRacTimestamp && this.data.paymentMethod === 'T'
            ? parseInt((this.data.vpRacTimestamp / 1000).toFixed(0))
            : parseInt((new Date().getTime() / 1000).toFixed(0))
        let paymentMeth = ''
        switch (this.data.paymentMethod) {
          case 'G':
            paymentMeth = 'NOVČANICE'
            break
          case 'K':
            paymentMeth = 'KARTICA'
            break
          case 'T':
            paymentMeth = 'TRANSAKCIJSKI RAČUN'
            break
        }

        var receiptApi = functions.httpsCallable('receiptapi')

        var params = {
          company_id: state.getCurrentCompany().id,
          location_id: state.getPointOfSale().id,
          // seller_id: auth.currentUser.uid,
          register_id: state.getCashRegister().id,
          issue_date_time: issueDateTime,
          payment_method: paymentMeth,
          number: this.data.receiptNumber,
          // seller_name: state.getUser().name + " " + state.getUser().surname,
          currency: this.data.currency,
          payment_reference: this.referenceNumber,
          language: this.data.language,
          type: type,
          items: items,
          note: this.data.note,
          reqid: reqId
        }

        var userID = auth.currentUser.uid
        if (state.getCashRegisterUser().id !== undefined && state.getCashRegisterUser().id !== '') {
          userID = state.getCashRegisterUser().id
        }

        if (entity === 'receipt') {
          params.delivery_date = deliveryDate
          params.seller_id = userID
        }

        if (this.data.fullTotal !== undefined && this.data.fullTotal > 0) {
          params.full_total = Math.round(clone(this.data.fullTotal))
        } else {
          params.full_total = Math.round(clone(this.data.total))
        }

        if (this.data.discount !== undefined) {
          params.discount = clone(this.data.discount)
        }

        if (this.data.total !== undefined && this.data.total > 0) {
          params.total = Math.round(clone(this.data.total))
        }

        if (this.data.totalWithDiscount !== undefined && this.data.totalWithDiscount > 0) {
          params.total = Math.round(clone(this.data.totalWithDiscount))
        }
        if (state.getCashRegister().type === 'VP') {
          var price = 0
          params.items.forEach((item, key) => {
            var priceWithPdv = item.price * (1 + item.pdv.rate / 10000)
            price += priceWithPdv
            params.items[key].pdv.base = item.price
            params.items[key].pdv.total = priceWithPdv - item.price
          })
          params.total = price
        }
        if (wholeSaleRcptType !== '') {
          params.wholesale_receipt_type = wholeSaleRcptType
        }

        if (entity === 'receipt') {
          if (this.data.paymentMethod === 'T') {
            params.payment_due = paymentDueDate
          } else {
            delete params.delivery_date
          }
          // payload.action.params.print_size = state.getPrinter()
          params.print_size = 'A4'
          params.fiscalize = this.data.fiscalize
          params.seller_oib = ''
        } else {
          params.offer_due = paymentDueDate
          delete params.delivery_date
          delete params.seller_id
        }

        receiptApi({
          action: {
            operation: 'set',
            entity: entity,
            params: params
          }
        })
          .then((result) => {
            // Read result of the Cloud Function.
            /** @type {any} */
            // const data = result.data
            // const sanitizedMessage = data.text

            if (result && result.data && result.data.zki !== '') {
              if (state.getCurrentCompany().save_log) {
                prepareReceiptLogCall(result?.data?.full_receipt)
              }
            }

            if (!this.selectedPrinter.library || this.selectedPrinter.library === 'BIXOLON' || this.selectedPrinter.library === 'UNIVERSAL') {
              if (!this.selectedPrinter.width || this.selectedPrinter.width === '80') {
                this.hideLoader()
                reprint(this.selectedPrinter, result.data.full_receipt, 2)
              } else if (this.selectedPrinter.width === '58') {
                this.hideLoader()
                print58(this.selectedPrinter, result.data.full_receipt, 2)
              }
            } else {
              this.showLoader()
              var receipttemplate = functions.httpsCallable('receiptTemplate')

              var documentType = 'racun'
              if (entity === 'offer') {
                documentType = 'ponuda'
              }

              var printTemplate = ''
              if (state.getCurrentCompany().print_template && state.getCurrentCompany().print_template !== '') {
                printTemplate = state.getCurrentCompany().print_template
              }

              receipttemplate({
                template: printTemplate,
                companyId: result.data.full_receipt.company_details.id,
                documentId: result.data.full_receipt.id,
                documentType: documentType,
                rawJson: ''
              })
                .then((result) => {
                  this.hideLoader()
                  if (result.data?.document !== '') {
                    this.openPdfPrint(result.data?.document)
                  }
                })
            }

            this.$emit('advanceStep')
            this.setForm(false)
          })
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      }
    },
    backwardStep () {
      this.setForm(false)
      // const deletableProperties = ['payerName', 'payerAddress', 'payerCity', 'payerZip', 'payerCountry', 'payerOib']
      // this.$emit('backwardStep', deletableProperties)
      this.$emit('backwardStep')
    },
    getWholesaleItem (item) {
      var currency = 'EUR'
      const newItem = clone(item)
      var pnpAmt = 0
      if (newItem.prices[currency].taxes.length > 0) {
        newItem.prices[currency].taxes.forEach(tax => {
          if (tax.name === 'PNP') {
            pnpAmt = tax.rate
          }
        })
      }

      var taxes = newItem.prices[currency].taxes[0].rate + pnpAmt

      newItem.prices[currency].price =
      Math.round(parseFloat(newItem.prices[currency].price / (1 + taxes / 10000)), 2)

      return newItem
    }
  }
}
</script>
<style scoped>
.uppercased-input >>> input {
  text-transform: uppercase !important;
}
.v-list-item--link {
  text-transform: uppercase !important;
}
</style>
