<template>
  <v-dialog v-model="visible" v-if="receipt" persistent scrollable width="400">
    <v-form ref="paymentChangeForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto py-3 pa-2"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="mb-2">
            <h3 class="text--secondary">Promijeni način plaćanja</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <label>
                Trenutni način plaćanja: {{ receipt.payment_method }}
              </label>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                label="Način plaćanja"
                dense
                :items="paymentMethods"
                v-model="selectedPaymentMethod"
                item-text="name"
                item-value="value"
                :rules="[rules.req]"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" @click="close"> Odustani </v-btn>
          <v-btn class="white--text okButton" @click="submit"> Završi </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-overlay v-if="loading" absolute z-index="10">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'
import { clone } from '@/plugins/utils'
import { functions } from '@/plugins/firebase'
import state from '@/state'
// import { v4 as uuidv4 } from 'uuid'
export default {
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: function () {
    return {
      visible: false,
      loading: false,
      receipt: undefined,
      selectedPaymentMethod: undefined,
      paymentMethods: [{
        name: 'Promijeni način plaćanja u novčanice', value: 'N'
      },
      {
        name: 'Promijeni način plaćanja u kartice', value: 'K'
      },
      {
        name: 'Promijeni način plaćanja u ostalo', value: 'O'
      }],
      rules: {
        req: rules.req()
      }
    }
  },
  computed: {},
  methods: {
    open (receipt) {
      if (!receipt) return
      this.visible = true
      this.receipt = clone(receipt)

      if (this.receipt.payment_method && this.receipt.payment_method !== '') {
        switch (this.receipt.payment_method) {
          case 'NOVČANICE':
            this.paymentMethods = [
              {
                name: 'Promijeni način plaćanja u kartice', value: 'KARTICA'
              },
              {
                name: 'Promijeni način plaćanja u ostalo', value: 'OSTALO'
              }]
            break
          case 'KARTICA':
            this.paymentMethods = [{
              name: 'Promijeni način plaćanja u novčanice', value: 'NOVČANICE'
            },
            {
              name: 'Promijeni način plaćanja u ostalo', value: 'OSTALO'
            }]
            break
          case 'OSTALO':
            this.paymentMethods = [{
              name: 'Promijeni način plaćanja u novčanice', value: 'NOVČANICE'
            },
            {
              name: 'Promijeni način plaćanja u kartice', value: 'KARTICA'
            }]
            break
        }
      }
    },
    close () {
      this.visible = false
    },
    submit () {
      if (!this.selectedPaymentMethod || this.selectedPaymentMethod === '') return
      var that = this
      this.loading = true
      try {
        var receiptApi = functions.httpsCallable('receiptapi')

        const params = {
          company_id: state.getCurrentCompany().id,
          payment_method: this.selectedPaymentMethod
        }

        receiptApi({
          action: {
            operation: 'update',
            entity: 'payment',
            resource_id: this.receipt.id,
            params: params
          }
        })
          .then(async (doc) => {
            // if (state.getPrinter() === 'BIXOLON80') {
            that.receipt.payment_method = that.selectedPaymentMethod
            that.$emit('reprintReceipt', that.receipt)
            // }

            if (doc.data) {
              if (doc.data.status !== 'OK') {
                that.showMsgBox({
                  title: 'Neuspješna promjena načina plaćanja',
                  text: 'Promjene načina plaćanja nije uspjela.',
                  actions: ['cancel'],
                  cancelBtnText: that.$t('$vuetify.close'),
                  color: 'error'
                })
              }
            }
            that.close()
            that.loading = false
          }
          )
      } catch (err) {
        this.showMsgBox({
          text: err,
          actions: ['cancel'],
          cancelBtnText: 'OK'
        })
      }
    }
  }
}
</script>
