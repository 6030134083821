<template>
  <v-dialog v-model="visible" persistent scrollable width="800" v-if="offer">
    <v-form ref="addReceiptFromOffer" @submit.prevent="submit">
      <v-card
        class="overflow-auto py-3"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Izrada računa po ponudi</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <div>
                <div style="background-color: #e6e6e6" class="pl-2">
                  <div class="title">Podaci o kupcu:</div>
                  <div>
                    <span>{{ $t("$vuetify.formDetails.name") }}</span
                    >:
                    <span class="font-weight-bold text-capitalize">{{
                      offer.type.payer_name
                    }}</span>
                  </div>
                  <div>
                    <span>{{ $t("$vuetify.formDetails.address") }}</span
                    >:
                    <span class="font-weight-bold text-capitalize">{{
                      offer.type.payer_address
                    }}</span>
                  </div>
                  <div>
                    <span>{{ $t("$vuetify.formDetails.city") }}</span
                    >:
                    <span class="font-weight-bold text-capitalize">{{
                      offer.type.payer_city
                    }}</span>
                  </div>
                  <div>
                    <span>{{ $t("$vuetify.formDetails.zipCode") }}</span
                    >:
                    <span class="font-weight-bold">{{
                      offer.type.payer_zip_code
                    }}</span>
                  </div>
                  <div>
                    <span>{{ $t("$vuetify.formDetails.country") }}</span
                    >:
                    <span class="font-weight-bold">{{
                      offer.type.payer_country
                    }}</span>
                  </div>
                  <div v-if="offerHasOib">
                    <span>{{ $t("$vuetify.formDetails.oib") }}</span
                    >:
                    <span class="font-weight-bold">{{
                      offer.type.payer_oib
                    }}</span>
                  </div>
                </div>
                <v-text-field
                  v-if="!offerHasOib"
                  v-model="offer.type.payer_oib"
                  :label="$t('$vuetify.formDetails.oib')"
                ></v-text-field>
                <v-row>
                  <v-col class="pb-0">
                    <v-checkbox
                      :label="$t('$vuetify.receipts.receiptPaidStatus')"
                      v-model="receiptPaid"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-menu
                      offset-y
                      v-model="deliveryDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="deliveryComputedDate"
                          :label="$t('$vuetify.deliveryDate')"
                          dense
                          prepend-icon="mdi-clock-time-four-outline"
                          @click:prepend="deliveryDateMenu = !deliveryDateMenu"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="deliveryDate"
                        locale="hr-HR"
                        no-title
                        scrollable
                        @input="deliveryDateMenu = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-menu
                      offset-y
                      v-model="paymentDueDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="paymentDueComputedDate"
                          :label="$t('$vuetify.paymentDueDate')"
                          dense
                          prepend-icon="mdi-clock-time-four-outline"
                          @click:prepend="
                            paymentDueDateMenu = !paymentDueDateMenu
                          "
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="paymentDueDate"
                        :min="minPaymentDueDate"
                        :max="maxPaymentDueDate"
                        locale="hr"
                        no-title
                        scrollable
                        @input="paymentDueDateMenu = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-text-field
                      tabindex="-1"
                      v-model="referenceNumber"
                      :label="$t('$vuetify.referenceNumber')"
                      autocomplete="off"
                      validate-on-blur
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      :label="$t('$vuetify.issueYear')"
                      @keypress="checkInput($event)"
                      v-model="issueYear"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      :label="$t('$vuetify.note')"
                      v-model="note"
                      rows="3"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-divider vertical inset></v-divider>
            <v-col cols="7">
              <div>
                <div>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr style="background-color: #e6e6e6">
                          <th class="text-left">Naziv artikla</th>
                          <th class="text-center">Količina</th>
                          <th class="text-right">Jed. cijena</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in offer.items" :key="i">
                          <td class="text-left">
                            {{ $options.filters.capitalize(item.name) }}
                          </td>
                          <td class="text-center">
                            {{
                              (item.amt / multiplier)
                                .toFixed(3)
                                .replace(".", ",")
                            }}
                          </td>
                          <td class="text-right">
                            {{
                              item.single_item_price
                                | money(100, offer.currency)
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Izradi račun
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { clone, duck, toISOLocal, DAY } from '@/plugins/utils'
import rules from '@/plugins/rules'
import state from '@/state'
import { v4 as uuidv4 } from 'uuid'
import { df, functions } from '@/plugins/firebase'

export default {
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'openPdfPrint'],
  data: function () {
    return {
      visible: false,
      submitting: false,
      offer: undefined,
      paymentDueDate: '',
      paymentDueDateMenu: false,
      deliveryDate: '',
      deliveryDefaultDate: '',
      deliveryDateMenu: false,
      offerHasOib: false,
      referenceNumber: '',
      listeners: [],
      multiplier: 0,
      issueYear: '',
      rules: {
        req: rules.req()
      },
      receiptPaid: false,
      note: '',
      paymentModels: []
    }
  },
  async mounted () {
    this.getMultiplier()
  },
  computed: {
    minPaymentDueDate () {
      return toISOLocal(new Date())
    },
    maxPaymentDueDate () {
      return this.receiptPaid ? toISOLocal(new Date()) : undefined
    },
    paymentDueDefault () {
      if (this.offer.currency === 'EUR') {
        return toISOLocal(new Date(new Date().getTime() + 8 * DAY)).substr(
          0,
          10
        )
      }
      return toISOLocal(new Date(new Date().getTime() + 30 * DAY)).substr(
        0,
        10
      )
    },
    paymentDueComputedDate () {
      if (!this.paymentDueDate) return ''
      return new Date(this.paymentDueDate).toLocaleDateString('hr-HR')
    },
    deliveryComputedDate () {
      if (!this.deliveryDate) return ''
      return new Date(this.deliveryDate).toLocaleDateString('hr-HR')
    }
  },
  watch: {
    receiptPaid (isPaid) {
      if (!isPaid) return
      this.paymentDueDate = toISOLocal(new Date()).substr(0, 10)
    }
  },

  methods: {
    open (offer, multiplier) {
      if (!offer) return
      if (offer.type.flag !== 'P') {
        this.showMsgBox({
          text: 'Niste odabrali ponudu.',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
        return
      }
      this.multiplier = clone(multiplier)
      this.offer = clone(offer)
      this.visible = true
      this.setDefultValues()
    },
    close () {
      this.visible = false
    },
    setDefultValues () {
      if (!this.offer) return
      this.issueYear = ''
      this.paymentDueDate = clone(this.paymentDueDefault)
      this.deliveryDefaultDate = toISOLocal(
        new Date(new Date().getTime())
      ).substr(0, 10)
      this.deliveryDate = clone(this.deliveryDefaultDate)
      this.offerHasOib = !!this.offer.type?.payer_oib
      const offerNumber = `${this.offer.designation.number}-${this.offer.designation.location_id}-${this.offer.designation.register_number}`
      this.note = this.$t('$vuetify.receipts.defaultNote', {
        offer: offerNumber
      })

      if (this.offer.note && this.offer.note !== '') {
        this.note = this.note + '\r\n\r\n' + this.offer.note
      }

      this.referenceNumber = this.offer.payment_reference ? this.offer.payment_reference : ''
      if (this.referenceNumber === '') {
        this.generatePaymentReference()
      }
      this.receiptPaid = false
    },
    checkInput (ev) {
      // change this with a custom directive  that accepts custom regex and checks input field
      const maskRe = /[0-9]/
      if (!maskRe.test(ev.key)) {
        ev.preventDefault()
        return false
      }
    },
    getCurrentTimePart () {
      return new Date().toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      })
    },
    async submit () {
      if (!this.$refs.addReceiptFromOffer.validate()) return
      try {
        const items = this.offer.items.map((item) => {
          var it = {
            name: item.name,
            amt: item.amt,
            id: item.id,
            single_item_price: item.single_item_price,
            pdv: {
              rate: item.pdv.rate
            },
            pnp: null
          }

          if (item.discount && item.discount?.amount > 0) {
            it.discount = item.discount
            it.discounted_price = item.discounted_price
          }

          return it
        })
        const type = {
          flag: 'R', // iterate offer data
          payer_name: this.offer.type.payer_name,
          payer_address: this.offer.type.payer_address,
          payer_city: this.offer.type.payer_city,
          payer_zip_code: this.offer.type.payer_zip_code,
          payer_country: this.offer.type.payer_country,
          payer_oib: this.offer.type.payer_oib,
          issue_year: this.issueYear
        }

        const issueDateTime = parseInt(
          (new Date().getTime() / 1000).toFixed(0)
        )

        let deliveryDate
        if (duck(this.deliveryDate, this.deliveryDefaultDate)) {
          deliveryDate = parseInt((new Date().getTime() / 1000).toFixed(0))
        } else {
          deliveryDate = parseInt(
            (
              new Date(
                `${this.deliveryDate}T${this.getCurrentTimePart()}`
              ).getTime() / 1000
            ).toFixed(0)
          )
        }
        const paymentDueDate = parseInt(
          (
            new Date(
              `${this.paymentDueDate}T${this.getCurrentTimePart()}`
            ).getTime() / 1000
          ).toFixed(0)
        )
        this.submitting = true

        const receiptData = await df
          .doc(`cash_registers/${this.offer.designation.register_id}`)
          .get()
        if (!receiptData?.exists) {
          this.showMsgBox({
            text: 'Podaci o blagajni na kojoj je napravljena ponuda nisu pronađeni',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
          this.submitting = false
          return
        }
        const currentReceiptNumber = receiptData?.data()?.bill_sequence || 0

        const payload = {
          action: {
            operation: 'set',
            entity: 'receipt',
            params: {
              company_id: state.getCurrentCompany().id,
              location_id: this.offer.location_details.id,
              // seller_id: auth.currentUser.uid,
              number: parseInt(currentReceiptNumber + 1),
              register_id: this.offer.designation.register_id,
              issue_date_time: issueDateTime,
              delivery_date: deliveryDate,
              payment_due: paymentDueDate,
              payment_method: 'TRANSAKCIJSKI RAČUN',
              seller_oib: '',
              // seller_name: state.getUser().name + " " + state.getUser().surname,
              currency: this.offer.currency,
              payment_reference: this.referenceNumber,
              language: this.offer.language,
              fiscalize: false,
              print_size: state.getPrinter(),
              type: type,
              items: items,
              note: this.note,
              reqid: uuidv4()
            }
          }
        }

        if (this.receiptPaid === true) {
          payload.action.params.paid_transaction_receipt = true
        }

        if (this.offer.wholesale_receipt_type !== undefined && this.offer.wholesale_receipt_type !== '') {
          payload.action.params.wholesale_receipt_type = this.offer.wholesale_receipt_type
        }

        let printTemplate = ''
        if (state.getCurrentCompany().print_template && state.getCurrentCompany().print_template !== '') {
          printTemplate = state.getCurrentCompany().print_template
        }

        var receiptApi = functions.httpsCallable('receiptapi')
        var receipttemplate = functions.httpsCallable('receiptTemplate')

        receiptApi({
          action: {
            operation: 'set',
            entity: 'receipt',
            params: payload.action.params
          }
        })
          .then(async (result) => {
            if (result && result.data && result.data.header && result.data.header.code !== 200) {
              this.hideLoader()
              this.submitting = false

              alert('Dogodila se greška pri izdavanju računa: ' + result.data.header.error)
            }

            var documentType = 'racun'

            receipttemplate({
              template: printTemplate,
              companyId: result.data.full_receipt.company_details.id,
              documentId: result.data.full_receipt.id,
              documentType: documentType,
              rawJson: ''
            })
              .then((result) => {
                if (result.data?.document !== '') {
                  this.openPdfPrint(result.data?.document)
                  this.hideLoader()
                  this.submitting = false
                  this.close()
                }
              })
          })
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      }
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.paymentModels = doc.data().payment_models
      })
      this.listeners.push(listener)
    },
    generatePaymentReference () {
      var regExp = /[a-zA-Z]/g

      var oib = this.offer.type.payer_oib ? this.offer.type.payer_oib : ''
      var paymentMethod = this.offer.payment_method
      if (oib && oib.length === 11 && !regExp.test(oib)) {
        Object.keys(this.paymentModels).map(
          (key) => {
            if (key === 'HR01' && this.paymentModels.HR01.enabled === true && paymentMethod === 'TRANSAKCIJSKI RAČUN') {
              const randomKey = Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000

              const forSecurity = oib.toString() + randomKey.toString()
              const securityNum = this.kontrolniBroj(forSecurity)
              this.referenceNumber = key + ' ' + oib + '-' + randomKey + '-' + securityNum
            }
          }
        )
      }
    },
    kontrolniBroj (s) {
      var i = 0; var v = 0; var p = 2; var c = ' '
      for (i = s.length; i >= 1; i--) {
        c = s.substr(i - 1, 1)
        if (c >= '0' && c <= '9' && v >= 0) { v = v + p * c; p = p + 1 } else { v = -1 }
      };
      if (v >= 0) { v = 11 - (v % 11); if (v > 9) { v = 0 }; }
      return (v)
    }
  }
}
</script>
<style scoped>
</style>
