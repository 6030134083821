<template>

  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    max-width="700px"
    style="z-index:6"
  >
    <v-form
      ref="createReportForm"
      @submit.prevent="submit"
    >
      <v-card
        class="overflow-auto"
        :class="{'mobile-dialog-card': $vuetify.breakpoint.smAndDown}"
      >
        <v-card-title
          class="d-flex"
          style="vertical-align:middle"
        >
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Preuzimanje izvještaja</h2>
            <v-spacer></v-spacer>
            <v-icon
              large
              @click="close"
            >mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text class="pl-0 ml-0">
          <v-row>
            <v-col cols="4">
              <v-tabs
                vertical
                v-model="tab"
              >
                <v-tab
                  class="justify-start"
                  href="#ClosingBalance"
                >Zaključak blagajne</v-tab>
                <v-tab
                  class="justify-start"
                  href="#IRA"
                >IRA izvještaj</v-tab>
              </v-tabs>
            </v-col>
            <v-divider
              vertical
              inset
            ></v-divider>
            <v-col cols="7">
              <v-tabs-items v-model="tab">
                <v-tab-item value="ClosingBalance">
                  <report-options
                    type='ClosingBalance'
                    ref='ClosingBalance'
                    @onSubmit="loading = true"
                    @onFinish="loading = false"
                  ></report-options>
                </v-tab-item>
                <v-tab-item value="IRA">
                  <report-options
                    type='IRA'
                    ref='IRA'
                    @onSubmit="loading = true"
                    @onFinish="loading = false"
                  ></report-options>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5 pr-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="primary"
            @click="close"
          >
            {{$t('$vuetify.close')}}
          </v-btn>
          <v-btn
            class="white--text okButton"
            @click="submit"
          >
            {{$t('$vuetify.formDetails.downloadReport')}}
          </v-btn>
        </v-card-actions>
        <v-overlay
          v-if="loading"
          absolute
          z-index="10"
        >
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import ReportOptions from '@/modules/cash-register/components/createReport/components/ReportOptions'
export default {
  components: { ReportOptions },
  data: function () {
    return {
      visible: false,
      tab: 'ClosingBalance',
      loading: false
    }
  },
  methods: {
    open () {
      this.visible = true
    },
    close () {
      this.visible = false
    },
    submit () {
      if (this.tab === 'ClosingBalance') {
        this.$refs.ClosingBalance.submit()
      } else if (this.tab === 'IRA') {
        this.$refs.IRA.submit()
      }
    }
  }
}
</script>
