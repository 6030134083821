var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"max-height":"100px"}},[_c('panels-toolbar-simple',{attrs:{"items":_vm.data.items,"receiptNote":_vm.receiptNote},on:{"openSearchItemsDialog":_vm.openSearchItemsDialog,"openCloseBalanceDialog":_vm.openCloseBalanceDialog,"openReceiptsDialog":_vm.openReceiptsDialog,"finishPaymentRep":function($event){return _vm.confirmReprezentacija()},"deliveryFinishSimplePayment":_vm.deliveryFinishSimplePayment}}),(_vm.cashRegisterType !== 'VP')?_c('v-row',[_c('v-col',{staticStyle:{"padding":"0 2px 2px 0"},attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltipDelay,"open-on-hover":true,"open-on-click":false,"open-on-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticStyle:{"border-radius":"12px"},attrs:{"block":"","tile":"","depressed":"","min-height":"3.5rem","color":"#2C5A90","disabled":!_vm.buttonsEnabled || _vm.cashRegisterType === 'VP'},on:{"click":function($event){return _vm.finishPayment('G', 'BO')}}},[_c('span',{staticClass:"white--text tile btn-lg"},[_vm._v(_vm._s(_vm.$t("$vuetify.paymentMethods.cash")))])])],1)]}}],null,false,1623659923)},[_c('span',[_vm._v(_vm._s(_vm.$t("$vuetify.tooltips.payWithCash")))])])],1),_c('v-col',{staticStyle:{"padding":"0 2px 2px 0"},attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltipDelay,"open-on-hover":true,"open-on-click":false,"open-on-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('div',_vm._g({},Object.assign({}, onMenu, onTooltip)),[_c('v-btn',_vm._b({class:[
                      {
                        'disable-events':
                          !_vm.buttonsEnabled || _vm.cashRegisterType === 'VP',
                      },
                      'white--text',
                      {
                        darkblue: _vm.buttonsEnabled && _vm.cashRegisterType !== 'VP',
                      } ],staticStyle:{"border-radius":"12px"},attrs:{"block":"","tile":"","depressed":"","min-height":"3.5rem","icon":""},on:{"click":function($event){return _vm.checkCards()}}},'v-btn',attrs,false),[_c('span',{staticClass:"white--text tile btn-lg"},[_vm._v(_vm._s(_vm.$t("$vuetify.paymentMethods.card")))])])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("$vuetify.tooltips.payWithCards")))])])]}}],null,false,3748274191)})],1),_c('v-col',{staticStyle:{"padding":"0 0 2px 0"},attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var onMenu = ref.on;
                    var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltipDelay,"open-on-hover":true,"open-on-click":false,"open-on-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var onTooltip = ref.on;
return [_c('div',_vm._g({},Object.assign({}, onMenu, onTooltip)),[_c('v-btn',_vm._b({class:[
                      { 'disable-events': !_vm.buttonsEnabled },
                      'white--text',
                      { darkblue: _vm.buttonsEnabled } ],staticStyle:{"border-radius":"12px"},attrs:{"block":"","tile":"","depressed":"","min-height":"3.5rem","icon":""},on:{"click":_vm.openR1ReceiptPaymentDialog}},'v-btn',attrs,false),[_c('span',{staticClass:"white--text tile btn-lg"},[_vm._v("R1")])])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("$vuetify.tooltips.payR1")))])])]}}],null,false,3330165596)})],1)],1):_vm._e(),(_vm.cashRegisterType === 'VP')?_c('v-row',[_c('v-col',{staticStyle:{"padding":"0 0 2px 0"},attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var onMenu = ref.on;
                    var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":_vm.tooltipDelay,"open-on-hover":true,"open-on-click":false,"open-on-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var onTooltip = ref.on;
return [_c('div',_vm._g({},Object.assign({}, onMenu, onTooltip)),[_c('v-btn',_vm._b({class:[
                      { 'disable-events': !_vm.buttonsEnabled },
                      'white--text',
                      { darkblue: _vm.buttonsEnabled } ],attrs:{"block":"","tile":"","depressed":"","min-height":"3.5rem","icon":""},on:{"click":_vm.openWsPaymentDialog}},'v-btn',attrs,false),[_c('span',{staticClass:"white--text tile btn-lg"},[_vm._v("Račun")])])],1)]}}],null,true)},[_c('span',[_vm._v("Račun")])])]}}],null,false,2910459842)})],1)],1):_vm._e()],1),(_vm.r1DialogOpen)?_c('account-details-dialog',{attrs:{"data":_vm.data,"paymentMethod":_vm.paymentMethod,"selectedTable":_vm.selectedTable,"selectedTables":_vm.selectedTables,"selectedCard":_vm.selectedCard,"selectedPrinter":_vm.selectedPrinter,"selectedTerminal":_vm.selectedTerminal},on:{"closeR1Dialog":_vm.onR1DialogClose,"imidiatePrintPrepare":_vm.imidiatePrintPrepare,"accountDetailsFinishPayment":_vm.onAccountDetailsFinishPayment}}):_vm._e(),_c('terminals',{ref:"terminals",on:{"terminalSelected":_vm.terminalSelected}}),_c('card-payment-selection-dialog',{ref:"cardSelectionDialog",attrs:{"cards":_vm.availableCards},on:{"checkTerminal":_vm.checkTerminal}}),_c('r1-receipt-payment-dialog',{ref:"r1ReceiptPaymentDialog",attrs:{"paymentMenu":_vm.paymentMenu},on:{"menuPaymentClick":_vm.menuPaymentClick,"checkCardsForR":_vm.checkCardsForR}}),_c('ws-payment-selection-dialog',{ref:"wsPaymentSelectionDialog",attrs:{"paymentMenu":_vm.paymentMenu},on:{"menuPaymentClick":_vm.menuPaymentClick,"checkCards":_vm.checkCards}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }