<template>
  <v-dialog
    v-model="dialogOpen"
    v-if="dialogOpen"
    persistent
    max-width="630"
    @keydown.esc="close"
  >
    <v-card class="justify-center overflow-auto" height="600px">
      <v-form ref="accountForm" @submit.prevent="finishPayment">
        <v-card-title class="h6 mx-0">
          <h2>{{ $t("$vuetify.accountDetailsTitle") }}</h2>
          <v-spacer></v-spacer>
          <span
            :style="{ color: saveAssociateEnabled ? 'black' : '#d9d9d9' }"
            style="font-size: 1rem"
          >
            {{ $t("$vuetify.formDetails.saveAssociate") }}
          </span>
          <v-checkbox
            class="ml-2"
            :disabled="!saveAssociateEnabled"
            v-model="doSaveAccount"
          ></v-checkbox>
        </v-card-title>
        <v-card-text class="pb-0 px-0">
          <v-container>
            <v-row class="input-row">
              <v-col class="pt-2 mt-0">
                <v-combobox
                  clearable
                  @focus="showKbd"
                  data-layout="normalAduro"
                  :label="$t('$vuetify.formDetails.oib')"
                  v-model="payerOib"
                  dense
                  hide-details
                  :search-input.sync="payerOib"
                  :items="payer ? payer.oibs : []"
                  validate-on-blur
                  @keydown.enter="finishPayment"
                  class="uppercased-input"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item
                      @keydown.enter.stop=""
                      v-on="on"
                      v-bind="attrs"
                      >{{ item }}</v-list-item
                    >
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row class="input-row pt-0 mt-0">
              <v-col class="pt-2 mt-0">
                <v-combobox
                  @focus="showKbd"
                  data-layout="normalAduro"
                  :label="$t('$vuetify.formDetails.name')"
                  :placeholder="$t('$vuetify.filterPlaceholderLabel')"
                  v-model="payer"
                  :loading="loading"
                  :items="associates"
                  return-object
                  item-text="name"
                  item-value="name"
                  @change="setForm(true)"
                  :search-input.sync="payerName"
                  @update:search-input="querySelections"
                  hide-no-data
                  clearable
                  @click:clear="setForm(false)"
                  :rules="[rules.req]"
                  @keydown.enter="finishPayment"
                  class="uppercased-input"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item
                      class="uppercased-input"
                      @keydown.enter.stop=""
                      v-on="on"
                      v-bind="attrs"
                      >{{ item.name }}</v-list-item
                    >
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row class="input-row pt-2 mt-0">
              <v-col class="pt-4 mt-0">
                <v-text-field
                  @focus="showKbd"
                  dense
                  hide-details
                  data-layout="normalAduro"
                  class="uppercased-input"
                  v-model="payerAddress"
                  :label="$t('$vuetify.formDetails.address')"
                  :rules="
                    payer && payer.name === 'Kupac građanin' ? [] : [rules.req]
                  "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="input-row pt-2 mt-0">
              <v-col class="pt-4 mt-0">
                <v-text-field
                  @focus="showKbd"
                  data-layout="normalAduro"
                  dense
                  hide-details
                  v-model="payerCity"
                  :label="$t('$vuetify.formDetails.city')"
                  :rules="
                    payer && payer.name === 'Kupac građanin' ? [] : [rules.req]
                  "
                  class="uppercased-input"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="input-row pt-2 mt-0">
              <v-col class="pt-4 mt-0">
                <v-text-field
                  @focus="showKbd"
                  data-layout="normalAduro"
                  dense
                  hide-details
                  :label="$t('$vuetify.formDetails.zipCode')"
                  v-model="payerZip"
                  :rules="
                    payer && payer.name === 'Kupac građanin' ? [] : [rules.req]
                  "
                  class="uppercased-input"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="input-row pt-2 mt-0">
              <v-col class="pt-4 mt-0">
                <v-select
                  @focus="showKbd"
                  data-layout="normalAduro"
                  :items="countries"
                  dense
                  hide-details
                  :label="$t('$vuetify.formDetails.country')"
                  v-model="payerCountry"
                  item-text="country"
                  item-value="country"
                  :rules="
                    payer && payer.name === 'Kupac građanin' ? [] : [rules.req]
                  "
                  class="uppercased-input"
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              v-if="
                inEu === true &&
                payerCountry !== 'Hrvatska' &&
                payerCountry !== undefined
              "
              class="pt-0 mt-0"
            >
              <v-col cols="12" class="d-flex pt-0 mt-0 py-0 justify-end">
                <v-btn
                  class="mt-10 mr-10"
                  href="https://ec.europa.eu/taxation_customs/vies/?locale=hr"
                  target="_blank"
                  height="25"
                >
                  Provjera VIES baze
                </v-btn>
                <v-radio-group
                  v-model="selectedVies"
                  :rules="[(v) => !!v || 'Molimo odaberite']"
                  required
                  class="small-radio"
                >
                  <v-radio
                    v-for="vies in vieses"
                    :key="vies.value"
                    :value="vies.value"
                    :label="vies.name"
                    :rules="[rules.req]"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row
              v-if="
                inEu === false &&
                payerCountry !== 'Hrvatska' &&
                payerCountry !== undefined
              "
              class="pt-0 mt-0"
            >
              <v-col cols="12" class="d-flex pt-0 mt-0 py-0 justify-end">
                <v-radio-group
                  v-model="selectedCustomerType"
                  :rules="[(v) => !!v || 'Molimo odaberite']"
                  required
                  class="small-radio"
                >
                  <v-radio
                    v-for="type in customerTypes"
                    :key="type.value"
                    :value="type.value"
                    :label="type.name"
                    :rules="[rules.req]"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row
              v-if="payerCountry === 'Hrvatska'"
              style="min-height: 80px"
            ></v-row>
          </v-container>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="mx-5 mb-4">
          <v-spacer></v-spacer>
          <v-row style="height: 60px" class="d-flex justify-end align-end">
            <v-btn outlined color="primary" @click="backwardStep" class="mr-2">
              {{ $t("$vuetify.backward") }}
            </v-btn>
            <v-btn
              class="white--text okButton"
              id="finishPayment"
              type="submit"
            >
              {{ $t("$vuetify.finishPayment") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-container
      class="kbd"
      v-if="!$vuetify.breakpoint.smAndDown"
      ref="keyboard"
      v-show="oskVisible"
      :style="{ 'max-width': width }"
    >
      <div class="kbd-header"></div>
      <vue-touch-keyboard
        :options="oskOptions"
        :layout="layout"
        :cancel="hideKbd"
        :accept="accept"
        :input="input"
      />
    </v-container>
  </v-dialog>
</template>

<script>
import state from '@/state'
import rules from '@/plugins/rules'
import countries from '@/mixins/countries'
import { clone, toISOLocal } from '@/plugins/utils'
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import { printerInit } from '@/libs/bixolon/printer'
// import { remapItems } from '@/libs/receiptIssuing/items'
// import { getPayload, getVirmanPayload } from '@/libs/receiptIssuing/payload'
// import { getPaymentMethod } from '@/libs/receiptIssuing/commons'
// import { sendHttp, sendVirmanHttp } from '@/libs/receiptIssuing/payment'
import onScreenKeyboard from '@/mixins/onScreenKeyboard'

export default {
  mixins: [countries, onScreenKeyboard],

  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'openPdfPrint'],

  data () {
    return {
      templatesDialogOpen: false,
      dialogOpen: false,
      loading: false,
      listeners: [],
      defaultAssociates: [],
      associates: [],
      fiscalize: false,
      payerName: undefined,
      payer: undefined,
      payerAddress: undefined,
      payerCity: undefined,
      payerZip: undefined,
      payerCountry: undefined,
      payerOib: undefined,
      rules: {
        req: rules.req(),
        oib: rules.oib()
      },
      inEu: false,
      languages: [],
      language: {},
      note: undefined,
      receiptNumber: 1,
      offer_sequence: undefined,
      bill_sequence: undefined,
      last_receipt: undefined,
      minIssueDate: undefined,
      flag: undefined,
      doSaveAccount: false,
      saveAssociateEnabled: true,
      submitting: false,
      euCountries: [],
      takenOibs: [],
      selectedVies: undefined,
      selectedCustomerType: undefined,
      wholeSaleRcptTypes: undefined
    }
  },

  props: {
    data: {
      type: Object,
      default: () => {}
    },
    r1DialogOpen: {
      type: Boolean,
      default: false
    },
    paymentMethod: {
      type: String,
      default: ''
    },
    // selectedTable: {
    //   type: Object,
    //   default: () => {}
    // },
    // selectedTables: {
    //   type: Array,
    //   default: () => []
    // },
    selectedCard: {
      type: Object,
      default: () => {}
    },
    selectedPrinter: {
      type: Object,
      default: () => {}
    },
    selectedTerminal: {
      type: Object,
      default: () => {}
    },
    showPreview: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    payerCountry (nv) {
      this.inEu = false
      this.euCountries.forEach(country => {
        if (country === nv) {
          this.inEu = true
        }
      })
    },
    storeReceiptNumber (nv) {
      this.receiptNumber = nv + 1
    },
    payerOib (nv) {
      if (nv && nv.length === 11) {
        if (this.takenOibs.includes(nv)) {
          this.doSaveAccount = false
          this.saveAssociateEnabled = false
        } else {
          this.saveAssociateEnabled = true
        }
      }
    }
  },

  computed: {
    vieses () {
      return [
        { name: 'Kupac unutar VIES baze', value: 'inside' },
        { name: 'Kupac izvan VIES baze', value: 'outside' }
      ]
    },
    customerTypes () {
      return [
        { name: 'Kupac - pravna osoba', value: 'legal' },
        { name: 'Kupac - fizička osoba', value: 'person' }
      ]
    },
    storeReceiptNumber () {
      if (this.receiptType && this.receiptType.value === 'P') {
        return this.offer_sequence
      } else {
        return this.bill_sequence
      }
    }
  },

  methods: {
    setDefaults () {
      this.fiscalize = true
      this.languages = [
        { name: this.$t('$vuetify.receiptLanguages.hr'), value: 'hr' },
        { name: this.$t('$vuetify.receiptLanguages.en'), value: 'en' }
      ]
      this.language = this.languages[0]
      this.note = ''
      this.flag = 'R'
    },

    setForm (data = true) {
      if (data) {
        this.referenceNumber = ''
        if (this.payer && typeof this.payer === 'object') {
          // this.payerCountry = this.payer.country
          this.payerCountry = 'Hrvatska'
          this.payerOib = this.payer.oibs ? this.payer.oibs[0] : ''
          this.payerAddress = this.payer.address
          this.payerCity = this.payer.city
          this.payerZip = this.payer.zip_code

          this.saveAssociateEnabled = false
        }
      } else {
        this.referenceNumber = ''
        this.associates = clone(this.defaultAssociates)
        this.saveAssociateEnabled = true
        this.$nextTick(() => {
          // this.$refs.accountForm.reset()
        })
      }
    },

    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.paymentModels = doc.data().payment_models
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },

    getEuCountries () {
      const listener = df.doc('project_constants/countries').onSnapshot(doc => {
        this.euCountries = doc.data().eu_countries
      })
      this.listeners.push(listener)
    },

    querySelections () {
      if (this.payerName) {
        this.associates = this.defaultAssociates.filter((associate) =>
          associate.name.toLowerCase().includes(this.payerName.toLowerCase())
        )
      } else {
        this.associates = clone(this.defaultAssociates)
      }
    },

    backwardStep () {
      this.setForm(false)
      this.$emit('closeR1Dialog')
    },

    dateTimeToUnixTimestamp (dt) {
      return Math.floor(new Date(toISOLocal(dt)).getTime() / 1000)
    },

    async finishPayment () {
      if (!this.$refs.accountForm.validate()) return
      const type = {
        flag: this.flag,
        payer_name: this.payerName,
        payer_address: this.payerAddress,
        payer_city: this.payerCity,
        payer_zip_code: this.payerZip,
        payer_country: this.payerCountry,
        payer_oib: this.payerOib
      }

      if (this.doSaveAccount) {
        if (this.takenOibs.includes(type.payer_oib)) {
          console.log('oib vec postoji')
          this.doSaveAccount = false
          this.saveAssociateEnabled = false
          return
        }
        this.saveAssociate()
      }

      let wholeSaleRcptType = ''

      // POTREBNO ZBOG ISPISA ČLANKA NA RAČUNU
      if (this.payerCountry !== undefined && this.payerCountry !== 'Hrvatska') {
        if (state.getCurrentCompany().is_taxed === true) {
          if (this.inEu) {
            if (this.selectedVies === 'inside') {
              wholeSaleRcptType = 'TAX_EU_VIES_TRUE'
            } else {
              wholeSaleRcptType = 'TAX_EU_VIES_FALSE'
            }
          } else {
            if (this.selectedCustomerType === 'person') {
              wholeSaleRcptType = 'TAX_OTHER_PERSON'
            } else {
              wholeSaleRcptType = 'TAX_OTHER_LEGAL_ENTITY'
            }
          }
        } else {
          if (this.inEu) {
            if (this.selectedVies === 'inside') {
              wholeSaleRcptType = 'EU_VIES_TRUE'
            } else {
              wholeSaleRcptType = 'EU_VIES_FALSE'
            }
          } else {
            if (this.selectedCustomerType === 'person') {
              wholeSaleRcptType = 'OTHER_PERSON'
            } else {
              wholeSaleRcptType = 'OTHER_LEGAL_ENTITY'
            }
          }
        }
      }

      const data = {
        type: type,
        card: this.selectedCard,
        wholesale_receipt_type: wholeSaleRcptType
      }

      if (this.showPreview === false) {
        this.$emit('accountDetailsFinishPayment', data)
      } else {
        this.$emit('accountDetailsPreviewReceipt', data)
      }

      this.setForm(false)
      this.$emit('closeR1Dialog')
    },

    saveAssociate () {
      const reqId = uuidv4()
      const oibs = [this.payerOib]
      const payload = {
        action: {
          operation: 'set',
          entity: 'associate',
          params: {
            company_id: state.getCurrentCompany().id,
            name: this.payerName.toLowerCase(),
            oibs: oibs,
            address: this.payerAddress.toLowerCase(),
            city: this.payerCity.toLowerCase(),
            zip_code: this.payerZip,
            country: this.payerCountry,
            associate_type: { BUYER: true }
          }
        }
      }

      try {
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'company',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function () {
            console.info('Associate saved.')
          })
          .catch(function (err) {
            console.error('An error has occured: Associate has not been saved')
            console.error('Error:', err)
          })
      } catch (err) {
        console.error('An error has occured: Associate has not been saved')
        console.error('Error:', err)
      }
    },

    // async finishPayment () {
    //   if (this.selectedTable === undefined || this.selectedTable.id === '') {
    //     this.$emit('imidiatePrintPrepare', this.data.items)
    //   }
    //   if (!this.$refs.accountForm.validate()) return

    //   let card
    //   if (this.selectedCard && this.selectedCard !== '') {
    //     card = this.selectedCard
    //   }

    //   const type = {
    //     flag: this.flag,
    //     payer_name: this.payerName,
    //     payer_address: this.payerAddress,
    //     payer_city: this.payerCity,
    //     payer_zip_code: this.payerZip,
    //     payer_country: this.payerCountry,
    //     payer_oib: this.payerOib
    //   }

    //   var currency = 'HRK'
    //   if (Date.now() / 1000 > 1672527599) {
    //     currency = 'EUR'
    //   }

    //   try {
    //     this.showLoader(this.$options.filters.money(this.data.total, 100, currency), { opacity: 1 })

    //     const items = await remapItems(this.data)

    //     let entity = 'receipt'
    //     if (this.paymentMethod === 'V') {
    //       entity = 'virman'
    //     }

    //     const paymentMethod = getPaymentMethod(this.paymentMethod)

    //     const args = {
    //       entity: entity,
    //       paymentMethod: paymentMethod,
    //       paymentReference: '',
    //       language: this.language,
    //       type: type,
    //       fiscalize: this.fiscalize,
    //       receiptNumber: this.receiptNumber
    //     }

    //     let payload = await getPayload(this.data, items, args, state)
    //     if (entity === 'virman') {
    //       payload = await getVirmanPayload(this.data, items, args, state)
    //     }

    //     if (this.selectedTerminal) {
    //       payload.terminal = this.selectedTerminal
    //     }

    //     // payload.action.params.payment_due = paymentDueDate
    //     // payload.action.params.seller_oib = ''

    //     const httpArgs = {
    //       issueDateTime: this.dateTimeToUnixTimestamp(new Date()),
    //       paymentMeth: paymentMethod,
    //       language: this.language,
    //       type: this.type,
    //       receiptNumber: this.receiptNumber,
    //       openPdfPrint: this.openPdfPrint,
    //       hideLoader: this.hideLoader
    //       // removeOrderFromTable: this.removeOrderFromTable
    //     }

    //     if (card && card !== '') {
    //       httpArgs.paymentCard = card
    //     }

    //     if (entity === 'virman') {
    //       sendVirmanHttp(this.data, items, payload, httpArgs, this.selectedPrinter)
    //     } else {
    //       sendHttp(this.data, items, payload, httpArgs, this.selectedPrinter)
    //     }
    //   } catch (err) {
    //     console.log(err)
    //     this.showMsgBox({
    //       text: err && err !== '' ? err : 'An error has occurred',
    //       actions: ['cancel'],
    //       cancelBtnText: this.$t('$vuetify.close'),
    //       color: 'error'
    //     })
    //   }
    //   this.setForm(false)
    //   this.$emit('closeR1Dialog')
    // },

    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    }
  },

  created () {
    this.dialogOpen = true
  },

  mounted () {
    // document.getElementById('oibInput').click()
    if (state.getPrinter() === 'BIXOLON80') {
      const issueID = 1
      printerInit(issueID)
      state.setPosPrinterSettings(2)
    }

    this.setDefaults()
    this.getMultiplier()
    this.getEuCountries()

    this.payerCountry = 'Hrvatska'

    df.doc(`company_associates/${state.getCurrentCompany().id}`)
      .get()
      .then((resp) => {
        if (resp && resp.data()) {
          this.defaultAssociates = Object.keys(resp.data().associates)
            .map((key) => {
              return resp.data().associates[key]
            })
            .filter((associate) => associate.status === 'OK')

          this.defaultAssociates.push({ name: 'Kupac građanin', id: 'kupac-gradanin' })
          // console.log(this.associates)
          this.associates = clone(this.defaultAssociates.sort((a, b) => a.name.localeCompare(b.name)))
          // Get all used up OIBs
          this.associates.forEach(item => {
            if (item?.oibs && item.oibs.length > 0) {
              item.oibs.forEach(oib => {
                this.takenOibs.push(oib)
              })
            }
          })
        }
      })

    this.minIssueDate = toISOLocal(new Date())
    this.listeners.push(
      df
        .doc(`cash_registers/${state.getCashRegister().id}`)
        .onSnapshot((doc) => {
          if (doc && doc.data()) {
            this.offer_sequence = doc.data().offer_sequence
            this.bill_sequence = doc.data().bill_sequence
            this.last_receipt = doc.data().last_receipt_issued_timestamp
            if (this.last_receipt) {
              this.minIssueDate = toISOLocal(
                new Date(this.last_receipt * 1000)
              )
            } else {
              this.minIssueDate = toISOLocal(new Date())
            }
          }
        })
    )
  },
  beforeDestroy () {
    this.detachListeners()
  }
}
</script>

<style scoped>
::v-deep .small-radio i {
  font-size: 19px;
}

::v-deep .small-radio label {
  font-size: 14px;
  padding-left: 0px;
  margin-left: -4px;
}
::v-deep .small-radio .v-radio {
  padding: 0px;
}
::v-deep .small-radio [class*="__ripple"] {
  left: 0;
}

.input-row {
  max-height: 4.5rem;
}
</style>
