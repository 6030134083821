<template>
  <div class="template-root" :style="`margin-left: ${margin}`">
    <v-row>
      <v-col cols="8">
        <v-row>
          <v-col class="register-invoice" cols="12">
            <register-invoice-rename />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row>
          <v-col cols="12">
            <register-toolbar style="margin-top: 0" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <register-actions />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <register-table-actions />
            <register-tables
              @registerTablesFinishPayment="onRegisterTablesFinishPayment"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="total-row" cols="12">
        <totals-row />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="register-tiles" :style="`margin-left: ${margin}`" cols="12">
        <register-tiles />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import registerCommons from '@/mixins/registerCommons'
import RegisterTiles from '@/modules/cash-register/components/RegisterTiles'
import RegisterInvoiceRename from '@/modules/cash-register/components/RegisterInvoiceRename'
import TotalsRow from '@/modules/cash-register/components/TotalsRow'
import RegisterToolbar from '@/modules/cash-register/components/RegisterToolbar'
import RegisterActions from '@/modules/cash-register/components/RegisterActions'
import RegisterTables from '@/modules/cash-register/components/RegisterTables'
import RegisterTableActions from '@/modules/cash-register/components/RegisterTableActions'

export default {
  components: {
    RegisterTiles,
    RegisterInvoiceRename,
    TotalsRow,
    RegisterToolbar,
    RegisterActions,
    RegisterTables,
    RegisterTableActions
  },

  mixins: [registerCommons],

  async created () {
    this.margin = state.getMargin()
  },

  mounted () {
    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })
  },
  methods: {
    onRegisterTablesFinishPayment (data) {

    }
  }
}
</script>

<style scoped>
.template-root {
  overflow: hidden;
}

.total-row {
  padding: 1rem 2rem 0 0;
  /* margin-left: 56px; */
  height: 4rem;
  color: white;
  background-color: #2c5a90;
  position: relative;
  bottom: 23px;
  text-align: right;
}

.register-tiles {
  position: absolute;
  bottom: -222px;
  right: 0;
  left: 0;
}
</style>
