<template>
  <div>
    <v-row v-if="showComponent">
      <v-col
        v-for="(btn, i) in sortedButtons"
        :key="i"
        :cols="colspan"
        style="padding: 0 2px 2px 0"
      >
        <v-btn
          v-if="!btn.submenu"
          :disabled="!buttonsEnabled"
          block
          tile
          depressed
          min-height="3.5rem"
          color="#2C5A90"
          @click="onButtonClick(btn)"
        >
          <span class="white--text tile btn-lg">{{ btn.label }}</span>
        </v-btn>
        <v-menu v-else bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              block
              tile
              depressed
              min-height="3.5rem"
              icon
              :class="[
                { 'disable-events': !buttonsEnabled },
                'white--text',
                { darkblue: buttonsEnabled },
              ]"
              v-bind="attrs"
              v-on="on"
              @click="checkCards()"
            >
              <span class="white--text tile btn-lg">{{ btn.label }}</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, idx) in btn.submenu"
              :key="idx"
              @click="
                item.type !== 'K'
                  ? onMenuItemClick(item.method, item.type, item)
                  : checkCards()
              "
            >
              <v-list-item-title v-if="!item.submenu">{{
                item.text
              }}</v-list-item-title
              ><v-list-item-title v-else>
                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-bind="attrs" v-on="on">{{
                      $t("$vuetify.paymentMethods.card")
                    }}</v-list-item-title>
                  </template>
                  <v-list v-if="availableCards && availableCards.length > 0">
                    <!-- TODO Forward card to payment -->
                    <v-list-item
                      v-for="(card, idx) in availableCards"
                      :key="idx"
                      @click="insertAccountDetails('K', 'R', undefined, card)"
                    >
                      <v-list-item-title>{{ card.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <!-- <v-list>
                    <v-list-item
                      v-for="(card, i) in item.submenu"
                      :key="i"
                      @click="onSubmenuItemClick('K', 'R', card)"
                    >
                      <v-list-item-title>{{ card.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list> -->
                </v-menu>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <account-details-dialog
      v-if="r1DialogOpen"
      :data="data"
      :paymentMethod="paymentMethod"
      :selectedCard="selectedCard"
      :selectedPrinter="selectedPrinter"
      @closeR1Dialog="onR1DialogClose"
      @imidiatePrintPrepare="imidiatePrintPrepare"
      @accountDetailsFinishPayment="onAccountDetailsFinishPayment"
    ></account-details-dialog>
    <terminals ref="terminals" @terminalSelected="terminalSelected"></terminals>
  </div>
</template>

<script>
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import { df } from '@/plugins/firebase'
import { toISOLocal, clone } from '@/plugins/utils'
import { remapItems } from '@/libs/receiptIssuing/items'
import { getPayload, getVirmanPayload } from '@/libs/receiptIssuing/payload'
import { getPaymentMethod } from '@/libs/receiptIssuing/commons'
import { sendHttp, sendVirmanHttp } from '@/libs/receiptIssuing/payment'
import { printOrder } from '@/libs/bixolon/order'
import AccountDetailsDialog from '@/modules/cash-register/components/dialogs/AccountDetailsDialog'
import { retry } from '@/libs/receiptIssuing/validation'
import Terminals from '@/modules/cash-register/components/dialogs/Terminals.vue'

export default {
  components: {
    AccountDetailsDialog,
    Terminals
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'confirm', 'confirmClose', 'openPdfPrint'],
  data () {
    return {
      data: {},
      r1DialogOpen: false,
      languages: [],
      language: null,
      receiptNumber: 1,
      bill_sequence: 0,
      offer_sequence: 0,
      last_receipt: '',
      receiptType: '',
      multiplier: 0,
      issueDate: '',
      minIssueDate: '',
      maxIssueDate: '',
      type: '',
      paymentMethod: '',
      orgUnits: [],
      printers: [],
      selectedPrinter: {},
      listeners: [],
      selectedTable: { },
      selectedCard: '',
      paymentMenu: [
        { text: this.$t('$vuetify.paymentMethods.cash'), type: 'G', icon: 'mdi-cash' },
        { text: this.$t('$vuetify.paymentMethods.card'), type: 'K', icon: 'mdi-card' },
        { text: this.$t('$vuetify.paymentMethods.virman'), type: 'V', icon: 'mdi-card' }
      ],
      availableCards: [],
      selectedTerminal: undefined,
      showComponent: false,
      sortedButtons: [],
      templateConfig: {
        tables: {
          show: true
        },
        toolbar: {
          buttons: [],
          submenu_options: []
        },
        register_actions: {
          buttons: []
        }
      },
      colspan: 4,
      receiptNote: null
    }
  },

  computed: {
    storeReceiptNumber () {
      if (this.receiptType && this.receiptType.value === 'P') {
        return this.offer_sequence
      } else {
        return this.bill_sequence
      }
    },
    buttonsEnabled () {
      return this.data.items ? this.data.items.length > 0 : false
    }
  },

  watch: {
    storeReceiptNumber (nv) {
      this.receiptNumber = nv + 1
    }
  },

  methods: {
    // Provjera i ponasanje ako nema aktivnih kartica
    checkCards () {
      if (!this.availableCards || this.availableCards.length === 0) {
        if (this.cashRegisterForTerminal.terminals) {
          var terms = this.cashRegisterForTerminal.terminals
          var termsArray = []
          if (terms && Object.keys(terms).length > 0) {
            Object.keys(terms).forEach(key => {
              termsArray.push(terms[key])
            })

            // Ako postoji vise terminala, dopusti odabir bilo kojeg
            if (termsArray && termsArray.length > 1) {
              this.$refs.terminals.open(this.cashRegisterForTerminal, undefined, 'K', 'BO')
            } else if (termsArray && termsArray.length === 1) {
              // Ako postoji ima samo jedan terminal, salji na njega
              this.finishPayment('K', 'BO', undefined, undefined, termsArray[0])
            } else {
              // Ako nema ni jedan terminal, samo napravi racun
              this.finishPayment('K', 'BO', undefined, undefined)
            }
          }
        } else {
          // Ako nema ni jedan terminal, samo napravi racun
          this.finishPayment('K', 'BO', undefined, undefined)
        }
      }
    },
    getCards () {
      const that = this

      df
        .doc(`company_cards/${state.getCurrentCompany().id}`)
        .onSnapshot((doc) => {
          if (
            doc &&
             doc.data() &&
             doc.data().cards &&
           doc.data().cards.length > 0
          ) {
            that.availableCards = doc.data().cards.filter(card => card.active === true)
          }
          that.availableCards = that.availableCards.sort((a, b) => a.name.localeCompare(b.name))
        })
    },
    onButtonClick (item) {
      this.finishPayment('G', 'BO')
    },

    onMenuItemClick (paymentMethod, receiptType, card) {
      if (receiptType === 'R') {
        this.insertAccountDetails(paymentMethod, 'R')
      } else {
        this.finishPayment(paymentMethod, 'BO', undefined, card)
      }
    },

    onSubmenuItemClick (paymentMethod, receiptType, card) {
      this.finishPayment('K', 'R', undefined, card)
    },

    onR1DialogClose () {
      this.r1DialogOpen = false
    },

    confirmReprezentacija () {
      this.confirm({
        title: 'Reprezentacija',
        message: 'Ovaj račun će biti reprezentacija, jeste li sigurni da želite nastaviti?',
        options: {
          toolbar: true,
          width: 410,
          confirmText: this.$t('$vuetify.yes'),
          cancelText: this.$t('$vuetify.no')
        }
      }).then((resp) => {
        if (resp) {
          this.confirmClose()
          this.finishPayment('O', 'REPREZENTACIJA')
        }
      })
    },

    menuPaymentClick (menu) {
      switch (menu.type) {
        case 'G':
          this.insertAccountDetails('G', 'R')
          break
        case 'K':
          this.insertAccountDetails('K', 'R')
          break
        case 'V':
          this.insertAccountDetails('V', 'R')
      }
    },

    async imidiatePrintPrepare (items) {
      const itemsInOrgUnits = {}
      if (items && items.length > 0) {
        items.forEach(item => {
          if (item.organizational_unit_id && item.organizational_unit_id !== '') {
            if (!itemsInOrgUnits[item.organizational_unit_id]) {
              itemsInOrgUnits[item.organizational_unit_id] = []
            }
            itemsInOrgUnits[item.organizational_unit_id].push(item)
          }

          if (item.choosenProductOrder) {
            Object.keys(item.choosenProductOrder).forEach(key => {
              if (item.choosenProductOrder[key] && item.choosenProductOrder[key].length > 0) {
                item.choosenProductOrder[key].forEach(product => {
                  if (product && product.productItems) {
                    product.productItems.forEach(it => {
                      if (it.org_units && it.org_units[state.getPointOfSale().id]) {
                        if (item.choosenTagOrder[key]) {
                          it.tags = item.choosenTagOrder[key]
                        }
                        it.menuId = key
                        it.menuData = {
                          id: item.id,
                          name: item.name
                        }

                        if (!itemsInOrgUnits[it.org_units[state.getPointOfSale().id]]) {
                          itemsInOrgUnits[it.org_units[state.getPointOfSale().id]] = []
                        }

                        itemsInOrgUnits[it.org_units[state.getPointOfSale().id]].push(it)
                      }
                    })
                  }
                })
              }
            })
          }
        })

        var otherUnits = []
        Object.keys(itemsInOrgUnits).forEach(unitId => {
          this.orgUnits.forEach(orgUnit => {
            if (orgUnit.id === unitId) {
              otherUnits.push(orgUnit)
            }
          })
        })

        Object.keys(itemsInOrgUnits).forEach(unitId => {
          this.orgUnits.forEach(orgUnit => {
            if (orgUnit.id === unitId) {
              this.printers.forEach(printer => {
                // TODO maknuti ovu provjeru sa name, to je izmjena u id
                if (printer.id === orgUnit.printer || printer.name === orgUnit.printer) {
                  this.imidiatePrint(itemsInOrgUnits[unitId], printer, orgUnit, otherUnits)
                }
              })
            }
          })
        })
      }
    },

    async imidiatePrint (items, printer, orgUnit, otherUnits) {
      state.setPosPrinterSettings(2)
      printOrder(items, printer, undefined, undefined, orgUnit, otherUnits, undefined, this.hideLoader)
    },

    setDefaults () {
      this.languages = [
        { name: this.$t('$vuetify.receiptLanguages.hr'), value: 'hr' },
        { name: this.$t('$vuetify.receiptLanguages.en'), value: 'en' }
      ]
      this.language = this.languages[0]
      this.maxIssueDate = toISOLocal(new Date())
      this.issueDate = ''
    },

    // async removeOrderFromTable () {
    //   EventBus.$emit('clear-selection')
    //   // this.selectedTable.item

    dateTimeToUnixTimestamp (dt) {
      return Math.floor(new Date(toISOLocal(dt)).getTime() / 1000)
    },

    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },

    async getCashRegisterNumber (receiptType) {
      var cashRegister = await retry(this.getCashRegister, 3)

      if (!cashRegister) {
        return
      }
      if (receiptType === 'P') {
        return cashRegister.offer_sequence + 1
      } else {
        return cashRegister.bill_sequence + 1
      }
    },

    async getCashRegister () {
      const response = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      if (!response.data()) {
        throw new Error('Nije moguće dohvatiti podatke o blagajni')
      } else {
        return response.data()
      }
    },

    insertAccountDetails (method, receiptType, deliveryName = undefined, paymentCard = undefined) {
      if (receiptType === 'R') {
        if (paymentCard && paymentCard.name && paymentCard.name !== '') {
          this.selectedCard = paymentCard.name
        }
        this.paymentMethod = method
        this.r1DialogOpen = true
      }
    },
    onAccountDetailsFinishPayment (data) {
      this.type = data.type

      if (this.paymentMethod === 'K') {
        this.checkTerminal(this.paymentMethod, 'R', undefined, this.selectedCard)
      } else {
        this.finishPayment(this.paymentMethod, 'R')
      }
    },

    /// FINISH PAYMENT
    async finishPayment (method, receiptType, deliveryName = undefined, paymentCard = undefined, terminal = undefined) {
      if (this.selectedTable === undefined || this.selectedTable.id === '') {
        this.imidiatePrintPrepare(this.data.items)
      }

      const currency = 'EUR'

      if (receiptType === 'REPREZENTACIJA') {
        var reprezentacijaAmount = 200
        if (Date.now() / 1000 > 1672527599) {
          reprezentacijaAmount = 50
        }
        this.showLoader(this.$options.filters.money(reprezentacijaAmount, 100, currency), { opacity: 1 })
      } else {
        this.showLoader(this.$options.filters.money(this.data.total, 100, currency), { opacity: 1 })
      }

      let entity = 'receipt'
      if (this.paymentMethod === 'V') {
        entity = 'virman'
      }
      const flag = 'BO'
      const fiscalize = true

      try {
        const items = await remapItems(this.data, method)
        const paymentMethod = getPaymentMethod(method)

        const args = {
          entity: entity,
          paymentMethod: paymentMethod,
          paymentReference: '',
          language: this.language,
          fiscalize: fiscalize,
          type: { flag: flag }
        }

        if (this.type) {
          args.type = clone(this.type)
          this.type = ''
        }

        try {
          args.receiptNumber = await this.getCashRegisterNumber(receiptType)
        } catch (err) {
          this.hideLoader()
          EventBus.$emit('showError', { message: err })
          return
        }

        let payload = await getPayload(this.data, items, args, state, this.receiptNote)
        if (entity === 'virman') {
          payload = await getVirmanPayload(this.data, items, args, state, this.receiptNote)
        }

        if (this.receiptNote && this.receiptNote.language) {
          this.language = { value: this.receiptNote.language }
        }

        if (terminal) {
          payload.terminal = terminal
        }

        const httpArgs = {
          issueDateTime: this.dateTimeToUnixTimestamp(new Date()),
          paymentMeth: paymentMethod,
          language: this.language,
          type: this.type,
          openPdfPrint: this.openPdfPrint,
          hideLoader: this.hideLoader
          // removeOrderFromTable: this.removeOrderFromTable
        }

        try {
          httpArgs.receiptNumber = await this.getCashRegisterNumber(receiptType)
        } catch (err) {
          this.hideLoader()
          EventBus.$emit('showError', { message: err })
          return
        }

        if (receiptType && receiptType !== '') {
          switch (receiptType) {
            case 'REPREZENTACIJA':
              httpArgs.reprezentacija = true
              break
            case 'DELIVERY':
              httpArgs.delivery = true
              httpArgs.deliveryName = deliveryName
              break
          }
        }

        if (paymentCard && paymentCard.type && paymentCard.type !== '') {
          httpArgs.paymentCard = paymentCard.type
        }

        if (paymentCard && paymentCard.name && paymentCard.name !== '') {
          httpArgs.paymentCard = paymentCard.name
        }

        if (entity === 'virman') {
          sendVirmanHttp(this.data, items, payload, httpArgs, this.selectedPrinter)
        } else {
          sendHttp(this.data, items, payload, httpArgs, this.selectedPrinter)
        }

        this.receiptNote = null
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      }
    },
    terminalSelected (terminal, paymentCard, method, receiptType) {
      this.selectedTerminal = terminal
      this.finishPayment(method, receiptType, undefined, paymentCard, terminal)
    },
    checkTerminal (method, receiptType, deliveryName = undefined, paymentCard = undefined) {
      var termsWithCards = []

      if (this.cashRegisterForTerminal.terminals) {
        var terms = this.cashRegisterForTerminal.terminals

        Object.keys(terms).forEach(key => {
          if (terms[key].cards && terms[key].cards.length === 0) {
            termsWithCards.push(terms[key])
          }

          if (terms[key].cards && terms[key].cards.length > 0 && terms[key].cards.some(card => card.ID === paymentCard.id)) {
            termsWithCards.push(terms[key])
          }
        })
      }

      if (termsWithCards) {
        switch (termsWithCards.length) {
          case 0:
            this.finishPayment(method, receiptType, undefined, paymentCard)
            break
          case 1:
            var terminal = termsWithCards[0]
            this.finishPayment(method, receiptType, undefined, paymentCard, terminal)
            break
          default:
            this.$refs.terminals.open(this.cashRegisterForTerminal, paymentCard, method, receiptType)
            break
        }
      }
    }
  },

  created () {
    this.selectedPrinter = state.getSelectedPrinter()
  },

  mounted () {
    this.getCards()
    const interval = setInterval(() => {
      if (state.getTemplateConfig()) {
        this.templateConfig = state.getTemplateConfig()
        this.showComponent = this.templateConfig.register_actions && this.templateConfig.register_actions.buttons && this.templateConfig.register_actions.buttons.length > 0
        this.sortedButtons = clone(this.templateConfig.register_actions.buttons.sort((a, b) => a.position - b.position))
        if (this.sortedButtons) {
          this.colspan = Math.round(12 / this.sortedButtons.length)
        }
        clearInterval(interval)
      }
    }, 100)

    EventBus.$on('cart-has-changed', (data) => {
      this.data = clone(data)
    })

    EventBus.$on('update-table-selection', (table) => {
      this.selectedTable = table
    })

    EventBus.$on('confirm-reprezentacija', () => {
      this.confirmReprezentacija()
    })

    EventBus.$on('delivery-finish-simple-payment', (delivery, paymentType) => {
      this.finishPayment(paymentType, 'BO', delivery, '')
    })

    EventBus.$on('set-selected-printer', (printer) => {
      this.selectedPrinter = printer
    })

    EventBus.$on('save-receipt-note', (receiptNote) => {
      this.receiptNote = receiptNote
    })

    this.getMultiplier()
    this.setDefaults()
    this.minIssueDate = toISOLocal(new Date())
    this.listeners.push(
      df
        .doc(`cash_registers/${state.getCashRegister().id}`)
        .onSnapshot((doc) => {
          if (doc && doc.data()) {
            this.offer_sequence = doc.data().offer_sequence
            this.bill_sequence = doc.data().bill_sequence
            this.last_receipt = doc.data().last_receipt_issued_timestamp
            if (this.last_receipt) {
              this.minIssueDate = toISOLocal(
                new Date(this.last_receipt * 1000)
              )
            } else {
              this.minIssueDate = toISOLocal(new Date())
            }
          }
        })
    )

    this.orgUnits = []
    let query = df.collection(`location_units/${state.getPointOfSale().id}/units`)
    let listener = query.onSnapshot((doc) => {
      doc.docs.forEach((rec) => {
        this.orgUnits.push(rec.data())
      })
    })
    this.listeners.push(listener)

    this.printers = []
    query = df.collection(`location_printers/${state.getPointOfSale().id}/printers`)
    listener = query.onSnapshot((doc) => {
      doc.docs.forEach((rec) => {
        this.printers.push(rec.data())
      })
    })
    this.listeners.push(listener)
  }
}
</script>

<style scoped>
@media only screen and (max-height: 899px) {
  span.tile {
    display: block;
    white-space: normal;
    max-width: 180px;
  }
}

@media only screen and (min-height: 900px) {
  span.tile {
    display: block;
    white-space: normal;
    max-width: 120px;
  }
}
.disable-events {
  pointer-events: none;
  background-color: #e0e0e0;
}
.darkblue {
  background-color: #2c5a90;
}
.btn-lg {
  font-size: 1rem;
  font-weight: 700;
}
</style>
