<template>
  <div>
    <v-dialog :max-width="width" v-model="dialog" @keydown.esc="close">
      <v-card tile color="white">
        <v-card-title>
          <h2 class="text--secondary">Odaberite prodavača</h2>
          <v-spacer></v-spacer>
          <v-icon large @click="close">mdi-close</v-icon>
        </v-card-title>
      </v-card>
      <v-card-text class="white">
        <v-card-text>
          <div
            class="row"
            v-for="rowIdx in Math.ceil(sellers.length / 4)"
            :key="rowIdx"
          >
            <div
              class="one-fourth column"
              v-for="seller in sellers.slice(4 * (rowIdx - 1), 4 * rowIdx)"
              :key="seller.id"
            >
              <div class="pl-5 pb-3 text-center">
                <v-btn
                  :color="stringToHslColor(seller.name)"
                  fab
                  x-large
                  dark
                  @click="menuClicked(seller)"
                >
                  <v-icon>mdi-account-circle</v-icon>
                </v-btn>
                <div
                  style="width: 100px"
                  class="text-capitalize text-center pt-1 font-weight-bold"
                >
                  {{ seller.name }} {{ seller.surname }}
                </div>
              </div>
            </div>
          </div>
          <v-row> </v-row>
        </v-card-text>
      </v-card-text>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      width: '550px',
      sellers: []
    }
  },

  methods: {
    open (sellers) {
      this.sellers = sellers
      this.sellers = this.sellers.sort((a, b) => a.name.localeCompare(b.name))
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    menuClicked (seller) {
      this.$emit('sellerSelected', seller)
      this.close()
    },
    stringToHslColor (str, s, l) {
      var hash = 0
      s = 30
      l = 50
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }

      var h = hash % 360
      return 'hsl(' + h + ', ' + s + '%, ' + l + '%)'
    }
  }
}
</script>

<style scoped>
@media only screen and (max-height: 899px) {
  #container {
    max-height: 193px;
  }
  span.tile {
    display: block;
    white-space: normal;
  }
}

@media only screen and (min-height: 900px) {
  #container {
    max-height: 440px;
  }
  span.tile {
    display: block;
    white-space: normal;
  }
}
.btn-lg {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>
