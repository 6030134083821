<template>
  <div>
    <confirm ref="confirmStorno" @selection="cancelReceipt" />
    <confirm ref="confirmFiscalize" @selection="fiscalizeReceipt" />
    <v-container>
      <div v-if="modal && !hideFilter">
        <v-col cols="4" sm="4" md="3" xl="2" class="pa-0 ma-0">
          <v-select
            label="Status računa"
            v-model="statusFilter"
            :items="filters"
            return-object
            item-text="name"
            item-value="value"
          >
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
          </v-select>
        </v-col>
      </div>
      <div v-if="!modal">
        <v-row style="margin-bottom: -2rem" class="d-flex justify-center">
          <v-col cols="1" sm="1" md="1" xl="1" align="center" class="pt-7">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="onExpandFilters"
                >
                  <v-icon dark>
                    {{ expandIcon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{
                !filtersExpanded
                  ? $t("$vuetify.showAdditionalFilters")
                  : $t("$vuetify.hideAdditionalFilters")
              }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3" sm="3" md="2" xl="1">
            <v-select
              label="Tip dokumenta"
              v-model="reciptOffer"
              :items="reciptOfferChoices"
              item-text="name"
              item-value="value"
              @change="getReceiptsOrOffers"
            >
            </v-select>
          </v-col>
          <v-col cols="3" sm="3" md="2" xl="2">
            <v-select
              :label="$t('$vuetify.companyTabs.pointsOfSale')"
              v-model="location"
              :items="locs"
              return-object
              item-text="name"
              item-value="location_id"
            >
              <template v-slot:item="{ item }">
                {{ item.name }}{{ item.id !== "" ? "," : "" }}
                {{ item.address }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="4" sm="4" md="3" xl="2">
            <v-select
              label="Status računa"
              v-model="statusFilter"
              :items="filters"
              return-object
              item-text="name"
              item-value="value"
            >
              <template v-slot:item="{ item }">
                {{ item.name }}
              </template>
            </v-select>
          </v-col>
          <v-col v-if="unfiskReceipts.length > 0">
            <v-btn
              class="white--text rounded-card"
              max-width="100%"
              width="100%"
              x-large
              color="fiscButton"
              :loading="fiscalizing"
              @click="fiscalizeAll"
              id="fiscalizeBtn"
            >
              {{ $t("$vuetify.receipts.fiscalizing") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-row v-if="filtersExpanded" class="d-flex justify-center">
            <v-col cols="4" sm="4" md="3" xl="2">
              <v-combobox
                :label="$t('$vuetify.formDetails.name')"
                :placeholder="$t('$vuetify.filterPlaceholderLabel')"
                @click="resetField('oib')"
                v-model="oibFilter"
                :loading="loading"
                :items="associates"
                return-object
                item-text="name"
                @change="applyFilter"
                hide-no-data
                clearable
                @click:clear="applyFilter"
                @keydown.enter="applyFilter"
                class="uppercased-input"
              >
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item
                    class="uppercased-input"
                    @keydown.enter.stop=""
                    v-on="on"
                    v-bind="attrs"
                    >{{ item.name }}</v-list-item
                  >
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="4" sm="4" md="3" xl="2">
              <v-text-field
                v-model="oibFilterManual"
                @click="resetField('oibManual')"
                label="OIB"
                @keydown.enter="applyFilter"
                @click:clear="resetField('oibManual')"
              >
                <template #append>
                  <v-icon color="#3F97C5" @click="applyFilter"
                    >mdi-magnify</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="3" xl="2">
              <v-text-field
                v-model="receiptNumberFilter"
                :label="$t('$vuetify.filterReceiptNumber')"
                @keydown.enter="applyFilter()"
              >
                <template #append>
                  <v-icon color="#3F97C5" @click="applyFilter"
                    >mdi-magnify</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-expand-transition>
      </div>
      <v-row class="justify-center">
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-expansion-panels focusable>
            <v-expansion-panel
              v-for="(receipt, i) in receipts"
              :key="i"
              hover
              tabindex="0"
            >
              <v-expansion-panel-header
                height="200"
                :class="[
                  receipt.payment_method !== 'TRANSAKCIJSKI RAČUN' &&
                  receipt.type.flag !== 'P' &&
                  receipt.fiscalize === true &&
                  receipt.zki !== '' &&
                  receipt.jir === '' &&
                  issueBefore(receipt.issue_date_time) > now
                    ? 'status-failed-exp'
                    : 'status-ok-exp',
                  receipt.payment_method !== 'TRANSAKCIJSKI RAČUN' &&
                  receipt.type.flag !== 'P' &&
                  receipt.fiscalize === true &&
                  receipt.zki !== '' &&
                  receipt.jir === '' &&
                  issueBefore(receipt.issue_date_time) < now
                    ? 'status-failed-expired'
                    : 'status-ok-exp',
                  receipt.payment_method === 'TRANSAKCIJSKI RAČUN' &&
                  receipt.type.flag !== 'P' &&
                  (!receipt.payment_due ||
                    parseInt(receipt.payment_due) > now) &&
                  receipt.payment !== undefined &&
                  receipt.payment.current_status !== 'PAID'
                    ? 'status-warn-exp'
                    : '',
                  receipt.payment_method === 'TRANSAKCIJSKI RAČUN' &&
                  receipt.type.flag !== 'P' &&
                  parseInt(receipt.payment_due) < now &&
                  receipt.payment !== undefined &&
                  receipt.payment.current_status !== 'PAID'
                    ? 'status-warn-after-exp'
                    : '',
                  receipt.payment_method === 'TRANSAKCIJSKI RAČUN' &&
                  receipt.type.flag !== 'P' &&
                  receipt.payment !== undefined &&
                  receipt.payment.current_status === 'PAID'
                    ? 'status-ok-exp'
                    : '',
                  receipt.payment_method === 'TRANSAKCIJSKI RAČUN' &&
                  receipt.type.flag === 'P'
                    ? 'status-ok-exp'
                    : '',
                  receipt.payment !== undefined &&
                  receipt.payment.current_status === 'REVERSED'
                    ? 'status-reversed'
                    : '',
                  receipt.type.flag === 'STORNO' ? 'status-storno' : '',
                ]"
                hide-actions
              >
                <v-row>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]"
                  >
                    <div class="caption grey--text text-center">
                      {{
                        reciptOffer === "receipts"
                          ? $t("$vuetify.receipts.receiptNumber")
                          : $t("$vuetify.receipts.offerNumber")
                      }}
                    </div>
                    <div
                      class="text-center"
                      v-html="receipt.receiptNumber"
                    ></div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    style="min-width: 100px; max-width: 100%"
                    :class="[densityPadding, 'flex-grow-1', 'flex-shrink-0']"
                  >
                    <div class="caption grey--text">
                      {{ $t("$vuetify.receipts.customer") }}
                    </div>
                    <div class="text-capitalize">{{ receipt.customer }}</div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding, 'flex-shrink-0']"
                  >
                    <div class="caption grey--text text-right">
                      {{
                        reciptOffer === "receipts"
                          ? $t("$vuetify.receipts.receiptPrice")
                          : $t("$vuetify.receipts.offerPrice")
                      }}
                    </div>
                    <div class="text-right">
                      {{ receipt.total | money(100, receipt.currency) }}
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]"
                  >
                    <div class="caption grey--text text-center">
                      {{ $t("$vuetify.receipts.receiptIssueDate") }}
                    </div>
                    <div class="text-center">
                      {{ receipt.issue_date_time | local }}
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]"
                  >
                    <div class="caption grey--text text-center">
                      {{ $t("$vuetify.receipts.receiptPaymentDue") }}
                    </div>
                    <div class="text-center">
                      {{ receipt.payment_due | local }}
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]"
                  >
                    <div class="d-flex justify-space-between">
                      <div>
                        <div class="caption grey--text text-left">
                          {{ $t("$vuetify.receipts.status") }}
                        </div>
                        <div class="text-left">
                          {{
                            $t(
                              `$vuetify.receipts.${
                                receipt.payment
                                  ? receipt.payment.current_status
                                  : "UNKNOWN_STATUS"
                              }`
                            )
                          }}
                        </div>
                      </div>
                      <div
                        class="d-flex justify-end align-center pl-2"
                        style="z-index: 999"
                      >
                        <v-btn
                          min-height="30"
                          min-width="25"
                          plain
                          rounded
                          @click.native.stop
                          @keypress.enter.prevent="openMenu(receipt)"
                          @click="openMenu(receipt)"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                        <!-- <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              min-height="55"
                              min-width="55"
                              v-bind="attrs"
                              v-on="on"
                              @keypress.enter.stop=""
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item
                              v-for="(item, index) in receipt.receiptOptions"
                              :key="index"
                              style="cursor: pointer"
                              @click="`${item.action(receipt)}`"
                              @keypress.enter.prevent="
                                `${item.action(receipt)}`
                              "
                              :disabled="
                                receipt.payment &&
                                receipt.payment.current_status === 'PROCESSING'
                              "
                            >
                              <v-list-item-title>{{
                                item.title
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu> -->
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" sm="5">
                    <div
                      class="d-flex justify-space-between text-center text-subtitle-2"
                    >
                      Operater: {{ receipt.seller_id }}
                    </div>
                    <div
                      class="d-flex justify-space-between text-center text-subtitle-2"
                    >
                      Način plaćanja: {{ receipt.payment_method }}
                    </div>
                  </v-col>
                </v-row>
                <v-list>
                  <v-row>
                    <v-col>
                      <div
                        class="text-center text-subtitle-2 one-liner-text font-weight-bold"
                      >
                        R. br.
                      </div>
                    </v-col>
                    <v-col>
                      <div
                        class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold"
                      >
                        Naziv
                      </div>
                    </v-col>
                    <v-col>
                      <div
                        class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold"
                      >
                        Količina
                      </div>
                    </v-col>
                    <v-col>
                      <div
                        class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold"
                      >
                        Jedinična cijena
                      </div>
                    </v-col>
                    <v-col>
                      <div
                        class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold"
                      >
                        Cijena
                      </div>
                    </v-col>
                  </v-row>
                  <v-list-item
                    v-for="(item, index) in receipt.items"
                    :key="index"
                    class="pa-0"
                  >
                    <v-row class="pa-0">
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ index + 1 }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.name }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.amt / multiplier }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{
                            item.single_item_price
                              | money(100, receipt.currency)
                          }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.price | money(100, receipt.currency) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
      <generate-receipt-from-offer ref="receiptFromOffer" />
      <generate-receipt-from-virman ref="receiptFromVirman" />
      <send-email ref="ordinaryEmail" @receiptEdit="onReceiptEdit"></send-email>
      <send-email ref="clientWarning" @receiptEdit="onReceiptEdit"></send-email>
      <pay-receipt ref="payReceipt" @receiptEdit="onReceiptEdit" />
      <status-to-unpaid
        ref="statusToUnpaid"
        @receiptEdit="onReceiptEdit"
      ></status-to-unpaid>
      <payment-change
        ref="paymentChanges"
        @receiptEdit="onReceiptEdit"
        @reprintReceipt="onReceiptReprint"
      ></payment-change>
      <add-tip
        ref="addTip"
        @receiptEdit="onReceiptEdit"
        @reprintReceipt="onReceiptReprint"
      ></add-tip>
    </v-container>
    <change-receipt-type-dialog
      ref="chengeReceiptTypeDialog"
    ></change-receipt-type-dialog>
    <crud-template-dialog ref="crudTemplateDialog"></crud-template-dialog>
    <edit-receipt-dialog ref="editReceiptDialog"></edit-receipt-dialog>
    <receipt-menu ref="receiptMenu" @menuClicked="onMenuClicked"></receipt-menu>
  </div>
</template>
<script>
import rules from '@/plugins/rules'
import { df, auth, functions } from '@/plugins/firebase'
import state from '@/state'
import { v4 as uuidv4 } from 'uuid'
import InfiniteLoading from 'vue-infinite-loading'
import Confirm from '@/components/Confirm.vue'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings'
import { clone, toISOLocal } from '@/plugins/utils'
import GenerateReceiptFromOffer from '@/modules/company/components/GenerateReceiptFromOffer'
import GenerateReceiptFromVirman from '@/modules/company/components/GenerateReceiptFromVirman'
import ChangeReceiptTypeDialog from '@/modules/cash-register/components/dialogs/ChangeReceiptTypeDialog.vue'
import PayReceipt from '@/modules/company/components/PayReceipt'
import SendEmail from '@/modules/company/components/SendEmail'
import StatusToUnpaid from '@/modules/company/components/StatusToUnpaid'
import PaymentChange from '@/modules/company/components/PaymentChange'
import AddTip from '@/modules/company/components/AddTip'
import ReceiptMenu from '@/modules/cash-register/components/dialogs/ReceiptMenu.vue'
import { reprint } from '@/libs/bixolon/reprint'
import CrudTemplateDialog from '@/modules/cash-register/components/dialogs/CrudTemplateDialog'
import EditReceiptDialog from '@/modules/cash-register/components/dialogs/EditReceiptDialog'
import EventBus from '@/plugins/event-bus'
import { print58 } from '@/libs/bixolon/print58'
import LogData from '@/model/LogData'
import log from '@/mixins/log'

export default {
  components: {
    InfiniteLoading,
    Confirm,
    GenerateReceiptFromOffer,
    PayReceipt,
    SendEmail,
    StatusToUnpaid,
    GenerateReceiptFromVirman,
    PaymentChange,
    ChangeReceiptTypeDialog,
    CrudTemplateDialog,
    AddTip,
    ReceiptMenu,
    EditReceiptDialog
  },
  mixins: [flow, applicationSettings, log],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader',
    'openPdfPrint',
    'closePdfPrint'
  ],
  data: function () {
    return {
      modal: false,
      loading: false,
      receipts: [],
      receiptsRaw: [],
      unfiskReceipts: [],
      location: {},
      locations: [],
      associates: [],
      statusFilter: {},
      oibFilter: undefined,
      oibFilterManual: undefined,
      receiptNumberFilter: '',
      receiptNumberParsed: null,
      filterChoosen: '',
      loadingReceipts: false,
      lastVisible: {},
      isExecuted: false,
      listeners: [],
      increment: 1,
      selected: {},
      reciptOffer: {},
      multiplier: 0,
      show: false,
      fiscalizing: false,
      filtersExpanded: true,
      expandIcon: 'mdi-minus',
      rules: {
        req: rules.req(),
        oib: rules.oib()
      },
      loadingDialog: false,
      registerIdFilter: undefined,
      locationIdFilter: undefined,
      lastBalanceTimestampFilter: undefined,
      hideFilter: false
    }
  },
  computed: {
    reciptOfferChoices () {
      return [
        { name: this.$tc('$vuetify.receipt', 2), value: 'receipts' },
        { name: this.$tc('$vuetify.offer', 2), value: 'offers' },
        { name: 'Virman', value: 'virmans' }
      ]
    },
    now () {
      return parseInt((`${new Date().getTime()}` / 1000).toFixed(0))
    },
    locs: {
      get: function () {
        return this.locations
      },
      set: function (nv) {
        this.locations = [
          { name: this.$t('$vuetify.all'), id: '', location_id: '' }
        ].concat(nv)
      }
    },
    filters () {
      if (this.reciptOffer === 'receipts') {
        return [
          { name: 'Plaćeni', value: 'payed' },
          { name: 'Neplaćeni', value: 'unpayed' },
          { name: 'Nefiskalizirani', value: 'unfisc' },
          { name: 'Email poslan', value: 'email' },
          { name: 'Opomena poslana', value: 'notice' },
          { name: 'Primljen predujam', value: 'avans' },
          { name: 'Povezan s predujmom', value: 'avans_deducted' },
          { name: 'Svi', value: 'all' }
        ]
      } else {
        return [
          { name: 'Svi', value: 'all' }
        ]
      }
    }
  },
  watch: {
    location: {
      deep: true,
      handler (nv, ov) {
        if (nv !== ov) {
          // paginate more columns
          this.lastVisible = {}
          this.receipts = []
          this.unfiskReceipts = []
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    },
    statusFilter: {
      deep: true,
      handler (nv, ov) {
        if (nv !== ov) {
          // paginate more columns
          this.lastVisible = {}
          this.receipts = []
          this.unfiskReceipts = []
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    }
  },
  mounted () {
    this.oibFilterManual = ''

    if (this.$attrs.receiptFilter) {
      this.oibFilterManual = this.$attrs.receiptFilter.oibFilter
      this.reciptOffer = this.$attrs.receiptFilter.reciptOffer
      this.modal = this.$attrs.receiptFilter.modal
    }

    if (this.$attrs.receiptFilter?.registerId) {
      this.registerIdFilter = this.$attrs.receiptFilter.registerId
    }
    if (this.$attrs.receiptFilter?.locationId) {
      this.locationIdFilter = this.$attrs.receiptFilter.locationId
    }
    if (this.$attrs.receiptFilter?.lastBalanceTimestamp) {
      this.lastBalanceTimestampFilter = this.$attrs.receiptFilter.lastBalanceTimestamp
    }
    if (this.$attrs.receiptFilter?.hideFilter) {
      this.hideFilter = this.$attrs.receiptFilter.hideFilter
    }

    this.getAssociates()
    this.getMultiplier()
    this.locations = [
      { name: this.$t('$vuetify.all'), id: '', location_id: '' }
    ]
    // this.location = this.locations[0]

    // this.reciptOffer = this.reciptOfferChoices[0].value

    const docRef = df.doc(
      `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
    )
    const listener = docRef.onSnapshot(doc => {
      if (
        doc &&
        doc.data() &&
        doc.data().locations &&
        Object.keys(doc.data().locations).length > 0
      ) {
        this.locs = Object.keys(doc.data().locations).map(key => {
          return doc.data().locations[key]
        })
      }
    })
    this.listeners.push(listener)

    this.receiptNumberFilter = ''

    EventBus.$off('stopButtonLoader')
    EventBus.$on('stopButtonLoader', (data) => {
      this.loadingDialog = false
    })
  },
  methods: {
    editReceipt (receipt) {
      this.loadingDialog = true
      this.$refs.editReceiptDialog.open(this.loadingDialog, receipt)
    },
    changeBalanceTimestampFilter (balanceTimestamp) {
      if (balanceTimestamp) {
        this.lastBalanceTimestampFilter = balanceTimestamp
      }
      this.applyFilter()
    },
    setReceiptType (receiptOffer) {
      this.reciptOffer = receiptOffer
    },
    printReceiptNew (receipt) {
      this.showLoader()
      var printTemplate = ''
      if (state.getCurrentCompany().print_template && state.getCurrentCompany().print_template !== '') {
        printTemplate = state.getCurrentCompany().print_template
      }

      let entity = 'racun'
      if (this.reciptOffer === 'offers') {
        entity = 'ponuda'
      }

      if (this.reciptOffer === 'virmans') {
        entity = 'virman'
      }

      var receipttemplate = functions.httpsCallable('receiptTemplate')

      var documentSubType = 'racun'
      if (state.getCurrentCompany().receipt_type && state.getCurrentCompany().receipt_type !== '') {
        documentSubType = state.getCurrentCompany().receipt_type
      }

      receipttemplate({
        template: printTemplate,
        companyId: receipt.company_details.id,
        documentId: receipt.id,
        documentType: entity,
        documentSubType: documentSubType,
        rawJson: ''
      })
        .then((result) => {
          this.hideLoader()
          if (result.data?.document !== '') {
            this.openPdfPrint(result.data?.document)
          }
        }
        )
    },
    openCrudDialog () {
      this.loadingDialog = true
      this.$refs.crudTemplateDialog.open(this.loadingDialog)
    },
    onMenuClicked (item, receipt) {
      var func = item.action
      func(receipt)
    },
    openMenu (receipt) {
      this.$refs.receiptMenu.open(receipt, 1)
    },
    onReceiptReprint (receipt) {
      reprint('Printer1', receipt, 2)
    },
    prepareLogCall () {
      var req = new LogData()

      let userId = auth.currentUser.uid
      if (state.getCashRegisterUser().id && state.getCashRegisterUser().id !== '') {
        userId = state.getCashRegisterUser().id
      }

      req.action_name = 'Ispis kopije računa'
      req.action_id = '6'
      req.user_id = userId

      this.setLog(req)
    },

    reprintReceipt (receipt) {
      if (state.getCurrentCompany().save_log) {
        this.prepareLogCall()
      }
      const printerData = state.getSelectedPrinter()
      if (printerData.id && (printerData.id === 'A4' || printerData.id === 'T58' || printerData.id === 'T80')) {
        this.printReceiptNew(receipt)
      } else {
        if (!printerData.width || printerData.width === '80') {
          reprint(printerData, receipt, 0)
        } else {
          print58(printerData, receipt, 0)
        }
      }
    },

    applyFilter () {
      this.lastVisible = {}
      this.receipts = []
      this.unfiskReceipts = []
      this.$refs.InfiniteLoading.stateChanger.reset()
    },

    changeReceiptType (receipt) {
      this.$refs.chengeReceiptTypeDialog.open(receipt)
    },

    issueBefore (issueDateTime) {
      const tmp = new Date(
        toISOLocal(
          new Date(issueDateTime * 1000)
        )
      )

      tmp.setDate(tmp.getDate() + 2)

      const before = parseInt(
        (tmp.getTime() / 1000).toFixed(0)
      )

      return before
    },
    navigateToFlow () {
      this.$router.push({
        name: 'Home',
        params: {
        }
      })
    },
    fiscalizeAll () {
      const that = this
      try {
        this.selected = {}
        const reqId = uuidv4()

        const payload = {
          action: {
            operation: 'update',
            entity: 'fiscalize_bulk',
            resource_id: '',
            params: {
              company_id: state.getCurrentCompany().id
            }
          }
        }

        that.showLoader()
        that.$refs.confirmFiscalize.close()
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'receipt',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function () {
          })
          .catch(function (err) {
            that.hideLoader()
            that.lastVisible = {}
            that.receipts = []
            that.unfiskReceipts = []
            that.$refs.InfiniteLoading.stateChanger.reset()
            that.$refs.confirmFiscalize.close()
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })

        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot(function (doc) {
          if (that.$refs && doc.data()) {
            unsubscribe()
            if (!doc.data().header || !doc.data().header.code || doc.data().header.code !== 200) {
              that.showMsgBox({
                text: doc.data().header.error ? doc.data().header.error : 'Nije uspjela fiskalizacija računa!',
                actions: ['cancel'],
                cancelBtnText: that.$t('$vuetify.close'),
                color: 'error'
              })
              that.hideLoader()
              that.lastVisible = {}
              that.receipts = []
              that.unfiskReceipts = []
              that.$refs.InfiniteLoading.stateChanger.reset()
              that.$refs.confirmFiscalize.close()
              return
            }

            that.hideLoader()
            that.lastVisible = {}
            that.receipts = []
            that.unfiskReceipts = []
            that.$refs.InfiniteLoading.stateChanger.reset()
            that.$refs.confirmFiscalize.close()
          }
        })
      } catch (err) {
        that.hideLoader()

        that.lastVisible = {}
        that.receipts = []
        that.unfiskReceipts = []
        that.$refs.InfiniteLoading.stateChanger.reset()
        that.$refs.confirmFiscalize.close()
        that.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    modifyItems (change) {
      if (change.type === 'added') {
        if (change.doc.data()) {
          const _document = clone(change.doc.data())
          const issueDate = new Date(_document.issue_date_time * 1000)
          const nowDate = new Date(Date.now())
          switch (_document.type.flag) {
            case 'R':
            case 'R1':
            case 'R2':
            case 'BO':
              _document.receiptOptions = [
                { title: 'Preuzmi račun', action: this.printReceiptNew },
                { title: 'Ponovi ispis', action: this.reprintReceipt },
                { title: 'Preuzmi račun (staro)', action: this.printReceipt },
                { title: 'Preuzmi konz. jela i pića', action: this.printReceiptKonz },
                { title: 'Storniraj račun', action: this.stornoReceipt },
                { title: 'Pošalji račun E-mailom', action: this.sendOrdinaryEmail },
                { title: this.$t('$vuetify.formDetails.changeReceiptType'), action: this.changeReceiptType }
              ]

              if (_document.payment_method !== 'TRANSAKCIJSKI RAČUN' && _document.jir === '') {
                _document.receiptOptions = _document.receiptOptions.filter((options) =>
                  options.title !== 'Storniraj račun')
              }

              if (_document.payment_method !== 'TRANSAKCIJSKI RAČUN' && _document.fiscalize === true && _document.zki !== '' &&
                  _document.jir === '') {
                _document.receiptOptions.unshift({
                  title: 'Fiskaliziraj račun',
                  action: this.openFiscalizeConfirm
                })
              }

              if (_document.payment_method !== 'TRANSAKCIJSKI RAČUN' && _document.jir !== '' && this.issueBefore(_document.issue_date_time) > this.now) {
                _document.receiptOptions.push({
                  title: 'Dodaj napojnicu',
                  action: this.addTip
                })
              }

              if (
                _document.payment?.current_status !== 'PAID' &&
                _document.payment_method === 'TRANSAKCIJSKI RAČUN'
              ) {
                _document.receiptOptions.push({
                  title: 'Plati račun',
                  action: this.payReceipt
                })
                _document.receiptOptions.push({
                  title: 'Opomeni korisnika',
                  action: this.sendWarningToClient
                })
              }

              if (
                _document.payment?.current_status !== 'UNPAID' &&
                _document.payment_method === 'TRANSAKCIJSKI RAČUN'
              ) {
                _document.receiptOptions.push({
                  title: 'Prebaci status u neplaćeno',
                  action: this.statusToUnpaid
                })
              }

              if (
                _document.payment?.current_status === 'REVERSED') {
                _document.receiptOptions = _document.receiptOptions.filter((options) =>
                  options.title !== 'Storniraj račun')
              }

              if (
                _document.payment?.current_status === 'PAID' &&
                _document.payment_method !== 'TRANSAKCIJSKI RAČUN' &&
                _document.fiscalize === true && _document.jir !== '' &&
                issueDate.getFullYear() === nowDate.getFullYear() &&
                issueDate.getMonth() === nowDate.getMonth() &&
                issueDate.getDate() === nowDate.getDate()

              ) {
                _document.receiptOptions.push({
                  title: 'Promijeni način plaćanja',
                  action: this.changePayment
                })
              }

              if (_document.payment_method === 'TRANSAKCIJSKI RAČUN') {
                _document.receiptOptions.unshift({
                  title: 'Promijeni račun',
                  action: this.editReceipt
                })
              }

              break
            case 'STORNO':
              _document.receiptOptions = [
                { title: 'Preuzmi račun', action: this.printReceiptNew },
                { title: 'Preuzmi račun (staro)', action: this.printReceipt },
                {
                  title: 'Pošalji račun E-mailom',
                  action: this.sendOrdinaryEmail
                }
              ]
              break
            case 'P':
              _document.receiptOptions = [
                { title: 'Preuzmi ponudu', action: this.printReceiptNew },
                { title: 'Preuzmi ponudu (staro)', action: this.printReceipt },
                {
                  title: 'Promijeni ponudu',
                  action: this.editReceipt
                },
                {
                  title: 'Izradi račun iz ponude',
                  action: this.generateReceipt
                },
                {
                  title: 'Pošalji ponudu E-mailom',
                  action: this.sendEmailOffer
                }
              ]
              break
            case 'V':
              _document.receiptOptions = [
                { title: 'Preuzmi virman', action: this.printReceipt },
                {
                  title: 'Izradi račun iz virmana',
                  action: this.generateReceipt
                }
              ]
          }

          let rcptSeparator = '-'
          if (_document.company_details.rcpt_separator !== undefined && _document.company_details.rcpt_separator !== '') {
            rcptSeparator = _document.company_details.rcpt_separator
          }

          if (_document.designation) {
            _document.receiptNumber = `${_document.designation.number}${rcptSeparator}${_document.designation.location_id}${rcptSeparator}${_document.designation.register_number}`
            _document.receiptNumberForEmail = _document.receiptNumber
          }

          if (_document.company_details.rcpt_num_year === true && _document.payment_method === 'TRANSAKCIJSKI RAČUN') {
            var d = new Date(_document.issue_date_time * 1000)

            _document.receiptNumber += `  <b>${d.getFullYear()}</b> `
            _document.receiptNumberForEmail += `  ${d.getFullYear()}`
          }
          if (_document.issue_date_time) {
            _document.time = _document.issue_date_time.seconds
          }
          if (_document?.type?.flag !== 'BO' && _document?.type?.payer_name) {
            _document.customer = _document.type.payer_name
          } else {
            _document.customer = this.$t('$vuetify.receipts.retailCustomer')
          }

          _document.show = false

          this.receipts.push({
            ..._document,
            ...{ [`hover-${change.doc.data().id}`]: false }
          })
          // }

          this.receipts.sort((a, b) => b.issue_date_time - a.issue_date_time) // b - a for reverse sort
          this.$forceUpdate()
        }
      }
      if (change.type === 'modified') {
        // TODO: duplicated code, make 1 fun
        this.receipts = this.receipts.map(item => {
          let ret = item
          if (item.id === change.doc.data().id) {
            const _document = clone(change.doc.data())
            const issueDate = new Date(_document.issue_date_time * 1000)
            const nowDate = new Date(Date.now())
            switch (_document.type.flag) {
              case 'R':
              case 'R1':
              case 'R2':
              case 'BO':
                _document.receiptOptions = [
                  { title: 'Preuzmi račun', action: this.printReceiptNew },
                  { title: 'Ponovi ispis', action: this.reprintReceipt },
                  { title: 'Preuzmi račun (staro)', action: this.printReceipt },
                  { title: 'Preuzmi konz. jela i pića', action: this.printReceiptKonz },
                  { title: 'Storniraj račun', action: this.stornoReceipt },
                  {
                    title: 'Pošalji račun E-mailom',
                    action: this.sendOrdinaryEmail
                  },
                  { title: this.$t('$vuetify.formDetails.changeReceiptType'), action: this.changeReceiptType }
                ]

                if (_document.payment_method !== 'TRANSAKCIJSKI RAČUN' && _document.fiscalize === true && _document.zki !== '' &&
                  _document.jir === '') {
                  _document.receiptOptions.unshift({
                    title: 'Fiskaliziraj račun',
                    action: this.openFiscalizeConfirm
                  })
                }

                if (_document.payment_method !== 'TRANSAKCIJSKI RAČUN' && _document.jir !== '' && this.issueBefore(_document.issue_date_time) > this.now) {
                  _document.receiptOptions.push({
                    title: 'Dodaj napojnicu',
                    action: this.addTip
                  })
                }

                if (
                  _document.payment?.current_status !== 'PAID' &&
                  _document.payment_method === 'TRANSAKCIJSKI RAČUN'
                ) {
                  _document.receiptOptions.push({
                    title: 'Plati račun',
                    action: this.payReceipt
                  })
                  _document.receiptOptions.push({
                    title: 'Opomeni korisnika',
                    action: this.sendWarningToClient
                  })
                }

                if (
                  _document.payment?.current_status !== 'UNPAID' &&
                  _document.payment_method === 'TRANSAKCIJSKI RAČUN' &&
                  _document.fiscalize === true
                ) {
                  _document.receiptOptions.push({
                    title: 'Prebaci status u neplaćeno',
                    action: this.statusToUnpaid
                  })
                }

                if (
                  _document.payment?.current_status === 'PAID' &&
                  _document.payment_method === 'TRANSAKCIJSKI RAČUN' &&
                  _document.fiscalize === true
                ) {
                  _document.receiptOptions.push({
                    title: 'Prebaci status u neplaćeno',
                    action: this.statusToUnpaid
                  })
                }

                if (
                _document.payment?.current_status === 'PAID' &&
                _document.payment_method !== 'TRANSAKCIJSKI RAČUN' &&
                _document.fiscalize === true && _document.jir !== '' &&
                issueDate.getFullYear() === nowDate.getFullYear() &&
                issueDate.getMonth() === nowDate.getMonth() &&
                issueDate.getDate() === nowDate.getDate()

                ) {
                  _document.receiptOptions.push({
                    title: 'Promijeni način plaćanja',
                    action: this.changePayment
                  })
                }

                if (_document.payment_method === 'TRANSAKCIJSKI RAČUN') {
                  _document.receiptOptions.unshift({
                    title: 'Promijeni račun',
                    action: this.editReceipt
                  })
                }

                break
              case 'STORNO':
                _document.receiptOptions = [
                  { title: 'Preuzmi račun', action: this.printReceiptNew },
                  { title: 'Preuzmi račun (staro)', action: this.printReceipt },
                  {
                    title: 'Pošalji račun E-mailom',
                    action: this.sendOrdinaryEmail
                  }
                ]
                break
              case 'P':
                _document.receiptOptions = [
                  { title: 'Preuzmi ponudu', action: this.printReceiptNew },
                  { title: 'Preuzmi ponudu - staro', action: this.printReceipt },
                  {
                    title: 'Promijeni ponudu',
                    action: this.editReceipt
                  },
                  {
                    title: 'Izradi račun iz ponude',
                    action: this.generateReceipt
                  },
                  {
                    title: 'Pošalji ponudu E-mailom',
                    action: this.sendEmailOffer
                  }
                ]
            }

            let rcptSeparator = '-'
            if (_document.company_details.rcpt_separator !== undefined && _document.company_details.rcpt_separator !== '') {
              rcptSeparator = _document.company_details.rcpt_separator
            }
            if (_document.designation) {
              _document.receiptNumber = `${_document.designation.number}${rcptSeparator}${_document.designation.location_id}${rcptSeparator}${_document.designation.register_number}`
              _document.receiptNumberForEmail = _document.receiptNumber
            }

            if (_document.company_details.rcpt_num_year === true && _document.payment_method === 'TRANSAKCIJSKI RAČUN') {
              var d = new Date(_document.issue_date_time * 1000)

              _document.receiptNumber += `  <b>${d.getFullYear()}</b> `
              _document.receiptNumberForEmail += `  ${d.getFullYear()}`
            }

            if (_document.issue_date_time) {
              _document.time = _document.issue_date_time.seconds
            }
            if (_document?.type?.flag !== 'BO' && _document?.type?.payer_name) {
              _document.customer = _document.type.payer_name
            } else {
              _document.customer = this.$t('$vuetify.receipts.retailCustomer')
            }
            ret = _document
          }
          return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
        })
      }

      this.receipts = this.receipts.filter((document, index, self) =>
        index === self.findIndex((t) => (
          t.id === document.id
        ))
      )
    },
    infiniteHandler ($state) {
      const that = this
      const where = that.location ? that.location.location_id : ''
      const whereFilter = that.statusFilter ? that.statusFilter.value : ''
      const oibFilter = this.oibFilter

      let receiptNumberParsed
      if (this.receiptNumberFilter !== '') {
        receiptNumberParsed = this.parseReceiptNumber(this.receiptNumberFilter)
      }
      let query = df
        .collection(
          `${this.reciptOffer}/${state.getCurrentCompany().id}/${
            this.reciptOffer
          }`
        )
        .orderBy('issue_date_time', 'desc')
        .limit(10)
        .startAfter(that.lastVisible)

      if (where) {
        query = query.where('designation.location_id', '==', where)
      }

      if (whereFilter === 'unfisc') {
        query = query.where('fiscalize', '==', true).where('jir', '==', '')
      }

      if (whereFilter === 'payed') {
        query = query.where('payment.current_status', '==', 'PAID')
      }
      if (whereFilter === 'email') {
        query = query.where('payment.current_status', '==', 'EMAIL_SENT')
      }
      if (whereFilter === 'notice') {
        query = query.where('payment.current_status', '==', 'WARNING_SENT')
      }

      if (whereFilter === 'unpayed') {
        query = query.where('payment.current_status', '==', 'UNPAID')
      }

      if (whereFilter === 'avans') {
        query = query.where('tags', 'array-contains', { receipt_type: 'AVANS', tag: 'RECEIPT_TYPE' })
      }

      if (whereFilter === 'avans_deducted') {
        query = query.where('tags', 'array-contains', { receipt_type: 'AVANS_DEDUCTED', tag: 'RECEIPT_TYPE' })
      }

      if (this.oibFilterManual && this.oibFilterManual.length > 0 && !isNaN(this.oibFilterManual)) {
        query = query.where('type.payer_oib', '==', this.oibFilterManual)
      }

      if (this.registerIdFilter) {
        query = query.where('designation.register_id', '==', this.registerIdFilter)
      }

      if (this.lastBalanceTimestampFilter) {
        query = query.where('issue_date_time', '>', this.lastBalanceTimestampFilter)
      }

      if (oibFilter) {
        // provjera da li je uneseni filter broj (oib) ili ne
        let argument
        if (!isNaN(oibFilter)) {
          // oibFilter je OIB
          argument = oibFilter
        } else {
          if (typeof (oibFilter) === 'object') {
            // oibFilter je račun
            argument = oibFilter.oibs[0]
          } else if (typeof (oibFilter) === 'string') {
            // oibFilter je string
            argument = oibFilter
          }
        }
        query = query.where('type.payer_oib', '==', argument)
      }

      if (receiptNumberParsed) {
        query = query
          .where('designation.number', '==', receiptNumberParsed.number)
          .where('designation.location_id', '==', receiptNumberParsed.location_id)
          .where('designation.register_number', '==', receiptNumberParsed.register_number)
      }

      const listener = query.onSnapshot(
        doc => {
          doc.docChanges().forEach(change => {
            that.modifyItems(change)
          })
          if (doc && !doc.empty) {
            if (doc.docs[doc.docs.length - 1]) {
              that.lastVisible = doc.docs[doc.docs.length - 1]
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        }
        // (error) => {

        // }
      )

      this.listeners.push(listener)

      if (state.getCurrentCompany().id !== '') {
        let query = df.collection(`receipts/${state.getCurrentCompany().id}/receipts`)
          .orderBy('issue_date_time', 'desc')

        const now = parseInt(
          (new Date().getTime() / 1000).toFixed(0)
        )

        const tmp = new Date()
        tmp.setDate(tmp.getDate() - 2)

        const before = parseInt(
          (tmp.getTime() / 1000).toFixed(0)
        )
        query = query.where('fiscalize', '==', true).where('jir', '==', '').where('issue_date_time', '>=', before).where('issue_date_time', '<=', now)

        const listener = query
          .onSnapshot((doc) => {
            doc.docs.forEach((rec) => {
              this.unfiskReceipts.push(rec.data())
            })
          }
          )
        this.listeners.push(listener)
      }

      this.receipts = this.receipts.sort((a, b) => { return a.issue_date_time < b.issue_date_time }
      )
    },
    getReceiptsOrOffers () {
      this.lastVisible = ''
      this.receipts = []
      this.unfiskReceipts = []
      this.detachListeners()

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    stornoReceipt (receipt) {
      this.$refs.confirmStorno.open(
        '',
        `Storniraj račun ${receipt.receiptNumber}?`,
        { confirmText: 'Da', cancelText: 'Ne' }
      )
      this.selected = receipt
    },
    changePayment (receipt) {
      this.$refs.paymentChanges.open(receipt)
    },
    addTip (receipt) {
      this.$refs.addTip.open(receipt)
    },
    printReceiptKonz (receipt, preview = false) {
      const that = this
      try {
        that.showLoader()
        const reqId = uuidv4()
        // const type = receipt && receipt.type && receipt.type.flag ? receipt.type.flag : "";
        let entity = 'receipt'
        if (this.reciptOffer === 'offers') {
          entity = 'offer'
        }

        if (this.reciptOffer === 'virmans') {
          entity = 'virman'
        }

        let printSize = null
        if (['T58', 'T80', 'A4'].includes(state.getPrinter().value)) {
          printSize = state.getPrinter().value
        } else {
          printSize = 'BIXOLON80'
        }

        const payload = {
          action: {
            operation: 'get',
            entity: entity,
            resource_id: receipt.id,
            params: {
              company_id: state.getCurrentCompany().id,
              print_size: printSize,
              konzumacija: true
            }
          }
        }

        if (that.reciptOffer === 'offers') {
          payload.action.entity = 'offer'
          payload.action.params.print_size = printSize
        }

        if (that.reciptOffer === 'virmans') {
          payload.action.entity = 'virman'
          payload.action.params.print_size = printSize
        }

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'document',
            payload: btoa(unescape(encodeURIComponent(JSON.stringify(payload))))
          })
          .then(function () {})
          .catch(function (err) {
            that.hideLoader()
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })

        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot(function (doc) {
          if (that.$refs && doc.data()) {
            // this.$refs.createBusinessForm.reset()
            unsubscribe()
            if (!doc.data().body || !doc.data().body.document) {
              that.showMsgBox({
                text: that.$t('$vuetify.errors.nullReceipt'),
                actions: ['cancel'],
                cancelBtnText: that.$t('$vuetify.close'),
                color: 'error'
              })
              that.hideLoader()
              return
            }

            if (preview) {
              that.openPdfPrint(doc.data().body.document)
              that.hideLoader()
            } else {
              const linkSource = `data:application/pdf;base64,${
                doc.data().body.document
              }`
              const downloadLink = document.createElement('a')
              const fileName = `${receipt.receiptNumber}.pdf`
              downloadLink.href = linkSource
              downloadLink.download = fileName
              downloadLink.click()
              that.hideLoader()
            }
          }
        })
      } catch (err) {
        that.hideLoader()
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },
    printReceipt (receipt, preview = false) {
      const that = this
      try {
        that.showLoader()
        const reqId = uuidv4()
        // const type = receipt && receipt.type && receipt.type.flag ? receipt.type.flag : "";
        let entity = 'receipt'
        if (this.reciptOffer === 'offers') {
          entity = 'offer'
        }

        if (this.reciptOffer === 'virmans') {
          entity = 'virman'
        }

        const payload = {
          action: {
            operation: 'get',
            entity: entity,
            resource_id: receipt.id,
            params: {
              company_id: state.getCurrentCompany().id,
              print_size: 'A4'
            }
          }
        }

        if (that.reciptOffer === 'offers') {
          payload.action.entity = 'offer'
          payload.action.params.print_size = 'A4'
        }

        if (that.reciptOffer === 'virmans') {
          payload.action.entity = 'virman'
          payload.action.params.print_size = 'A4'
        }

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'document',
            payload: btoa(unescape(encodeURIComponent(JSON.stringify(payload))))
          })
          .then(function () {})
          .catch(function (err) {
            that.hideLoader()
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })

        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot(function (doc) {
          if (that.$refs && doc.data()) {
            // this.$refs.createBusinessForm.reset()
            unsubscribe()
            if (!doc.data().body || !doc.data().body.document) {
              that.showMsgBox({
                text: that.$t('$vuetify.errors.nullReceipt'),
                actions: ['cancel'],
                cancelBtnText: that.$t('$vuetify.close'),
                color: 'error'
              })
              that.hideLoader()
              return
            }

            if (preview) {
              that.openPdfPrint(doc.data().body.document)
              that.hideLoader()
            } else {
              const linkSource = `data:application/pdf;base64,${
                doc.data().body.document
              }`
              const downloadLink = document.createElement('a')
              const fileName = `${receipt.receiptNumber}.pdf`
              downloadLink.href = linkSource
              downloadLink.download = fileName
              downloadLink.click()
              that.hideLoader()
            }
          }
        })
      } catch (err) {
        that.hideLoader()
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },
    cancelReceipt () {
      const that = this
      try {
        const receipt = Object.assign({}, this.selected)
        this.selected = {}
        const reqId = uuidv4()

        let receiptNumber = receipt.designation ? receipt.designation.number : 0
        df.doc(`cash_registers/${receipt.designation.register_id}`)
          .get()
          .then(reg => {
            if (reg && reg.exists) {
              if (reg.data()) {
                if (reg.data().status !== 'OK') {
                  this.$refs.confirmStorno.close()
                  that.showMsgBox({
                    text: that.$t('$vuetify.errors.unknownRegister'),
                    actions: ['cancel'],
                    cancelBtnText: that.$t('$vuetify.close'),
                    color: 'error'
                  })
                  return
                }
                if (this.reciptOffer === 'receipts') {
                  receiptNumber = reg.data().bill_sequence || 0
                } else if (this.reciptOffer === 'offers') {
                  receiptNumber = reg.data().offer_sequence || 0
                }
                const payload = {
                  action: {
                    operation: 'set',
                    entity: 'storno',
                    params: {
                      company_id: state.getCurrentCompany().id,
                      location_id: receipt.location_details.id,
                      receipt_id: receipt.id,
                      register_id: receipt.designation.register_id,
                      issue_date_time: parseInt(
                        (new Date().getTime() / 1000).toFixed(0)
                      ),
                      number: receiptNumber + 1
                      // seller_id: auth.currentUser.uid,
                    }
                  }
                }

                that.showLoader()
                that.$refs.confirmStorno.close()
                df.doc(`request/${reqId}`)
                  .set({
                    user_id: `${auth.currentUser.uid}`,
                    device_id: 'web',
                    created: `${new Date().getTime()}`,
                    type: 'receipt',
                    payload: btoa(
                      unescape(encodeURIComponent(JSON.stringify(payload)))
                    )
                  })
                  .then(function () {
                    setTimeout(() => {
                      that.hideLoader()
                      that.lastVisible = {}
                      that.receipts = []
                      that.unfiskReceipts = []
                      that.$refs.InfiniteLoading.stateChanger.reset()
                    }, 5500)
                  })
                  .catch(function (err) {
                    that.hideLoader()
                    that.showMsgBox({
                      text: err && err !== '' ? err : 'An error has occurred',
                      actions: ['cancel'],
                      cancelBtnText: 'OK',
                      color: 'error'
                    })
                  })
              }
            } else {
              that.showMsgBox({
                text: that.$t('$vuetify.errors.unknownRegister'),
                actions: ['cancel'],
                cancelBtnText: that.$t('$vuetify.close'),
                color: 'error'
              })
              that.hideLoader()
              this.$refs.confirmStorno.close()
            }
          })
      } catch (err) {
        this.$refs.confirmStorno.close()
        that.hideLoader()
        that.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },
    generateReceipt (receipt) {
      if (receipt && receipt.type && receipt.type.flag && receipt.type.flag === 'V') {
        this.$refs.receiptFromVirman.open(receipt, this.multiplier)
      } else {
        this.$refs.receiptFromOffer.open(receipt, this.multiplier)
      }
    },
    payReceipt (receipt) {
      this.$refs.payReceipt.open(receipt)
    },
    onReceiptEdit (receipt) {
      this.receipts = this.receipts.map(r => {
        if (r.id === receipt.id) {
          r.payment.current_status = 'PROCESSING'
        }
        return r
      })
    },
    sendOrdinaryEmail (receipt) {
      this.$refs.ordinaryEmail.open(receipt, {
        action: 'EMAIL_SENT',
        title: 'Pošalji račun mailom',
        okBtn: 'Pošalji EMail'
      })
    },
    sendEmailOffer (receipt) {
      this.$refs.ordinaryEmail.open(receipt, {
        action: 'OFFER_SENT',
        title: 'Pošalji ponudu mailom',
        okBtn: 'Pošalji EMail'
      })
    },
    sendWarningToClient (receipt) {
      this.$refs.clientWarning.open(receipt, {
        action: 'WARNING_SENT',
        title: 'Opomeni korisnika',
        okBtn: 'Opomeni'
      })
    },
    statusToUnpaid (receipt) {
      this.$refs.statusToUnpaid.open(receipt)
    },
    openFiscalizeConfirm (receipt) {
      this.$refs.confirmFiscalize.open(
        '',
        `Fiskaliziraj račun ${receipt.receiptNumber}?`,
        { confirmText: 'Da', cancelText: 'Ne' }
      )
      this.selected = receipt
    },
    fiscalizeReceipt () {
      const that = this
      try {
        const receipt = Object.assign({}, this.selected)
        this.selected = {}
        const reqId = uuidv4()

        const payload = {
          action: {
            operation: 'update',
            entity: 'fiscalize',
            resource_id: receipt.id,
            params: {
              company_id: state.getCurrentCompany().id
            }
          }
        }

        that.showLoader()
        that.$refs.confirmFiscalize.close()
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'receipt',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function () {
          })
          .catch(function (err) {
            that.hideLoader()
            that.lastVisible = {}
            that.receipts = []
            that.unfiskReceipts = []
            that.$refs.InfiniteLoading.stateChanger.reset()
            that.$refs.confirmFiscalize.close()
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })

        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot(function (doc) {
          if (that.$refs && doc.data()) {
            unsubscribe()
            if (!doc.data().header || !doc.data().header.code || doc.data().header.code !== 200) {
              that.showMsgBox({
                text: doc.data().header.error ? doc.data().header.error : 'Nije uspjela fiskalizacija računa!',
                actions: ['cancel'],
                cancelBtnText: that.$t('$vuetify.close'),
                color: 'error'
              })
              that.hideLoader()
              that.lastVisible = {}
              that.receipts = []
              that.unfiskReceipts = []
              that.$refs.InfiniteLoading.stateChanger.reset()
              that.$refs.confirmFiscalize.close()
              return
            }

            that.hideLoader()
            that.lastVisible = {}
            that.receipts = []
            that.unfiskReceipts = []
            that.$refs.InfiniteLoading.stateChanger.reset()
            that.$refs.confirmFiscalize.close()
          }
        })
      } catch (err) {
        that.hideLoader()

        that.lastVisible = {}
        that.receipts = []
        that.unfiskReceipts = []
        that.$refs.InfiniteLoading.stateChanger.reset()
        that.$refs.confirmFiscalize.close()
        that.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },

    onExpandFilters () {
      this.filtersExpanded = !this.filtersExpanded
      this.expandIcon = this.filtersExpanded ? 'mdi-minus' : 'mdi-plus'
    },

    parseReceiptNumber (receiptNumber) {
      receiptNumber = receiptNumber.replaceAll('/', '-')
      const parts = receiptNumber.split('-')
      if (parts.length !== 3) {
        return {
          number: 0,
          location_id: '',
          register_number: 0
        }
      }
      return { number: parseInt(parts[0]), location_id: parts[1], register_number: parseInt(parts[2]) }
    },

    getAssociates () {
      df.doc(`company_associates/${state.getCurrentCompany().id}`)
        .get()
        .then((resp) => {
          if (resp && resp.data()) {
            this.defaultAssociates = Object.keys(resp.data().associates)
              .map((key) => {
                return resp.data().associates[key]
              })
              .filter((associate) => associate.status === 'OK' && associate.associate_type.BUYER)
            this.associates = clone(this.defaultAssociates)
          }
        })
    },
    resetField (field) {
      field === 'oib' ? this.oibFilterManual = '' : this.oibFilter = undefined
      this.applyFilter()
    }
  }
}
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}
tr:hover {
  cursor: pointer;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.new-receipt-fab {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
</style>
