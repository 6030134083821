<template>
  <div>
    <v-container style="width: 100%">
      <div>
        <v-row>
          <v-col cols="10">
            <div
              v-if="lastBalanceTimestamp && lastBalanceTimestamp !== ''"
              style="padding-left: 5%; font-weight: bold"
            >
              Računi izdani nakon {{ lastBalanceTimestamp }}
            </div>
            <Receipts
              ref="generalReceipts"
              style="padding-top: 25px"
              :receiptFilter="receiptFilter"
            ></Receipts>
          </v-col>
          <v-col cols="2" style="text-align: center">
            <table>
              <tbody>
                <tr>
                  <td>
                    <v-btn
                      id="success"
                      style="width: 100%"
                      class="btn successButton"
                      @click="openCrudDialog"
                    >
                      Novi račun
                    </v-btn>
                  </td>
                </tr>

                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td><b>PREGLED</b></td>
                </tr>
                <tr>
                  <td>
                    <v-btn
                      id="info"
                      style="width: 100%"
                      class="btn infoButton"
                      @click="openReceipts('receipts')"
                    >
                      Računi
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-btn
                      id="info"
                      class="btn infoButton"
                      style="width: 100%"
                      @click="openReceipts('offers')"
                    >
                      Ponude
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td><b>BLAGAJNA</b></td>
                </tr>
                <tr>
                  <td>
                    <v-btn
                      id="warning"
                      class="btn warningButton"
                      style="width: 100%"
                      @click="openCloseBalance"
                    >
                      Zaključak
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </div>
      <crud-template-dialog ref="crudTemplateDialog"></crud-template-dialog>
      <close-balance ref="closeBalance"></close-balance>
      <v-dialog
        v-model="receiptModal"
        style="
          background-color: white;
          width: 70% !important;
          border-radius: 25px;
        "
        name="receipts"
      >
        <Receipts
          style="background-color: white"
          ref="dialogReceipts"
          :receiptFilter="receiptFilterDialog"
        ></Receipts>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>

import CrudTemplateDialog from '@/modules/cash-register/components/dialogs/CrudTemplateDialog'
import Receipts from '@/modules/company/components/receipts/Receipts'
import CloseBalance from '@/modules/cash-register/components/closeBalance/CloseBalance'
import EventBus from '@/plugins/event-bus'
import state from '@/state'

export default {
  components: {
    Receipts,
    CrudTemplateDialog,
    CloseBalance
  },
  inject: [
  ],
  data: function (vm) {
    return {
      receiptFilter: { oibFilter: undefined, reciptOffer: 'receipts', modal: true, registerId: state.getCashRegister().id, lastBalanceTimestamp: state.getCashRegister().last_balance_timestamp, hideFilter: true },
      loadingDialog: false,
      top: true,
      right: true,
      bottom: false,
      left: false,
      transition: 'slide-x-reverse-transition',
      direction: 'bottom',
      fab: true,
      bla: true,
      fling: false,
      hover: false,
      tabs: null,
      buttons: [
        { name: 'Novi račun', action: vm.openCrudDialog },
        { name: '', action: vm.openCrudDialog },
        { name: '', action: vm.openCrudDialog },
        { name: 'Zaključak blagajne', action: vm.openCrudDialog },
        { name: 'Primka', action: vm.openCrudDialog }
      ],
      receiptModal: false,
      vm: vm,
      receiptFilterDialog: {}
    }
  },
  computed: {

    lastBalanceTimestamp () {
      var balanceTimestamp = state.getCashRegister().last_balance_timestamp
      if (balanceTimestamp) {
        return this.$options.filters.local(balanceTimestamp)
      } else {
        return ''
      }
    }

  },

  mounted () {
    EventBus.$off('stopButtonLoader')
    EventBus.$on('stopButtonLoader', (data) => {
      this.loadingDialog = false
    })

    EventBus.$off('refreshCashRegister')
    EventBus.$on('refreshCashRegister', () => {
      if (this.$refs.generalReceipts) {
        this.$refs.generalReceipts.changeBalanceTimestampFilter(state.getCashRegister().last_balance_timestamp)
      }
    })
  },
  methods: {
    close () {
      this.receiptModal = false
    },
    closeBalance () {
      if (this.$refs.closeBalance) this.$refs.closeBalance.open()
    },
    openCloseBalance () {
      if (this.$refs.closeBalance) this.$refs.closeBalance.open()
    },
    openCrudDialog () {
      this.loadingDialog = true

      var register = state.getCashRegister()
      this.$refs.crudTemplateDialog.open(this.loadingDialog, register)
    },
    openReceipts (receiptOffer) {
      this.receiptFilterDialog = { reciptOffer: receiptOffer }
      this.receiptModal = true

      if (this.$refs.dialogReceipts) {
        this.$refs.dialogReceipts.setReceiptType(receiptOffer)
      }
    }

  }
}
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}
tr:hover {
  cursor: pointer;
}
td {
  background-color: white;
  padding: 2px;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.new-receipt-fab {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.categories {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 64px;
  z-index: 3;
  width: 100%;
}

.categories >>> .v-tabs-bar__content {
  justify-content: center;
  margin-top: 40px;
}

.myTest >>> .vm--modal {
  bottom: 100px !important;
  z-index: 200;
  border-radius: 25px !important;
}

.container {
  background-color: white;
}
</style>
