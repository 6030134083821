<template>
  <v-container fluid class="fill-height pa-0">
    <v-container
      fluid
      class="d-flex justify-center align-center flex-wrap pa-0 fill-height"
    >
      <v-row class="justify-center fill-height pt-0 mb-0">
        <v-col md="8" lg="9" class="pt-0 mt-0 mb-0 pb-0">
          <div class="categories">
            <v-row class="justify-center ma-0 ml-1 pa-0">
              <v-tabs
                v-model="category"
                hide-slider
                height="140"
                show-arrows
                color="success"
                class="flex-grow-0 flex-shrink-0 mt-0 mb-0 pb-0"
              >
                <v-tab
                  v-for="(category, i) in categories"
                  :key="i"
                  @click="selectCategory(category)"
                  @keyup.enter="selectCategory(category)"
                  @change="tabChange"
                  :id="`category-${i}`"
                  :ref="`category-${i}`"
                  class="my-back"
                  style="height: 120px; padding-top: 0px; margin-top: 0px"
                >
                  <div class="mb-0">
                    <v-avatar :size="$vuetify.breakpoint.smAndDown ? 55 : 70">
                      <v-img
                        v-if="category.picture"
                        :src="category.picture"
                        contain
                      >
                      </v-img>
                      <v-icon
                        v-else
                        :size="$vuetify.breakpoint.smAndDown ? 55 : 70"
                      >
                        mdi-image
                      </v-icon>
                    </v-avatar>
                    <p>{{ category.name }}</p>
                  </div>
                </v-tab>
                <v-menu
                  bottom
                  v-model="menu"
                  offset-y
                  nudge-bottom="10"
                  nudge-left="310"
                  v-if="moreCategories.length > 0"
                >
                  <template v-slot:activator="{ on: onMenu }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          class="mt-1"
                          v-on="on"
                          v-bind="attrs"
                          :size="$vuetify.breakpoint.smAndDown ? 55 : 70"
                        >
                          <v-icon
                            :size="$vuetify.breakpoint.smAndDown ? 35 : 50"
                            v-on="onMenu"
                          >
                            mdi-view-dashboard-outline
                          </v-icon>
                        </v-avatar>
                      </template>
                      <span>+ {{ moreCategories.length }} kategorija</span>
                    </v-tooltip>
                  </template>
                  <v-card>
                    <v-card-text>
                      <div class="ai-app-menu">
                        <v-row>
                          <v-col
                            cols="4"
                            v-for="(moreCategory, i) in moreCategories"
                            :key="i"
                            class="more-category"
                            @click="addToCategories(moreCategory)"
                          >
                            <v-avatar
                              :size="$vuetify.breakpoint.smAndDown ? 55 : 70"
                            >
                              <v-img
                                v-if="moreCategory.picture"
                                :src="moreCategory.picture"
                                contain
                              >
                              </v-img>
                              <v-icon
                                v-else
                                :size="$vuetify.breakpoint.smAndDown ? 55 : 70"
                              >
                                mdi-image
                              </v-icon>
                            </v-avatar>
                            <div>
                              {{ moreCategory ? moreCategory.name : "" }}
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-tabs>
            </v-row>
          </div>
          <v-container class="d-flex justify-center flex-wrap pa-0 ma-0">
            <v-row
              v-if="!categories || categories.length === 0"
              class="d-flex justify-center"
            >
              <p class="text--secondary" style="margin-top: 50px">
                {{ $t("$vuetify.cashRegister.noCategories") }}
              </p>
            </v-row>
            <p
              class="text--secondary font-weight-bold"
              style="
                padding-left: 50px;
                padding-top: 0px;
                padding-bottom: 0px;
                margin-top: 0px;
                margin-bottom: 0px;
              "
              v-if="showLoggedUser"
            >
              {{
                'Prijavljeni ste kao korisnik ' + $options.filters.capitalize(this.loggedUser.name) + ' ' + $options.filters.capitalize(this.loggedUser.surname),
              }}
            </p>
            <v-row
              v-if="
                categoryItems.length < 1 && categories && categories.length > 0
              "
              class="d-flex justify-center"
            >
              <p
                class="text--secondary"
                style="margin-top: 30px; padding-left: 50px"
              >
                {{
                  $t("$vuetify.cashRegister.noCategoryItems", {
                    category: categories[category]
                      ? categories[category].name
                      : "",
                  })
                }}
              </p>
            </v-row>
            <v-container>
              <v-row class="d-flex justify-center ma-0">
                <v-col cols="12" sm="11" lg="10">
                  <v-card
                    text
                    @click="showWriteInProductDialog"
                    @keypress.enter="showWriteInProductDialog"
                    :hover="!transactionStart"
                  >
                    <v-row>
                      <v-col class="d-flex justify-center mt-3 mb-3 ml-5">
                        {{ $t("$vuetify.writeInButtonLabel") }}
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-if="listView" class="d-flex justify-center ma-0">
                <v-col cols="12" sm="11" lg="10">
                  <v-card
                    text
                    v-for="(item, i) in categoryItems"
                    :key="i"
                    :disabled="!isSeller"
                    retain-focus-on-click="false"
                    @click="itemSelected(item)"
                    @keypress.enter="itemSelected(item)"
                    :hover="!transactionStart"
                    :ref="`item${i}`"
                  >
                    <v-row
                      :class="[
                        'ma-0',
                        'row',
                        item.status === 'OK' ? 'status-ok' : 'status-failed',
                      ]"
                    >
                      <v-col
                        v-if="density !== 'greater'"
                        cols="4"
                        sm="3"
                        md="2"
                        lg="2"
                        xl="2"
                        :class="[densityPadding]"
                      >
                        <v-img
                          v-if="item.picture"
                          :src="item.picture"
                          contain
                          :height="density === 'normal' ? '48' : '68'"
                        ></v-img>
                        <v-img
                          v-else
                          contain
                          src="@/assets/no-item.jpg"
                          :height="density === 'normal' ? '48' : '68'"
                        ></v-img>
                      </v-col>
                      <v-col
                        cols="8"
                        sm="9"
                        md="5"
                        lg="4"
                        :class="[densityPadding]"
                      >
                        <div class="caption grey--text">Naziv artikla</div>
                        <div
                          class="text-subtitle-1 one-liner-text"
                          @mouseover="hoverOver(`hover-${item.id}`, item)"
                          @mouseleave="item[`hover-${item.id}`] = false"
                          :id="`hover-${item.id}`"
                          :ref="`hover-${item.id}`"
                        >
                          {{ $options.filters.capitalize(item.name) }}
                        </div>
                        <v-tooltip v-model="item[`hover-${item.id}`]" bottom>
                          <!--Fake activator to avoid an attach property which is not working properly -->
                          <template v-slot:activator="{ on }">
                            <div v-on="on"></div>
                          </template>
                          <div style="max-width: 300px">
                            {{ $options.filters.capitalize(item.name) }}
                          </div>
                        </v-tooltip>
                      </v-col>
                      <v-col
                        cols="4"
                        sm="7"
                        md="2"
                        lg="2"
                        :class="[densityPadding]"
                      >
                        <div
                          class="caption grey--text"
                          v-if="cashReg !== undefined && cashReg.type !== 'VP'"
                        >
                          Cijena artikla
                        </div>
                        <div
                          v-if="cashReg !== undefined && cashReg.type !== 'VP'"
                        >
                          <div v-if="item.manual_price_input">
                            - {{ currency }}
                          </div>
                          <div v-if="!item.manual_price_input">
                            {{
                              item.prices[currency].price | money(100, currency)
                            }}
                          </div>
                        </div>

                        <div
                          class="caption grey--text"
                          v-if="cashReg !== undefined && cashReg.type === 'VP'"
                        >
                          Cijena artikla
                        </div>
                        <div
                          v-if="cashReg !== undefined && cashReg.type === 'VP'"
                        >
                          <div v-if="item.manual_price_input">
                            - {{ currency }}
                          </div>
                          <div v-if="!item.manual_price_input">
                            {{ calculateBasePrice(item) }}
                            <!-- {{
                              parseFloat(
                                item.prices[currency].price /
                                  (1 +
                                    item.prices[currency].taxes[0].rate / 10000)
                              ) | money(100, currency)
                            }} -->
                          </div>
                          <div
                            style="font-size: 12px !important"
                            v-if="!item.manual_price_input"
                          >
                            ({{
                              item.prices[currency].price
                                | money(100, currency)
                            }})
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        class="hidden-md-and-down"
                        cols="4"
                        md="2"
                        lg="2"
                        :class="[densityPadding]"
                      >
                        <div
                          class="caption grey--text text-sm-center text-md-left"
                        >
                          Sifra artikla
                        </div>
                        <div class="text-sm-center text-md-left">
                          {{ item.code }}
                        </div>
                      </v-col>
                      <v-col
                        sm="5"
                        md="3"
                        lg="2"
                        style="min-width: 100px; max-width: 100%"
                        :class="[densityPadding, 'flex-grow-1']"
                      >
                        <div class="caption grey--text text-right">
                          Stanje na skladištu
                        </div>
                        <div class="text-right">
                          {{ item.type === "GOODS" ? item.stock : "-" }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                  </v-card>
                </v-col>
              </v-row>

              <v-row v-else class="d-flex justify-center fill-height">
                <v-card
                  class="align-center ma-5"
                  v-for="(item, i) in categoryItems"
                  :key="i"
                  @click="itemSelected(item)"
                  retain-focus-on-click="false"
                  @keypress.enter="itemSelected(item)"
                  :hover="!transactionStart"
                  :disabled="!isSeller"
                  height="300"
                  width="250"
                  :ref="`item${i}`"
                >
                  <v-img
                    height="210"
                    width="250"
                    v-if="item.picture"
                    :src="item.picture"
                  >
                  </v-img>
                  <v-img
                    height="210"
                    width="250"
                    v-else
                    src="@/assets/no-item.jpg"
                  >
                  </v-img>
                  <v-card-actions class="d-flex flex-column">
                    <h2 class="selling-point-title-text one-liner-text">
                      {{ $options.filters.capitalize(item.name) }}
                    </h2>
                    <!-- <h2 class="grey--text selling-point-text one-liner-text">{{$t('$vuetify.price')}}: {{item.prices[currency].price | money(100, currency)}}</h2> -->
                    <h2 class="grey--text selling-point-text one-liner-text">
                      {{ item.prices[currency].price | money(100, currency) }}
                    </h2>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-container>
          </v-container>

          <infinite-loading
            ref="InfiniteLoading"
            @infinite="infiniteHandler"
            spinner="waveDots"
          >
            <div slot="no-more" class="text--secondary font-italic"></div>
            <div slot="no-results" class="text--secondary font-italic"></div>
          </infinite-loading>
        </v-col>
        <v-col md="4" lg="3" class="mt-5">
          <receipt-calculator
            ref="receiptCalculator"
            @transactionStarted="transactionStarted"
            @transactionEnded="transactionEnded"
            @transactionEndedWithReceipt="transactionEndedWithReceipt"
            @currencyChanged="setCurency"
            :selectedPrinter="selectedPrinter"
          ></receipt-calculator>
        </v-col>
      </v-row>
    </v-container>
    <add-write-in-product
      v-if="writeInProductDialog"
      :currency="currency"
      @addProduct="onAddProduct"
      @close="onDialogClose"
    ></add-write-in-product>
    <add-manual-price
      ref="addManualPrice"
      :currency="currency"
      @add="onAddPrice"
    ></add-manual-price>
    <create-report ref="createReport"></create-report>
    <close-balance ref="closeBalance"></close-balance>
    <menu-dialog ref="menuDialog" @add="addMenuProduct"></menu-dialog>
  </v-container>
</template>
<script>
import { auth, df } from '@/plugins/firebase'
import state from '@/state'
import ReceiptCalculator from '@/modules/cash-register/components/ReceiptCalculator'
import AddManualPrice from '@/modules/cash-register/components/AddManualPrice'
import AddWriteInProduct from '@/modules/cash-register/components/AddWriteInProduct'
import InfiniteLoading from 'vue-infinite-loading'
import applicationSettings from '@/mixins/applicationSettings'
import EventBus from '@/plugins/event-bus'
import CreateReport from '@/modules/cash-register/components/createReport/CreateReport'
import CloseBalance from '@/modules/cash-register/components/closeBalance/CloseBalance'
import MenuDialog from '@/modules/cash-register/components/MenuDialog'

import barcodeChecker from '@/mixins/barcodeChecker' // don't move.. detaches firebase listeners
import { clone } from '@/plugins/utils'
export default {
  name: 'CashRegister',
  components: { ReceiptCalculator, InfiniteLoading, AddWriteInProduct, CreateReport, CloseBalance, AddManualPrice, MenuDialog },
  mixins: [applicationSettings, barcodeChecker],
  inject: ['showMsgBox', 'confirm', 'confirmClose', 'showLoader', 'hideLoader', 'showMsgBoxHtml'],
  data: () => ({
    barcode: [],
    writeInProductDialog: false,
    transactionStart: false,
    loading: false,
    category: null,
    cashReg: undefined,
    selectedCategory: 0,
    categories: [],
    categoryItems: [],
    _categoryItems: [],
    selectedItem: undefined,
    currency: 'EUR',
    multiplier: 0,
    lastVisible: '',
    listeners: [],
    cashRegListeners: [],
    moreCategories: [],
    menu: false,
    user: {},
    loggedUser: undefined,
    showLoggedUser: false,
    selectedPrinter: {}
  }),
  computed: {
    listView () {
      return state.isListView()
    },
    cashRegisterOptions () {
      return this.getRowTabsModel()
    },
    search () {
      return state.search
    },
    isSeller () {
      return state.getUser()?.roles?.PRODAVAČ
    },
    isAdmin () {
      return state.getUser()?.roles?.SUPERADMIN || state.getUser()?.roles?.ADMIN
    }
  },
  watch: {
    category (nv, ov) {
      if (nv !== ov) {
        this.selectCategory(this.categories[nv])
        this.tabChange()
      }
    },
    search () {
      this.searchItems()
    }
  },
  created () {
    this.selectedPrinter = state.getSelectedPrinter()
  },
  mounted () {
    EventBus.$on('set-selected-printer', (printer) => {
      this.selectedPrinter = printer
    })

    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }

    this.cashReg = state.getCashRegister()

    if (this.cashReg.login_type !== 'DEFAULT') {
      this.loggedUser = state.getCashRegisterUser()
      if (this.loggedUser.name !== undefined && this.loggedUser.name !== '') {
        this.showLoggedUser = true
      }
    }
    this.getMultiplier()
    if (!this.isSeller && !this.isAdmin) {
      this.showMsgBoxHtml({ text: this.$t('$vuetify.errors.isNotSeller'), actions: ['cancel'], cancelBtnText: this.$t('$vuetify.close'), color: 'error' })
    }
    if (!this.isSeller && this.isAdmin) {
      df.doc(`users/${state.getUser()?.id}`)
        .get()
        .then((user) => {
          if (user && user.exists) {
            state.setUser(user.data())
          }

          if (!user.roles?.PRODAVAČ) {
            this.confirm({
              title: this.$t('$vuetify.addSellerRole'),
              message: this.$t('$vuetify.errors.notSellerButSuperuser'),
              options: {
                toolbar: true,
                width: 410,
                confirmText: this.$t('$vuetify.addRole'),
                cancelText: this.$t('$vuetify.close')
              }
            }).then(resp => {
              if (resp) {
                this.confirmClose()
                this.$router.push({
                  name: 'pointOfSale.users',
                  params: { companyId: state.getCurrentCompany().id, locationId: state.getPointOfSale().id }
                })
                setTimeout(() => {
                  EventBus.$emit('add-role', state.getUser())
                }, 150)
              }
            })
          }
        })
    }

    this.loading = true

    this.cashRegListeners.push(df.doc(`cash_registers/${state.getCashRegister().id}`).onSnapshot((doc) => {
      const _cashregister = doc?.data() || {}

      this.getUser(_cashregister).then(resp => {
        if (resp !== undefined) {
          this.user = resp.data()
          if (_cashregister.reserved_by !== undefined && _cashregister.reserved_by !== '' && _cashregister.reserved_by !== this.user.name.trim() + ' ' + this.user.surname.trim()) {
            if (this.$route.name === 'cashRegister') {
              this.showMsgBox({
                text: this.$t('$vuetify.cashRegister.user') + _cashregister.reserved_by + this.$t('$vuetify.cashRegister.otherUserReserved'),
                actions: ['cancel'],
                cancelBtnText: this.$t('$vuetify.close'),
                color: 'error'
              })

              this.$router.push({
                name: 'pointOfSale.cashRegisters',
                params: {
                }
              }).catch(() => {})
            }
          }
        } else {
          this.$router.push({
            name: 'pointOfSale.cashRegisters',
            params: {
            }
          }).catch(() => {})
        }
      })
    }))

    this.listeners.push(df.doc(`company_categories/${state.getCurrentCompany().id}`).onSnapshot(doc => {
      const _categoreis = doc?.data()?.categories || []
      if (_categoreis.length === 0) return
      var iterator = 0
      Object.keys(_categoreis).forEach(async (key) => {
        df.collection(`location_pricelist/${state.getPointOfSale().id}/items`).where('category_ids', 'array-contains', `${_categoreis[key].id}`)
          .where('status', '==', 'OK')
          .where('active', '==', true)
          .limit(1)
          .get()
          .then((documentSnapshots) => {
            if (documentSnapshots.docs && documentSnapshots.docs.length > 0 && !documentSnapshots.docs.empty) {
              const _category = { ..._categoreis[key], [`hover-${_categoreis[key].id}`]: false }
              if (iterator < 6) this.categories.push(_category)
              if (iterator >= 6) this.moreCategories.push(_category)
              iterator++
            }
          })
      })
    }))

    document.onkeydown = this.handleKeyEvents
  },
  beforeDestroy () {
    this.categoryItems = []
    this.categories = []
    this.moreCategories = []
    this.detachListeners()
  },
  methods: {
    calculateBasePrice (item) {
      var pnpAmt = 0
      if (item.prices[this.currency].taxes.length > 0) {
        item.prices[this.currency].taxes.forEach(tax => {
          if (tax.name === 'PNP') {
            pnpAmt = tax.rate
          }
        })
      }

      var taxes = item.prices[this.currency].taxes[0].rate + pnpAmt
      var basePrice = parseFloat(
        item.prices[this.currency].price /
                                  (1 +
                                  taxes / 10000)
      )

      return this.$options.filters.money(basePrice, 100, this.currency)
    },
    showWriteInProductDialog () {
      this.writeInProductDialog = true
    },

    onDialogClose () {
      this.writeInProductDialog = false
    },

    // capitalizeFirst (name) {
    //   return name.charAt(0).toUpperCase() + name.slice(1)
    // },
    callReservation (register, user) {
      const that = this

      this.showLoader()
      const reserving = user.name.trim() + ' ' + user.surname.trim()

      if (register.reserved_by !== undefined) {
        df.doc(`cash_registers/${register.id}`).update({ reserved_by: reserving })
          .then(() => {
          })
          .catch(() => {
            that.hideLoader()
            that.showMsgBox({ text: 'Pogreška prilikom zapisa podataka', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
            that.$router.push({
              name: 'pointOfSale.cashRegisters',
              params: {
                companyId: state.getCurrentCompany().id,
                locationId: state.getPointOfSale().id
              }
            })
          })
          .finally(() => {
            that.hideLoader()
          })
      }
    },
    async getUser (cashReg) {
      if (cashReg.login_type !== undefined && cashReg.login_type !== '' && cashReg.login_type !== 'DEFAULT') {
        if (state.getCashRegisterUser().id !== undefined && state.getCashRegisterUser().id !== '') {
          const user = await df.doc(`users/${state.getCashRegisterUser()?.id}`).get()
          return user
        }
      } else {
        const user = await df.doc(`users/${auth.currentUser.uid}`).get()
        return user
      }
    },
    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].associateHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },
    changeView () {
      state.setListView(!state.isListView())
    },
    getRowTabsModel () {
      const companyId = state.getCurrentCompany().id
      const pointOfSaleId = state.getPointOfSale().id
      return [
        { title: this.$t('$vuetify.cashRegisterTabs.myCompanis'), icon: 'mdi-briefcase-outline', to: { path: '/companies' } },
        { title: this.$t('$vuetify.cashRegisterTabs.pointsOfSale'), icon: 'mdi-crosshairs-gps', to: { path: `/companies/${companyId}/locations` } },
        { title: this.$t('$vuetify.cashRegisterTabs.registers'), icon: 'mdi-cash-register', to: { path: `/companies/${companyId}/locations/${pointOfSaleId}/registers` } },
        { divider: true },
        { title: this.$t('$vuetify.cashRegisterTabs.lager'), icon: 'mdi-basket', to: { path: `/companies/${companyId}/locations/${pointOfSaleId}/lager` } },
        { title: this.$t('$vuetify.pointsOfSaleTabs.categories'), icon: 'mdi-food-variant', to: { path: `/companies/${companyId}/locations/${pointOfSaleId}/categories` } },
        { title: this.$t('$vuetify.cashRegisterTabs.warehouse'), icon: 'mdi-warehouse', to: { path: `/companies/${companyId}/locations/${pointOfSaleId}/warehouse` } },
        { title: this.$t('$vuetify.cashRegisterTabs.priceList'), icon: 'mdi-list-status', to: { path: `/companies/${companyId}/locations/${pointOfSaleId}/price-list` } },
        { title: this.$t('$vuetify.cashRegisterTabs.receipts'), icon: 'mdi-printer', to: { path: `/companies/${companyId}/locations/${pointOfSaleId}/receipts` } },
        { title: this.$t('$vuetify.pointsOfSaleTabs.associates'), icon: 'mdi-account-multiple-outline', to: { path: `/companies/${companyId}/locations/${pointOfSaleId}/associates` } }
      ]
    },
    onAddPrice (item) {
      if (!this.transactionStart) {
        const price = parseFloat(clone(item.price * 100).toFixed(2)) // all numbers are ok but for some reason number 555.55 is formated like a 555.5499999999 thats why this number is parsed again
        item.prices[this.currency].price = price

        var newItem = clone(item)
        if (this.cashReg.type === 'VP') {
          newItem.prices[this.currency].price = Math.round(parseFloat(
            newItem.prices[this.currency].price /
                                  (1 +
                                    newItem.prices[this.currency].taxes[0].rate / 10000)
          ), 2)
        }
        EventBus.$emit('add-basket-item', newItem)
      }
    },
    tabChange () {
      this.lastVisible = ''
      this.categoryItems = []
      this.detachListeners()
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },

    selectCategory (category) {
      this.selectedCategory = category
    },
    addMenuProduct (item) {
      if (!this.transactionStart) {
        if (item.manual_price_input) {
          this.$refs.addManualPrice.open({ ...item, currency: this.currency })
        } else {
          EventBus.$emit('add-basket-item', item)
        }
      }
    },
    itemSelected (item) {
      var newItem = clone(item)
      if (!this.transactionStart) {
        if (this.selectedCategory.id === '5692439e-4269-4fd9-875e-71e0e08a4e99') {
          this.$refs.menuDialog.open(newItem, this.currency)
        } else {
          if (newItem.manual_price_input) {
            this.$refs.addManualPrice.open({ ...newItem, currency: this.currency })
          } else {
            if (this.cashReg.type === 'VP') {
              var pnpAmt = 0
              if (newItem.prices[this.currency].taxes.length > 0) {
                newItem.prices[this.currency].taxes.forEach(tax => {
                  if (tax.name === 'PNP') {
                    pnpAmt = tax.rate
                  }
                })
              }

              var taxes = newItem.prices[this.currency].taxes[0].rate + pnpAmt

              newItem.prices[this.currency].price = Math.round(parseFloat(
                newItem.prices[this.currency].price /
                                  (1 +
                                  taxes / 10000)
              ), 2)
            }
            EventBus.$emit('add-basket-item', newItem)
          }
        }
      }
      document.activeElement.blur()
    },

    onAddProduct (item) {
      if (!this.transactionStart) {
        item.quantity = Number(item.quantity).toFixed(3).replace('.', ',')
        const objectKeys = Object.keys(item.prices)
        objectKeys.forEach(key => {
          item.prices[key].price *= 100
        })
        var newItem = clone(item)
        // if (this.cashReg.type === 'VP') {
        //   newItem.prices[this.currency].price = Math.round(parseFloat(
        //     newItem.prices[this.currency].price /
        //                           (1 +
        //                             newItem.prices[this.currency].taxes[0].rate / 10000)
        //   ), 2)
        // }
        EventBus.$emit('add-write-in-item', newItem)
      }
    },

    transactionStarted () {
      this.transactionStart = true
    },

    transactionEnded () {
      this.transactionStart = false
    },
    transactionEndedWithReceipt () {
      if (this.cashReg.after_receipt_behaviour !== undefined && this.cashReg.after_receipt_behaviour !== null && this.cashReg.after_receipt_behaviour !== '') {
        if (this.cashReg.after_receipt_behaviour === 'SHOW_CASH_REGS') {
          if (this.cashReg.login_type === 'SELECT_USER' || this.cashReg.login_type === 'PINCODE') {
            state.setCashRegisterUser({ id: undefined })
            df.doc(`cash_registers/${state.getCashRegister().id}`).update({ reserved_by: '' })
          }
          this.$router.push({
            name: 'pointOfSale.cashRegisters',
            params: {
              companyId: state.getCurrentCompany().id,
              locationId: state.getPointOfSale().id
            }
          })
        } else if (this.cashReg.after_receipt_behaviour === 'SHOW_ALL_RECEIPTS') {
          if (this.cashReg.login_type === 'SELECT_USER' || this.cashReg.login_type === 'PINCODE') {
            state.setCashRegisterUser({ id: undefined })
            df.doc(`cash_registers/${state.getCashRegister().id}`).update({ reserved_by: '' })
          }

          // Potreban timeout jer kasa izbaci kad odjavim korisnika pa ostane na blagajni
          setTimeout(() => {
            this.$router.push({
              name: 'pointOfSale.receipts',
              params: {
                companyId: state.getCurrentCompany().id,
                locationId: state.getPointOfSale().id
              }
            })
          }, 500)
        }
      }
    },

    setCurency (currency) {
      this.currency = currency
      this.lastVisible = ''
      this.categoryItems = []
      this.detachListeners()

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
      // this.filterItemsByCurrency()
    },
    filterItemsByCurrency () {
      this.categoryItems = this._categoryItems.map(it => {
        const itemPrice = it.prices.find(price => price.currency === this.currency)
        if (itemPrice) {
          return {
            ...it, ...{ price: itemPrice.price, currency: itemPrice.currency }
          }
        } else {
          return null
        }
      })
      this.categoryItems = this.categoryItems.filter(it => it)
    },

    createReport () {
      if (this.$refs.createReport) this.$refs.createReport.open()
    },

    closeBalance () {
      if (this.$refs.closeBalance) this.$refs.closeBalance.open()
    },
    validateBarcode (b) {
      var Barcoder = require('barcoder')
      return Barcoder.validate(b)
    },
    barcodeFind (e) {
      if (e.key !== 'Enter') { this.barcode.push(e.key) }
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        if (this.validateBarcode(this.barcode.join(''))) {
          df.collection(`location_pricelist/${state.getPointOfSale().id}/items`)
            .where('status', '==', 'OK')
            .where('active', '==', true)
            .where(`prices.${this.currency}.active`, '==', true)
            .where('barcodes', 'array-contains', this.barcode.join(''))
            .limit(1)
            .get()
            .then((documentSnapshots) => {
              if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
                documentSnapshots.docs.forEach((item) => {
                  let it = item.data()

                  it.stock = it.stock / this.multiplier

                  if (it.warehouse_units !== undefined && it.warehouse_units.units !== undefined) {
                    Object.keys(it.warehouse_units.units).forEach(key => {
                      if (it.warehouse_units.units[key].prices !== undefined && it.warehouse_units.units[key].prices[this.currency].active === true) {
                        let newIt = {}
                        newIt = clone(it)
                        newIt.prices = it.warehouse_units.units[key].prices
                        // if (it.warehouse_units.units[key].storage_unit && it.warehouse_units.units[key].storage_unit !== '') {
                        //   newIt.name = it.warehouse_units.units[key].storage_unit
                        // } else {
                        //   newIt.name = it.name
                        // }
                        var stUnit = it.warehouse_units.units[key].storage_unit
                        if (stUnit === '.kom') {
                          stUnit = ''
                        }

                        newIt.name = it.name + ' ' + stUnit
                        newIt.id = newIt.id + '.' + it.warehouse_units.units[key].id
                        it = newIt
                      }
                    })
                  }

                  EventBus.$emit('add-basket-item', it)
                })
              }
            })
        }
        this.barcode = []
      }, 100)
    },
    handleKeyEvents (e) {
      e = e || window.event
      this.barcodeFind(e)
    },
    infiniteHandler ($state) {
      const where = this.search ? this.search.toLowerCase() : ''

      let search = 'name'
      if (this.isBarcodeSearch(this.search)) {
        search = 'barcode'
      }
      let query = df.collection(`location_pricelist/${state.getPointOfSale().id}/items`)
        .where('category_ids', 'array-contains', `${this.selectedCategory.id}`)
        .where('status', '==', 'OK')
        .where('active', '==', true)
        .where(`prices.${this.currency}.active`, '==', true)
        .orderBy(search, 'asc')
        .limit(10)
        .startAfter(this.lastVisible)

      if (where) {
        query = query.where(search, '>=', where).where(search, '<=', where + '\uf8ff')
      }

      const listener = query
        .onSnapshot((doc) => {
          doc.docChanges().forEach((change) => {
            if (change.type === 'added') {
              if (!this.categoryItems.some(item => item.id === change.doc.data().id)) {
                const it = change.doc.data()
                it.stock = it.stock / this.multiplier

                if (it.warehouse_units !== undefined && it.warehouse_units.units !== undefined) {
                  Object.keys(it.warehouse_units.units).forEach(key => {
                    if (it.warehouse_units.units[key].prices && it.warehouse_units.units[key].prices[this.currency] && it.warehouse_units.units[key].prices[this.currency].active === true) {
                      let newIt = {}
                      newIt = clone(it)
                      newIt.prices = it.warehouse_units.units[key].prices
                      // if (it.warehouse_units.units[key].storage_unit && it.warehouse_units.units[key].storage_unit !== '') {
                      //   newIt.name = it.warehouse_units.units[key].storage_unit
                      // } else {
                      //   newIt.name = it.name
                      // }
                      var stUnit = it.warehouse_units.units[key].storage_unit
                      if (stUnit === '.kom') {
                        stUnit = ''
                      }

                      newIt.name = it.name + ' ' + stUnit
                      newIt.id = newIt.id + '.' + it.warehouse_units.units[key].id
                      this.categoryItems.push({ ...newIt, ...{ [`hover-${newIt.id}`]: false } })
                    }
                  })
                } else {
                  this.categoryItems.push({ ...it, ...{ [`hover-${it.id}`]: false } })
                }
              }
            }
            if (change.type === 'modified') {
              this.categoryItems = this.categoryItems.map(item => {
                let ret = item
                if (item.id === change.doc.data().id) {
                  ret = change.doc.data()
                  ret.stock = ret.stock / this.multiplier
                } else if (change.doc.data().warehouse_units !== undefined && change.doc.data().warehouse_units.units !== undefined) {
                  const changedItem = change.doc.data()
                  Object.keys(changedItem.warehouse_units.units).forEach(key => {
                    const newItem = clone(changedItem)
                    const unitId = newItem.id + '.' + newItem.warehouse_units.units[key].id
                    if (unitId === item.id) {
                      newItem.prices = newItem.warehouse_units.units[key].prices
                      // if (newItem.warehouse_units.units[key].storage_unit && newItem.warehouse_units.units[key].storage_unit !== '') {
                      //   newItem.name = newItem.warehouse_units.units[key].storage_unit
                      // }
                      var stUnit = newItem.warehouse_units.units[key].storage_unit
                      if (stUnit === '.kom') {
                        stUnit = ''
                      }

                      newItem.name = newItem.name + ' ' + stUnit
                      newItem.id = newItem.id + '.' + newItem.warehouse_units.units[key].id
                      newItem.stock = newItem.stock / this.multiplier

                      ret = newItem
                    }
                  })
                }
                return { ...ret, ...{ [`hover-${ret.id}`]: false } }
              })
            }

            if (change.type === 'modified') {
              this.categoryItems = this.categoryItems.map(item => {
                let ret = item
                if (item.id === change.doc.data().id) {
                  ret = change.doc.data()
                  ret.stock = ret.stock / this.multiplier
                }
                return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
              })
            }
          })
          if (doc && !doc.empty) {
            if (doc.docs[doc.docs.length - 1]) {
              this.lastVisible = doc.docs[doc.docs.length - 1]
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        })

      // Zakomentirano jer se meni vidi samo na novom templateu
      // if (this.selectedCategory.id === '5692439e-4269-4fd9-875e-71e0e08a4e99') {
      //   const query = df.collection(`location_menu_pricelist/${state.getPointOfSale().id}/items`)
      //     .where('status', '==', 'OK')
      //     .where('active', '==', true)
      //     .where(`prices.${this.currency}.active`, '==', true)
      //     .orderBy(search, 'asc')
      //     .limit(10)
      //     .startAfter(this.lastVisible)

      //   const listener = query
      //     .onSnapshot((doc) => {
      //       doc.docChanges().forEach((change) => {
      //         if (change.type === 'added') {
      //           if (!this.categoryItems.some(item => item.id === change.doc.data().id)) {
      //             const it = change.doc.data()
      //             this.categoryItems.push(it)
      //           }
      //         }
      //       })
      //     })

      //   this.listeners.push(listener)
      // }

      this.listeners.push(listener)
    },
    searchItems () {
      this.lastVisible = ''
      this.categoryItems = []

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
      setTimeout(() => {
        if (this.$refs.item0 && this.$refs.item0[0]) {
          this.$refs.item0[0].$el.focus()
        }
      }, 500)
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    addToCategories (category) {
      // var leng = this.categories.length
      // if (leng > 0)

      this.moreCategories.push(this.categories[this.categories.length - 1])
      this.categories[this.categories.length - 1] = category
      this.moreCategories = this.moreCategories.filter(c => c.id !== category.id)
      this.selectCategory(category)
      this.category = this.categories.length - 1
      this.tabChange()
    }
  }
}
</script>
<style scoped>
.categories {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 64px;
  padding-bottom: 20px;
  z-index: 3;
  width: 100%;
}

.categories >>> .v-tabs-bar__content {
  justify-content: center;
  margin-top: 40px;
}

.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0.1px;
  z-index: 4;
  width: 100%;
}
.status-ok {
  border-left: 4px solid var(--v-success-base);
}

.status-failed {
  border-left: 4px solid var(--v-error-base);
}
.theme--light.v-tabs >>> .v-tabs-bar {
  background-color: rgb(256, 256, 256, 1) !important;
}

.theme--dark.v-tabs >>> .v-tabs-bar {
  background-color: rgb(18, 18, 18, 1) !important;
}

.ai-app-menu {
  /* border: none;
  width: 100%;
  height: 100%; */
  height: 300px;
  width: 340px;
  overflow: auto;
  overflow-x: hidden;
}

.more-category {
  cursor: pointer;
  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>
