<template>
  <v-row v-if="showToolbar" class="mb-1">
    <v-col
      v-for="(btn, i) in sortedButtons"
      :key="i"
      cols="2"
      style="max-height: 3.5rem; margin: 0; padding: 0 1px 1px 0"
    >
      <v-btn
        v-if="!btn.submenu"
        block
        tile
        depressed
        :color="btn.background_color"
        height="3.4rem"
        @click="onToolbarClick(btn.action)"
      >
        <v-icon :color="btn.foreground_color">{{ btn.icon }}</v-icon>
      </v-btn>
      <v-menu v-else bottom right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            block
            tile
            depressed
            color="white"
            width="100%"
            height="3.4rem"
            icon
            v-bind="attrs"
            v-on="on"
            :style="
              items && items.length > 0
                ? { 'font-weight': 'bold' }
                : {
                    'background-color': '#E0E0E0 !important',
                    'font-weight': 'bold',
                  }
            "
            :disabled="items && items.length > 0 ? false : true"
            :class="['white--text', { purple: true }]"
          >
            {{ btn.label }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(menu, idx) in btn.submenu"
            :key="idx"
            @click="menuPaymentClick(menu, btn.label)"
          >
            <v-list-item-title v-if="menu.type === 'G' || menu.type === 'V'">{{
              menu.text
            }}</v-list-item-title>
            <v-list-item-title v-else>{{ menu.text }} </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col
      v-show="hasSubmenu"
      cols="2"
      style="max-height: 3.5rem; margin: 0; padding: 0 1px 1px 0"
    >
      <v-menu
        bottom
        left
        flat
        rounded="false"
        nudge-bottom="60px"
        min-width="200px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            block
            tile
            depressed
            color="purple"
            width="100%"
            height="3.4rem"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <div v-for="(item, s) in sortedSubmenu" :key="s">
            <v-list-item @click="onToolbarClick(item.action)">
              <v-list-item-title>
                {{ item.label }}
              </v-list-item-title>
            </v-list-item>
          </div>
        </v-list>
      </v-menu>
      <search-items-dialog-new ref="searchItemsDialog" />
      <receipts-dialog-new ref="receiptsDialog" />
      <close-balance ref="closeBalance" />

      <split-receipt-dialog
        ref="splitReceiptDialog"
        :selectedTable="selectedTable"
        :availableTables="availableTables"
        @createNewTable="onCreateNewTable"
        @addItemsToTable="onAddItemsToTable"
      />
      <switch-table-by-pin ref="pinSwitch" />
      <add-receipt-note-dialog ref="addReceiptNoteDialog" />
    </v-col>
  </v-row>
</template>

<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import { clone } from '@/plugins/utils'
import SwitchTableByPin from '@/modules/cash-register/components/SwitchTableByPin'
import AddReceiptNoteDialog from '@/modules/cash-register/components/dialogs/AddReceiptNoteDialog.vue'
import SearchItemsDialogNew from '@/modules/cash-register/components/dialogs/SearchItemsDialogNew'
import ReceiptsDialogNew from '@/modules/cash-register/components/dialogs/ReceiptsDialogNew'
import CloseBalance from '@/modules/cash-register/components/closeBalance/CloseBalance'
import SplitReceiptDialog from '@/modules/cash-register/components/dialogs/SplitReceiptDialog'

export default {
  components: {
    SwitchTableByPin,
    AddReceiptNoteDialog,
    ReceiptsDialogNew,
    SearchItemsDialogNew,
    CloseBalance,
    SplitReceiptDialog
  },
  data: function () {
    return {
      selectedTable: {},
      availableTables: [],
      items: [],
      paymentMenu: [
        { text: this.$t('$vuetify.paymentMethods.cash'), type: 'G', icon: 'mdi-cash' },
        { text: this.$t('$vuetify.paymentMethods.card'), type: 'K', icon: 'mdi-card' }
      ],
      sortedButtons: [],
      sortedSubmenu: [],
      cashRegUsers: [],
      templateConfig: {
        toolbar: {
          buttons: [],
          submenu_options: []
        }
      },
      receiptNote: null
    }
  },

  computed: {
    paymentRepDisabled () {
      return this.data
    },
    hasSubmenu () {
      return this.templateConfig.toolbar.submenu_options.length > 0
    },
    showToolbar () {
      return this.templateConfig.toolbar.buttons.length > 0
    }
  },

  methods: {
    onToolbarClick (action) {
      if (action) {
        this[action]()
      }
    },

    openSearchItemsDialog () {
      this.$refs.searchItemsDialog.open()
    },

    openReceiptsDialog () {
      this.$refs.receiptsDialog.open()
    },

    openCloseBalanceDialog () {
      this.$refs.closeBalance.open()
    },

    openSplitReceiptDialog () {
      this.$refs.splitReceiptDialog.open()
    },

    finishPaymentRep () {
      EventBus.$emit('confirm-reprezentacija')
    },

    openPinSwitch () {
      this.$refs.pinSwitch.open(this.cashRegUsers, state.getCashRegister(), this.selectedTable)
    },

    openReceiptNoteDialog () {
      this.$refs.addReceiptNoteDialog.open(this.receiptNote, this.selectedTable)
    },

    menuPaymentClick (menu, deliveryService) {
      switch (menu.type) {
        case 'G':
          EventBus.$emit('delivery-finish-simple-payment', deliveryService, 'G')
          break
        case 'K':
          EventBus.$emit('delivery-finish-simple-payment', deliveryService, 'O')
          break
      }
    },

    // From split receipt
    onCreateNewTable (sourceTable, destinationTable) {
      EventBus.$emit('on-create-new-table', sourceTable, destinationTable)
    },

    onAddItemsToTable (sourceTable, targetTable) {
      EventBus.$emit('on-add-items-to-table', sourceTable, targetTable)
    },

    async getCashRegUsers (id) {
      const query = await df.collection(`cash_registers/${id}/users`)
      this.cashRegUsers = []
      query.get().then((snapshot) => {
        snapshot.forEach((doc) => {
          this.cashRegUsers.push(doc.data())
        })
      })
    }
  },
  created () {
    this.getCashRegUsers(state.getCashRegister().id)
  },

  mounted () {
    EventBus.$on('save-receipt-note', (receiptNote) => {
      this.receiptNote = receiptNote
    })
    const interval = setInterval(() => {
      if (state.getTemplateConfig()) {
        this.templateConfig = state.getTemplateConfig()
        this.sortedButtons = clone(this.templateConfig.toolbar.buttons.sort((a, b) => a.position - b.position))
        this.sortedSubmenu = clone(this.templateConfig.toolbar.submenu_options.sort((a, b) => a.position - b.position))
        clearInterval(interval)
      }
    }, 100)

    EventBus.$on('update-table-selection', (table) => {
      this.selectedTable = table
    })

    EventBus.$on('set-available-tables', (availableTables) => {
      this.availableTables = availableTables
    })

    EventBus.$on('cart-has-changed', (invoice) => {
      this.items = invoice.items
    })
  }
}
</script>
