<template>
  <div>
    <v-row v-if="showComponent">
      <v-col xl="3" lg="6" md="6" sm="12" xs="12" style="padding: 0 2px 2px 0">
        <v-btn block tile depressed min-height="3.5rem" color="#2c5a90" @click="onOpenTablesDialog">
          <span class="white--text tile btn-lg">
            {{ $t("$vuetify.tables.select") }}
          </span>
        </v-btn>
      </v-col>
      <v-col xl="3" lg="6" md="6" sm="12" xs="12" style="padding: 0 2px 2px 0">
        <v-btn block tile depressed min-height="3.5rem" color="#2c5a90" @click="onNewOrder">
          <span class="white--text tile btn-lg">
            {{ $t("$vuetify.tables.new") }}
          </span>
        </v-btn>
      </v-col>
      <v-col xl="3" lg="6" md="6" sm="12" xs="12" style="padding: 0 2px 2px 0">
        <v-btn :disabled="clearOrderDisabled" block tile depressed min-height="3.5rem" color="#2c5a90" @click="onClearOrder">
          <span class="white--text tile btn-lg">
            {{ $t("$vuetify.tables.clear") }}
          </span>
        </v-btn>
      </v-col>
      <v-col xl="3" lg="6" md="6" sm="12" xs="12" style="padding: 0 0 2px 0">
        <v-btn :disabled="onSaveItemsToTableDisabled" block tile depressed min-height="3.5rem" style="background-color: #9c27b0 !important" @click="onSaveItemsToTable">
          <span v-if="selectedTable.number !== 0" class="white--text tile btn-lg">
            {{ `${$t("$vuetify.tables.saveTable")}` }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import EventBus from '@/plugins/event-bus'
import { clone } from '@/plugins/utils'
import Table from '@/model/Table'
import state from '@/state'

export default {
  data () {
    return {
      selectedTable: null,
      data: null,
      showComponent: false,
      templateConfig: {
        tables: {
          show: true
        },
        toolbar: {
          buttons: [],
          submenu_options: []
        }
      }
    }
  },
  computed: {
    clearOrderDisabled () {
      return this.selectedTable.number === 0 || this.data.items.length === 0
    },
    onSaveItemsToTableDisabled () {
      return this.selectedTable.number === 0 || this.data.items.length === 0
    }
  },

  methods: {
    onOpenTablesDialog () {
      EventBus.$emit('on-open-tables-dialog')
    },
    onNewOrder () {
      EventBus.$emit('on-new-order')
    },
    onClearOrder () {
      EventBus.$emit('on-clear-order')
    },
    onSaveItemsToTable () {
      EventBus.$emit('on-save-items-to-table')
    }
  },

  created () {
    const interval = setInterval(() => {
      if (state.getTemplateConfig()) {
        this.templateConfig = state.getTemplateConfig()
        this.showComponent = this.templateConfig.tables && this.templateConfig.tables.show
        clearInterval(interval)
      }
    }, 100)

    this.selectedTable = new Table()

    EventBus.$on('cart-has-changed', (data) => {
      this.data = clone(data)
    })

    EventBus.$on('update-table-selection', (table) => {
      this.selectedTable = table
    })
  }
}
</script>
