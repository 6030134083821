<template>
  <v-row class="mb-1">
    <v-col cols="2" style="max-height: 3.5rem; margin: 0; padding: 0 1px 1px 0">
      <v-tooltip
        bottom
        :open-delay="tooltipDelay"
        :open-on-hover="true"
        :open-on-click="false"
        :open-on-focus="false"
      >
        <template #activator="{ on }">
          <div style="padding: 1px" v-on="on">
            <v-btn
              style="border-radius: 12px"
              block
              tile
              depressed
              color="purple"
              width="100%"
              height="3.4rem"
              @click="$emit('openSearchItemsDialog')"
            >
              <v-icon color="white">mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
        <span>{{ $t("$vuetify.tooltips.searchItems") }}</span>
      </v-tooltip>
    </v-col>

    <v-col cols="2" style="max-height: 3.5rem; margin: 0; padding: 0 1px 1px 0">
      <v-tooltip
        bottom
        :open-delay="tooltipDelay"
        :open-on-hover="true"
        :open-on-click="false"
        :open-on-focus="false"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <v-btn
              style="border-radius: 12px"
              @click="openDeliveryPayment"
              block
              tile
              depressed
              color="purple"
              width="100%"
              height="3.4rem"
              :disabled="items && items.length > 0 ? false : true"
              :style="
                items && items.length > 0
                  ? { color: 'purple' }
                  : { color: 'blue' }
              "
            >
              <v-icon color="white">mdi-bike</v-icon>
            </v-btn>
          </div>
        </template>
        <span>Plaćanje</span>
      </v-tooltip>
    </v-col>
    <v-col cols="2" style="max-height: 3.5rem; margin: 0; padding: 0 1px 1px 0">
      <v-tooltip
        bottom
        :open-delay="tooltipDelay"
        :open-on-hover="true"
        :open-on-click="false"
        :open-on-focus="false"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <v-btn
              style="border-radius: 12px"
              block
              tile
              depressed
              color="purple"
              width="100%"
              height="3.4rem"
              @click="$emit('openReceiptsDialog')"
            >
              <v-icon color="white">mdi-receipt-text</v-icon>
            </v-btn>
          </div>
        </template>
        <span>{{ $t("$vuetify.tooltips.receiptsDialog") }}</span>
      </v-tooltip>
    </v-col>
    <v-col cols="2" style="max-height: 3.5rem; margin: 0; padding: 0 1px 1px 0">
      <v-tooltip
        bottom
        :open-delay="tooltipDelay"
        :open-on-hover="true"
        :open-on-click="false"
        :open-on-focus="false"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <v-btn
              style="border-radius: 12px"
              block
              tile
              depressed
              color="purple"
              width="100%"
              height="3.4rem"
              @click="$emit('openCloseBalanceDialog')"
            >
              <v-icon color="white">mdi-cash-register</v-icon>
            </v-btn>
          </div>
        </template>
        <span>{{ $t("$vuetify.tooltips.closeBalance") }}</span>
      </v-tooltip>
    </v-col>
    <v-col cols="2" style="max-height: 3.5rem; margin: 0; padding: 0 1px 1px 0">
      <v-tooltip
        bottom
        :open-delay="tooltipDelay"
        :open-on-hover="true"
        :open-on-click="false"
        :open-on-focus="false"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <v-btn
              style="border-radius: 12px"
              :disabled="
                selectedTable.number === 0 && selectedTable.items.length === 0
              "
              block
              tile
              depressed
              color="purple"
              width="100%"
              height="3.4rem"
              @click="$emit('openSplitReceiptDialog')"
            >
              <v-icon color="white">mdi-table-split-cell</v-icon>
            </v-btn>
          </div>
        </template>
        <span>{{ $t("$vuetify.tooltips.splitTable") }}</span>
      </v-tooltip>
    </v-col>
    <v-col cols="2" style="max-height: 3.5rem; margin: 0; padding: 0 1px 1px 0">
      <v-menu
        bottom
        left
        flat
        rounded="false"
        nudge-bottom="60px"
        min-width="200px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="border-radius: 12px"
            block
            tile
            depressed
            @click="collapseListGroups()"
            color="purple"
            width="100%"
            height="3.4rem"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list style="min-width: 100%">
          <v-list-item
            :disabled="selectedTable.id === ''"
            @click="openPinSwitch"
          >
            <v-list-item-title>
              {{ $t("$vuetify.tables.transferTableTitle") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="openReceiptNoteDialog">
            <v-list-item-title>
              {{ $t("$vuetify.tables.receiptNote") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="showWriteInProductDialog">
            <v-list-item-title>
              {{ $t("$vuetify.formDetails.writeInTitle") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="openTableHistory">
            <v-list-item-title> Povijest stolova </v-list-item-title>
          </v-list-item>
          <v-list-item class="pl-0 pr-0">
            <v-list-group
              v-model="showPrevs"
              v-if="!showPreviews"
              group="previews"
              @click.stop=""
            >
              <template v-slot:activator>
                <v-list-item class="pl-0" active-class="active-link">
                  <v-list-item-content>
                    <div>Pregled računa</div>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-flex v-for="(preview, i) in previews" :key="i">
                <v-list-item
                  link
                  class="pl-7 ma-0 pa-0 remove_padd"
                  exact
                  @click="openReceiptPreview(preview.value)"
                  active-class="active-link"
                >
                  <v-list-item-content>
                    <div class="pl-0">
                      {{ preview.name }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>
            </v-list-group>
          </v-list-item>
          <v-list-item @click="showWriteInProductDialog">
            <v-list-item-title>
              {{ $t("$vuetify.formDetails.writeInTitle") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :disabled="noItems" @click="$emit('finishPaymentRep')">
            <v-list-item-title> Reprezentacija </v-list-item-title>
          </v-list-item>

          <!-- <v-list-item
            :disabled="noItems"
            style="min-width: 100%"
            class="pa-0 d-flex"
          > -->
          <!-- <v-menu style="min-width: 100%" bottom right offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="border-radius:20px "
                  style="
                  align-items: center;
                  justify-items: start;
                  font-family: font-family: Raleway, sans-serif;
                  font-size: 15px;
                  color: black !important;
                  text-align: start  !important;
                  text-transform: unset !important;
                  "
                  block
                  plain
                  v-bind="attrs"
                  v-on="on"
                  @keypress.enter.stop=""
                >
                  Plaćanje
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(delivery, i) in deliveries"
                  :key="i"
                  @click="openDeliveryPayment(delivery.value)"
                  link
                  class="pl-7 ma-0 pa-0 remove_padd"
                  exact
                  active-class="active-link"
                >
                  <v-list-item-title> {{ delivery.name }} </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->

          <!-- <v-list-group
              v-model="showDeliveries"
              style="min-width: 100%"
              group="deliveries"
              @click.stop=""
            >
              <template v-slot:activator>
                <v-list-item
                  :disabled="noItems"
                  style="min-width: 50%"
                  class="pl-0"
                  active-class="active-link"
                >
                  <v-list-item-content>
                    <div>Plaćanje</div>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-flex v-for="(delivery, i) in deliveries" :key="i">
                <v-list-item
                  link
                  class="pl-7 ma-0 pa-0 remove_padd"
                  exact
                  @click="openDeliveryPayment(delivery.value)"
                  active-class="active-link"
                >
                  <v-list-item-content>
                    <div class="pl-0">
                      {{ delivery.name }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>
            </v-list-group> -->
          <!-- </v-list-item> -->
          <v-list-item :disabled="noItems" @click="printRecapitulation">
            <v-list-item-title>
              {{ $t("$vuetify.tables.cartRecpitulation") }}
            </v-list-item-title>
          </v-list-item>
          <!-- ZA PREDUJAM SAKRIVENO -->
          <!-- <v-list-item :disabled="!noItems" @click="openAvans">
            <v-list-item-title> Račun za predujam </v-list-item-title>
          </v-list-item>
          <v-list-item :disabled="noItems" @click="openAvansSummary">
            <v-list-item-title> Poveži račun za predujam </v-list-item-title>
          </v-list-item> -->
        </v-list>
      </v-menu>
      <switch-table-by-pin ref="pinSwitch"></switch-table-by-pin>
      <add-receipt-note-dialog
        ref="addReceiptNoteDialog"
      ></add-receipt-note-dialog>
      <table-history ref="tableHistory"></table-history>
      <add-write-in-product
        v-if="writeInProductDialog"
        :currency="currency"
        @addProduct="onAddProduct"
        @close="onDialogClose"
      ></add-write-in-product>
      <delivery-payment-dialog
        ref="deliveryPaymentDialog"
      ></delivery-payment-dialog>
      <avans-dialog ref="avans"></avans-dialog>
      <avans-summary ref="avansSummary"></avans-summary>
    </v-col>
  </v-row>
</template>

<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import SwitchTableByPin from '@/modules/cash-register/components/SwitchTableByPin.vue'
import AddReceiptNoteDialog from '@/modules/cash-register/components/dialogs/AddReceiptNoteDialog.vue'
import AddWriteInProduct from '@/modules/cash-register/components/AddWriteInProduct'
import TableHistory from '@/modules/cash-register/components/dialogs/tableHistory/TableHistory.vue'
import DeliveryPaymentDialog from '@/modules/cash-register/components/dialogs/DeliveryPaymentDialog.vue'
import EventBus from '@/plugins/event-bus'
import AvansDialog from '@/modules/cash-register/components/dialogs/avans/AvansDialog.vue'
import AvansSummary from '@/modules/cash-register/components/dialogs/avans/AvansSummary.vue'

export default {
  components: {
    SwitchTableByPin,
    AddReceiptNoteDialog,
    TableHistory,
    AddWriteInProduct,
    DeliveryPaymentDialog,
    AvansDialog,
    AvansSummary
  },
  data: function () {
    return {
      deliveries: [
        { name: 'Wolt', value: 'wolt' },
        { name: 'Glovo', value: 'glovo' },
        { name: 'Bolt', value: 'bolt' }
      ],
      cashRegUsers: [],
      tooltipDelay: 500,
      previews: [
        { name: 'Gotovina', value: 'G' },
        { name: 'Ponuda', value: 'P' },
        { name: 'Transakcijski', value: 'T' },
        { name: 'Virman ', value: 'V' }
      ],
      showPreviews: false,
      writeInProductDialog: false,
      showPrevs: false,
      showDeliveries: false
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectedTable: {
      type: Object,
      default: () => {}
    },
    receiptNote: {
      type: Object,
      default: () => undefined
    },
    currency: {
      type: String,
      default: ''
    },
    discount: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    noItems () {
      return this.items.length === 0
    }

  },
  watch: {

  },
  methods: {
    openAvansSummary () {
      this.$refs.avansSummary.open(null, null, this.items, this.discount)
    },
    openAvans () {
      this.$refs.avans.open()
    },
    collapseListGroups () {
      this.showPrevs = false
      this.showDeliveries = false
    },
    openPinSwitch () {
      this.$refs.pinSwitch.open(this.cashRegUsers, state.getCashRegister(), this.selectedTable)
    },

    openReceiptNoteDialog () {
      this.$refs.addReceiptNoteDialog.open(this.receiptNote, this.selectedTable)
    },

    openTableHistory () {
      this.$refs.tableHistory.open()
    },

    openReceiptPreview (method) {
      if (!this.items || this.items.length === 0) {
        return
      }

      var type = 'R'
      switch (method) {
        case 'G':
          type = 'BO'
          break
        case 'P':
          type = 'P'
          method = 'T'
          break
      }

      if (type === 'R' || type === 'P') {
        EventBus.$emit('preview_receipt_r', method, type)
      } else {
        EventBus.$emit('preview_receipt', method, type)
      }
    },

    openDeliveryPayment () {
      this.$refs.deliveryPaymentDialog.open()
    },

    showWriteInProductDialog () {
      this.writeInProductDialog = true
    },

    onDialogClose () {
      this.writeInProductDialog = false
      this.showDeliveries = false
    },

    onAddProduct (item) {
      this.$emit('addWriteInProduct', item)
    },

    printRecapitulation () {
      this.$emit('printRecapitulation')
    },

    async getCashRegUsers (id) {
      const query = await df.collection(`cash_registers/${id}/users`)
      this.cashRegUsers = []
      query.get().then((snapshot) => {
        snapshot.forEach((doc) => {
          this.cashRegUsers.push(doc.data())
        })
      })
    }
  },
  mounted () {
    EventBus.$off('open_receipts')
    EventBus.$on('open_receipts', () => {
      this.$emit('openReceiptsDialog')
    })
  },
  created () {
    this.getCashRegUsers(state.getCashRegister().id)
    this.tooltipDelay = state.getTooltipDelay()

    var type = state.getCashRegister().type
    switch (type) {
      case 'MP':
        this.previews = [{ name: 'Gotovina', value: 'G' },
          { name: 'Virman ', value: 'V' }]
        break
      case 'VP':
        this.previews = [
          { name: 'Transakcijski', value: 'T' },
          { name: 'Ponuda', value: 'P' },
          { name: 'Virman ', value: 'V' }]
        break
      case 'ALL':
        this.previews = [{ name: 'Gotovina', value: 'G' },
          { name: 'Transakcijski', value: 'T' },
          { name: 'Ponuda', value: 'P' },
          { name: 'Virman ', value: 'V' }]
        break
      default:
        this.previews = [
          { name: 'Gotovina', value: 'G' },
          { name: 'Transakcijski', value: 'T' },
          { name: 'Ponuda', value: 'P' },
          { name: 'Virman ', value: 'V' }
        ]
    }
  }
}
</script>
