<template>
  <v-dialog
    v-model="dialog"
    content-class="my-custom-dialog"
    persistent
    height="689"
    width="350"
  >
    <v-form ref="passwordReset" @submit.prevent="resetPassword">
      <!-- <v-alert
        v-model="error.show"
        :type="alertType"
        :color="alertType"
        dismissible
        >{{ error.message }}</v-alert
      > -->
      <v-card :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }">
        <v-card-title
          style="background-color: #369fc9; font-weight: bold; color: white"
        >
          <span class="text-h5" style="font-weight: bold"
            >Zaboravljena lozinka</span
          >
        </v-card-title>
        <v-card-text class="pt-10 mt-0 pb-0 mb-0">
          <v-row class="mb-5">
            <v-col class="pt-0 mt-0 pb-0 mb-0">
              <div class="subtitle-2">
                Unesite e-mail adresu s kojom ste se registrirali
              </div>
            </v-col>
          </v-row>
          <v-row class="pt-0 mt-0 pb-0 mb-0">
            <v-col class="pt-0 mt-0 pb-0 mb-0">
              <v-text-field
                label="Email"
                outlined
                rounded
                autofocus
                dense
                style="border-radius: 10px"
                :rules="[rules.email, rules.req]"
                v-model="email"
                prepend-inner-icon="mdi-email"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pr-5 pb-5">
          <v-spacer></v-spacer>
          <v-btn class="btn dangerButton" @click="close"> Odustani </v-btn>
          <v-btn
            id="success"
            class="btn successButton"
            ref="footer"
            type="submit"
          >
            {{ $t("$vuetify.passwordReset.resetBtn") }}
          </v-btn>

          <!-- <v-row>
            <v-col class="d-flex justify-center">
              <v-btn
                type="submi"
                class="white--text okButton rounded-card"
                dark
                height="45"
                x-large
                color="loginButton"
              >
                {{ $t("$vuetify.passwordReset.resetBtn") }}
              </v-btn>
            </v-col>
          </v-row> -->
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { functions } from '@/plugins/firebase'
import rules from '@/plugins/rules'
import defaults from '@/mixins/defaults'
export default {
  data: () => ({
    alertType: 'error',
    email: '',
    error: {
      show: false,
      message: undefined,
      code: undefined
    },
    rules: {
      req: rules.req(),
      email: rules.email()
    },
    dialog: false
  }),
  mixins: [defaults],
  methods: {
    open () {
      this.email = ''
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    resetPassword () {
      if (!this.$refs.passwordReset.validate()) return
      var passwordReset = functions.httpsCallable('PasswordReset')
      passwordReset({ email: this.email }).finally(() => {
        this.alertType = 'success'
        this.error.message = this.$t('$vuetify.messages.resetLinkSent')
        this.error.show = true
        this.dialog = false
      })
    }
  }
}
</script>
