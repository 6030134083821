<template>
  <v-dialog v-model="visible" v-if="receipt" persistent width="400">
    <v-form ref="paymentChangeForm" @submit.prevent="submit">
      <v-card
        class="py-3 pa-2"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="mb-2">
            <h3 class="text--secondary">Dodaj napojnicu</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="pt-5">
            <v-col class="pb-0 mb-0">
              <money-input
                hide-details
                dense
                ref="total"
                label="Iznos računa"
                disabled
                v-model="receipt.total"
                :currency="currency"
                :rules="[rules.req]"
              ></money-input>
            </v-col>
            <v-col class="pb-0 mb-0">
              <money-input
                hide-details
                dense
                ref="gotMoney"
                label="Primljen iznos"
                v-model="gotMoney"
                :currency="currency"
                :rules="[rules.req]"
                @blur="setTip()"
              ></money-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-5 mt-0 pb-0 mb-0">
              <money-input
                hide-details
                dense
                autofocus
                ref="tipAmount"
                label="Iznos napojnice"
                v-model="tipAmount"
                :currency="currency"
                :rules="[rules.req]"
              ></money-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-5 mt-0 pb-0 mb-0">
              <v-select
                label="Način plaćanja"
                dense
                hide-details
                :items="paymentMethods"
                v-model="selectedPaymentMethod"
                item-text="name"
                item-value="value"
                :rules="[rules.req]"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-5 mt-0 pb-0 mb-0">
              <v-row>
                <v-col cols="8">
                  <div ref="selectedSeller" v-if="selectedSeller">
                    <b>
                      Prodavač:
                      {{
                        $options.filters.capitalize(selectedSeller.name) +
                        " " +
                        $options.filters.capitalize(selectedSeller.surname)
                      }}
                    </b>
                  </div>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    small
                    @click="openSellers"
                    class="white--text okButton"
                  >
                    <span class="white--text tile btn-lg text-xs-center"
                      >Promijeni</span
                    ></v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" @click="close"> Odustani </v-btn>
          <v-btn class="white--text okButton" @click="submit"> Završi </v-btn>
        </v-card-actions>
      </v-card>
      <tip-seller
        ref="tipSeller"
        @sellerSelected="onSellerSelected"
      ></tip-seller>
    </v-form>
    <v-overlay v-if="loading" absolute z-index="10">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'
import { clone } from '@/plugins/utils'
import { df, functions } from '@/plugins/firebase'
import MoneyInput from '@/components/MoneyInput'
import TipSeller from '@/modules/company/components/TipSeller'

import state from '@/state'
export default {
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'confirm', 'confirmClose'],
  components: {
    MoneyInput,
    TipSeller
  },
  data: function () {
    return {
      visible: false,
      loading: false,
      receipt: undefined,
      selectedPaymentMethod: undefined,
      tipAmount: 0,
      gotMoney: 0,
      currency: 'EUR',
      sellers: [],
      selectedSeller: undefined,
      paymentMethods: [{
        name: 'Novčanice', value: 'NOVČANICE'
      },
      {
        name: 'Kartica', value: 'KARTICA'
      }
      ],
      rules: {
        req: rules.req()
      }
    }
  },
  computed: {

  },
  methods: {
    onSellerSelected (seller) {
      this.selectedSeller = seller
    },
    openSellers () {
      this.$refs.tipSeller.open(this.sellers)
    },
    setTip () {
      var gotMoney = parseFloat(this.$refs.gotMoney.currencyToFloat(this.gotMoney) * 100)
      var total = parseFloat(this.$refs.total.currencyToFloat(this.receipt.total) * 100)

      if (gotMoney > 0) {
        var calculation = Math.round(gotMoney - total)
        if (calculation < 0) {
          calculation = 0
        }
        this.tipAmount = calculation
        this.setMoneyField('tipAmount', this.tipAmount)
      }
    },
    setMoneyField (field, value) {
      this.$refs[field].$el.getElementsByTagName('input')[0].value = value
    },
    open (receipt) {
      if (!receipt) return
      this.visible = true
      this.receipt = clone(receipt)

      this.paymentMethods.forEach((payment) => {
        if (payment.value === this.receipt.payment_method) {
          this.selectedPaymentMethod = payment.value
        }
      })

      this.getSellers()
    },
    close () {
      this.visible = false
      this.tipAmount = 0
      this.gotMoney = 0
      this.selectedPaymentMethod = undefined
      this.receipt = undefined
    },
    async getSellers () {
      const snapshot = await df.collection(`cash_registers/${this.receipt.designation.register_id}/users`).get()
      this.sellers = []

      var sellerId = this.receipt.seller_id
      var sellerName = this.receipt.seller_name
      var sellerUid = this.receipt.seller_uid ? this.receipt.seller_uid : ''

      snapshot.forEach((doc) => {
        var seller = doc.data()
        this.sellers.push(seller.user)

        if (seller.user.name + ' ' + seller.user.surname === sellerName) {
          this.selectedSeller = seller.user
        }

        if (seller.user.designation === sellerId) {
          this.selectedSeller = seller.user
        }

        if (seller.user.name + ' ' + seller.user.surname === sellerName && seller.user.designation === sellerId) {
          this.selectedSeller = seller.user
        }

        if (seller.id === sellerUid) {
          this.selectedSeller = seller.user
        }
      })
    },
    submit () {
      if (!this.selectedPaymentMethod || this.selectedPaymentMethod === '') return
      this.loading = true
      var tips = functions.httpsCallable('tips')

      var tipAmount = Math.round(parseFloat(this.$refs.tipAmount.currencyToFloat(this.tipAmount) * 100))
      if (tipAmount <= 0) {
        this.loading = false
        this.showMsgBox({ text: 'Iznos napojnice je 0. Molimo unesite iznos napojnice.', actions: ['cancel'], cancelBtnText: 'OK', color: 'ok' })
        return
      }

      const params = {
        company_id: state.getCurrentCompany().id,
        tip_payment_method: this.selectedPaymentMethod,
        receipt_id: this.receipt.id,
        tip_amount: Math.round(parseFloat(this.$refs.tipAmount.currencyToFloat(this.tipAmount) * 100)),
        override: false
      }

      params.seller_id = ''
      if (this.selectedSeller && this.selectedSeller.id !== '') {
        params.seller_id = this.selectedSeller.id
      }

      tips(
        params
      )
        .then((result) => {
          if (result.data.code === 200) {
            this.loading = false
            this.showMsgBox({ text: 'Uspješno spremljena napojnica.', actions: ['cancel'], cancelBtnText: 'OK', color: 'ok' })
            this.close()
          } else {
            if (result.data.code === 403) {
              this.loading = false
              this.confirm({
                title: 'Napojnica već postoji',
                message: result.data.message,
                options: {
                  toolbar: true,
                  width: 410,
                  confirmText: 'Nastavi',
                  cancelText: 'Odustani'
                }
              }).then(async (resp) => {
                if (resp) {
                  this.loading = true
                  params.override = true
                  tips(
                    params
                  )
                    .then((result) => {
                      this.confirmClose()
                      this.loading = false
                      this.showMsgBox({ text: result.data.message, actions: ['cancel'], cancelBtnText: 'OK', color: 'ok' })
                      this.close()
                    })
                } else {
                  this.confirmClose()
                  this.loading = false
                  this.close()
                }
              })
            } else {
              this.loading = false
              this.showMsgBox({ text: result.data.message, actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
              this.close()
            }
          }
        })
    }
  }
}
</script>
