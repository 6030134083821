<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="350"
    min-width="350"
    style="z-index: 6"
  >
    <v-form ref="addPriceForm" @submit.prevent.native="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Odaberi cijenu za artikl</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row v-show="!isManual">
              <v-col>
                <money-input
                  autofocus
                  label="Željena cijena"
                  :rules="[rules.req]"
                  v-model="price"
                  :currency="currency"
                  ref="priceInput"
                  @keyup="calculateBase"
                >
                </money-input>
              </v-col>
              <v-col>
                <money-input
                  label="Osnovica"
                  :rules="[rules.req]"
                  v-model="base"
                  :currency="currency"
                  ref="baseInput"
                  @keyup="calculatePrice"
                >
                </money-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  disabled
                  :items="[pdv]"
                  v-model="pdv"
                  return-object
                  item-text="name"
                  item-value="rate"
                  :rules="[rules.req]"
                  :messages="!isTaxed ? 'Firma nije u sustavu PDV-a' : ''"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :disabled="disabled"
            type="submit"
          >
            Potvrdi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>

import rules from '@/plugins/rules'
import MoneyInput from '@/components/MoneyInput'
import { clone } from '@/plugins/utils'
import state from '@/state'
export default {
  components: { MoneyInput },
  name: 'AddCategory',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  props: ['isManual'],
  data: () => ({
    visible: false,
    price: 0,
    base: 0,
    pdv: {},
    disabled: true,
    currencies: ['EUR', 'USD', 'GBP'],
    currency: 'EUR',
    rules: {
      req: rules.req()
    },
    item: {}
  }),
  computed: {
    baseInput () {
      return this.base
    },
    isTaxed () {
      return state.getCurrentCompany().is_taxed
    }

  },
  watch: {
    price (nv) {
      const pr = this.$refs.priceInput.currencyToFloat(nv)
      if (pr > 0) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    }
  },
  methods: {
    async open (item) {
      this.currency = 'HRK'
      if (Date.now() / 1000 > 1672527599) {
        this.currency = 'EUR'
      }

      this.visible = true
      this.$nextTick(() => {
        this.item = clone(item)
        this.currency = item.currency
        this.price = 0
        this.base = 0
        this.pdv = item.prices[this.currency].taxes[0]
        this.pdv = this.isTaxed ? item.prices[this.currency].taxes[0] : { name: 'PDV 0%', rate: 0 }
        this.calculatePrice()
        this.calculateBase()
      })
    },
    close () {
      this.$emit('close')
      this.item = {}
      this.price = 0
      this.pdv = {}
      this.base = 0
      this.visible = false
    },
    calculateBase () {
      const price = this.$refs.priceInput.currencyToFloat(this.price)
      if (this.price > 0) {
        this.submitting = true
      } else {
        this.submitting = false
      }
      const base = parseFloat(price / (1 + this.pdv.rate / 10000)).toFixed(2)
      this.base = this.$n(base, 'currency', this.currency)
      this.$refs.baseInput.$el.getElementsByTagName('input')[0].value = base
    },
    calculatePrice () {
      const base = this.$refs.baseInput.currencyToFloat(this.base)
      const price = parseFloat(base + this.pdv.rate / 10000 * base).toFixed(2)
      this.price = this.$n(price, 'currency', this.currency)
      this.$refs.priceInput.$el.getElementsByTagName('input')[0].value = price
    },
    submit () {
      if (!this.$refs.addPriceForm.validate()) return
      const price = this.$refs.priceInput.currencyToFloat(this.price)
      this.$emit('add', { ...this.item, price: price })
      this.close()
    }
  }
}
</script>
