<template>
  <v-dialog
    v-model="dialogOpen"
    v-if="dialogOpen"
    content-class="my-custom-dialog"
    transition="dialog-top-transition"
    persistent
    scrollable
    max-width="60%"
    @keydown.esc="close"
  >
    <!-- BUG: if you put the border around the card, the bottom of the card won't be transparent -->
    <v-card tile color="white" style="border: 1px solid white">
      <v-card-title>
        <h2 class="text--secondary">{{ $t("$vuetify.tables.tables") }}</h2>
        <v-spacer></v-spacer>
        <v-switch
          v-model="switchDeleteMode"
          :disabled="switchDisabled"
          style="margin-right: 1rem; margin-top: 1.25rem"
          :label="`${switchDeleteMode ? 'Delete On' : 'Delete Off'}`"
        ></v-switch>
        <v-btn class="mr-2" color="blue" @click="addTable">
          <span style="color: white">{{ $t("$vuetify.tables.create") }}</span>
        </v-btn>
        <v-btn color="blue" @click="close">
          <span style="color: white">{{ $t("$vuetify.tables.close") }}</span>
        </v-btn>
      </v-card-title>
      <v-card-text class="white">
        <v-container class="panel-container">
          <v-row>
            <v-col
              v-for="(table, idxc) in emptyTables"
              :key="idxc"
              :cols="3"
              class="panel-cell"
              align="center"
            >
              <v-btn
                style="border-radius: 12px; display: inline-block"
                v-longpress:[table]="renameTable"
                :disabled="tableLocked(table)"
                block
                tile
                depressed
                min-height="6rem"
                color="primary"
                return-object
                @click="onCellClick(table)"
              >
                <span class="white--text tile btn-lg">{{ table.name }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog v-model="tableRename" width="25%">
      <v-card>
        <v-card-title> Preimenuj stol </v-card-title>
        <v-card-text>
          <v-text-field v-model="newTableName"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="text" @click="updateTableName()">
            Spremi
          </v-btn>

          <v-btn color="primary" variant="text" @click="tableRename = false">
            Otkaži
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <add-new-table-dialog
      v-if="addTableDialogOpen"
      :table="table"
      :tables="availableTables"
      @closeAddTableDialog="onCloseAddTableDialog"
      @addTable="finishAddTable"
    ></add-new-table-dialog>
  </v-dialog>
</template>

<script>
import AddNewTableDialog from '@/modules/cash-register/components/dialogs/AddNewTableDialog.vue'
import state from '@/state'
import { df } from '@/plugins/firebase'

export default {
  components: {
    AddNewTableDialog
  },
  inject: ['confirm', 'confirmClose'],
  data () {
    return {
      dialogOpen: true,
      tables: [],
      persist: true,
      addTableDialogOpen: false,
      table: {},
      currentTableNumber: 0,
      switchDeleteMode: false,
      switchDisabled: true,
      emptyTables: [],
      tableForRename: undefined,
      newTableName: '',
      tableRename: false
    }
  },
  props: {
    availableTables: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    availableTables: {
      immediate: true,
      deep: true,
      handler (newValue, _) {
        if (newValue.length === 0) {
          this.switchDeleteMode = false
          this.switchDisabled = true
        } else {
          this.switchDisabled = false
        }

        this.emptyTables = newValue.filter((table) =>
          !table.items || table.items.length === 0
        )
      }
    }
  },
  mounted () {
    this.emptyTables = this.availableTables.filter((table) =>
      !table.items || table.items.length === 0
    )
  },
  methods: {
    renameTable (table) {
      this.tableForRename = table
      this.newTableName = table.name
      this.tableRename = true
    },
    updateTableName () {
      var tb = this.tableForRename
      var newName = this.newTableName

      this.tableRename = false
      this.tableForRename = undefined
      this.newTableName = ''

      if (this.emptyTables && this.emptyTables.length > 0) {
        this.emptyTables.forEach((tbl, key) => {
          if (tbl.id === tb.id) {
            this.emptyTables[key].name = newName
          }
        })
      }

      this.updateTableNameInDb(tb.id, newName)
      this.$forceUpdate()
    },
    updateTableNameInDb (tableId, newName) {
      df.doc(`location_orders/${state.getPointOfSale().id}/tables/${tableId}`).update({
        name: newName
      })
        .then(() => {
          console.info('Table succesfully updated.')
        })
        .catch(() => {
          console.error('Table has not been updated succesfully.')
        })
    },
    tableLocked (table) {
      return table.locked && table.locked_by !== state.getUser().id
    },
    close () {
      this.$emit('closeTablesDialog')
    },
    onCloseAddTableDialog () {
      this.addTableDialogOpen = false
    },
    addTable () {
      this.getCurrentTableNumber(true)
    },
    finishAddTable (table, persist) {
      table.persistent = persist
      this.addTableDialogOpen = false
      try {
        this.availableTables.push(table)
        this.saveTable(table, persist)
      } catch (err) {
        console.error('Error saving table: ', err)
      }
    },
    async saveTable (table, persist) {
      this.$emit('saveTable', table, persist, true)
    },
    onCellClick (table) {
      if (!this.switchDeleteMode) {
        this.$emit('addTable', table)
        this.$emit('closeTablesDialog')
      } else {
        this.deleteTable(table)
      }
    },
    deleteTable (table) {
      this.confirm({
        title: 'Brisanje stola',
        message: `${this.$t('$vuetify.tables.delete')} ${table.name}?`,
        options: {
          toolbar: true,
          width: 410,
          confirmText: this.$t('$vuetify.yes'),
          cancelText: this.$t('$vuetify.no')
        }
      }).then(async (resp) => {
        if (resp) {
          this.$emit('deleteTable', table)
        }
        this.confirmClose()
      })
    },
    getCurrentTableNumber (openDialog = false) {
      const query = df.collection(`location_orders/${state.getPointOfSale().id}/tables`).orderBy('number', 'desc').limit(1)
      query.get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty && documentSnapshots.docs.length > 0) {
            documentSnapshots.docs.forEach((item) => {
              this.currentTableNumber = item.data().number

              if (openDialog === true) {
                const number = this.currentTableNumber + 1
                const name = `Stol ${number}`
                this.table = { name: `${name}`, number: number, items: [], persistent: true, color: 'primary' }
                this.addTableDialogOpen = true
              }
            })
          } else {
            this.currentTableNumber = 0
            if (openDialog === true) {
              const number = this.currentTableNumber + 1
              const name = `Stol ${number}`
              this.table = { name: `${name}`, number: number, items: [], persistent: true, color: 'primary' }
              this.addTableDialogOpen = true
            }
          }
        })
      /*
      if (this.availableTables.length === 0) {
        this.currentTableNumber = 0
      } else {
        this.currentTableNumber = Math.max(...this.availableTables.map(table => table.number))
      } */
    }
  },
  created () {
    this.getCurrentTableNumber()
  }
}
</script>

<style scoped>
.v-dialog__content >>> .my-custom-dialog {
  position: absolute;
  top: 50px;
  border-radius: 25px;
}
.panel-container {
  margin-top: 1rem;
}
.text-wrap {
  width: min-content;
  margin: auto;
}
.panel-cell {
  padding: 0 2px 2px 0;
}
.active {
  background-color: var(--v-accent-darken3);
  color: white;
}
.label {
  font-size: 0.6rem;
  color: white;
  min-width: 90px;
}
.btn-md {
  font-size: 0.8rem;
  font-weight: 700;
}
.btn-lg {
  font-size: 1.4rem;
  font-weight: 700;
}
</style>
