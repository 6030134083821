var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showComponent)?_c('v-row',_vm._l((_vm.sortedButtons),function(btn,i){return _c('v-col',{key:i,staticStyle:{"padding":"0 2px 2px 0"},attrs:{"cols":_vm.colspan}},[(!btn.submenu)?_c('v-btn',{attrs:{"disabled":!_vm.buttonsEnabled,"block":"","tile":"","depressed":"","min-height":"3.5rem","color":"#2C5A90"},on:{"click":function($event){return _vm.onButtonClick(btn)}}},[_c('span',{staticClass:"white--text tile btn-lg"},[_vm._v(_vm._s(btn.label))])]):_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:[
              { 'disable-events': !_vm.buttonsEnabled },
              'white--text',
              { darkblue: _vm.buttonsEnabled } ],attrs:{"block":"","tile":"","depressed":"","min-height":"3.5rem","icon":""},on:{"click":function($event){return _vm.checkCards()}}},'v-btn',attrs,false),on),[_c('span',{staticClass:"white--text tile btn-lg"},[_vm._v(_vm._s(btn.label))])])]}}],null,true)},[_c('v-list',_vm._l((btn.submenu),function(item,idx){return _c('v-list-item',{key:idx,on:{"click":function($event){item.type !== 'K'
                ? _vm.onMenuItemClick(item.method, item.type, item)
                : _vm.checkCards()}}},[(!item.submenu)?_c('v-list-item-title',[_vm._v(_vm._s(item.text))]):_c('v-list-item-title',[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(_vm.$t("$vuetify.paymentMethods.card")))])]}}],null,true)},[(_vm.availableCards && _vm.availableCards.length > 0)?_c('v-list',_vm._l((_vm.availableCards),function(card,idx){return _c('v-list-item',{key:idx,on:{"click":function($event){return _vm.insertAccountDetails('K', 'R', undefined, card)}}},[_c('v-list-item-title',[_vm._v(_vm._s(card.name))])],1)}),1):_vm._e()],1)],1)],1)}),1)],1)],1)}),1):_vm._e(),(_vm.r1DialogOpen)?_c('account-details-dialog',{attrs:{"data":_vm.data,"paymentMethod":_vm.paymentMethod,"selectedCard":_vm.selectedCard,"selectedPrinter":_vm.selectedPrinter},on:{"closeR1Dialog":_vm.onR1DialogClose,"imidiatePrintPrepare":_vm.imidiatePrintPrepare,"accountDetailsFinishPayment":_vm.onAccountDetailsFinishPayment}}):_vm._e(),_c('terminals',{ref:"terminals",on:{"terminalSelected":_vm.terminalSelected}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }