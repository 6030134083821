<template>
  <span
    :ref="`${refName}`"
    :style="`height: 56px; font-size: ${fontSize}px; display: grid; align-items: center; color: ${color}`"
    class="text-wrap label"
    >{{ label }}</span
  >
</template>
<script>
import textfit from 'textfit'

export default {
  computed: {
    fontSize () {
      return this.fontsize
    },
    refName () {
      return this.refname
    }
  },
  name: 'PrioductTile',
  props: {
    label: String,
    color: String,
    fontsize: {
      type: Number,
      default: 14
    },
    refname: {
      type: String,
      default: 'textref'
    }
  },
  mounted () { },
  watch: {
    label: {
      immediate: true,
      deep: true,
      handler (nval, oval) {
        this.$nextTick(() => {
          this.$refs[this.refName].textContent = nval
          if (nval !== '' && nval !== oval) {
            textfit(this.$refs[this.refName], { minFontSize: 5, maxFontSize: parseInt(this.fontsize), alignVert: true, alignHoriz: true, multiLine: true, alignVertWithFlexbox: true })
          }
        })
      }

    }
  }
}
</script>
