<template>
  <v-dialog v-model="visible" persistent scrollable width="400" v-if="receipt">
    <v-form ref="payReceiptForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto py-3"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Plati račun</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              {{
                `${$t(
                  "$vuetify.receipts.receiptIssueDate"
                )}: ${receiptIssueDate}`
              }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                offset-y
                v-model="paymentDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="paymentComputedDate"
                    :rules="[rules.req]"
                    :label="$t('$vuetify.receipts.receiptPaymentDate')"
                    dense
                    prepend-icon="mdi-clock-time-four-outline"
                    @click:prepend="paymentDateMenu = !paymentDateMenu"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="paymentDate"
                  locale="hr"
                  no-title
                  scrollable
                  @input="paymentDateMenu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :label="$t('$vuetify.journalNum')"
                v-model="journalNum"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea :label="$t('$vuetify.note')" v-model="note" rows="3">
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" @click="close">
            {{ $t("$vuetify.close") }}
          </v-btn>
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Plati račun
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'
import { clone, toISOLocal } from '@/plugins/utils'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import { v4 as uuidv4 } from 'uuid'
import requestChecker from '@/mixins/requestChecker'

export default {
  inject: ['showMsgBox'],
  mixins: [requestChecker],
  data: function () {
    return {
      visible: false,
      submitting: false,
      receipt: undefined,
      paymentDate: '',
      paymentDateMenu: false,
      rules: {
        req: rules.req()
      },
      note: '',
      journalNum: ''
    }
  },
  computed: {
    paymentComputedDate () {
      if (!this.paymentDate) return ''
      return new Date(this.paymentDate).toLocaleDateString('hr-HR')
    },
    receiptIssueDate () {
      return new Date(
        toISOLocal(
          new Date(this.receipt.issue_date_time * 1000 || new Date().getTime())
        )
      ).toLocaleDateString('hr-HR')
    },
    minPaymentDate () {
      return this.receipt.issue_date_time
    },
    minPaymentViewDate () {
      return toISOLocal(new Date(this.minPaymentDate * 1000))
    }
  },
  methods: {
    open (receipt) {
      if (!receipt) return
      this.visible = true
      this.receipt = clone(receipt)
      const reqParams = {
        status_info: this.note
      }
      this.registerRequestParams(reqParams)
    },
    close () {
      this.note = ''
      this.journalNum = ''
      this.paymentDate = ''
      this.submitting = false
      this.paymentDateMenu = false
      this.visible = false
    },
    getCurrentTimePart () {
      return new Date().toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      })
    },
    submit () {
      if (!this.$refs.payReceiptForm.validate()) return
      try {
        const paymetDate = (new Date(this.paymentDate).getTime() / 1000).toFixed(
          0
        )

        const reqId = uuidv4()
        const payload = {
          action: {
            operation: 'update',
            entity: 'receipt',
            resource_id: this.receipt.id,
            params: {
              company_id: state.getCurrentCompany().id,
              date: parseInt(paymetDate),
              status: 'PAID',
              status_info: this.note,
              journal_number: this.journalNum
            }
          }
        }

        const cleanParams = this.getCleanParams(payload.action.params)
        Object.keys(cleanParams).forEach((key) => {
          delete payload.action.params[key]
        })

        this.submitting = true
        df.doc(`request/${reqId}`)
          .set({
            msg_id: reqId,
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'receipt',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(() => {
            this.$emit('receiptEdit', this.receipt)
            this.close()
          })
          .catch((error) => {
            // console.error('Error writing document: ', error)
            throw new Error(error)
          })
          .finally(() => {
            this.submitting = false
          })
      } catch (err) {
        this.showMsgBox({
          text: err,
          actions: ['cancel'],
          cancelBtnText: 'OK'
        })
      }
    }
  }
}
</script>
