<template>
  <div>
    <v-dialog
      :max-width="width"
      v-model="dialog"
      v-if="dialog"
      persistent
      @keydown.esc="close"
      eager
    >
      <v-card tile color="white">
        <v-card-title>
          <h2 class="text--secondary">Odaberite opciju</h2>
          <v-spacer></v-spacer>
          <v-icon large @click="close">mdi-close</v-icon>
        </v-card-title>
      </v-card>
      <v-card-text class="white">
        <v-container>
          <v-row class="panel-row">
            <v-col
              v-for="(option, idxc) in menu"
              :key="idxc"
              :cols="12"
              class="mb-1 pb-0 mt-0 pt-0"
            >
              <v-btn
                block
                tile
                depressed
                min-height="6rem"
                color="#295586"
                class="mb-0 pb-0"
                return-object
                @click="menuClick(option.method)"
              >
                <span class="white--text tile btn-lg text-xs-center">{{
                  option.text
                }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      item: {},
      menu: [],
      card: null,
      cards: [
        { name: 'Maestro', value: 'maestro' },
        { name: 'MasterCard', value: 'mastercard' },
        { name: 'Visa', value: 'visa' }
      ],
      width: '500px'
    }
  },
  methods: {
    open (item) {
      this.item = item
      this.menu = this.item.menu
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    menuClick (method) {
      this.$emit('itemMenuClick', method, this.item)
      this.close()
    }
  }
}
</script>

<style scoped>
span.darkblue {
  color: #275281;
}

@media only screen and (max-height: 899px) {
  #container {
    max-height: 193px;
  }
  span.tile {
    display: block;
    white-space: normal;
  }
}

.panel-row-inactive {
  background-color: #816827;
}

@media only screen and (min-height: 900px) {
  #container {
    max-height: 440px;
  }
  span.tile {
    display: block;
    white-space: normal;
  }
}
.btn-lg {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>
