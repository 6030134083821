<template>
  <div>
    <v-dialog
      v-model="dialogOpen"
      v-if="dialogOpen"
      content-class="my-custom-dialog"
      persistent
      max-width="60%"
      @keydown.esc="close"
    >
      <v-card tile color="white">
        <v-card-title>
          <h2 class="text--secondary">
            {{ $t("$vuetify.cashRegister.search") }}
          </h2>
          <v-spacer></v-spacer>
          <v-icon large @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="white">
          <v-progress-linear v-if="!loaded" indeterminate></v-progress-linear>
          <v-container class="panel-container">
            <v-row class="panel-row">
              <v-col cols="12">
                <v-text-field
                  autofocus
                  ref="defInput"
                  v-model="searchText"
                  label="Pretraži artikle po nazivu ili šifri"
                  data-layout="normalAduro"
                  append-icon="mdi-magnify"
                  clearable
                  @click="showKbd"
                  @keydown.enter="searchProducts()"
                  @click:append="searchProducts()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="panel-row">
              <v-col
                v-for="(item, idxc) in cols"
                :key="idxc"
                :cols="4"
                class="panel-cell"
                align="center"
              >
                <v-btn
                  block
                  tile
                  depressed
                  min-height="6rem"
                  :color="item.color"
                  class=""
                  return-object
                  @click="onCellClick(item)"
                >
                  <span class="white--text tile">{{ item.label }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container
      class="kbd"
      v-if="!$vuetify.breakpoint.smAndDown"
      ref="keyboard"
      v-show="oskVisible"
      :style="{ 'max-width': width }"
    >
      <div class="kbd-header"></div>
      <vue-touch-keyboard
        :options="oskOptions"
        :layout="layout"
        :cancel="hideKbd"
        :accept="accept"
        :input="input"
      />
    </v-container>
  </div>
</template>

<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import { clone, isNumeric } from '@/plugins/utils'
import onScreenKeyboard from '@/mixins/onScreenKeyboard'

export default {
  mixins: [onScreenKeyboard],
  data () {
    return {
      dialogOpen: false,
      products: [],
      cols: [],
      searchText: '',
      priceListItems: [],
      loaded: true,
      documents: [],
      items: [],
      menuItems: [],
      currency: 'EUR'
    }
  },
  methods: {
    async open () {
      this.items = await this.getItems()
      this.menuItems = await this.getMenuItems()

      const documents = [...this.items, ...this.menuItems]
      this.documents = clone(documents.sort((a, b) => a.name.localeCompare(b.name)))

      this.currency = 'EUR'

      this.setEnterAction(this.searchProducts)
      this.setPosition(this.$refs.keyboard, '217px', '502px')
      // const ref = this.$refs.defInput.$refs.input
      // this.showKbdOnMount(ref, 'normalAduro')

      this.dialogOpen = true
    },

    close () {
      this.dialogOpen = false
    },

    onCellClick (i) {
      const item = this.cols.find(it => it.id === i.id)
      EventBus.$emit('add-product-to-invoice', item)
    },

    async searchProducts () {
      this.loaded = false
      this.priceListItems = []
      this.cols = []

      const searchProp = isNumeric(this.searchText) ? 'code' : 'name'

      for (let i = 0; i < this.documents.length; i++) {
        if (this.documents[i][searchProp].toUpperCase().indexOf(this.searchText ? this.searchText.toUpperCase() : '') === 0) {
          this.priceListItems.push(this.documents[i])
          if (this.priceListItems.length === 9) { break }
        }
      }

      this.priceListItems.forEach(product => {
        if (product.warehouse_units && product.warehouse_units.units) {
          Object.keys(product.warehouse_units.units).forEach(key => {
            if (product.warehouse_units.units[key].prices && product.warehouse_units.units[key].prices[this.currency].active === true) {
              let newProduct = {}
              newProduct = clone(product)
              newProduct.prices = product.warehouse_units.units[key].prices

              var stUnit = product.warehouse_units.units[key].storage_unit
              if (stUnit === '.kom') {
                stUnit = ''
              }
              if (product.warehouse_units.units[key].base_unit === true) {
                stUnit = product.selling_unit
              }
              newProduct.name = product.name + ' ' + stUnit
              newProduct.id = newProduct.id + '.' + product.warehouse_units.units[key].id
              this.cols.push({ type: newProduct.type, color: '#2C5A90', id: newProduct.id, label: newProduct.name })
            }
          })
        } else {
          this.cols.push({ type: product.type, color: '#2C5A90', id: product.id, label: product.name })
        }
      })

      this.loaded = true
    },

    async getItems () {
      try {
        const query = await df.collection(`location_pricelist/${state.getPointOfSale().id}/items`)
          .where('status', '==', 'OK')
          .where('active', '==', true)
          .get()
        return query.docs.map(doc => doc.data())
      } catch (err) {
        console.error('Error fetching items')
        return []
      }
    },

    async getMenuItems () {
      try {
        const query = await df.collection(`location_menu_pricelist/${state.getPointOfSale().id}/items`)
          .where('status', '==', 'OK')
          .where('active', '==', true)
          .get()
        const res = query.docs.map(doc => doc.data())
        res.forEach(el => {
          el.type = 'menu-product'
        })
        return res
      } catch (err) {
        console.error('Error fetching items')
        return []
      }
    }
  }
}
</script>

<style scoped>
.v-dialog__content >>> .my-custom-dialog {
  position: absolute;
  top: 50px;
  border-radius: 25px;
}
.panel-container {
  margin-top: 1rem;
}
.text-wrap {
  width: min-content;
  margin: auto;
}
.panel-cell {
  padding: 0 1px 1px 0;
}
.active {
  background-color: var(--v-accent-darken3);
  color: white;
}
.label {
  font-size: 0.6rem;
  color: white;
}
span.tile {
  display: block;
  white-space: normal;
  max-width: 330px;
  font-weight: 700;
  font-size: 1.4rem;
}
</style>
