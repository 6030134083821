<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-data-table
          v-if="!ordersView"
          class="invoice-table"
          :height="tableHeight"
          fixed-header
          :headers="headers"
          :items="invoice.items"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar
              dense
              flat
              outlined
              style="border-left: 0; height: 3.4rem"
              ><span
                style="
                  font-weight: bold;
                  text-transform: capitalize;
                  font-size: 16px;
                  padding: 0 20px 0 0;
                "
                >{{
                  selectedTable.user_name ? selectedTable.user_name : ""
                }}</span
              >
              <v-spacer></v-spacer>
              <v-toolbar-title>
                <div
                  style="
                    padding-top: 1.5rem;
                    padding-right: 2rem;
                    padding-left: 2rem;
                  "
                >
                  <v-switch
                    v-model="switchOnOrdersTable"
                    @change="onOrdersChange"
                  ></v-switch>
                </div>
              </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`header.controls`]="{}">
            <v-row class="justify-end pr-1">
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-3" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(menu, idx) in generalMenu"
                    :key="idx"
                    @click="generalMenuClick(menu.method)"
                  >
                    <v-list-item-title>{{ menu.text }}</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="lastInsertBy && lastInsertBy !== '' ? true : false"
                  >
                    <v-list-item-title
                      >Zadnje dodao
                      {{
                        $options.filters.capitalize(lastInsertBy)
                      }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </template>
          <template #[`item.name`]="{ item }">
            <div
              v-if="!showEdit(item)"
              class="invoice-row"
              style="min-width: 18rem; max-width: 22rem"
              :style="
                showPencil(item)
                  ? { 'text-decoration': 'underline', cursor: 'pointer' }
                  : { 'text-decoration': 'none' }
              "
              @mouseover="onNameMouseover(item)"
              @mouseleave="onNameMouseover(item)"
              @click="onNameClick(item)"
            >
              {{ item.name }}
              <v-icon v-if="showPencil(item)" class="ml-2">mdi-pencil</v-icon>
            </div>
            <div v-else>
              <v-text-field
                autofocus
                class="invoice-row"
                v-model="item.name"
                append-icon="mdi-check"
                @click:append="finishEdit(item)"
                @keypress.enter="finishEdit(item)"
              >
              </v-text-field>
            </div>
          </template>
          <template #[`item.selling_unit`]="{ item }">
            <div class="invoice-row">
              {{ item.selling_unit }}
            </div>
          </template>
          <template #[`item.unitPrice`]="{ item }">
            <div class="invoice-row">
              {{ $options.filters.money(item.unitPrice, 100, currency) }}
            </div>
          </template>
          <template #[`item.discountedUnitPrice`]="{ item }">
            <div class="invoice-row">
              {{
                $options.filters.money(item.discountedUnitPrice, 100, currency)
              }}
            </div>
          </template>
          <template #[`item.total`]="{ item }">
            <div class="invoice-row">
              {{ $options.filters.money(item.total, 100, currency) }}
            </div>
          </template>
          <template #[`item.quantity`]="{ item }">
            <v-text-field
              style="
                max-width: 2rem;
                height: 24px !important;
                margin-top: -28px;
              "
              class="invoice-row"
              v-model="item.quantity"
              :disabled="item.type && item.type === 'menu-product'"
              @change="recalculate()"
            ></v-text-field>
          </template>
          <template #[`item.controls`]="props">
            <v-container>
              <v-row>
                <v-col cols="3">
                  <v-btn
                    icon
                    :disabled="
                      !props.item || props.item.type === 'menu-product'
                    "
                    color="red"
                    @click="onRowBtnClick(props.item, 'decrement')"
                  >
                    <v-icon dark>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    icon
                    :disabled="
                      !props.item || props.item.type === 'menu-product'
                    "
                    color="red"
                    @click="onRowBtnClick(props.item, 'increment')"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="2" class="mr-1">
                  <v-menu
                    bottom
                    right
                    transition="scale-transition"
                    max-width="170px"
                    min-width="170px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(menu, idx) in props.item.menu"
                        :key="idx"
                        @click="itemMenuClick(menu.method, props.item)"
                      >
                        <v-list-item-content>
                          <v-row>
                            <v-col>
                              {{ menu.text }}
                              <!-- <v-btn icon>
                                <v-icon>{{ menu.icon }}</v-icon>
                              </v-btn> -->
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>

        <v-data-table
          v-if="selectedTable && ordersView"
          :headers="orderHeaders"
          :items="selectedTable.orders"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          hide-default-footer
          fixed-header
          :items-per-page="-1"
          item-key="id"
          show-expand
          class="invoice-table"
        >
          <template v-slot:top>
            <v-toolbar dense flat outlined rounded shaped>
              <span
                style="
                  font-weight: bold;
                  text-transform: capitalize;
                  font-size: 16px;
                  padding: 0 20px 0 0;
                "
                >{{
                  selectedTable.user_name ? selectedTable.user_name : ""
                }}</span
              >
              <v-spacer></v-spacer>
              <v-toolbar-title>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div
                      v-on="on"
                      style="
                        padding-top: 1.5rem;
                        padding-right: 2rem;
                        padding-left: 2rem;
                      "
                    >
                      <v-switch
                        v-model="switchOnOrdersTable"
                        @change="onOrdersChange"
                      ></v-switch>
                    </div>
                  </template>
                  <span>{{
                    switchOnOrdersTable ? "Narudžbe" : "Blagajna"
                  }}</span>
                </v-tooltip>
              </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td :colspan="orderHeaders.length">
              <v-row class="d-flex" flat>
                <v-col cols="4" class="d-flex justify-center pt-5 pl-5"
                  >Ime artikla</v-col
                >
                <v-col class="d-flex justify-center pt-5 pr-5 pl-5"
                  >Količina artikla</v-col
                >
                <v-col cols="4" class="d-flex justify-center pt-5"
                  >Jedinična cijena</v-col
                >
              </v-row>
              <v-row
                v-for="(it, index) in item.order_items"
                :key="item.id + it.id + index"
                flat
                class="d-flex"
              >
                <v-col cols="4" class="d-flex justify-center pt-5 pl-5">{{
                  it.name
                }}</v-col>
                <v-col cols="4" class="d-flex justify-center pt-5 pr-5 pl-5">{{
                  it.quantity
                }}</v-col>
                <v-col cols="4" class="d-flex justify-center pt-5">{{
                  $options.filters.money(it.discountedUnitPrice, 100, currency)
                }}</v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- DIALOGS -->
    <add-discount-form ref="addDiscountForm" @add="onAddDiscount" />
    <add-note-dialog ref="addNoteDialog" />
    <add-note-article-dialog ref="addNoteArticleDialog" />
    <show-error ref="showError"></show-error>
  </div>
</template>

<script>
import { getHeight, clone } from '@/plugins/utils'
import EventBus from '@/plugins/event-bus'
import state from '@/state'
import Table from '@/model/Table'
import { df } from '@/plugins/firebase'
import onScreenKeyboard from '@/mixins/onScreenKeyboard'
import AddDiscountForm from '@/modules/cash-register/components/receipt-calculator-steps/AddDiscountForm'
import AddNoteDialog from '@/modules/cash-register/components/dialogs/AddNoteDialog'
import AddNoteArticleDialog from '@/modules/cash-register/components/dialogs/AddNoteArticleDialog'
import ShowError from '@/components/ShowError'

export default {
  components: {
    AddDiscountForm,
    AddNoteDialog,
    AddNoteArticleDialog,
    ShowError
  },
  inject: ['showMsgBox', 'hideLoader'],
  mixins: [onScreenKeyboard],
  data () {
    return {
      expanded: [],
      singleExpand: false,
      switchOnOrdersTable: false,
      cashReg: {},
      headers: [
        {
          text: 'Rb.',
          align: 'start',
          sortable: false,
          value: 'rbr'
        },
        { text: 'Naziv', value: 'name', sortable: false },
        { text: 'Količina', value: 'quantity', sortable: false },
        { text: 'Mj. jedinica', value: 'selling_unit', sortable: false },
        { text: 'Jed. cijena', value: 'unitPrice', sortable: false },
        { text: 'Jed. cijena s pop.', value: 'discountedUnitPrice', sortable: false },
        { text: 'Ukupno', value: 'total', sortable: false },
        { text: '', value: 'controls', sortable: false }
      ],
      orderHeaders: [
        {
          text: 'Rb.',
          align: 'start',
          value: 'order_number'
        },
        { text: 'Izmjenio', value: 'added_by_name' },
        { text: 'Status', value: 'order_status' },
        { text: '', value: 'data-table-expand' }

      ],
      itemMouseover: [],
      itemEdit: [],
      savedName: '',
      invoice: {},
      rows: [],
      selectedTable: {},
      ordersView: false,
      listeners: [],
      tableHeight: 0,
      barcode: [],
      currency: 'EUR',
      generalMenu: [
        { text: 'Dodaj popust', method: 'addDiscount', icon: 'mdi-percent' },
        { text: 'Ukloni artikle', method: 'removeProducts', icon: 'mdi-delete' }
      ],
      lastInsertBy: ''
    }
  },
  watch: {
    invoice: {
      immediate: false,
      deep: true,
      handler () {
        this.recalculateRows()
        if (!this.invoice.discount) {
          this.generalMenu = [
            { text: 'Dodaj popust', method: 'addDiscount', icon: 'mdi-percent' },
            { text: 'Ukloni artikle', method: 'removeProducts', icon: 'mdi-delete' },
            { text: 'Dodaj napomenu', method: 'addNoteDialogOpen', icon: 'mdi-delete' }
          ]
        } else {
          this.generalMenu = [
            { text: 'Ukloni popust', method: 'removeDiscount', icon: 'mdi-delete' },
            { text: 'Ukloni artikle', method: 'removeProducts', icon: 'mdi-delete' },
            { text: 'Dodaj napomenu', method: 'addNoteDialogOpen', icon: 'mdi-delete' }
          ]
        }
        EventBus.$emit('cart-has-changed', this.invoice)
      }
    }
  },
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    selectedPrinter: {
      // passed over from PanelRegisterWide
      type: Object,
      default: () => {}
    }
  },
  computed: {
    total () {
      return this.invoice.total
    }
  },
  methods: {
    onNameMouseover (item) {
      if (this.itemMouseover.length === 0) {
        this.itemMouseover.push(item)
      } else {
        this.itemMouseover = []
      }
    },

    onNameClick (item) {
      if (this.itemEdit.length === 0) {
        this.savedName = item.name
        this.itemMouseover = []
        this.itemEdit.push(item)
      }
    },

    showEdit (item) {
      return this.itemEdit.length > 0 && this.itemEdit[0].id === item.id
    },

    showPencil (item) {
      return this.itemMouseover.length > 0 && this.itemMouseover[0].id === item.id
    },

    finishEdit (item) {
      if (item.name.length === 0) {
        item.name = this.savedName
      }
      this.itemEdit = []
    },

    onClearTable () {
      this.selectedTable = new Table()
      this.invoice.items = []
    },

    onOrdersChange () {
      EventBus.$emit('set-orders-view', this.switchOnOrdersTable)
    },

    onSelectTable (table) {
      this.selectedTable = table
      this.invoice.items = [...this.selectedTable.items]
      this.recalculate()
    },

    removeProducts () {
      this.invoice.items = []
      this.invoice.total = 0
      this.invoice.fullTotal = 0
      this.invoice.totalWithDiscount = 0
      this.invoice.totalShoppingCart = 0
      this.invoice.discount = undefined
      this.$forceUpdate()
      EventBus.$emit('clear-table')
    },

    generalMenuClick (method) {
      switch (method) {
        case 'addDiscount':
          this.addDiscount(true)
          break
        case 'removeDiscount':
          this.removeDiscount(true)
          break
        case 'removeProducts':
          this.removeProducts()
          break
        case 'addNoteDialogOpen':
          this.addNoteDialogOpen()
          break
      }
    },

    itemMenuClick (method, item) {
      switch (method) {
        case 'addDiscount':
          this.addDiscount(false, item.id)
          break
        case 'removeDiscount':
          this.removeDiscount(false, item.id)
          break
        case 'removeItem':
          this.onRowBtnClick(item, 'remove')
          break
        case 'addNoteArticleDialogOpen':
          this.addNoteArticleDialogOpen(item)
          break
      }
    },

    validateBarcode (b) {
      var Barcoder = require('barcoder')
      return Barcoder.validate(b)
    },

    barcodeFind (e) {
      if (e.key !== 'Enter') { this.barcode.push(e.key) }
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        if (this.validateBarcode(this.barcode.join(''))) {
          df.collection(`location_pricelist/${state.getPointOfSale().id}/items`)
            .where('status', '==', 'OK')
            .where('active', '==', true)
            .where(`prices.${this.currency}.active`, '==', true)
            .where('barcodes', 'array-contains', this.barcode.join(''))
            .limit(1)
            .get()
            .then((documentSnapshots) => {
              if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
                documentSnapshots.docs.forEach((item) => {
                  let it = item.data()

                  it.stock = it.stock / this.multiplier

                  if (it.warehouse_units !== undefined && it.warehouse_units.units !== undefined) {
                    Object.keys(it.warehouse_units.units).forEach(key => {
                      if (it.warehouse_units.units[key].prices !== undefined) {
                        let newIt = {}
                        newIt = clone(it)
                        newIt.prices = it.warehouse_units.units[key].prices
                        // if (it.warehouse_units.units[key].storage_unit && it.warehouse_units.units[key].storage_unit !== '') {
                        //   newIt.name = it.warehouse_units.units[key].storage_unit
                        // } else {
                        //   newIt.name = it.name
                        // }

                        var stUnit = it.warehouse_units.units[key].storage_unit
                        if (stUnit === '.kom') {
                          stUnit = ''
                        }

                        newIt.name = it.name + ' ' + stUnit
                        newIt.id = newIt.id + '.' + it.warehouse_units.units[key].id
                        it = newIt
                      }
                    })
                  }

                  it.type = 'barcode-added'
                  this.addProduct(it)
                })
              }
            })
        } this.barcode = []
      }, 100)
    },

    handleKeyEvents (e) {
      e = e || window.event
      this.barcodeFind(e)
    },

    recalculateRows () {
      let rbr = this.invoice.items.length + 1
      this.invoice.items.forEach((item) => {
        rbr -= 1
        item.rbr = rbr
      })
    },

    onRowBtnClick (item, operation) {
      const idx = this.invoice.items.findIndex(p => p.id === item.id)
      switch (operation) {
        case 'increment':
          item.quantity = Number(item.quantity) + 1
          break
        case 'decrement':
          if (item.quantity === 1) {
            this.invoice.items.splice(idx, 1)
          } else if (item.quantity > 1) {
            item.quantity = Number(item.quantity) - 1
          }
          break
        case 'discount':
          if (item.discountedUnitPrice === undefined || item.discountedUnitPrice === 0) {
            this.addDiscount(false, item.id)
          } else {
            this.removeDiscount(false, item.id)
          }
          break
        case 'remove':
          this.invoice.items.splice(idx, 1)
          break
        default:
          break
      }
      if (this.invoice.items.length === 0) {
        this.invoice.total = 0
        this.invoice.fullTotal = 0
        this.invoice.totalWithDiscount = 0
        this.invoice.totalShoppingCart = 0
        this.invoice.discount = undefined
        this.$forceUpdate()
        EventBus.$emit('clear-table')
      }
      this.recalculate()
    },

    async addProduct (item) { // Note to myself: refactor!
      if (item.type === 'menu-product') {
        const product = await df.doc(`location_menu_pricelist/${state.getPointOfSale().id}/items/${item.id}`).get()
        const invoiceItem = product.data()

        this.$refs.menuDialog.open(invoiceItem, this.currency)
        return
      } else if (item.type === 'barcode-added') {
      // Added via barcode reader - already got full item
        const id = item.id
        let unitId = ''

        if (id.includes('.')) {
          unitId = id.split('.')[1]
        }

        let invoiceItem = item
        invoiceItem.total = 0

        const existingInvoiceItem = this.invoice.items.find(p => {
          return p.id === item.id
        }
        )

        if (invoiceItem.warehouse_units !== undefined && invoiceItem.warehouse_units.units !== undefined) {
          Object.keys(invoiceItem.warehouse_units.units).forEach(key => {
            if (key === unitId && invoiceItem.warehouse_units.units[key].prices !== undefined) {
              let newIt = {}
              newIt = clone(invoiceItem)
              newIt.prices = invoiceItem.warehouse_units.units[key].prices
              newIt.name = invoiceItem.name
              invoiceItem = newIt
            }
          })
        }

        if (existingInvoiceItem) {
          const quantity = existingInvoiceItem.quantity + 1
          existingInvoiceItem.quantity = quantity
          existingInvoiceItem.unitPrice = existingInvoiceItem.prices[this.currency].price
          existingInvoiceItem.discountedUnitPrice = existingInvoiceItem.unitPrice
          existingInvoiceItem.total = existingInvoiceItem.unitPrice * quantity
        } else {
          invoiceItem.quantity = 1
          invoiceItem.unitPrice = invoiceItem.prices[this.currency].price
          invoiceItem.discountedUnitPrice = invoiceItem.unitPrice
          invoiceItem.total = invoiceItem.unitPrice
          this.invoice.items.unshift(invoiceItem)
        }
      } else {
        if (item.id.includes('.')) {
          const ids = item.id.split('.')
          item.id = ids[0]
          item.unitId = ids[1]
        }
        const product = await df.doc(`location_pricelist/${state.getPointOfSale().id}/items/${item.id}`).get()
        if (item.unitId && item.unitId !== '') { item.id = item.id + '.' + item.unitId }
        let invoiceItem = product.data()

        if (item.unitId && item.unitId !== '') {
          if (invoiceItem.warehouse_units && invoiceItem.warehouse_units.units && invoiceItem.warehouse_units.units[item.unitId].prices) {
            let newProduct = {}
            newProduct = clone(invoiceItem)
            newProduct.prices = invoiceItem.warehouse_units.units[item.unitId].prices

            var stUnit = invoiceItem.warehouse_units.units[item.unitId].storage_unit
            if (stUnit === '.kom') {
              stUnit = ''
            }
            if (invoiceItem.warehouse_units.units[item.unitId].base_unit === true) {
              stUnit = invoiceItem.selling_unit
            }
            newProduct.name = invoiceItem.name + ' ' + stUnit
            newProduct.id = newProduct.id + '.' + item.unitId
            invoiceItem = newProduct
          }
        }

        invoiceItem.menu = [
          { text: 'Dodaj popust', method: 'addDiscount', icon: 'mdi-percent' },
          { text: 'Ukloni artikl', method: 'removeItem', icon: 'mdi-delete' },
          { text: 'Dodaj napomenu', method: 'addNoteArticleDialogOpen' }
        ]
        invoiceItem.total = 0

        const existingInvoiceItem = this.invoice.items.find(p =>
          p.id === invoiceItem.id
        )
        if (existingInvoiceItem) {
          const quantity = existingInvoiceItem.quantity + 1
          existingInvoiceItem.quantity = quantity
          existingInvoiceItem.unitPrice = existingInvoiceItem.prices[this.currency].price
          existingInvoiceItem.discountedUnitPrice = existingInvoiceItem.unitPrice
          existingInvoiceItem.total = existingInvoiceItem.unitPrice * quantity
        } else {
          invoiceItem.quantity = 1
          invoiceItem.unitPrice = invoiceItem.prices[this.currency].price
          invoiceItem.discountedUnitPrice = invoiceItem.unitPrice
          invoiceItem.total = invoiceItem.unitPrice
          this.invoice.items.unshift(invoiceItem)
          const rbr = this.invoice.items.findIndex(p => p.id === item.id) + 1

          if (this.invoice.items.find(p => p.id === item.id)) {
            this.invoice.items.find(p => p.id === item.id).rbr = rbr
          }
        }
      }

      this.recalculate()
    },

    transactionEndedWithReceipt () {
      if (this.cashReg.after_receipt_behaviour !== undefined && this.cashReg.after_receipt_behaviour !== null && this.cashReg.after_receipt_behaviour !== '') {
        if (this.cashReg.after_receipt_behaviour === 'SHOW_CASH_REGS') {
          if (this.cashReg.login_type === 'SELECT_USER' || this.cashReg.login_type === 'PINCODE') {
            state.setCashRegisterUser({ id: undefined })
            df.doc(`cash_registers/${state.getCashRegister().id}`).update({ reserved_by: '' })
          }
          this.$router.push({
            name: 'pointOfSale.cashRegisters',
            params: {
              companyId: state.getCurrentCompany().id,
              locationId: state.getPointOfSale().id
            }
          })
        } else if (this.cashReg.after_receipt_behaviour === 'SHOW_ALL_RECEIPTS') {
          if (this.cashReg.login_type === 'SELECT_USER' || this.cashReg.login_type === 'PINCODE') {
            state.setCashRegisterUser({ id: undefined })
            df.doc(`cash_registers/${state.getCashRegister().id}`).update({ reserved_by: '' })
          }

          // Potreban timeout jer kasa izbaci kad odjavim korisnika pa ostane na blagajni
          setTimeout(() => {
            this.$router.push({
              name: 'pointOfSale.receipts',
              params: {
                companyId: state.getCurrentCompany().id,
                locationId: state.getPointOfSale().id
              }
            })
          }, 500)
        }
      }
    },

    addDiscount (mainBasket = false, itemID) {
      this.$refs.addDiscountForm.open(mainBasket, itemID, this.currency)
    },

    addNoteDialogOpen () {
      this.$refs.addNoteDialog.open(this.selectedTable)
    },

    addNoteArticleDialogOpen (item) {
      this.$refs.addNoteArticleDialog.open(item, this.invoice, this.selectedTable)
    },

    onSendNotes (_, order) {
      this.$refs.addNoteDialog.sendNotes(order)
    },

    onResetNotes () {
      this.$refs.addNoteDialog.resetNotes()
    },

    onAddDiscount (discount) {
      if (discount !== undefined) {
        if (discount.basketDiscount === true) {
          switch (discount.type) {
            case 'RATE': {
              const temp = ((this.invoice.total) * (discount.rate / 10000))
              this.invoice.discount = discount
              this.invoice.discount.amount = temp
              break
            }
            case 'AMOUNT':
              this.invoice.discount = discount
              this.invoice.discount.rate = (discount.amount / this.invoice.total) * 100
              break
          }
        } else {
          this.invoice.items.forEach((item, key) => {
            if (item.id === discount.itemID) {
              this.invoice.items[key].discount = discount

              switch (discount.type) {
                case 'RATE': {
                  const temp = Math.round((this.invoice.items[key].prices[this.currency].price) - ((this.invoice.items[key].prices[this.currency].price * (discount.rate / 10000))))
                  this.invoice.items[key].discounted_price = temp
                  this.invoice.items[key].discount.amount = this.invoice.items[key].prices[this.currency].price - this.invoice.items[key].discounted_price
                  this.invoice.items[key].discountedUnitPrice = this.invoice.items[key].discounted_price

                  if (this.invoice.items[key].discounted_price <= 0) {
                    this.invoice.items[key].discount = undefined
                    this.invoice.items[key].discounted_price = 0

                    this.showMsgBox({ text: 'Popust je veći od cijene artikla, te se ne može primjeniti.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
                  }
                  break
                }
                case 'AMOUNT':
                  this.invoice.items[key].discounted_price = this.invoice.items[key].prices[this.currency].price - discount.amount
                  this.invoice.items[key].discount.rate = ((discount.amount / this.invoice.items[key].prices[this.currency].price) * 100) * 100
                  this.invoice.items[key].discountedUnitPrice = this.invoice.items[key].discounted_price
                  if (this.invoice.items[key].discounted_price <= 0) {
                    this.invoice.items[key].discount = undefined
                    this.invoice.items[key].discounted_price = 0
                    this.invoice.items[key].discountedUnitPrice = this.invoice.items[key].discounted_price
                    this.showMsgBox({ text: 'Popust je veći od cijene artikla, te se ne može primjeniti.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
                  }
                  break
              }

              this.invoice.items[key].menu = [
                { text: 'Ukloni popust', method: 'removeDiscount', icon: 'mdi-delete' },
                { text: 'Ukloni artikl', method: 'removeItem', icon: 'mdi-delete' }
              ]
            }
          })
        }
        this.recalculate()
      }
    },

    recalculate () {
      if (this.invoice.items.length > 0) {
        this.invoice.total = 0
        this.invoice.fullTotal = 0
        this.invoice.totalWithDiscount = 0
        this.invoice.totalShoppingCart = 0
        // let dodatna = 0

        this.invoice.items.forEach((item, key) => {
          const quantityString = String(this.invoice.items[key].quantity)
          let quantity = parseFloat(quantityString.replace(',', '.'))
          if (!quantity) {
            this.invoice.items[key].quantity = 1
            quantity = 1
          }
          this.invoice.items[key].total = this.invoice.items[key].unitPrice * quantity
          this.invoice.items[key].fullTotal = this.invoice.items[key].total

          if (this.invoice.items[key].discountedUnitPrice !== null && this.invoice.items[key].discountedUnitPrice !== undefined && this.invoice.items[key].discountedUnitPrice > 0) {
            this.invoice.items[key].fullTotal = this.invoice.items[key].total

            this.invoice.items[key].total = this.invoice.items[key].discountedUnitPrice * quantity
          }
          this.invoice.totalWithDiscount += this.invoice.items[key].total
          this.invoice.total += this.invoice.items[key].total

          this.invoice.fullTotal += this.invoice.items[key].fullTotal
          this.invoice.totalShoppingCart += this.invoice.items[key].total
        })

        if (this.invoice.discount !== undefined) {
          if (this.invoice.discount.type === 'RATE') {
            var temp = Math.round(((this.invoice.totalShoppingCart) * (this.invoice.discount.rate / 10000)))

            this.invoice.discount.amount = temp
            this.invoice.total = this.invoice.total - temp
            this.invoice.totalWithDiscount = this.invoice.total
            // dodatna = this.invoice.totalShoppingCart - temp
          } else {
            if (this.invoice.discount.amount > this.invoice.totalShoppingCart) {
              this.invoice.discount.rate = 1000
              this.invoice.discount.amount = this.invoice.totalShoppingCart
            } else {
              const temp = (this.invoice.totalShoppingCart) / (this.invoice.discount.amount)
              this.invoice.discount.rate = Math.round(temp * 100)
              this.invoice.total = this.invoice.totalShoppingCart - this.invoice.discount.amount
              this.invoice.totalWithDiscount = this.invoice.totalShoppingCart - this.invoice.discount.amount
              // dodatna = this.invoice.totalShoppingCart - this.invoice.discount.amount
            }
          }
        }

        // roundanje na 2 decimale
        if (this.invoice.total <= 125) {
          var skini = 0
          if (this.invoice.discount !== undefined) {
            skini = this.invoice.discount.amount
            this.$refs.showError.open('Uklonjen je popust na košaricu jer prelazi iznos ukupnog računa, ukoliko želite možete dodati popust na košaricu.')
          }
          this.invoice.discount = undefined
          this.invoice.totalWithDiscount = 0
          this.invoice.total += skini
        }

        // if (this.invoice.total <= 125) {
        //   if (this.invoice.discount !== undefined) {
        //     this.showMsgBox({ text: 'Uklonjen je popust na košaricu jer prelazi iznos ukupnog računa, ukoliko želite možete dodati popust na košaricu.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        //   }
        //   this.invoice.discount = undefined
        //   this.invoice.totalWithDiscount = 0
        //   if (dodatna > 0) {
        //     this.invoice.totalWithDiscount = dodatna
        //   }
        // }

        this.$forceUpdate()
      }
      if (!this.invoice.items || this.invoice.items.length === 0) {
        this.invoice.total = 0
        this.invoice.fullTotal = 0
        this.invoice.totalWithDiscount = 0
        this.invoice.totalShoppingCart = 0
      }
    },

    removeDiscount (basketDiscount = false, itemID) {
      if (basketDiscount) {
        this.invoice.totalWithDiscount = 0
        this.invoice.discount = undefined
      } else {
        this.invoice.items.forEach((item, key) => {
          if (item.id === itemID) {
            this.invoice.items[key].discounted_price = this.invoice.items[key].unitPrice
            this.invoice.items[key].discountedUnitPrice = this.invoice.items[key].unitPrice

            this.invoice.items[key].menu = [
              { text: 'Dodaj popust', method: 'addDiscount', icon: 'mdi-percent' },
              { text: 'Ukloni artikl', method: 'removeItem', icon: 'mdi-delete' }
            ]
          }
        })
      }
      this.recalculate()
    },

    onClearInvoice (table) {
      this.invoice.items = [...table.items]
    },

    clearSelection () {
      this.invoice.items = []
      this.selectedTable = new Table()
      this.recalculate()
    },

    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    }
  },

  created () {
    this.selectedTable = new Table()

    this.invoice = {
      currency: this.currency,
      fullTotal: 0,
      items: [],
      paymentConfig: {},
      registerType: 'MP',
      title: '',
      total: 0,
      totalShoppingCart: 0,
      totalWithDiscount: 0,
      type: 'R'
    }

    this.cashReg = state.getCashRegister()
  },
  async mounted () {
    EventBus.$on('showError', (data) => {
      this.$refs.showError.open(data.message)
    })

    EventBus.$on('clear-reload-tables', () => {
      this.onClearTable()
    })

    EventBus.$on('add-product-to-invoice', (item) => {
      this.addProduct(item)
    })

    EventBus.$on('select-table', (table) => {
      this.onSelectTable(table)
    })

    EventBus.$on('clear-receipt-data', () => {
      EventBus.$emit('save-receipt-note', null)
      EventBus.$emit('clear-table')
      this.hideLoader()
      this.invoice.items = []
      this.selectedTable = new Table()
      this.transactionEndedWithReceipt()
    })

    EventBus.$on('set-orders-view', (ordersView) => {
      this.ordersView = ordersView
    })

    EventBus.$on('clear-selection', () => {
      this.clearSelection()
    })

    EventBus.$on('send-notes', (_, order) => {
      this.onSendNotes(null, order)
    })

    EventBus.$on('clear-invoice', (table) => {
      this.onClearInvoice(table)
    })

    EventBus.$on('reset-notes', () => { this.onResetNotes() })

    EventBus.$on('recalculate', () => {
      this.recalculate()
    })

    if (getHeight() > 900) {
      this.tableHeight = 540
    } else {
      this.tableHeight = 350
    }

    document.onkeydown = this.handleKeyEvents
  },

  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  }
}
</script>

<style scoped>
.invoice-row {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
}
</style>
