import { printClosingBalance } from '@/libs/bixolon/printClosingBalance'
import { clone } from '@/plugins/utils'

export const closingBalance = async (closeBalance, printer) => {
  var receipts = closeBalance.receipts
  var items = []
  var total = 0
  var cash = 0
  var card = 0
  var trans = 0
  var ostalo = 0
  var cashAmt = 0
  var cardAmt = 0
  var transAmt = 0
  var ostaloAmt = 0
  var storno = []
  var stornoTotal = 0
  var totalPoArtiklima = 0

  receipts.sort((a, b) => a.issue_date_time - b.issue_date_time) // b - a for reverse sort

  if (receipts && receipts.length > 0) {
    receipts.forEach(receipt => {
      if (receipt.payment.current_status === 'REVERSED') {
        stornoTotal += receipt.total
        storno.push(receipt)
      }
      if (receipt.items && receipt.items.length > 0 && receipt.type.flag !== 'STORNO' && receipt.payment.current_status !== 'REVERSED') {
        receipt.items.forEach(item => {
          var amt = clone(item.amt)
          totalPoArtiklima += item.price
          const idx = items.findIndex(it => it?.id === item.id)
          if (idx > -1) {
            items[idx].amt += amt
            items[idx].price += item.price
          } else {
            items.push(item)
          }
        })
        total += receipt.total
      }

      if (receipt.payment_method === 'NOVČANICE' && receipt.type.flag !== 'STORNO' && receipt.payment.current_status !== 'REVERSED') {
        cash += receipt.total
        cashAmt++
      }

      if (receipt.payment_method === 'KARTICA' && receipt.type.flag !== 'STORNO' && receipt.payment.current_status !== 'REVERSED') {
        card += receipt.total
        cardAmt++
      }

      if (receipt.payment_method === 'TRANSAKCIJSKI RAČUN' && receipt.type.flag !== 'STORNO' && receipt.payment.current_status !== 'REVERSED') {
        trans += receipt.total
        transAmt++
      }

      if (receipt.payment_method === 'OSTALO' && receipt.type.flag !== 'STORNO' && receipt.payment.current_status !== 'REVERSED') {
        ostalo += receipt.total
        ostaloAmt++
      }
    })

    closeBalance.total = total
    closeBalance.cash = cash
    closeBalance.card = card
    closeBalance.trans = trans
    closeBalance.ostalo = ostalo
    closeBalance.cashAmt = cashAmt
    closeBalance.cardAmt = cardAmt
    closeBalance.transAmt = transAmt
    closeBalance.ostaloAmt = ostaloAmt
    closeBalance.totalPoArtiklima = totalPoArtiklima

    closeBalance.startReceipt = undefined
    closeBalance.deposit = closeBalance.deposit?.amt > 0 ? closeBalance.deposit.amt : 0

    if (receipts[0]) {
      var firstReceipt = receipts[0]
      var startReceipt = ''

      var separator = '-'
      if (firstReceipt.company_details && firstReceipt.company_details.rcpt_separator !== '') {
        separator = firstReceipt.company_details.rcpt_separator
      } else if (closeBalance.company_details.rcpt_separator !== '') {
        separator = closeBalance.company_details.rcpt_separator
      }

      startReceipt = firstReceipt.designation.number + separator + firstReceipt.designation.location_id + separator + firstReceipt.designation.register_number

      closeBalance.startReceipt = startReceipt
    }
    closeBalance.startReceiptTime = receipts[0] ? receipts[0].issue_date_time : undefined

    closeBalance.endReceipt = undefined
    if (receipts[receipts.length - 1]) {
      var lastReceipt = receipts[receipts.length - 1]
      var endReceipt = ''

      endReceipt = lastReceipt.designation.number + separator + lastReceipt.designation.location_id + separator + lastReceipt.designation.register_number

      closeBalance.endReceipt = endReceipt
    }
    closeBalance.endReceiptTime = receipts[receipts.length - 1] ? receipts[receipts.length - 1].issue_date_time : undefined
    closeBalance.storno = storno
    closeBalance.stornoTotal = stornoTotal
  }

  printClosingBalance(closeBalance, items, printer)
}
