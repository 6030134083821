<template>
  <v-dialog v-model="visible" v-if="receipt" persistent scrollable width="650">
    <v-form ref="sendEmail" @submit.prevent="submit" style="overflow-x: hidden">
      <v-card
        class="py-3"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pl-3 mb-0">
            <h2 class="text--secondary">{{ title }}</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text class="pt-0 pb-0 mb-0 mt-0">
          <v-row class="pt-0 pb-0 mb-0 mt-0">
            <v-col class="pt-0 pb-0 mb-0 mt-0">
              <v-expansion-panels flat>
                <v-expansion-panel class="pa-0 ma-0">
                  <v-expansion-panel-header
                    style="width: 100% !important"
                    class="pa-0 mb-2 mt-0"
                    @keyup.space.prevent=""
                  >
                    <v-combobox
                      label="Primatelji"
                      v-model="emails"
                      multiple
                      :delimiters="[',', ' ']"
                      :items="[]"
                      append-icon=""
                      :rules="[rules.arrReq]"
                      @change="checkEmailAdresses"
                      @click.native.stop=""
                      :loading="loading"
                      deletable-chips
                      small-chips
                    >
                    </v-combobox>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content id="expansion-panel-content-1">
                    <v-row>
                      <v-col class="pt-0 pb-0 mb-0 mt-0">
                        <v-combobox
                          label="Odgovori na"
                          v-model="replyTo"
                          :items="[]"
                          append-icon=""
                          @change="checkEmailAdresses"
                          :loading="loading"
                          deletable-chips
                          small-chips
                        >
                        </v-combobox>
                      </v-col>
                    </v-row>
                    <v-row class="pt-0 pb-0 mb-0 mt-0">
                      <v-col class="pt-0 pb-0 mb-0 mt-0">
                        <v-combobox
                          label="Cc"
                          v-model="cc"
                          multiple
                          :delimiters="[',', ' ']"
                          :items="[]"
                          append-icon=""
                          @change="checkEmailAdresses"
                          :loading="loading"
                          deletable-chips
                          small-chips
                        >
                        </v-combobox>
                      </v-col>
                    </v-row>
                    <v-row class="pt-0 pb-0 mb-0 mt-0">
                      <v-col class="pt-0 pb-0 mb-0 mt-0">
                        <v-combobox
                          label="Bcc"
                          v-model="bcc"
                          multiple
                          :delimiters="[',', ' ']"
                          :items="[]"
                          append-icon=""
                          @change="checkEmailAdresses"
                          :loading="loading"
                          deletable-chips
                          small-chips
                        >
                        </v-combobox>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <!-- <v-row class="pt-0 pb-0 mb-0 mt-0"> </v-row> -->
          <v-row class="pt-0 pb-0 mb-0 mt-0">
            <v-col class="pt-0 pb-0 mb-0 mt-0">
              <v-text-field label="Naslov" v-model="emailTitle"> </v-text-field>
            </v-col>
          </v-row>
          <v-row class="pt-0 pb-0 mb-0 mt-0">
            <v-col class="pt-0 pb-0 mb-0 mt-0">
              <v-textarea
                label="Poruka"
                v-model="emailText"
                ref="textarea"
                auto-grow
                row-height="20"
                rows="7"
                style="white-space: pre-line"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-0 pr-5">
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" @click="close">
            {{ $t("$vuetify.close") }}
          </v-btn>
          <v-btn
            class="white--text okButton"
            :loading="submitting"
            :disabled="submitting"
            @click="submit"
          >
            {{ okBtn }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-overlay v-if="loading" absolute z-index="10">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>
<script>
// import { v4 as uuidv4 } from 'uuid'
import { df, functions } from '@/plugins/firebase'
import state from '@/state'
import { clone } from '@/plugins/utils'
import rules from '@/plugins/rules'

export default {
  inject: ['showMsgBox'],
  name: 'SendEmail',
  data: function () {
    return {
      visible: false,
      loading: false,
      submitting: false,
      action: undefined,
      receipt: undefined,
      title: undefined,
      okBtn: undefined,
      emailText: '',
      emailTitle: '',
      emails: [],
      cc: [],
      bcc: [],
      rules: {
        arrReq: rules.arrReq(),
        email: rules.email()
      },
      associates: [],
      replyTo: '',
      documentType: 'racun',
      documentName: 'Račun'
    }
  },
  methods: {
    async open (receipt, params) {
      if (!params?.action) {
        this.showMsgBox({
          text: 'Nepoznata akcija',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
        return
      }
      if (!params?.title) {
        this.showMsgBox({
          text: 'Title nije poslan',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
        return
      }
      if (!params?.okBtn) {
        this.showMsgBox({
          text: 'okBtn nije poslan',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
        return
      }
      this.getReplyTo()
      this.action = params.action
      this.title = params.title
      this.okBtn = params.okBtn
      this.receipt = clone(receipt)
      switch (this.action) {
        case 'EMAIL_SENT':
          this.documentType = 'racun'
          this.documentName = `Račun ${receipt.receiptNumberForEmail}`
          this.emailTitle = `Račun ${receipt.receiptNumberForEmail}`
          this.emailText = `Poštovani/poštovana,

u privitku Vam šaljemo račun ${
            receipt.receiptNumberForEmail
          } za isporučene usluge u iznosu od ${this.$options.filters.money(
            receipt.total | 0,
            100,
            receipt.currency
          )}.

Srdačan pozdrav,
${state.getCurrentCompany().name}`
          break
        case 'WARNING_SENT':
          this.emailTitle = `Opomena po racunu ${receipt.receiptNumberForEmail}`
          this.emailText = `Poštovani/poštovana,

Vaša uplata za isporučene usluge po računu ${
            receipt.receiptNumberForEmail
          } (u iznosu od ${this.$options.filters.money(
            receipt.total | 0,
            100,
            receipt.currency
          )}) još nije evidentirana.

U privitak ove poruke je stavljena kopija računa te Vas molimo da isti podmirite u najkraćem roku.

Ukoliko ste u međuvremenu izvršili uplatu zanemarite ovu poruku.

Srdačan pozdrav,
${state.getCurrentCompany().name}`
          break
        case 'OFFER_SENT':
          this.documentType = 'ponuda'
          this.documentName = `Ponuda ${receipt.receiptNumberForEmail}`
          this.emailTitle = `Ponuda ${receipt.receiptNumberForEmail}`
          this.emailText = `Poštovani/poštovana,

u privitku Vam šaljemo ponudu ${
            receipt.receiptNumberForEmail
          } u iznosu od ${this.$options.filters.money(
            receipt.total | 0,
            100,
            receipt.currency
          )}.

Srdačan pozdrav,
${state.getCurrentCompany().name}`
          this.action = 'EMAIL_SENT'
          break
      }
      this.visible = true
      if (!this.receipt?.type?.payer_oib) return

      this.loading = true
      const associatesDocument = await df
        .doc(`company_associates/${state.getCurrentCompany().id}`)
        .get()
      if (!associatesDocument?.exists) {
        this.loading = false
        return
      }
      const associates = associatesDocument.data()?.associates || {}
      const associateEmail = Object.keys(associates)
        .filter((key) =>
          associates[key]?.oibs?.includes(this.receipt.type.payer_oib)
        )
        .map((key) => { if (associates[key].email && associates[key].email !== '') return associates[key].email })
      if (associateEmail && associateEmail !== '') {
        if (associateEmail.length > 0) {
          associateEmail.forEach(email => {
            if (email && email !== '') {
              this.emails.push(email.replace(',', '').trim())
            }
          })
        }
      }
      this.loading = false
    },
    close () {
      this.emails = []
      this.visible = false
    },
    checkEmailAdresses () {
      this.emails = this.emails.filter((email) => rules.isEmail()(email))
    },
    async getReplyTo () {
      const query = df.collection(`company_communications/${state.getCurrentCompany().id}/communications`)
      await query.get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((item) => {
              var comm = item.data()

              if (comm.usage && comm.usage.length > 0) {
                comm.usage.forEach(usage => {
                  if (usage === 'reply_to') {
                    this.replyTo = comm.value
                  }
                })
              }
            })
          }
        })
    },
    submit () {
      this.emails = this.emails.filter((email) => rules.isEmail()(email))
      if (this.emails.length === 0) {
        return
      }
      this.loading = true
      var sendDocument = functions.httpsCallable('senddocument')

      var printTemplate = ''
      if (state.getCurrentCompany().print_template && state.getCurrentCompany().print_template !== '') {
        printTemplate = state.getCurrentCompany().print_template
      }

      sendDocument({
        to: this.emails,
        cc: this.cc,
        bcc: this.bcc,
        subject: state.getCurrentCompany().name + ' - ' + this.emailTitle,
        reply_to: this.replyTo,
        body: this.emailText.replace(/\n|\r\n|\r/g, '<br/>'),
        company_id: state.getCurrentCompany().id,
        document_id: this.receipt.id,
        template: printTemplate,
        document_type: this.documentType,
        document_name: this.documentName,
        document_action: this.action,
        hub3: true
      }).then((result) => {
        this.loading = false

        if (result.data.code === 200) {
          let subject = 'Email'
          if (this.action === 'WARNING_SENT') subject = 'Opomena'
          this.showMsgBox({
            text: `${subject} je poslan${subject === 'Opomena' ? 'a' : ''}.`,
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'info'
          })
          this.close()
        } else {
          this.showMsgBox({
            text: 'Slanje maila nije uspjelo.',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
          this.close()
        }
      })
    }
    // submit () {
    //   if (!this.$refs.sendEmail.validate()) return

    //   let entity = 'receipt'
    //   if (this.receipt.type.flag === 'P') {
    //     entity = 'offer'
    //   }
    //   const payload = {
    //     action: {
    //       operation: 'get',
    //       entity: entity,
    //       resource_id: this.receipt.id,
    //       params: {
    //         action: this.action,
    //         emails: this.emails,
    //         subject: state.getCurrentCompany().name + ' - ' + this.emailTitle,
    //         template: 'receipt_email',
    //         body: this.emailText.replace(/\n|\r\n|\r/g, '<br/>'),
    //         company_id: state.getCurrentCompany().id,
    //         print_size: 'A4'
    //       }
    //     }
    //   }

    //   const reqId = uuidv4()
    //   this.submitting = true
    //   df.doc(`request/${reqId}`)
    //     .set({
    //       user_id: `${auth.currentUser.uid}`,
    //       device_id: 'web',
    //       created: `${new Date().getTime()}`,
    //       type: 'document',
    //       payload: btoa(unescape(encodeURIComponent(JSON.stringify(payload))))
    //     })
    //     .then(() => {
    //       this.$emit('receiptEdit', this.receipt)
    //       let subject = 'Email'
    //       if (this.action === 'WARNING_SENT') subject = 'Opomena'
    //       this.showMsgBox({
    //         text: `${subject} je poslan${subject === 'Opomena' ? 'a' : ''}.`,
    //         actions: ['cancel'],
    //         cancelBtnText: this.$t('$vuetify.close'),
    //         color: 'info'
    //       })
    //       this.close()
    //     })
    //     .catch((err) => {
    //       this.showMsgBox({
    //         text: err && err !== '' ? err : 'An error has occurred',
    //         actions: ['cancel'],
    //         cancelBtnText: this.$t('$vuetify.close'),
    //         color: 'error'
    //       })
    //     })
    //     .finally(() => {
    //       this.submitting = false
    //     })
    // }
  }
}
</script>
<style scoped>
#expansion-panel-content-1::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
