<template>
  <v-card class="justify-center overflow-auto" height="595px">
    <v-card-title class="h6 mx-0 pb-0 pt-2">
      <b>{{ data.title }}</b>
    </v-card-title>
    <v-form ref="paymentForm" @submit.prevent="advanceStep">
      <v-card-actions class="mx-5">
        <v-row style="height: 25px" class="d-flex justify-end align-end">
          <v-btn
            outlined
            color="primary"
            @click="backwardStep"
            class="mr-2 mb-3"
          >
            {{ $t("$vuetify.backward") }}
          </v-btn>
          <v-btn class="white--text loginButton mb-3" type="submit">
            {{ $t("$vuetify.finishPayment") }}
          </v-btn>
        </v-row>
      </v-card-actions>
      <v-card-text class="pb-0 px-0">
        <v-container class="fill-height">
          <v-row v-if="data.type === 'R' && data.registerType != 'VP'">
            <v-col class="pb-0 d-flex align-center" cols="6" xl="7">
              <div class="text-subtitle">
                {{ $t("$vuetify.total") }}:
                {{
                  data.totalWithDiscount > 0
                    ? data.totalWithDiscount
                    : data.total | money(100, data.currency)
                }}
              </div>
            </v-col>
            <v-col class="pb-0" cols="6" xl="5">
              <v-text-field
                reverse
                autofocus
                autocomplete="off"
                :label="$t('$vuetify.amountReceivedText')"
                v-model="received"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0 d-flex justify-end" cols="12">
              <p class="text-subtitle">
                {{ $t("$vuetify.change") }}:
                {{
                  (data.totalWithDiscount > 0
                    ? data.totalWithDiscount
                    : data.total) -
                    formatNumber(received, 100) >
                  0
                    ? 0
                    : Math.round(
                        Math.abs(
                          (data.totalWithDiscount > 0
                            ? data.totalWithDiscount
                            : data.total) - formatNumber(received, 100)
                        )
                      ) | money(100, data.currency)
                }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" cols="6">
              <v-select
                :items="paymentMethods"
                return-object
                item-text="name"
                :label="$t('$vuetify.paymentMethodText')"
                :rules="[rules.req]"
                v-model="paymentMethod"
                @change="onChangePaymentMethod"
              ></v-select>
            </v-col>
            <v-col class="py-0" cols="6">
              <v-switch
                :label="$t('$vuetify.fiscalizeText')"
                v-model="fiscalize"
                :disabled="fiscalizeDisabled"
              ></v-switch>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select
                :items="receiptTypes"
                return-object
                item-text="name"
                :label="$t('$vuetify.receiptTypeText')"
                v-model="receiptType"
                :rules="[rules.req]"
                @change="onChangeReceiptType"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-text-field
                tabindex="-1"
                v-model="receiptNumber"
                :label="
                  receiptType && receiptType.value === 'P'
                    ? $t('$vuetify.offerNumber')
                    : $t('$vuetify.receiptNumber')
                "
                @keypress="checkInput($event)"
                autocomplete="off"
                :rules="[rules.req, rules.gt(storeReceiptNumber)]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-end mt-1 pr-5">
            <v-btn plain text tabindex="-1" icon @click="more = !more">
              {{ $t("$vuetify.more") }}
              <v-icon>{{
                more ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-row>
        </v-container>
        <v-spacer></v-spacer>
      </v-card-text>
      <v-spacer></v-spacer>
      <v-expand-transition>
        <v-container v-show="more" fluid>
          <v-card-text class="pb-0 px-0">
            <v-row class="pr-3 pl-3">
              <v-select
                v-model="language"
                :items="languages"
                :label="$t('$vuetify.language')"
                item-text="name"
                return-object
              ></v-select>
            </v-row>
            <v-row class="pr-3 pl-3">
              <v-text-field
                :label="$t('$vuetify.contractNumText')"
                v-model="contractNum"
              >
              </v-text-field>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-if="paymentMethod && paymentMethod.value === 'T'"
                  :label="$t('$vuetify.issueYear')"
                  @keypress="checkInput($event)"
                  v-model="issueYear"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu
                  offset-y
                  v-model="dateMenu"
                  v-if="
                    paymentMethod &&
                    paymentMethod.value === 'T' &&
                    receiptType &&
                    receiptType.value !== 'P'
                  "
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="issueDate"
                      :label="$t('$vuetify.issueDate')"
                      dense
                      prepend-icon="mdi-clock-time-four-outline"
                      @click:prepend="dateMenu = !dateMenu"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="issueDate"
                    :min="minIssueDate"
                    @change="setVpRacTimestamp"
                    locale="hr-HR"
                    no-title
                    scrollable
                    @input="dateMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu
                  offset-y
                  v-model="deliveryDateMenu"
                  v-if="
                    paymentMethod &&
                    paymentMethod.value === 'T' &&
                    receiptType &&
                    receiptType.value !== 'P'
                  "
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="deliveryDate"
                      :label="$t('$vuetify.deliveryDate')"
                      dense
                      prepend-icon="mdi-clock-time-four-outline"
                      @click:prepend="deliveryDateMenu = !deliveryDateMenu"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="deliveryDate"
                    locale="hr-HR"
                    no-title
                    scrollable
                    @input="deliveryDateMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu
                  offset-y
                  v-model="paymentDueDateMenu"
                  v-if="paymentMethod && paymentMethod.value === 'T'"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="paymentDueDate"
                      :label="
                        receiptType.value === 'P'
                          ? $t('$vuetify.offerDueDate')
                          : $t('$vuetify.paymentDueDate')
                      "
                      dense
                      prepend-icon="mdi-clock-time-four-outline"
                      @click:prepend="paymentDueDateMenu = !paymentDueDateMenu"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="paymentDueDate"
                    :min="minPaymentDueDate"
                    locale="hr-HR"
                    no-title
                    scrollable
                    @input="paymentDueDateMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  rows="3"
                  row-height="10"
                  :label="$t('$vuetify.note')"
                  v-model="note"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
      </v-expand-transition>
    </v-form>
  </v-card>
</template>
<script>
import rules from '@/plugins/rules'
import state from '@/state'
import { v4 as uuidv4 } from 'uuid'
import { auth, df, functions } from '@/plugins/firebase'
import { SEC, DAY, toISOLocal, clone } from '@/plugins/utils'
// import { printerInit } from '@/libs/bixolon/printer'
// import { initPrintManager } from '@/libs/jsPrintManager/utils'
import { reprint } from '@/libs/bixolon/reprint'
import { print58 } from '@/libs/bixolon/print58'
import { prepareReceiptLogCall } from '@/libs/receiptIssuing/payment'

export default {
  props: ['data', 'selectedPrinter'],
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'openPdfPrint'],
  data: function () {
    return {
      note: '',
      contractNum: '',
      more: false,
      language: undefined,
      referenceNumber: '',
      defaultPaymentMethods: [],
      paymentMethod: undefined,
      paymentMethods: [],
      receiptNumber: 1,
      bill_sequence: 0,
      offer_sequence: 0,
      last_receipt: '',
      receiptType: '',
      multiplier: 0,
      receiptTypes: [],
      fiscalize: false,
      fiscalizeDisabled: false,
      issueYear: undefined,
      dateMenu: false,
      issueDate: '',
      minIssueDate: '',
      maxIssueDate: '',
      received: null,
      defaultReceiptTypes: [],
      languages: [],
      oibRules: [],
      rules: {
        req: rules.req(),
        oib: rules.oib(),
        gt: rules.gt,
        arrReq: rules.arrReq()
      },
      listeners: [],
      vpRacTimestamp: '',
      deliveryDate: '',
      deliveryDateMenu: false,
      minPaymentDueDate: '',
      paymentDueDate: '',
      paymentDueDateMenu: false,
      type: ''
    }
  },
  computed: {
    storeReceiptNumber () {
      if (this.receiptType && this.receiptType.value === 'P') {
        return this.offer_sequence
      } else {
        return this.bill_sequence
      }
    },
    fiscalizeForbidden () {
      if (this.receiptType && this.receiptType.value === 'P') {
        return true
      } else {
        return false
      }
    },
    paymentDueDefault () {
      var dateNow = Date.now() / 1000

      if ((this.data.currency === 'HRK' && dateNow < 1672527599) || (this.data.currency === 'EUR' && dateNow > 1672527599)) {
        return parseInt(
          (
            new Date(
              toISOLocal(new Date(new Date().getTime() + 8 * DAY))
            ).getTime() / 1000
          ).toFixed(0)
        )
      } else {
        return parseInt(
          (
            new Date(
              toISOLocal(new Date(new Date().getTime() + 30 * DAY))
            ).getTime() / 1000
          ).toFixed(0)
        )
      }
    }
  },

  watch: {
    data: {
      deep: true,
      handler (nv, ov) {
        if (nv.type !== ov.type) this.setDefaults()
      }
    },
    storeReceiptNumber (nv) {
      this.receiptNumber = nv + 1
    }
  },
  async mounted () {
    // if (['T58', 'T80', 'A4'].includes(state.getPrinter().value)) {
    //   state.setPrinterLib('NONE')
    // } else {
    //   const cashRegister = state.getCashRegister()
    //   const printerResponse = await df.doc(`location_printers/${state.getPointOfSale().id}/printers/${cashRegister.printer}`).get()
    //   if (printerResponse) {
    //     const printer = printerResponse.data()
    //     if (printer.library === 'BIXOLON') {
    //       state.setPrinterLib('BIXOLON80')
    //       const issueID = 1
    //       printerInit(issueID)
    //       state.setPosPrinterSettings(2)
    //     } else if (printer.library === 'UNIVERSAL') {
    //       state.setPrinterLib('UNIVERSAL')
    //       state.setPosPrinterSettings('A')
    //       initPrintManager()
    //     }
    //   }
    // }

    this.getMultiplier()
    this.setDefaults()
    this.minIssueDate = toISOLocal(new Date())
    this.listeners.push(
      df
        .doc(`cash_registers/${state.getCashRegister().id}`)
        .onSnapshot((doc) => {
          if (doc && doc.data()) {
            this.offer_sequence = doc.data().offer_sequence
            this.bill_sequence = doc.data().bill_sequence
            this.last_receipt = doc.data().last_receipt_issued_timestamp
            if (this.last_receipt) {
              this.minIssueDate = toISOLocal(
                new Date(this.last_receipt * 1000)
              )
            } else {
              this.minIssueDate = toISOLocal(new Date())
            }
          }
        })
    )
  },
  beforeDestroy () {
    this.detachListeners()
  },
  methods: {
    formatNumber (num, multiplier = 100) {
      if (!num || num === '' || num === 0) return 0

      var bla = num.toString().trim()
      if (bla === '') return 0

      var number = bla.replace(/,/g, '.')

      number = parseFloat(number)
      return parseInt(number * multiplier)
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    setVpRacTimestamp () {
      const chosenTime = new Date(`${this.issueDate} 00:00:00`).getTime()
      const minTime = new Date(this.minIssueDate).getTime()
      if (chosenTime < minTime) {
        this.vpRacTimestamp = minTime + SEC
      } else {
        this.vpRacTimestamp = chosenTime
      }
    },
    advanceStep () {
      if (!this.$refs.paymentForm.validate()) return
      if (this.receiptType.value === 'BO') {
        this.finishPayment()
      } else {
        const _wizardData = {
          receiptNumber: parseInt(this.receiptNumber),
          receiptType: this.receiptType.value,
          paymentMethod: this.paymentMethod.value,
          referenceNumber: this.referenceNumber,
          language: this.language.value,
          contractNum: this.contractNum,
          fiscalize: this.fiscalize,
          note: this.note,
          issueYear: this.issueYear,
          vpRacTimestamp: this.vpRacTimestamp,
          deliveryDate: this.deliveryDate,
          paymentDueDate: this.paymentDueDate,
          paymentDueDefault: this.paymentDueDefault
        }
        this.$emit('advanceStep', _wizardData)
      }
    },
    backwardStep () {
      const deletableProperties = [
        'receiptNumber',
        'paymentMethod',
        'language',
        'contractNum',
        'fiscalize',
        'note',
        'oibRules',
        'receiptType',
        'issueYear',
        'vpRacTimestamp',
        'issueDate',
        'type'
      ]
      this.$emit('backwardStep', deletableProperties)
      if (this.more) this.more = false
    },
    checkInput (ev) {
      // change this with a custom directive  that accepts custom regex and checks input field
      const maskRe = /[0-9]/
      if (!maskRe.test(ev.key)) {
        ev.preventDefault()
        return false
      }
    },
    setDefaults () {
      this.defaultReceiptTypes = [
        { name: this.$t('$vuetify.receiptTypes.withCustomerData'), value: 'R' },
        { name: this.$t('$vuetify.receiptTypes.noCustomerData'), value: 'BO' },
        { name: this.$t('$vuetify.receiptTypes.offer'), value: 'P' }
      ]

      this.defaultPaymentMethods = [
        { name: this.$t('$vuetify.paymentMethods.cash'), value: 'G' },
        { name: this.$t('$vuetify.paymentMethods.card'), value: 'K' },
        { name: this.$t('$vuetify.paymentMethods.transaction'), value: 'T' }
      ]
      //  fdsafsda
      this.offerReceipt = this.data.type === 'P' ? 'offer' : 'receipt'

      const configPaymentMethods = this.data.paymentConfig[this.offerReceipt].payment_types || {}
      this.paymentMethods = Object.keys(configPaymentMethods).map((method) => {
        return {
          ...configPaymentMethods[method],
          ...this.defaultPaymentMethods.find(
            (defaultMethod) => defaultMethod.value === method
          )
        }
      })
      this.paymentMethod =
        this.paymentMethods?.find((method) => method.default) || {}
      this.onChangePaymentMethod()

      this.languages = [
        { name: this.$t('$vuetify.receiptLanguages.hr'), value: 'hr' },
        { name: this.$t('$vuetify.receiptLanguages.en'), value: 'en' }
      ]
      this.language = this.languages[0]
      this.referenceNumber = ''
      this.issueYear = ''
      this.received = null
      this.maxIssueDate = toISOLocal(new Date())
      this.minPaymentDueDate = toISOLocal(new Date())
      this.issueDate = ''
      this.vpRacTimestamp = ''
      this.deliveryDate = ''
      this.paymentDueDate = ''
    },
    onChangePaymentMethod () {
      if (!this.paymentMethod) return
      const configReceiptTypes =
        this.data.paymentConfig[this.offerReceipt]?.payment_types?.[this.paymentMethod.value]
          ?.receipt_types || {}
      this.fiscalize = this.paymentMethod?.fiscalize || false
      this.fiscalizeDisabled =
        this.paymentMethod?.fiscalize_switch_disabled || false
      this.receiptTypes = Object.keys(configReceiptTypes).map((type) => {
        return {
          ...configReceiptTypes[type],
          ...this.defaultReceiptTypes.find(
            (defaultType) => defaultType.value === type
          )
        }
      })
      this.receiptType = this.receiptTypes.find((rType) => rType.default) || {}
      if (
        this.receiptType &&
        this.receiptTypes.every((type) => type.value !== this.receiptType.value)
      ) {
        // v-model not updating itself if item does not exist in v-select items array
        this.receiptType = undefined
      }
    },
    onChangeReceiptType () {
      if (!this.receiptType || !this.receiptType.value) return
      this.fiscalize =
        this.paymentMethod.receipt_types?.[this.receiptType.value]?.fiscalize ||
        false
      this.fiscalizeDisabled =
        this.paymentMethod?.receipt_types?.[this.receiptType.value]
          ?.fiscalize_switch_disabled || false
      if (this.receiptType.value === 'R') {
        this.data.title = this.$t('$vuetify.receipt').split('|')[0]
        this.data.type = 'R'
      }
      if (this.receiptType.value === 'P') {
        this.data.title = this.$t('$vuetify.offer').split('|')[0]
        this.data.type = 'P'
      }
    },
    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },

    dateTimeToUnixTimestamp (dt) {
      return Math.floor(new Date(toISOLocal(dt)).getTime() / 1000)
    },

    finishPayment () {
      try {
        const items = this.data.items.map((item) => {
          const it = {
            name: item.name,
            amt: Math.round(item.quantity * this.multiplier),
            id: item.id,
            single_item_price: item.prices[this.data.currency].price,
            selling_unit: item.selling_unit,
            price: Math.round(item.quantity * item.prices[this.data.currency].price),
            type: item.type,
            pdv: {
              rate: item.prices[this.data.currency].taxes[0].rate
            },
            pnp: null
          }

          if (item.prices[this.data.currency].taxes.length > 0) {
            item.prices[this.data.currency].taxes.forEach(tax => {
              if (tax.name === 'PNP') {
                it.pnp = {
                  name: 'PNP',
                  rate: tax.rate
                }
              }
            })
          }

          if (item.discounted_price !== undefined && item.discounted_price > 0) {
            it.discounted_price = item.discounted_price
            it.price = item.discounted_price * item.quantity
          }

          if (item.discount !== undefined) {
            it.discount = item.discount
          }

          if (item.choosenProductOrder) {
            it.menu_products = []
            Object.keys(item.choosenProductOrder).forEach(key => {
              if (item.choosenProductOrder[key] && item.choosenProductOrder[key].length > 0) {
                item.choosenProductOrder[key].forEach(product => {
                  if (product && product.productItems && product.productItems.length > 0) {
                    product.productItems.forEach(pi => {
                      if (pi && pi.id !== '') {
                        it.menu_products.push(pi)
                      }
                    })
                  }
                })
              }
            })
          }

          return it
        })

        const type = {
          flag: this.receiptType.value
        }

        if (this.issueYear && this.paymentMethod.value === 'T') {
          // veleprodajni račun je račun transakcijskog tipa i za njega se mogu slati i godina izdavanja i datum izdavanja
          type.issue_year = this.issueYear
        }

        let deliveryDate
        let paymentDueDate
        let entity = 'receipt'

        deliveryDate = parseInt((new Date().getTime() / 1000).toFixed(0))

        if (
          this.paymentMethod?.value === 'T' &&
          this.receiptType.value !== 'P'
        ) {
          deliveryDate = this.deliveryDate
            ? parseInt(
              (
                new Date(`${this.deliveryDate}T23:59:59`).getTime() / 1000
              ).toFixed(0)
            )
            : parseInt((new Date().getTime() / 1000).toFixed(0))
          paymentDueDate = this.paymentDueDate
            ? parseInt(
              (
                new Date(`${this.paymentDueDate}T23:59:59`).getTime() / 1000
              ).toFixed(0)
            )
            : this.paymentDueDefault
        }
        if (this.receiptType.value === 'P') {
          paymentDueDate = this.paymentDueDate
            ? parseInt(
              (
                new Date(`${this.paymentDueDate}T23:59:59`).getTime() / 1000
              ).toFixed(0)
            )
            : parseInt(
              (
                new Date(
                  toISOLocal(new Date(new Date().getTime() + 8 * DAY))
                ).getTime() / 1000
              ).toFixed(0)
            )
          entity = 'offer'
        }
        const issueDateTime =
          this.vpRacTimestamp && this.paymentMethod.value === 'T'
            ? parseInt((this.vpRacTimestamp / 1000).toFixed(0))
            : parseInt((new Date().getTime() / 1000).toFixed(0))
        const reqId = uuidv4()
        this.showLoader()

        let paymentMeth = ''
        switch (this.paymentMethod.value) {
          case 'G':
            paymentMeth = 'NOVČANICE'
            break
          case 'K':
            paymentMeth = 'KARTICA'
            break
          case 'T':
            paymentMeth = 'TRANSAKCIJSKI RAČUN'
            break
        }
        const payload = {
          action: {
            operation: 'set',
            entity: entity,
            params: {
              company_id: state.getCurrentCompany().id,
              location_id: state.getPointOfSale().id,
              // seller_id: auth.currentUser.uid,
              register_id: state.getCashRegister().id,
              issue_date_time: issueDateTime,
              delivery_date: deliveryDate,
              payment_method: paymentMeth,
              seller_oib: '',
              // seller_name: state.getUser().name + " " + state.getUser().surname,
              currency: this.data.currency,
              payment_reference: '',
              language: this.language.value,
              type: type,
              items: items,
              note: this.note
            }
          }
        }

        var tags = [{
          terminal_type: 'viva'
        },
        {
          terminal_id: '16239859'
        }]

        payload.action.params.tags = tags

        if (this.data.fullTotal !== undefined && this.data.fullTotal > 0) {
          payload.action.params.full_total = clone(this.data.fullTotal)
        } else {
          payload.action.params.full_total = clone(this.data.total)
        }

        if (this.data.discount !== undefined) {
          payload.action.params.discount = clone(this.data.discount)
        }

        if (this.data.total !== undefined && this.data.total > 0) {
          payload.action.params.total = clone(this.data.total)
        }

        if (this.data.totalWithDiscount !== undefined && this.data.totalWithDiscount > 0) {
          payload.action.params.total = clone(this.data.totalWithDiscount)
        }

        if (entity === 'offer') {
          payload.action.params.number = parseInt(this.contractNum)
        } else {
          payload.action.params.number = parseInt(this.receiptNumber)
          payload.action.params.fiscalize = this.fiscalize
          payload.action.params.payment_due = paymentDueDate

          if (['T58', 'T80', 'A4'].includes(this.selectedPrinter.width)) {
            payload.action.params.print_size = this.selectedPrinter.width
          } else {
            payload.action.params.print_size = 'BIXOLON80'
          }

          // if (['T58', 'T80', 'A4'].includes(state.getPrinter().value)) {
          //   payload.action.params.print_size = state.getPrinter().value
          // } else {
          //   payload.action.params.print_size = 'BIXOLON80'
          // }
        }

        var userID = auth.currentUser.uid
        if (state.getCashRegisterUser().id !== undefined && state.getCashRegisterUser().id !== '') {
          userID = state.getCashRegisterUser().id
        }

        var receiptApi = functions.httpsCallable('receiptapi')

        var params = {
          company_id: state.getCurrentCompany().id,
          location_id: state.getPointOfSale().id,
          // seller_id: auth.currentUser.uid,
          register_id: state.getCashRegister().id,
          issue_date_time: issueDateTime,
          payment_method: paymentMeth,
          seller_oib: '',
          // seller_name: state.getUser().name + " " + state.getUser().surname,
          currency: this.data.currency,
          payment_reference: '',
          language: this.language.value,
          type: type,
          items: items,
          note: this.note,
          number: parseInt(this.receiptNumber),
          fiscalize: true,
          // payment_due: paymentDueDate,
          print_size: 'BIXOLON80',
          reqid: reqId
        }

        if (this.data.fullTotal !== undefined && this.data.fullTotal > 0) {
          params.full_total = clone(this.data.fullTotal)
        } else {
          params.full_total = clone(this.data.total)
        }

        if (this.data.discount !== undefined) {
          params.discount = clone(this.data.discount)
        }

        if (this.data.total !== undefined && this.data.total > 0) {
          params.total = clone(this.data.total)
        }

        if (this.data.totalWithDiscount !== undefined && this.data.totalWithDiscount > 0) {
          params.total = clone(this.data.totalWithDiscount)
        }

        params.seller_id = userID

        const httpArgs = {
          issueDateTime: this.dateTimeToUnixTimestamp(new Date()),
          paymentMeth: this.paymentMethod,
          language: this.language,
          type: this.type,
          receiptNumber: this.receiptNumber,
          openPdfPrint: this.openPdfPrint,
          hideLoader: this.hideLoader,
          removeOrderFromTable: this.removeOrderFromTable
        }

        if (this.receiptType && this.receiptType !== '') {
          switch (this.receiptType) {
            case 'REPREZENTACIJA':
              httpArgs.reprezentacija = true
              break
            case 'DELIVERY':
              httpArgs.delivery = true
              httpArgs.deliveryName = this.deliveryName
              break
          }
        }

        if (this.paymentCard && this.paymentCard.type && this.paymentCard.type !== '') {
          httpArgs.paymentCard = clone(this.paymentCard.type)
        }

        if (this.data.fullTotal !== undefined && this.data.fullTotal > 0) {
          params.full_total = Math.round(clone(this.data.fullTotal))
        } else {
          params.full_total = Math.round(clone(this.data.total))
        }

        if (this.data.discount !== undefined) {
          params.discount = clone(this.data.discount)
        }

        if (this.data.total !== undefined && this.data.total > 0) {
          params.total = Math.round(clone(this.data.total))
        }

        if (this.data.totalWithDiscount !== undefined && this.data.totalWithDiscount > 0) {
          params.total = Math.round(clone(this.data.totalWithDiscount))
        }

        // var validationData = validateParamsForReceipt(payload.action.params)
        // if (validationData.valid === false) {
        //   EventBus.$emit('showError', validationData)
        //   return
        // }
        receiptApi({
          action: {
            operation: 'set',
            entity: entity,
            params: params
          }
        })
          .then((result) => {
            // Read result of the Cloud Function.
            /** @type {any} */
            // const data = result.data
            // const sanitizedMessage = data.text

            if (result && result.data && result.data.zki !== '') {
              if (state.getCurrentCompany().save_log) {
                prepareReceiptLogCall(result?.data?.full_receipt)
              }
            }

            if (!this.selectedPrinter.library || this.selectedPrinter.library === 'BIXOLON' || this.selectedPrinter.library === 'UNIVERSAL') {
              if (!this.selectedPrinter.width || this.selectedPrinter.width === '80') {
                this.hideLoader()

                reprint(this.selectedPrinter, result.data.full_receipt, 2)
              } else if (this.selectedPrinter.width === '58') {
                this.hideLoader()

                print58(this.selectedPrinter, result.data.full_receipt, 2)
              }
            } else {
              var receipttemplate = functions.httpsCallable('receiptTemplate')

              var documentType = 'racun'
              if (payload.action.entity === 'offer') {
                documentType = 'ponuda'
              }

              var printTemplate = ''
              if (state.getCurrentCompany().print_template && state.getCurrentCompany().print_template !== '') {
                printTemplate = state.getCurrentCompany().print_template
              }

              receipttemplate({
                template: printTemplate,
                companyId: result.data.full_receipt.company_details.id,
                documentId: result.data.full_receipt.id,
                documentType: documentType,
                rawJson: ''
              })
                .then((result) => {
                  this.hideLoader()
                  if (result.data?.document !== '') {
                    this.openPdfPrint(result.data?.document)
                  }
                })
            }

            this.$emit('paymentDone')
            this.$emit('transactionEndedWithReceipt')
            this.setDefaults()
          })
      } catch (err) {
        this.hideLoader()

        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      }
    }
  }
}
</script>
