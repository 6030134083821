<template>
  <v-dialog
    v-model="dialogOpen"
    v-if="dialogOpen"
    content-class="my-custom-dialog"
    transition="slide-x-transition"
    persistent
    max-width="1200"
    @keydown.esc="close"
  >
    <v-card tile color="white" style="border: 1px solid white">
      <v-card-title>
        <h2 class="text--secondary">{{ $t("$vuetify.tables.tables") }}</h2>
        <v-spacer></v-spacer>
        <v-icon large @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="white">
        <v-container class="panel-container">
          <v-row>
            <v-col
              v-for="(table, idxc) in userTables"
              :key="idxc"
              :cols="3"
              class="panel-cell"
              align="center"
            >
              <v-btn
                :disabled="tableLocked(table)"
                block
                tile
                depressed
                min-height="6rem"
                color="primary"
                return-object
                @click="onCellClick(table)"
              >
                <span class="white--text tile btn-lg">{{ table.name }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import state from '@/state'
import { clone } from '@/plugins/utils'

export default {
  inject: ['confirm', 'confirmClose'],
  data () {
    return {
      dialogOpen: false,
      persist: true,
      addTableDialogOpen: false,
      table: {},
      sourceTable: {},
      destinationTable: {},
      userTables: []
    }
  },
  props: {
    availableTables: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    open (sourceTable, destinationTable) {
      this.sourceTable = sourceTable
      this.destinationTable = destinationTable
      const sourceTableIdx = this.availableTables.findIndex(t => t.id === sourceTable.id)
      if (sourceTableIdx > -1) {
        this.availableTables.splice(sourceTableIdx, 1)
      }
      this.userTables = clone(this.availableTables)

      var user = state.getCashRegisterUser().id ? state.getCashRegisterUser() : state.getUser()
      var temp = []
      this.userTables.forEach(table => {
        if (user.roles.VODITELJ === true && (table.user_id !== undefined && table.user_id !== null && table.user_id !== '') && table.items.length > 0) {
          temp.push(table)
        } else if (table.user_id !== '' && table.user_id === user.id && table.items.length > 0) {
          temp.push(table)
        }
      })
      this.userTables = []
      this.userTables = temp

      this.dialogOpen = true
    },
    close () {
      this.dialogOpen = false
    },
    tableLocked (table) {
      return table.locked && table.locked_by !== state.getUser().id
    },
    onCloseAddTableDialog () {
      this.addTableDialogOpen = false
    },
    onCellClick (targetTable) {
      this.destinationTable.items.forEach(item => {
        const existingItem = targetTable.items.find(i => i.id === item.id)
        if (existingItem) {
          existingItem.quantity += item.quantity
        } else {
          targetTable.items.push(item)
          const idx = this.sourceTable.items.findIndex(x => x.id === item.id)
          if (idx > -1) {
            // this.sourceTable.items.splice(idx, 1)
            this.sourceTable.items.quantity -= item.quantity
          }
        }
      })

      this.$emit('addItemsToTable', this.sourceTable, targetTable)
      this.close()
    }
  }
}
</script>

<style scoped>
.v-dialog__content >>> .my-custom-dialog {
  position: absolute;
  top: 50px;
}
.panel-container {
  margin-top: 1rem;
}
.text-wrap {
  width: min-content;
  margin: auto;
}
.panel-cell {
  padding: 0 2px 2px 0;
}
.active {
  background-color: var(--v-accent-darken3);
  color: white;
}
.label {
  font-size: 0.6rem;
  color: white;
  min-width: 90px;
}
.btn-md {
  font-size: 0.8rem;
  font-weight: 700;
}
.btn-lg {
  font-size: 1.4rem;
  font-weight: 700;
}
</style>
