<template>
  <v-dialog v-model="visible" persistent scrollable width="800" v-if="virman">
    <v-form ref="addReceiptFromVirman" @submit.prevent="submit">
      <v-card
        class="overflow-auto py-3"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Izrada računa po virmanu</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <div>
                <div style="background-color: #e6e6e6" class="pl-2">
                  <div class="title">Podaci o kupcu:</div>
                  <div>
                    <span>{{ $t("$vuetify.formDetails.name") }}</span
                    >:
                    <span class="font-weight-bold text-capitalize">{{
                      virman.type.payer_name
                    }}</span>
                  </div>
                  <div>
                    <span>{{ $t("$vuetify.formDetails.address") }}</span
                    >:
                    <span class="font-weight-bold text-capitalize">{{
                      virman.type.payer_address
                    }}</span>
                  </div>
                  <div>
                    <span>{{ $t("$vuetify.formDetails.city") }}</span
                    >:
                    <span class="font-weight-bold text-capitalize">{{
                      virman.type.payer_city
                    }}</span>
                  </div>
                  <div>
                    <span>{{ $t("$vuetify.formDetails.zipCode") }}</span
                    >:
                    <span class="font-weight-bold">{{
                      virman.type.payer_zip_code
                    }}</span>
                  </div>
                  <div>
                    <span>{{ $t("$vuetify.formDetails.country") }}</span
                    >:
                    <span class="font-weight-bold">{{
                      virman.type.payer_country
                    }}</span>
                  </div>
                  <div v-if="virmanHasOib">
                    <span>{{ $t("$vuetify.formDetails.oib") }}</span
                    >:
                    <span class="font-weight-bold">{{
                      virman.type.payer_oib
                    }}</span>
                  </div>
                </div>
                <v-text-field
                  v-if="!virmanHasOib"
                  v-model="virman.type.payer_oib"
                  :label="$t('$vuetify.formDetails.oib')"
                  :rules="[rules.req]"
                ></v-text-field>
                <v-row class="pt-3 pb-0">
                  <v-col class="pb-0">
                    <v-select
                      class="pb-0"
                      outlined
                      dense
                      label="Odaberi veleprodajnu blagajnu"
                      :items="cashRegisters"
                      item-text="name"
                      item-value="id"
                      v-model="cashReg"
                      hide-details
                      :rules="[rules.req]"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="pt-0">
                  <v-col class="pb-0 pt-0">
                    <v-checkbox
                      :label="$t('$vuetify.receipts.receiptPaidStatus')"
                      v-model="receiptPaid"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-menu
                      offset-y
                      v-model="deliveryDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="deliveryComputedDate"
                          :label="$t('$vuetify.deliveryDate')"
                          dense
                          prepend-icon="mdi-clock-time-four-outline"
                          @click:prepend="deliveryDateMenu = !deliveryDateMenu"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="deliveryDate"
                        locale="hr-HR"
                        no-title
                        scrollable
                        @input="deliveryDateMenu = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-menu
                      offset-y
                      v-model="paymentDueDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="paymentDueComputedDate"
                          :label="$t('$vuetify.paymentDueDate')"
                          dense
                          prepend-icon="mdi-clock-time-four-outline"
                          @click:prepend="
                            paymentDueDateMenu = !paymentDueDateMenu
                          "
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="paymentDueDate"
                        :min="minPaymentDueDate"
                        :max="maxPaymentDueDate"
                        locale="hr"
                        no-title
                        scrollable
                        @input="paymentDueDateMenu = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-text-field
                      tabindex="-1"
                      v-model="referenceNumber"
                      :label="$t('$vuetify.referenceNumber')"
                      autocomplete="off"
                      validate-on-blur
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      :label="$t('$vuetify.issueYear')"
                      @keypress="checkInput($event)"
                      v-model="issueYear"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      :label="$t('$vuetify.note')"
                      v-model="note"
                      rows="3"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-divider vertical inset></v-divider>
            <v-col cols="7">
              <div>
                <div>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr style="background-color: #e6e6e6">
                          <th class="text-left">Naziv artikla</th>
                          <th class="text-center">Količina</th>
                          <th class="text-right">Jed. cijena</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in virman.items" :key="i">
                          <td class="text-left">
                            {{ $options.filters.capitalize(item.name) }}
                          </td>
                          <td class="text-center">
                            {{
                              (item.amt / multiplier)
                                .toFixed(3)
                                .replace(".", ",")
                            }}
                          </td>
                          <td class="text-right">
                            {{
                              item.single_item_price
                                | money(100, virman.currency)
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Izradi račun
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { clone, duck, toISOLocal, DAY } from '@/plugins/utils'
import rules from '@/plugins/rules'
import state from '@/state'
import { v4 as uuidv4 } from 'uuid'
import { df, auth } from '@/plugins/firebase'
// import { validateParamsForReceipt } from '@/libs/receiptIssuing/validation'
// import EventBus from '@/plugins/event-bus'

export default {
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'openPdfPrint'],
  data: function () {
    return {
      visible: false,
      submitting: false,
      virman: undefined,
      paymentDueDate: '',
      paymentDueDateMenu: false,
      deliveryDate: '',
      deliveryDefaultDate: '',
      deliveryDateMenu: false,
      virmanHasOib: false,
      referenceNumber: '',
      listeners: [],
      multiplier: 0,
      issueYear: '',
      rules: {
        req: rules.req()
      },
      receiptPaid: false,
      note: '',
      cashReg: undefined,
      cashRegisters: [],
      paymentModels: []
    }
  },
  async mounted () {
    this.getMultiplier()
    if (state.getPointOfSale() && state.getPointOfSale().id) {
      const registers = await df
        .doc(
          `user_cash_registers/${auth.currentUser.uid}.${state.getCurrentCompany().id}.${state.getPointOfSale().id}`
        )
        .get()

      this.cashRegisters = registers.data().cash_registers

      const temp = []
      if (this.cashRegisters) {
        Object.keys(this.cashRegisters).forEach(key => {
          if (this.cashRegisters[key].type === 'VP') {
            temp.push(this.cashRegisters[key])
          }
        })
        this.cashRegisters = temp
      }
    }
  },
  computed: {
    minPaymentDueDate () {
      return toISOLocal(new Date())
    },
    maxPaymentDueDate () {
      return this.receiptPaid ? toISOLocal(new Date()) : undefined
    },
    paymentDueDefault () {
      if (this.virman.currency === 'EUR') {
        return toISOLocal(new Date(new Date().getTime() + 8 * DAY)).substr(
          0,
          10
        )
      }
      return toISOLocal(new Date(new Date().getTime() + 30 * DAY)).substr(
        0,
        10
      )
    },
    paymentDueComputedDate () {
      if (!this.paymentDueDate) return ''
      return new Date(this.paymentDueDate).toLocaleDateString('hr-HR')
    },
    deliveryComputedDate () {
      if (!this.deliveryDate) return ''
      return new Date(this.deliveryDate).toLocaleDateString('hr-HR')
    }
  },
  watch: {
    receiptPaid (isPaid) {
      if (!isPaid) return
      this.paymentDueDate = toISOLocal(new Date()).substr(0, 10)
    }
  },

  methods: {
    open (virman, multiplier) {
      if (!virman) return
      if (virman.type.flag !== 'V') {
        this.showMsgBox({
          text: 'Niste odabrali virman.',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
        return
      }
      this.multiplier = clone(multiplier)
      this.virman = clone(virman)
      this.visible = true
      this.setDefaultValues()
    },
    close () {
      this.visible = false
    },
    setDefaultValues () {
      if (!this.virman) return
      this.issueYear = ''
      this.paymentDueDate = clone(this.paymentDueDefault)
      this.deliveryDefaultDate = toISOLocal(
        new Date(new Date().getTime())
      ).substr(0, 10)
      this.deliveryDate = clone(this.deliveryDefaultDate)
      this.virmanHasOib = !!this.virman.type?.payer_oib
      const virmanNumber = `${this.virman.designation.number}-${this.virman.designation.location_id}-${this.virman.designation.register_number}`
      this.note = this.$t('$vuetify.receipts.defaultVirman', {
        virman: virmanNumber
      })

      if (this.virman.note && this.virman.note !== '') {
        this.note = this.note + '\r\n\r\n' + this.virman.note
      }

      this.referenceNumber = this.virman.payment_reference ? this.virman.payment_reference : ''
      if (this.referenceNumber === '') {
        this.generatePaymentReference()
      }
      this.receiptPaid = false
    },
    checkInput (ev) {
      // change this with a custom directive  that accepts custom regex and checks input field
      const maskRe = /[0-9]/
      if (!maskRe.test(ev.key)) {
        ev.preventDefault()
        return false
      }
    },
    getCurrentTimePart () {
      return new Date().toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      })
    },
    async submit () {
      if (!this.$refs.addReceiptFromVirman.validate()) return

      if (!this.cashReg) return

      try {
        const items = this.virman.items.map((item) => {
          const it = {
            name: item.name,
            amt: item.amt,
            id: item.id,
            single_item_price: item.single_item_price,

            pdv: {
              rate: item.pdv.rate
            },
            pnp: null
          }

          if (item.discount && item.discounted_price > 0) {
            it.discount = item.discount

            if (item.discount.type === 'RATE') {
              const temp = ((it.single_item_price / 100) * (item.discount.rate / 100))
              it.discounted_price = it.single_item_price - temp
              it.discount.amount = temp
            } else {
              it.discounted_price = it.single_item_price - it.discount.amount
              it.discount.rate = (it.discount.amount / it.single_item_price) * 100
            }
          }
          return it
        })
        const type = {
          flag: 'R', // iterate virman data
          payer_name: this.virman.type.payer_name,
          payer_address: this.virman.type.payer_address,
          payer_city: this.virman.type.payer_city,
          payer_zip_code: this.virman.type.payer_zip_code,
          payer_country: this.virman.type.payer_country,
          payer_oib: this.virman.type.payer_oib,
          issue_year: this.issueYear
        }

        const issueDateTime = parseInt(
          (new Date().getTime() / 1000).toFixed(0)
        )

        let deliveryDate
        if (duck(this.deliveryDate, this.deliveryDefaultDate)) {
          deliveryDate = parseInt((new Date().getTime() / 1000).toFixed(0))
        } else {
          deliveryDate = parseInt(
            (
              new Date(
                `${this.deliveryDate}T${this.getCurrentTimePart()}`
              ).getTime() / 1000
            ).toFixed(0)
          )
        }
        const paymentDueDate = parseInt(
          (
            new Date(
              `${this.paymentDueDate}T${this.getCurrentTimePart()}`
            ).getTime() / 1000
          ).toFixed(0)
        )
        const reqId = uuidv4()
        this.submitting = true

        const receiptData = await df
          .doc(`cash_registers/${this.cashReg}`)
          .get()
        if (!receiptData?.exists) {
          this.showMsgBox({
            text: 'Podaci o odabranoj blagajni nisu pronađeni',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
          this.submitting = false
          return
        }
        const currentReceiptNumber = receiptData?.data()?.bill_sequence || 0
        const cashRegData = receiptData?.data()

        const payload = {
          action: {
            operation: 'set',
            entity: 'receipt',
            params: {
              company_id: state.getCurrentCompany().id,
              location_id: this.virman.location_details.id,
              // seller_id: auth.currentUser.uid,
              number: parseInt(currentReceiptNumber + 1),
              register_id: cashRegData.id,
              issue_date_time: issueDateTime,
              delivery_date: deliveryDate,
              payment_due: paymentDueDate,
              payment_method: 'TRANSAKCIJSKI RAČUN',
              seller_oib: '',
              // seller_name: state.getUser().name + " " + state.getUser().surname,
              currency: this.virman.currency,
              payment_reference: this.referenceNumber,
              language: this.virman.language,
              fiscalize: false,
              print_size: state.getPrinter(),
              type: type,
              items: items,
              note: this.note,
              tags: [{ source: 'VIRMAN' }]
            }
          }
        }

        if (this.receiptPaid === true) {
          payload.action.params.paid_transaction_receipt = true
        }

        if (this.virman.full_total && this.virman.full_total > 0) {
          payload.action.params.full_total = this.virman.full_total
        }

        if (this.virman.discount) {
          payload.action.params.discount = this.virman.discount
        }

        if (this.virman.total && this.virman.total > 0) {
          payload.action.params.total = this.virman.total
        }

        if (this.virman.wholesale_receipt_type && this.virman.wholesale_receipt_type !== '') {
          payload.action.params.wholesale_receipt_type = this.virman.wholesale_receipt_type
        }

        // var validationData = validateParamsForReceipt(payload.action.params)
        // if (validationData.valid === false) {
        //   EventBus.$emit('showError', validationData)
        //   return
        // }

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'receipt',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .catch((err) => {
            this.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })
          })
        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot((doc) => {
          const data = doc.data()
          if (data) {
            unsubscribe()
            if (!data.body || !data.body.document) {
              this.showMsgBox({
                text: this.$t('$vuetify.errors.nullReceipt'),
                actions: ['cancel'],
                cancelBtnText: this.$t('$vuetify.close'),
                color: 'error'
              })
              this.hideLoader()
              this.submitting = false
              return
            }
            this.close()
            this.openPdfPrint(data.body.document)
            this.hideLoader()
            this.submitting = false
          }
        })
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      }
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.paymentModels = doc.data().payment_models
      })
      this.listeners.push(listener)
    },
    generatePaymentReference () {
      var regExp = /[a-zA-Z]/g

      var oib = this.virman.type.payer_oib ? this.virman.type.payer_oib : ''
      var paymentMethod = this.virman.payment_method
      if (oib && oib.length === 11 && !regExp.test(oib)) {
        Object.keys(this.paymentModels).map(
          (key) => {
            if (key === 'HR01' && this.paymentModels.HR01.enabled === true && paymentMethod === 'TRANSAKCIJSKI RAČUN') {
              const randomKey = Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000

              const forSecurity = oib.toString() + randomKey.toString()
              const securityNum = this.kontrolniBroj(forSecurity)
              this.referenceNumber = key + ' ' + oib + '-' + randomKey + '-' + securityNum
            }
          }
        )
      }
    },
    kontrolniBroj (s) {
      var i = 0; var v = 0; var p = 2; var c = ' '
      for (i = s.length; i >= 1; i--) {
        c = s.substr(i - 1, 1)
        if (c >= '0' && c <= '9' && v >= 0) { v = v + p * c; p = p + 1 } else { v = -1 }
      };
      if (v >= 0) { v = 11 - (v % 11); if (v > 9) { v = 0 }; }
      return (v)
    }
  }
}
</script>
<style scoped>
</style>
