<template>
  <v-dialog
    v-model="dialogOpen"
    v-if="dialogOpen"
    persistent
    max-width="770"
    @keydown.esc="close"
  >
    <v-card tile color="white">
      <v-card-title>
        <h2 class="text--secondary">Odaberi kategoriju ili proizvod</h2>
        <v-spacer></v-spacer>
        <v-icon large @click="close">mdi-close</v-icon>
      </v-card-title>
    </v-card>
    <v-card-text class="white">
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="selectedCategory"
              :items="filteredCategories"
              placeholder="Kategorija"
              item-text="name"
              item-value="id"
              return-object
              @change="onSelectionChange('category')"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="selectedProduct"
              :items="filteredProducts"
              placeholder="Proizvod"
              item-text="name"
              item-value="id"
              return-object
              @change="onSelectionChange('product')"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4"></v-col>
          <v-col cols="4" class="pa-2">
            <v-btn
              style="display: inline-block; border-radius: 12px;"
              block
              tile
              depressed
              min-height="3.5rem"
              :color="backgroundColor"
            >
              <span class="text-wrap label" :style="`color: ${foregroundColor}`">{{ previewBtnText }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <h4 style="margin-bottom: 1rem">Boja pozadine</h4>
            <v-color-picker
              show-swatches
              :swatches="swatches"
              canvas-height="100"
              mode="hexa"
              v-model="backgroundColor"
            ></v-color-picker>
          </v-col>
          <v-col cols="6">
            <h4 style="margin-bottom: 1rem">Boja teksta</h4>
            <v-color-picker
              show-swatches
              :swatches="swatches"
              canvas-height="100"
              mode="hexa"
              v-model="foregroundColor"
            ></v-color-picker>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="3">
            <v-text-field v-model="colspan" label="Colspan" type="number"></v-text-field>
          </v-col>
        </v-row> -->
      </v-container>
    </v-card-text>
    <v-card-actions class="white">
      <v-btn
        class="white--text okButton"
        height="45"
        @click="onAddItem('category')"
        :disabled="!categoryIsSelected"
      >
        Dodaj kategoriju
      </v-btn>
      <v-btn
        class="white--text okButton"
        height="45"
        @click="onAddItem('product')"
        :disabled="!productIsSelected"
      >
        Dodaj proizvod
      </v-btn>
    </v-card-actions>
  </v-dialog>
</template>

<script>
import { df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import state from '@/state'

export default {
  data () {
    return {
      dialogOpen: false,
      products: [],
      currency: 'EUR',
      selectedCategory: undefined,
      selectedProduct: undefined,
      categoryIsSelected: false,
      productIsSelected: false,
      colspan: 1,
      backgroundColor: {},
      foregroundColor: {},
      swatches: [
        ['#797979', '#494949', '#000000'],
        ['#C02DD8', '#9C21B0', '#721881'],
        ['#4996F3', '#3B78C3', '#2C5A90'],
        ['#D50000', '#BEC600', '#00BB0C'],
        ['#FF7301', '#00D5BD', '#E400B9']
      ],
      previewBtnText: ''
    }
  },
  props: {
    addItemDialogOpen: {
      type: Boolean,
      default: false
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    filteredCategories () {
      const cats = this.categories.filter(cat => cat.status === 'OK')
      return clone(cats.sort((a, b) => a.name.localeCompare(b.name)))
    },
    filteredProducts () {
      const prods = this.products.filter(prod => prod.status === 'OK')
      return clone(prods.sort((a, b) => a.name.localeCompare(b.name)))
    }
  },
  watch: {
    addItemDialogOpen (newValue) {
      this.reset()
      this.dialogOpen = newValue
      this.sortedCategories = clone(this.categories.sort((a, b) => a.name.localeCompare(b.name)))
    },
    color (newValue) {
    }
  },
  mounted () {
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }
    this.backgroundColor = '#D50000'
    this.foregroundColor = '#ffffff'
  },
  methods: {
    close () {
      this.$emit('addItemDialogClose')
    },
    onSelectionChange (type) {
      if (type === 'category') {
        this.categoryIsSelected = true
        this.productIsSelected = false
        this.selectedProduct = undefined
        this.previewBtnText = this.selectedCategory.name
        this.getProductsByCategory(this.selectedCategory.id)
      } else {
        this.productIsSelected = true
        this.previewBtnText = this.selectedProduct.name
      }
    },
    onAddItem (type) {
      if (type === 'category') {
        this.$emit('addCategoryToPanel', this.selectedCategory, this.colspan, this.backgroundColor, this.foregroundColor)
      } else {
        this.$emit('addProductToPanel', this.selectedProduct, this.selectedCategory, this.colspan, this.backgroundColor, this.foregroundColor)
      }
      this.reset()
      this.$emit('addItemDialogClose')
    },
    reset () {
      this.selectedCategory = {}
      this.selectedProduct = {}
      this.categoryIsSelected = false
      this.productIsSelected = false
    },
    async getProductsByCategory (categoryId) {
      if (categoryId === '5692439e-4269-4fd9-875e-71e0e08a4e99') {
        const query = await df.collection(`location_menu_pricelist/${state.getPointOfSale().id}/items`)
          .where('status', '==', 'OK')
          .where('active', '==', true)
          // .where(`prices.${this.currency}.active`, '==', true)
          .get()

        const documents = query.docs.map(doc => doc.data())
        this.products = [...documents]
      } else {
        const query = await df.collection(`location_pricelist/${state.getPointOfSale().id}/items`)
          .where('category_ids', 'array-contains', `${categoryId}`)
          .where('status', '==', 'OK')
          .where('active', '==', true)
          // .where(`prices.${this.currency}.active`, '==', true)
          .get()

        this.products = []
        const documents = query.docs.map(doc => doc.data())
        documents.forEach(document => {
          if (document.warehouse_units && document.warehouse_units.units) {
            Object.keys(document.warehouse_units.units).forEach(key => {
              if (document.warehouse_units.units[key].prices && document.warehouse_units.units[key].prices[this.currency].active === true) {
                let newProduct = {}
                newProduct = clone(document)
                newProduct.prices = document.warehouse_units.units[key].prices
                // if (document.warehouse_units.units[key].storage_unit && document.warehouse_units.units[key].storage_unit !== '') {
                //   newProduct.name = document.warehouse_units.units[key].storage_unit
                // } else {
                //   newProduct.name = document.name
                // }

                var stUnit = document.warehouse_units.units[key].storage_unit
                if (stUnit === '.kom') {
                  stUnit = ''
                }
                if (document.warehouse_units.units[key].base_unit === true) {
                  stUnit = document.selling_unit
                }
                newProduct.name = document.name + ' ' + stUnit
                newProduct.id = newProduct.id + '.' + document.warehouse_units.units[key].id
                this.products.push(newProduct)
              }
            })
          } else {
            this.products.push(document)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.v-card >>> .v-card__actions {
  background-color: white !important;
}

.label {
  font-weight: 700;
}
</style>
