import { requestPrint } from '@/libs/bixolon/bxlcommon'
// import { cutPaper, getPosData, printQRCode } from '@/libs/bixolon/bxlpos'
import { cutPaper, getPosData } from '@/libs/bixolon/bxlpos'
// import { formatCurrency, formatCurrencyFull, formatCurrencyCustom, getPosNote, getCummulatedDiscount } from '@/libs/bixolon/helpers'
// import { printLine, printMultiple, printItems, printSeparator, printTotals } from '@/libs/bixolon/printer'
import { printLine, printTotals, printSeparator, printMultiple } from '@/libs/bixolon/printer'
import { formatCurrency } from '@/libs/bixolon/helpers'

// import EventBus from '@/plugins/event-bus'
// import { df } from '@/plugins/firebase'
import state from '@/state'
import { printerErrors } from '@/libs/jsPrintManager/utils'
import * as JSPM from 'jsprintmanager'

export const printClosingBalance = async (data, items, printer) => {
  var companyAddress = data.company_details.address + ', ' + data.company_details.city
  var locationAddress = data.location_details.address + ', ' + data.location_details.city

  if (!data.total) {
    data.total = 0
  }

  const printerType = printer?.library
  // Fix za staru konfiguraciju
  if (!['UNIVERSAL', 'NONE'].includes(printerType)) {
    if (!printer.font) printer.font = 0
    if (!printer.size) printer.size = 0
    if (!printer.width) printer.width = '80'
    if (!printer.line_length) printer.line_length = 48
    if (!printer.library) printer.library = 'BIXOLON'
  }

  // let totalDiscount = 0

  let [escpos, doc, printerConfiguration] = [null, null, null]

  // const currencySymbol = printer.euro_symbol_type && printer.euro_symbol_type != null ? printer.euro_symbol_type : 'EUR'

  printerConfiguration = printer

  if (printerType === 'UNIVERSAL') {
    escpos = window.Neodynamic.JSESCPOSBuilder
    doc = new escpos.Document()
  }

  const currency = 'EUR'

  const options = {
    horizontal: 0,
    vertical: 0,
    bold: false,
    invert: false,
    underline: false,
    alignment: 0,
    font: printerConfiguration.font,
    printerConfiguration: printerConfiguration,
    document: doc,
    escpos: escpos
  }

  if (state.getPrinterLib === 'UNIVERSAL') {
    options.font = printerConfiguration.font
  } else {
    if (printerConfiguration.font === 'A') options.font = 0
    else if (printerConfiguration.font === 'B') options.font = 1
    else if (printerConfiguration.font === 'C') options.font = 2
  }

  function getOptions (option) {
    const modifiedOptions = JSON.parse(JSON.stringify(options))
    Object.keys(option).forEach(key => {
      modifiedOptions[key] = option[key]
    })
    modifiedOptions.document = options.document
    modifiedOptions.escpos = options.escpos
    modifiedOptions.printerConfiguration = options.printerConfiguration
    return modifiedOptions
  }
  // Begin print
  var date = new Date()

  printLine(data.company_details.name, getOptions({ alignment: 1 }))
  printLine(companyAddress, getOptions({ alignment: 1 }))
  printLine(data.location_details.name, getOptions({ alignment: 1, horizontal: 1, vertical: 1, bold: true }))
  printLine(locationAddress, getOptions({ alignment: 1 }))
  printLine('OIB: ' + data.company_details.oib, getOptions({ alignment: 1 }))

  if (data.startReceipt && data.endReceipt) {
    printLine(`${data.location_details.name}, računi:`, getOptions({ alignment: 1 }))
    var startReceiptTime = new Date(data.startReceiptTime * 1000)
    printTotals('od ' + data.startReceipt + ' u ' + ('0' + startReceiptTime.getDate()).slice(-2) + '.' + ('0' + (startReceiptTime.getMonth() + 1)).slice(-2) + '.' + startReceiptTime.getFullYear(), 'Vrijeme: ' + ('0' + startReceiptTime.getHours()).slice(-2) + ':' + ('0' + startReceiptTime.getMinutes()).slice(-2), getOptions({ alignment: 1 }))
    var endReceiptTime = new Date(data.endReceiptTime * 1000)
    printTotals('do ' + data.endReceipt + ' u ' + ('0' + endReceiptTime.getDate()).slice(-2) + '.' + ('0' + (endReceiptTime.getMonth() + 1)).slice(-2) + '.' + endReceiptTime.getFullYear(), 'Vrijeme: ' + ('0' + endReceiptTime.getHours()).slice(-2) + ':' + ('0' + endReceiptTime.getMinutes()).slice(-2), getOptions({ alignment: 1 }))
  }

  printTotals('Ukupni promet za:', `${data.location_details.name}`, options)
  printTotals('Datum: ' + ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear(), 'Vrijeme: ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2), options)
  printTotals('Operater:', `${data.requested_by.name + ' ' + data.requested_by.surname}`, options)
  printSeparator(options)
  printTotals('Ukupno izdano EUR:', formatCurrency(currency, data.total / 100), options)
  printSeparator(options)

  printLine('Promet: ' + data.cash_register.name, options)
  printMultiple([
    'Artikl',
    'Cijena',
    'Kol',
    'Ukupno'], 0, 3, options)
  printSeparator(options)

  items.forEach(item => {
    printMultiple([
      item.name.toUpperCase(),
      formatCurrency(currency, item.single_item_price / 100),
      formatCurrency(currency, item.amt / 1000),
      formatCurrency(currency, item.price / 100)], 0, 3, options)
  })

  printSeparator(options)
  printTotals('Ukupno po artiklima EUR:', formatCurrency(currency, (data.totalPoArtiklima) / 100), options)
  printSeparator(options)
  printTotals('Ukupni popust:', formatCurrency(currency, (data.totalPoArtiklima - data.total) / 100), options)
  printSeparator(options)

  printTotals(data.cash_register.name + ' Ukupno EUR:', formatCurrency(currency, data.total / 100), options)
  printSeparator(options)
  printTotals('Polog:', formatCurrency(currency, data.deposit / 100), options)
  printSeparator(options)

  printLine('Rekapitulacija prometa', getOptions({ alignment: 1 }))
  printLine('po načinu plaćanja', getOptions({ alignment: 1 }))

  printTotals('Način plaćanja', 'Iznos', options)
  printSeparator(options)

  if (data.cash && data.cash > 0) {
    printTotals('Gotovina ' + '(' + data.cashAmt + ')', formatCurrency(currency, data.cash / 100), options)
  }

  if (data.card && data.card > 0) {
    printTotals('Kartice ' + '(' + data.cardAmt + ')', formatCurrency(currency, data.card / 100), options)
  }

  if (data.trans && data.trans > 0) {
    printTotals('Transakcijski račun ' + '(' + data.transAmt + ')', formatCurrency(currency, data.trans / 100), options)
  }

  if (data.ostalo && data.ostalo > 0) {
    printTotals('Ostalo ' + '(' + data.ostaloAmt + ')', formatCurrency(currency, data.ostalo / 100), options)
  }

  printSeparator(options)
  printTotals('Ukupno naplaćeno EUR: ', formatCurrency(currency, (data.total) / 100), options)
  printSeparator(options)

  if (data.stornoTotal && data.stornoTotal !== 0) {
    printLine('Stornirani računi', getOptions({ alignment: 1 }))
    printSeparator(options)
    printMultiple([
      'Broj',
      'Izdao/Stornirao',
      'Ukupno'
    ], 0, 3, options)
    printSeparator(options)

    if (data.storno && data.storno.length > 0) {
      data.storno.forEach(receipt => {
        printMultiple([
          receipt.designation.number + ' ',
          receipt.seller_name,
          formatCurrency(currency, receipt.total / 100)
        ], 0, 3, options)
      })
    }

    printSeparator(options)
    printTotals('Stornirano računa ukupno', formatCurrency(currency, data.stornoTotal / 100), options)
    printSeparator(options)
  }

  if (data.tips_card) {
    printTotals('Napojnice kartice', formatCurrency(currency, data.tips_card / 100), options)
  }

  if (data.tips_cash) {
    printTotals('Napojnice gotovina', formatCurrency(currency, data.tips_cash / 100), options)
  }

  if (data.tips_total) {
    printTotals('Napojnice ukupno', formatCurrency(currency, data.tips_total / 100), options)
  }

  if (data.user_tips && data.user_tips.length > 0) {
    printSeparator(options)

    printTotals('Promet napojnica po prodavaču', '', options)
    data.user_tips.forEach(userTip => {
      if (userTip && userTip.tips_by_payments && userTip.tips_by_payments.length > 0) {
        userTip.tips_by_payments.forEach(tipPayment => {
          if (tipPayment.amount > 0 && tipPayment.quantity > 0) {
            printTotals(userTip.name.toUpperCase() + ' - ' + tipPayment.payment_method, formatCurrency(currency, tipPayment.amount / 100), options)
          }
        })
      }
    })

    printSeparator(options)
  }

  if (data.balance_data?.incoming_previous_amount && data.balance_data?.incoming_previous_amount > 0) {
    printTotals('Ostatak utržka', formatCurrency(currency, data.balance_data?.incoming_previous_amount / 100), options)
  }

  if (data.balance_data?.current_previous_amount && data.balance_data?.current_previous_amount > 0) {
    printTotals('Prethodni ostatak utržka', formatCurrency(currency, data.balance_data?.current_previous_amount / 100), options)
  }

  if (data.balance_data?.total_previous_amount && data.balance_data?.total_previous_amount > 0) {
    printTotals('Novi ostatak utržka', formatCurrency(currency, data.balance_data?.total_previous_amount / 100), options)
  }

  if (data.balance_data?.withdraw_amount) {
    printTotals('Iznos novca za polaganje u banku', formatCurrency(currency, data.balance_data?.withdraw_amount / 100), options)
  }

  printTotals('Ukupno novčanica u blagajni', formatCurrency(currency, (data.cash + data.deposit + data.balance_data?.current_previous_amount) / 100), options)

  // var a = 1
  // if (a === 1) {
  //   return
  // }

  printLine('\n'.repeat(3), options)

  if (printerType === 'UNIVERSAL') {
    doc.feed(1).cut()
    var escposCommands = options.document.generateUInt8Array()

    // create ClientPrintJob
    var cpj = new JSPM.ClientPrintJob()

    // Set Printer info
    let selectedPrinter
    if (printer.ip_address) {
      if (printer.port) {
        selectedPrinter = new JSPM.NetworkPrinter(printer.port, printer.ip_address)
      } else {
        selectedPrinter = new JSPM.NetworkPrinter(9100, printer.ip_address)
      }
    } else {
      selectedPrinter = new JSPM.InstalledPrinter(printer.printer_name)
    }

    cpj.clientPrinter = selectedPrinter

    // Set the ESC/POS commands
    cpj.binaryPrinterCommands = escposCommands

    // Send print job to printer!
    try {
      await cpj.sendToClient()
    } catch (err) {
      printerErrors('JSPM_not_running')
    }

    return true
  } else if (printerType === 'BIXOLON') {
    cutPaper()
    var strSubmit = getPosData()

    // printSeparator(options)

    let printerUrl
    const pName = printer.printer_name
    if (printer.ip_address && printer.ip_address !== '') {
      printerUrl = printer.ip_address
    }

    try {
      requestPrint(pName, strSubmit, viewResult, printerUrl)
    } catch (err) {
      alert('Greška pri reprintu računa, molimo pokušajte ponovno!')

      console.error(err)
    } finally {
    }

    return true
  }
}

const viewResult = (result) => {
  if (result === 'Cannot connect to server') {
    alert('Pisač je nedostupan.')
  }
}
