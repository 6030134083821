import { requestPrint } from '@/libs/bixolon/bxlcommon'
// import { cutPaper, getPosData, printQRCode } from '@/libs/bixolon/bxlpos'
import { cutPaper, getPosData } from '@/libs/bixolon/bxlpos'
// import { formatCurrency, formatCurrencyFull, formatCurrencyCustom, getPosNote, getCummulatedDiscount } from '@/libs/bixolon/helpers'
// import { printLine, printMultiple, printItems, printSeparator, printTotals } from '@/libs/bixolon/printer'
import { printLine, printTotals, printSeparator, printMultiple } from '@/libs/bixolon/printer'
// import { formatCurrency } from '@/libs/bixolon/helpers'

// import EventBus from '@/plugins/event-bus'
// import { df } from '@/plugins/firebase'
import state from '@/state'
import { printerErrors } from '@/libs/jsPrintManager/utils'
import * as JSPM from 'jsprintmanager'

export const printBalancePreview = async (data, printer) => {
  var firmaAdresa = data.firma.adresa + ', ' + data.firma.grad
  var prodajnoMjestoAdresa = data.prodajno_mjesto.adresa + ', ' + data.prodajno_mjesto.grad
  var operater = state.getCashRegisterUser() && state.getCashRegisterUser().name !== undefined ? state.getCashRegisterUser().name + ' ' + state.getCashRegisterUser().surname : state.getUser().name + ' ' + state.getUser().surname

  if (!data.total) {
    data.total = 0
  }

  const printerType = printer?.library
  // Fix za staru konfiguraciju
  if (!['UNIVERSAL', 'NONE'].includes(printerType)) {
    if (!printer.font) printer.font = 0
    if (!printer.size) printer.size = 0
    if (!printer.width) printer.width = '80'
    if (!printer.line_length) printer.line_length = 48
    if (!printer.library) printer.library = 'BIXOLON'
  }

  // let totalDiscount = 0

  let [escpos, doc, printerConfiguration] = [null, null, null]

  // const currencySymbol = printer.euro_symbol_type && printer.euro_symbol_type != null ? printer.euro_symbol_type : 'EUR'

  printerConfiguration = printer

  if (printerType === 'UNIVERSAL') {
    escpos = window.Neodynamic.JSESCPOSBuilder
    doc = new escpos.Document()
  }

  // const currency = 'EUR'

  const options = {
    horizontal: 0,
    vertical: 0,
    bold: false,
    invert: false,
    underline: false,
    alignment: 0,
    font: printerConfiguration.font,
    printerConfiguration: printerConfiguration,
    document: doc,
    escpos: escpos
  }

  if (state.getPrinterLib === 'UNIVERSAL') {
    options.font = printerConfiguration.font
  } else {
    if (printerConfiguration.font === 'A') options.font = 0
    else if (printerConfiguration.font === 'B') options.font = 1
    else if (printerConfiguration.font === 'C') options.font = 2
  }

  function getOptions (option) {
    const modifiedOptions = JSON.parse(JSON.stringify(options))
    Object.keys(option).forEach(key => {
      modifiedOptions[key] = option[key]
    })
    modifiedOptions.document = options.document
    modifiedOptions.escpos = options.escpos
    modifiedOptions.printerConfiguration = options.printerConfiguration
    return modifiedOptions
  }
  // Begin print
  // var date = new Date()

  printLine(data.firma.naziv, getOptions({ alignment: 1 }))
  printLine(firmaAdresa, getOptions({ alignment: 1 }))
  printLine(data.prodajno_mjesto.naziv, getOptions({ alignment: 1, horizontal: 1, vertical: 1, bold: true }))
  printLine(prodajnoMjestoAdresa, getOptions({ alignment: 1 }))
  printLine('OIB: ' + data.firma.oib, getOptions({ alignment: 1 }))

  if (data.racuni_od && data.racuni_do) {
    printLine(`${data.prodajno_mjesto.naziv}, računi:`, getOptions({ alignment: 1 }))
    printTotals('od ', data.racuni_od, getOptions({ alignment: 1 }))
    printTotals('do ', data.racuni_do, getOptions({ alignment: 1 }))
  }

  printTotals('Ukupni promet za:', `${data.ukupni_promet_za}`, options)
  printTotals('Datum: ', data.datum_vrijeme_ispisa, options)
  printTotals('Operater:', `${operater}`, options)
  printSeparator(options)
  printTotals('Ukupno izdano EUR:', `${data.ukupno_izdano_eur}`, options)
  printSeparator(options)

  printLine('Promet: ' + data.blagajna, options)
  printMultiple([
    'Artikl',
    'Cijena',
    'Kol',
    'Ukupno'], 0, 3, options)
  printSeparator(options)

  data.artikli.forEach(artikl => {
    printMultiple([
      artikl.ime.toUpperCase(),
      artikl.cijena,
      artikl.kolicina,
      artikl.ukupno], 0, 3, options)
  })

  printSeparator(options)
  printTotals('Ukupno po artiklima EUR:', data.ukupno_po_artiklima, options)
  printSeparator(options)
  printTotals('Ukupni popust:', data.popust_na_iznos, options)
  printSeparator(options)

  printTotals(data.blagajna + ' Ukupno EUR:', data.ukupno_izdano_eur, options)
  printSeparator(options)
  printTotals('Polog:', data.polog, options)
  printSeparator(options)

  printLine('Rekapitulacija prometa', getOptions({ alignment: 1 }))
  printLine('po načinu plaćanja', getOptions({ alignment: 1 }))

  printTotals('Način plaćanja', 'Iznos', options)
  printSeparator(options)

  if (data.rekapitulacija_prometa?.promet_po_placanjima && data.rekapitulacija_prometa.promet_po_placanjima.length > 0) {
    data.rekapitulacija_prometa.promet_po_placanjima.forEach(promet => {
      if (promet && promet.iznos !== '' && promet.iznos !== '0,00') {
        printTotals(getNacinPlacanja(promet.nacin_placanja) + ' (' + promet.kolicina + ')', promet.iznos, options)
      }
    })
  }

  printSeparator(options)
  printTotals('Ukupno naplaćeno EUR: ', data.rekapitulacija_prometa.ukupno, options)
  printSeparator(options)

  if (data.storno && data.storno?.total !== '' && data.storno?.racuni) {
    printLine('Stornirani računi', getOptions({ alignment: 1 }))
    printSeparator(options)
    printMultiple([
      'Broj',
      'Izdao/Stornirao',
      'Ukupno'
    ], 0, 3, options)
    printSeparator(options)

    if (data.storno && data.storno.racuni.length > 0) {
      data.storno.racuni.forEach(stracun => {
        printMultiple([
          stracun.broj,
          stracun.prodavac,
          stracun.iznos
        ], 0, 3, options)
      })
    }

    printSeparator(options)
    printTotals('Stornirano računa ukupno', data.storno.total, options)
    printSeparator(options)
  }

  if (data.napojnice_kartice) {
    printTotals('Napojnice kartice', data.napojnice_kartice, options)
  }

  if (data.napojnice_gotovina) {
    printTotals('Napojnice gotovina', data.napojnice_gotovina, options)
  }

  if (data.napojnice_ukupno && data.napojnice_ukupno !== '' && data.napojnice_ukupno !== '0,00') {
    printTotals('Napojnice ukupno', data.napojnice_ukupno, options)
  }

  if (data.napojnice_po_osoblju && data.napojnice_po_osoblju.length > 0) {
    printSeparator(options)

    printTotals('Promet napojnica po prodavaču', '', options)
    data.napojnice_po_osoblju.forEach(osobaNapojnica => {
      if (osobaNapojnica && osobaNapojnica.napojnice_po_nacinu_placanja.length > 0) {
        osobaNapojnica.napojnice_po_nacinu_placanja.forEach(prometNapojnica => {
          if (prometNapojnica.kolicina !== '' && prometNapojnica.iznos !== '' && prometNapojnica.iznos !== '0,00') {
            printTotals(osobaNapojnica.ime.toUpperCase() + ' - ' + prometNapojnica.nacin_placanja, prometNapojnica.iznos, options)
          }
        })
      }
    })

    printSeparator(options)
  }

  // if (data.balance_data?.incoming_previous_amount && data.balance_data?.incoming_previous_amount > 0) {
  //   printTotals('Ostatak utržka', formatCurrency(currency, data.balance_data?.incoming_previous_amount / 100), options)
  // }

  if (data.prethodni_ostatak_utrzka && data.prethodni_ostatak_utrzka !== '') {
    printTotals('Prethodni ostatak utržka', data.prethodni_ostatak_utrzka, options)
  }

  // if (data.balance_data?.total_previous_amount && data.balance_data?.total_previous_amount > 0) {
  //   printTotals('Novi ostatak utržka', formatCurrency(currency, data.balance_data?.total_previous_amount / 100), options)
  // }

  if (data.iznos_novca_za_polaganje_u_banku && data.iznos_novca_za_polaganje_u_banku !== '') {
    printTotals('Iznos novca za polaganje u banku', data.iznos_novca_za_polaganje_u_banku, options)
  }

  printTotals('Ukupno novčanica u blagajni', data.ukupno_novcanica_u_blagajni, options)

  // var a = 1
  // if (a === 1) {
  //   return
  // }

  printLine('\n'.repeat(3), options)

  if (printerType === 'UNIVERSAL') {
    doc.feed(1).cut()
    var escposCommands = options.document.generateUInt8Array()

    // create ClientPrintJob
    var cpj = new JSPM.ClientPrintJob()

    // Set Printer info
    let selectedPrinter
    if (printer.ip_address) {
      if (printer.port) {
        selectedPrinter = new JSPM.NetworkPrinter(printer.port, printer.ip_address)
      } else {
        selectedPrinter = new JSPM.NetworkPrinter(9100, printer.ip_address)
      }
    } else {
      selectedPrinter = new JSPM.InstalledPrinter(printer.printer_name)
    }

    cpj.clientPrinter = selectedPrinter

    // Set the ESC/POS commands
    cpj.binaryPrinterCommands = escposCommands

    // Send print job to printer!
    try {
      await cpj.sendToClient()
    } catch (err) {
      printerErrors('JSPM_not_running')
    }

    return true
  } else if (printerType === 'BIXOLON') {
    cutPaper()
    var strSubmit = getPosData()

    // printSeparator(options)

    let printerUrl
    const pName = printer.printer_name
    if (printer.ip_address && printer.ip_address !== '') {
      printerUrl = printer.ip_address
    }

    try {
      requestPrint(pName, strSubmit, viewResult, printerUrl)
    } catch (err) {
      alert('Greška pri reprintu računa, molimo pokušajte ponovno!')

      console.error(err)
    } finally {
    }

    return true
  }
}

const getNacinPlacanja = (nacinPlacanja) => {
  switch (nacinPlacanja) {
    case 'NOVČANICE':
      return 'Gotovina'

    case 'KARTICA':
      return 'Kartice'

    case 'OSTALO':
      return 'Ostalo'

    case 'TRANSAKCIJSKI RAČUN':
      return 'Transakcijski račun'
  }
}

const viewResult = (result) => {
  if (result === 'Cannot connect to server') {
    alert('Pisač je nedostupan.')
  }
}
