<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" width="100%">
        <v-dialog
          v-model="dialog"
          v-if="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card
            style="
              padding-top: 5px !important;
              padding-right: 5px !important;
              padding-left: 5px !important;
            "
          >
            <div style="border: 1px solid #e8e8e8; min-height: 99vh !important">
              <v-card-title>
                <h5>
                  {{ $t("$vuetify.tables.splitTablesDisclaimer") }}
                </h5>
                <v-spacer></v-spacer>
                <v-icon large @click="close">mdi-close</v-icon>
              </v-card-title>
              <v-card-actions>
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-card>
                        <v-card-actions>
                          <v-container>
                            <v-row>
                              <v-col cols="12" style="min-height: 77vh">
                                <v-data-table
                                  :height="tableHeight"
                                  fixed-header
                                  :headers="headersLeft"
                                  :items="sourceTable.items"
                                  :items-per-page="-1"
                                  hide-default-footer
                                  class="elevation-1"
                                >
                                  <template #[`header.controls`]="props">
                                    <v-container>
                                      <v-row>
                                        <v-spacer></v-spacer>
                                        <v-col class="mr-6" cols="1">
                                          <v-btn
                                            v-if="sourceTable.items.length > 0"
                                            icon
                                            color="red"
                                            @click="
                                              onRowBtnClick(
                                                props.item,
                                                'pushEverythingRight'
                                              )
                                            "
                                          >
                                            <v-icon dark
                                              >mdi-chevron-triple-right</v-icon
                                            >
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </template>
                                  <template #[`item.quantity`]="{ item }">
                                    <v-text-field
                                      style="
                                        max-width: 2rem;
                                        height: 24px !important;
                                        margin-top: -28px;
                                      "
                                      v-model="item.quantity"
                                      :disabled="
                                        item.type &&
                                        item.type === 'menu-product'
                                      "
                                    ></v-text-field>
                                  </template>
                                  <template #[`item.controls`]="props">
                                    <v-container>
                                      <v-row>
                                        <v-spacer></v-spacer>
                                        <v-col cols="2">
                                          <v-btn
                                            icon
                                            color="red"
                                            @click="
                                              onRowBtnClick(
                                                props.item,
                                                'pushRight'
                                              )
                                            "
                                          >
                                            <v-icon dark
                                              >mdi-chevron-right</v-icon
                                            >
                                          </v-btn>
                                        </v-col>
                                        <v-col cols="2" class="mr-4">
                                          <v-btn
                                            icon
                                            :disabled="
                                              !props.item ||
                                              props.item.type === 'menu-product'
                                            "
                                            color="red"
                                            @click="
                                              onRowBtnClick(
                                                props.item,
                                                'pushAllRight'
                                              )
                                            "
                                          >
                                            <v-icon dark
                                              >mdi-chevron-double-right</v-icon
                                            >
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </template>
                                </v-data-table>
                              </v-col>
                            </v-row>
                            <div class="total-row">
                              <v-row>
                                <v-col align="start" cols="5">
                                  <h2
                                    style="
                                      margin-left: 1rem;
                                      text-transform: uppercase;
                                    "
                                  >
                                    {{ `${selectedTable.name}` }}
                                  </h2>
                                </v-col>
                                <v-col class="mt-0 pt-0" cols="7">
                                  <h3 style="text-transform: uppercase">
                                    {{ $t("$vuetify.receipts.receiptPrice") }}:
                                    <br />
                                    {{ leftTotalFormatted }}
                                  </h3>
                                </v-col>
                              </v-row>
                            </div>
                          </v-container>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                    <v-col cols="6">
                      <v-card>
                        <v-card-actions>
                          <v-container>
                            <v-row>
                              <v-col cols="12" style="min-height: 77vh">
                                <v-data-table
                                  :height="tableHeight"
                                  fixed-header
                                  :headers="headersRight"
                                  :items="destinationTable.items"
                                  :items-per-page="-1"
                                  hide-default-footer
                                  class="elevation-1"
                                >
                                  <template #[`header.controls`]="props">
                                    <v-container>
                                      <v-row>
                                        <v-col class="ml-1" cols="1">
                                          <v-btn
                                            v-if="
                                              destinationTable.items.length > 0
                                            "
                                            icon
                                            color="red"
                                            @click="
                                              onRowBtnClick(
                                                props.item,
                                                'pushEverythingLeft'
                                              )
                                            "
                                          >
                                            <v-icon dark
                                              >mdi-chevron-triple-left</v-icon
                                            >
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </template>
                                  <template #[`item.quantity`]="{ item }">
                                    <v-text-field
                                      style="
                                        max-width: 2rem;
                                        height: 24px !important;
                                        margin-top: -28px;
                                      "
                                      v-model="item.quantity"
                                      :disabled="
                                        item.type &&
                                        item.type === 'menu-product'
                                      "
                                    ></v-text-field>
                                  </template>
                                  <template #[`item.controls`]="props">
                                    <v-container>
                                      <v-row>
                                        <v-col cols="1">
                                          <v-btn
                                            icon
                                            color="red"
                                            @click="
                                              onRowBtnClick(
                                                props.item,
                                                'pushAllLeft'
                                              )
                                            "
                                          >
                                            <v-icon dark
                                              >mdi-chevron-double-left</v-icon
                                            >
                                          </v-btn>
                                        </v-col>
                                        <v-col cols="1">
                                          <v-btn
                                            icon
                                            color="red"
                                            @click="
                                              onRowBtnClick(
                                                props.item,
                                                'pushLeft'
                                              )
                                            "
                                          >
                                            <v-icon dark
                                              >mdi-chevron-left</v-icon
                                            >
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </template>
                                </v-data-table>
                              </v-col>
                            </v-row>
                            <div class="total-row">
                              <v-row>
                                <v-col align="start" cols="5">
                                  <h2
                                    style="
                                      margin-left: 1rem;
                                      text-transform: uppercase;
                                    "
                                  >
                                    {{ $t("$vuetify.tables.selectedItems") }}
                                  </h2>
                                </v-col>
                                <v-col class="mt-0 pt-0" cols="7">
                                  <h3 style="text-transform: uppercase">
                                    {{ $t("$vuetify.receipts.receiptPrice") }}:
                                    <br />
                                    {{
                                      new Intl.NumberFormat("hr-HR", {
                                        style: "currency",
                                        currency: currency,
                                      }).format(rightTotal)
                                    }}
                                  </h3>
                                </v-col>
                              </v-row>
                            </div>
                          </v-container>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row style="margin: 10px -3px 0 0">
                    <v-col
                      class="actions-row"
                      cols="4"
                      style="padding: 0 2px 2px 0"
                    >
                      <v-btn
                        class="dark-blue-buttons"
                        :disabled="buttonsDisabled"
                        block
                        tile
                        depressed
                        min-height="3.5rem"
                        @click="save('newTable')"
                      >
                        <span class="white--text tile btn-lg">
                          {{ $t("$vuetify.tables.splitTable") }}
                        </span>
                      </v-btn>
                    </v-col>
                    <v-col cols="4" style="padding: 0 2px 2px 0">
                      <v-btn
                        class="dark-blue-buttons"
                        :disabled="buttonsDisabled"
                        block
                        tile
                        depressed
                        min-height="3.5rem"
                        @click="save('existingTable')"
                      >
                        <span class="white--text tile btn-lg">
                          {{ $t("$vuetify.tables.existingTable") }}
                        </span>
                      </v-btn>
                    </v-col>
                    <v-col cols="4" style="padding: 0 2px 2px 0">
                      <v-btn
                        class="dark-blue-buttons"
                        block
                        tile
                        depressed
                        min-height="3.5rem"
                        @click="close"
                      >
                        <span class="white--text tile btn-lg">
                          {{ $t("$vuetify.cancel") }}
                        </span>
                      </v-btn>
                    </v-col></v-row
                  >
                </v-container>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <select-table-dialog
      ref="selectTableDialog"
      :availableTables="availableTables"
      @addItemsToTable="onAddItemsToTable"
    ></select-table-dialog>
  </v-container>
</template>

<script>
import { clone } from '@/plugins/utils'
import SelectTableDialog from '@/modules/cash-register/components/dialogs/SelectTableDialog'
import Table from '@/model/Table'
export default {
  components: {
    SelectTableDialog
  },
  data () {
    return {
      dialog: false,
      currency: 'EUR',
      tableHeight: '',
      headersLeft: [
        { text: 'Naziv', value: 'name', sortable: false },
        { text: 'Količina', value: 'quantity', sortable: false },
        { text: 'Mj. jedinica', value: 'selling_unit', sortable: false },
        { text: 'Jed. cijena', value: 'formattedUnitPrice', sortable: false },
        // { text: 'Jed. cijena s pop.', value: 'formattedDiscountedUnitPrice', sortable: false },
        { text: 'Ukupno', value: 'formattedTotal', sortable: false },
        { text: '', value: 'controls', sortable: false }
      ],
      headersRight: [],
      sourceTable: {},
      destinationTable: {},
      paymentMenu: [
        { text: this.$t('$vuetify.paymentMethods.cash'), type: 'G', icon: 'mdi-cash' },
        { text: this.$t('$vuetify.paymentMethods.card'), type: 'K', icon: 'mdi-card' },
        { text: this.$t('$vuetify.paymentMethods.virman'), type: 'V', icon: 'mdi-card' }
      ],
      leftTotal: 0,
      rightTotal: 0
    }
  },
  computed: {
    buttonsDisabled () {
      return this.destinationTable?.items?.length === 0
    },
    leftTotalFormatted () {
      return new Intl.NumberFormat('hr-HR', { style: 'currency', currency: this.currency }).format(this.leftTotal)
    },
    rightTotalFormatted () {
      return new Intl.NumberFormat('hr-HR', { style: 'currency', currency: this.currency }).format(this.rightTotal)
    }
  },
  props: {
    selectedTable: {
      type: Object,
      default: () => {}
    },
    selectedTables: {
      type: Array,
      default: () => []
    },
    availableTables: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    open () {
      this.destinationTable = new Table()
      this.headersRight = clone(this.headersLeft.slice(0, 5))
      this.headersRight.unshift({ text: '', value: 'controls', sortable: false, align: 'end' })

      this.tableHeight = '68vh'
      this.sourceTable = clone(this.selectedTable)
      this.sourceTable.items = this.sourceTable.items.sort((a, b) => a.name.localeCompare(b.name))
      this.calculateTotals()
      this.dialog = true
    },

    close () {
      this.dialog = false
    },

    onRowBtnClick (item, action) {
      if (action === 'pushEverythingRight') {
        this.transferEverything({ leftTable: this.sourceTable, rightTable: this.destinationTable }, action)
      } else if (action === 'pushEverythingLeft') {
        this.transferEverything({ leftTable: this.destinationTable, rightTable: this.sourceTable }, action)
      } else {
        this.transferItem(item, action)
      }
    },

    onAddItemsToTable (sourceTable, destinationTable) {
      this.$emit('addItemsToTable', sourceTable, destinationTable)
      this.close()
    },

    save (action) {
      switch (action) {
        case 'newTable':
          this.$emit('createNewTable', this.sourceTable, this.destinationTable)
          this.dialog = false
          break
        case 'existingTable':
          this.$refs.selectTableDialog.open(this.sourceTable, this.destinationTable)
          break
        default:
          break
      }
    },

    transferEverything (tables, action) {
      const transferAction = action === 'pushEverythingRight' ? 'pushAllRight' : 'pushAllLeft'
      for (let i = tables.leftTable.items.length - 1; i >= 0; i--) {
        const item = tables.leftTable.items[i]
        this.transferItem(item, transferAction)
      }
    },

    transferItem (item, action) {
      let tables = {}
      const all = action === 'pushAllRight' || action === 'pushAllLeft'
      if (action === 'pushRight' || action === 'pushAllRight') {
        tables = { leftTable: this.sourceTable, rightTable: this.destinationTable }
      } else if (action === 'pushLeft' || action === 'pushAllLeft') {
        tables = { leftTable: this.destinationTable, rightTable: this.sourceTable }
      }
      this.updateDestinationItemQuantity(item, all, tables)
      this.updateOriginItemQuantity(item, all, tables)
    },

    updateDestinationItemQuantity (item, all, tables) {
      const otherItemIdx = tables.rightTable.items.findIndex(i => i.id === item.id)
      if (all) {
        if (otherItemIdx === -1) {
          const otherItem = clone(item)
          tables.rightTable.items.push(otherItem)
        } else {
          tables.rightTable.items[otherItemIdx].quantity += item.quantity
        }
      } else {
        if (otherItemIdx < 0) {
          const otherItem = clone(item)
          otherItem.quantity = 1
          tables.rightTable.items.push(otherItem)
        } else {
          tables.rightTable.items[otherItemIdx].quantity += 1
        }
      }
      tables.rightTable.items = tables.rightTable.items.sort((a, b) => a.name.localeCompare(b.name))
      this.calculateTotals()
    },

    updateOriginItemQuantity (item, all, tables) {
      const itemIdx = tables.leftTable.items.findIndex(i => i.id === item.id)
      if (all) {
        tables.leftTable.items.splice(itemIdx, 1)
      } else {
        if (item.quantity > 1) {
          item.quantity -= 1
        } else {
          tables.leftTable.items.splice(itemIdx, 1)
        }
      }
      tables.leftTable.items = tables.leftTable.items.sort((a, b) => a.name.localeCompare(b.name))
      this.calculateTotals()
    },

    calculateTotals () {
      this.leftTotal = 0
      this.sourceTable.items.forEach(item => {
        this.leftTotal += item.prices[this.currency].price / 100 * item.quantity
      })

      this.rightTotal = 0
      this.destinationTable.items.forEach(item => {
        this.rightTotal += item.prices[this.currency].price / 100 * item.quantity
      })
    }
  }
}
</script>
<style scoped>
.dark-blue-buttons {
  background-color: #2c5a90 !important;
  font-size: 1rem;
}
.total-row {
  padding: 0.8rem 1rem 0 0;
  width: 100%;
  height: 4rem;
  color: white;
  background-color: #2c5a90;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: right;
}
</style>
