<template>
  <v-form ref="reportForm">
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-select
              label="Tip dokumenta"
              dense
              :items="documentTypes"
              v-model="documentType"
              item-text="name"
              item-value="value"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="7">
            <v-menu v-model="reportFromMenu" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatDateFrom"
                  label="Od datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                no-title
                scrollable
                :max="dateTo"
                @input="reportFromMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="5">
            <v-menu
              offset-y
              v-model="reportTimeFromMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formatTimeFrom"
                  label="Vrijeme od"
                  dense
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="reportTimeFromMenu"
                v-model="timeFrom"
                :max="dateFrom === dateTo ? timeTo : undefined"
                full-width
                format="24hr"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="7">
            <v-menu v-model="reportToMenu" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatDateTo"
                  label="Do datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTo"
                no-title
                :min="dateFrom"
                scrollable
                @input="reportToMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="5">
            <v-menu
              offset-y
              v-model="reportTimeToMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formatTimeTo"
                  label="Vrijeme do"
                  dense
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="reportTimeToMenu"
                v-model="timeTo"
                format="24hr"
                full-width
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>
<script>
import rules from '@/plugins/rules'
import { DAY, toISOLocal } from '@/plugins/utils'
import state from '@/state'
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'ReportOptions',
  inject: ['showMsgBox'],
  props: ['type'],
  data: function () {
    return {
      name: undefined,
      surName: undefined,
      documentTypes: [],
      documentType: undefined,
      reportFromMenu: false,
      reportTimeFromMenu: false,
      reportToMenu: false,
      reportTimeToMenu: false,

      dateFrom: new Date(new Date().getTime() - DAY)
        .toISOString()
        .substr(0, 10),
      timeFrom: toISOLocal(new Date()).substr(11, 5),
      dateTo: new Date().toISOString().substr(0, 10),
      timeTo: toISOLocal(new Date()).substr(11, 5),
      rules: {
        req: rules.req()
      }
    }
  },
  computed: {
    formatDateTo () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateTo))
    },
    formatDateFrom () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateFrom))
    },
    formatTimeTo () {
      return new Date(`${this.dateTo}T${this.timeTo}`).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      })
    },
    formatTimeFrom () {
      return new Date(`${this.dateFrom}T${this.timeFrom}`).toLocaleTimeString(
        [],
        { hour: '2-digit', minute: '2-digit', hour12: false }
      )
    },
    dateTo__datetime () {
      return parseInt(
        (new Date(`${this.dateTo}T${this.timeTo}`).getTime() / 1000).toFixed(0)
      )
    },
    dateFrom__datetime () {
      return parseInt(
        (
          new Date(`${this.dateFrom}T${this.timeFrom}`).getTime() / 1000
        ).toFixed(0)
      )
    }
  },
  mounted () {
    if (this.type === 'IRA') {
      this.documentTypes = [
        { name: 'PDF', value: 'pdf', disabled: true },
        { name: 'XLSX', value: 'xlsx' }
      ]
    }
    if (this.type === 'ClosingBalance') {
      this.documentTypes = [
        { name: 'PDF', value: 'pdf' },
        { name: 'XLSX', value: 'xlsx' }
      ]
    }
    this.documentType = this.documentTypes.some((type) => !type.disabled)
      ? this.documentTypes.find((type) => !type.disabled).value
      : 'XLSX'
  },
  methods: {
    reset () {
      this.$refs.reportForm.reset()
    },
    submit () {
      if (!this.$refs.reportForm.validate()) return
      let entity = 'ira_report'
      if (this.type === 'ClosingBalance') {
        entity = 'closing_report'
      }
      try {
        const payload = {
          action: {
            operation: 'get',
            entity: entity,
            resource_id: state.getCashRegister().id,
            params: {
              document_type: this.documentType,
              company_id: state.getCurrentCompany().id,
              // location_id: state.getPointOfSale().id,
              date_from: this.dateFrom__datetime,
              date_to: this.dateTo__datetime
            }
          }
        }

        const reqId = uuidv4()
        this.$emit('onSubmit')
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'document',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .catch((error) => {
            this.showMsgBox({
              text: error || 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            this.$emit('onFinish')
          })
        const unsubscribe = df.doc(`response/${reqId}`).onSnapshot((doc) => {
          if (this.$refs && doc.data()) {
            unsubscribe()
            if (
              !doc.data().body ||
              !doc.data().body.document ||
              doc.data().body.document === ''
            ) {
              this.showMsgBox({
                text: this.$t('$vuetify.errors.nullReportReceipts'),
                actions: ['cancel'],
                cancelBtnText: this.$t('$vuetify.close'),
                color: 'error'
              })
              this.$emit('onFinish')
              return
            }

            let linkSource = ''
            let fileName = ''
            if (this.type === 'IRA') {
              fileName = `report-ira.${new Date().getTime()}`
            }
            if (this.type === 'ClosingBalance') {
              fileName = `report-close-balance.${new Date().getTime()}`
            }
            if (this.documentType === 'pdf') {
              linkSource = `data:application/pdf;base64,${
                doc.data().body.document
              }`
              fileName = `${fileName}.pdf`
            }

            if (this.documentType === 'xlsx') {
              linkSource = `data:application/vnd.ms-excel;base64,${
                doc.data().body.document
              }`
              fileName = `${fileName}.xlsx`
            }

            const downloadLink = document.createElement('a')
            downloadLink.href = linkSource
            downloadLink.download = fileName
            downloadLink.click()
            this.$emit('onFinish')
          }
        })
      } catch (err) {
        this.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
        this.$emit('onFinish')
      }
    }
  }
}
</script>
