<template>
  <v-row justify="center">
    <v-form ref="addProductForm" @submit.prevent.native="submit">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5 title">{{
              $t("$vuetify.formDetails.writeInTitle")
            }}</span>
            <v-spacer></v-spacer>
            <v-icon large @click="$emit('close')">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="form-row">
                <v-col cols="12">
                  <v-text-field
                    :label="$t('$vuetify.formDetails.itemName')"
                    v-model="product.name"
                    :rules="[rules.req]"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="form-row">
                <v-col cols="4">
                  <v-combobox
                    :label="$t('$vuetify.formDetails.unit')"
                    :items="units"
                    v-model="product.unit"
                    item-text="text"
                    item-value="text"
                    :rules="[rules.req]"
                  ></v-combobox>
                </v-col>
                <v-col cols="4">
                  <money-input
                    ref="inputBasePrice"
                    :label="$t('$vuetify.formDetails.baseAmount')"
                    v-model="product.basePrice"
                    :currency="currency"
                    @change="lastPriceEntered = 'base'"
                    @blur="priceAdjustment('base')"
                  ></money-input>
                </v-col>
                <v-col cols="4">
                  <money-input
                    ref="inputUnitPrice"
                    :label="$t('$vuetify.price')"
                    v-model="product.unitPrice"
                    :currency="currency"
                    @change="lastPriceEntered = 'unit'"
                    @blur="priceAdjustment('unit')"
                  ></money-input>
                </v-col>
              </v-row>
              <v-row class="form-row">
                <v-col cols="4">
                  <v-text-field
                    ref="inputQuantity"
                    :label="$t('$vuetify.quantity')"
                    v-model="product.quantity"
                    type="number"
                    :rules="[rules.req]"
                    @blur="recalculate"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                    :label="$t('$vuetify.taxName')"
                    :items="taxes"
                    v-model="product.tax"
                    @change="priceAdjustment(lastPriceEntered)"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <money-input
                    ref="inputTotalPrice"
                    class="total"
                    :label="$t('$vuetify.total')"
                    v-model="product.totalPrice"
                    :currency="currency"
                    readonly
                  ></money-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="onReset">
              {{ $t("$vuetify.reset") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onConfirm">
              {{ $t("$vuetify.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </v-row>
</template>

<script>
import MoneyInput from '@/components/MoneyInput'
import rules from '@/plugins/rules'
import { v4 as uuidv4 } from 'uuid'
import WriteInProductModel from '@/model/WriteInProduct'
import state from '@/state'

export default {
  components: {
    MoneyInput
  },
  props: {
    currency: {
      type: String,
      default: 'EUR'
    }
  },
  data () {
    return {
      dialog: true,
      lastPriceEntered: undefined,
      product: {
        id: undefined,
        type: '',
        name: '',
        unit: '',
        quantity: '',
        unitPrice: 0,
        basePrice: 0,
        tax: 1.25,
        totalPrice: 0,
        prices: []
      },
      units: [
        { text: 'komad', value: 'pcs' },
        { text: 'kilogram', value: 'kg' },
        { text: 'litra', value: 'l' },
        { text: 'sat', value: 'h' },
        { text: 'm2', value: 'm2' }
      ],
      taxes: [
        { text: 'PDV 25%', value: 1.25, rate: 0.25 },
        { text: 'PDV 13%', value: 1.13, rate: 0.13 },
        { text: 'PDV 5%', value: 1.05, rate: 0.05 },
        { text: 'PDV 0%', value: 1, rate: 0 }
      ],
      rules: {
        req: rules.req()
      }
    }
  },

  computed: {
    isTaxed () {
      return state.getCurrentCompany().is_taxed
    }
  },

  methods: {
    onReset () {
      this.product.name = ''
      this.product.unit = ''
      this.product.quantity = 0
      this.product.unitPrice = 0
      this.product.basePrice = 0
      this.product.tax = 1.25
      this.product.totalPrice = 0

      this.setMoneyField('inputUnitPrice', this.product.unitPrice)
      this.setMoneyField('inputBasePrice', this.product.basePrice)
      this.setMoneyField('inputTotalPrice', this.product.totalPrice)
    },

    onConfirm () {
      if (!this.$refs.addProductForm.validate()) return

      var unitPrice = this.$refs.inputUnitPrice.currencyToFloat(this.product.unitPrice)

      const taxModel = this.taxes.find(t => t.value === this.product.tax)

      if (state.getCashRegister().type === 'VP') {
        unitPrice = this.$refs.inputUnitPrice.currencyToFloat(this.product.basePrice)
      }

      this.model = new WriteInProductModel()

      this.model.created = Math.floor(new Date().getTime() / 1000)
      this.model.id = uuidv4()
      this.model.name = this.product.name
      this.model.prices[this.currency] = {
        active: true,
        price: Number(unitPrice).toFixed(2),
        taxes: [{
          name: taxModel.text,
          rate: taxModel.rate * 10000
        }]
      }

      let pdUnit = ''
      if (this.product.unit.text !== undefined && this.product.unit.text !== null) {
        pdUnit = this.product.unit.text
      } else {
        pdUnit = this.product.unit
      }
      this.model.quantity = this.product.quantity
      this.model.selling_unit = pdUnit
      this.model.storage_unit = pdUnit

      this.$emit('addProduct', this.model)
      this.$emit('close')
    },

    priceAdjustment (inputPrice) {
      if (inputPrice === 'unit') {
        const unitPrice = this.$refs.inputUnitPrice.currencyToFloat(this.product.unitPrice)
        if (!this.isValid(unitPrice)) return
        this.product.basePrice = (unitPrice / this.product.tax).toFixed(2)
        this.setMoneyField('inputBasePrice', parseFloat(this.product.basePrice).toFixed(2))
      } else {
        const basePrice = this.$refs.inputBasePrice.currencyToFloat(this.product.basePrice)
        if (!this.isValid(basePrice)) return
        this.product.unitPrice = (basePrice * this.product.tax).toFixed(2)
        this.setMoneyField('inputUnitPrice', parseFloat(this.product.unitPrice).toFixed(2))
      }
      this.recalculate()
    },

    recalculate () {
      const unitPrice = this.$refs.inputUnitPrice.currencyToFloat(this.product.unitPrice)
      const quantity = this.product.quantity

      if (this.isValid(unitPrice, quantity)) {
        this.product.totalPrice = unitPrice * this.product.quantity
        this.product.prices[this.currency] = { price: unitPrice.toFixed(2) }
        this.setMoneyField('inputTotalPrice', parseFloat(this.product.totalPrice).toFixed(2))
      }

      if (state.getCashRegister().type === 'VP') {
        const basePrice = this.$refs.inputBasePrice.currencyToFloat(this.product.basePrice)
        const quantity = this.product.quantity

        if (this.isValid(unitPrice, quantity)) {
          var tempPrice = basePrice * quantity
          this.product.totalPrice = (tempPrice * this.product.tax).toFixed(2)
          this.product.prices[this.currency] = { price: unitPrice.toFixed(2) }
          this.setMoneyField('inputTotalPrice', parseFloat(this.product.totalPrice).toFixed(2))
        }
      }
    },

    setMoneyField (field, value) {
      this.$refs[field].$el.getElementsByTagName('input')[0].value = value
    },

    isValid () {
      let isValid = true
      for (var i = 0; i < arguments.length; i++) {
        if (!arguments[i] && arguments[i] <= 0) {
          isValid = false
          break
        }
      }
      return isValid
    }
  },

  mounted () {
    if (this.isTaxed) {
      this.product.tax = 1.25
    } else {
      this.product.tax = 1
    }
  }
}
</script>

<style scoped>
.form-row {
  margin: -1.8rem 0 -1.8rem;
}
.total {
  font-weight: 900;
}
</style>
