<template>
  <div>
    <v-dialog
      v-model="dialog"
      v-if="dialog"
      @keydown.esc="close"
      persistent
      max-width="40%"
      style="z-index: 6"
    >
      <v-card class="overflow-auto">
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">
              {{ $t("$vuetify.tables.receiptNote") }}
            </h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <!-- <v-row>
            <v-col>
              <v-select
                autofocus
                v-model="language"
                :items="languages"
                item-text="label"
                item-value="value"
              ></v-select>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col>
              <v-textarea
                @focus="showKbd"
                data-layout="normalAduro"
                auto-grow
                rows="10"
                dense
                outlined
                :label="$t('$vuetify.tables.receiptNote')"
                v-model="note"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="note.length === 0"
            class="white--text okButton mb-2"
            height="45"
            @click="submit()"
          >
            {{ $t("$vuetify.tables.addReceiptNote") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container
      class="kbd"
      v-if="!$vuetify.breakpoint.smAndDown"
      ref="keyboard"
      v-show="oskVisible"
      style="top: 180px; left: -500px"
      :style="{ 'max-width': width }"
    >
      <div class="kbd-header"></div>
      <vue-touch-keyboard
        :options="oskOptions"
        :layout="layout"
        :cancel="hideKbd"
        :accept="accept"
        :input="input"
      />
    </v-container>
  </div>
</template>

<script>
import onScreenKeyboard from '@/mixins/onScreenKeyboard'
import EventBus from '@/plugins/event-bus'
// import { df } from '@/plugins/firebase'
// import { v4 as uuidv4 } from 'uuid'
// import state from '@/state'

export default {
  mixins: [onScreenKeyboard],
  data () {
    return {
      dialog: false,
      language: 'hr',
      note: '',
      selectedTable: undefined,
      languages: [
        { label: 'hrvatski', value: 'hr' },
        { label: 'english', value: 'en' }
      ]
    }
  },

  methods: {
    open (receiptNote, selectedTable = undefined) {
      this.selectedTable = selectedTable

      if (receiptNote && receiptNote.note) {
        this.note = receiptNote.note
      } else {
        this.note = ''
      }
      this.dialog = true
    },
    close () {
      this.language = 'hr'
      this.note = ''
      this.dialog = false
    },
    async submit () {
      const receiptNote = { note: this.note }
      EventBus.$emit('save-receipt-note', receiptNote)

      // if (this.selectedTable && this.selectedTable.id) {
      //   console.log('table', this.selectedTable)
      //   const reqid = uuidv4()
      //   const docRef = df.doc(`location_orders_note_history/${state.getPointOfSale().id}/table_notes/${reqid}`)
      //   var table = this.selectedTable

      //   var time = new Date()
      //   try {
      //     await docRef.set(
      //       {
      //         id: reqid,
      //         history_created: time.getTime(),
      //         history_created_readable: 'Datum: ' + ('0' + time.getDate()).slice(-2) + '.' + ('0' + (time.getMonth() + 1)).slice(-2) + '.' + time.getFullYear() + ', ' + 'Vrijeme: ' + ('0' + time.getHours()).slice(-2) + ':' + ('0' + time.getMinutes()).slice(-2),
      //         name: table && table.name ? table.name : 'Za van',
      //         number: table.number,
      //         orders: table.orders,
      //         update_time: table.update_time,
      //         user_id: table.user_id,
      //         user_name: table.user_name,
      //         order_added_by: state.getCashRegisterUser() ? state.getCashRegisterUser().name + ' ' + state.getCashRegisterUser().surname : state.getUser().name + ' ' + state.getUser().surname,
      //         items: table.items,
      //         receipt_note: receiptNote
      //       }
      //     )
      //   } catch (err) {
      //     console.error('Error saving order: ', err)
      //   }
      // }

      this.close()
    }
  }
}
</script>
