<template>
  <v-form>
    <v-card flat>
      <v-card-text>
        <!-- <v-row>
          <v-col>
            <div>
              Najveća količina novca na blagajni: <span class="font-weight-bold">{{maxHoldingAmt | money(100, 'EUR')}}</span>
            </div>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col class="pt-0 mt-2 pb-0 mb-0" cols="6">
            <v-row class="pt-0 mt-2 pb-0 mb-0">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <div class="pt-0 mt-0 pb-0 mb-0">
                  Novčanice:
                  <span class="font-weight-bold">{{
                    $options.filters.money(availableFunds, 100, currency)
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row class="pt-0 mt-2 pb-0 mb-0">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <div class="pt-0 mt-0 pb-0 mb-0">
                  Kartice:
                  <span class="font-weight-bold">{{
                    $options.filters.money(cardFunds, 100, currency)
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row class="pt-0 mt-2 pb-0 mb-0">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <div class="pt-0 mt-0 pb-0 mb-0">
                  Ostalo:
                  <span class="font-weight-bold">{{
                    $options.filters.money(otherFunds, 100, currency)
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row class="pt-0 mt-2 pb-0 mb-0">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <div class="pt-0 mt-0 pb-0 mb-0">
                  Polog na blagajni:
                  <span class="font-weight-bold">{{
                    $options.filters.money(depositAmt, 100, currency)
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row class="pt-0 mt-2 pb-0 mb-0">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <div class="pt-0 mt-0 pb-0 mb-0">
                  Ostatak utržka:
                  <span class="font-weight-bold">{{
                    $options.filters.money(previousAmt, 100, currency)
                  }}</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pt-0 mt-2 pb-0 mb-0" cols="6">
            <v-row class="pt-0 mt-2 pb-0 mb-0">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <div class="pt-0 mt-0 pb-0 mb-0">
                  Napojnice (Novčanice):
                  <span class="font-weight-bold">{{
                    $options.filters.money(tipCash, 100, currency)
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row class="pt-0 mt-2 pb-0 mb-0">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <div class="pt-0 mt-0 pb-0 mb-0">
                  Napojnice (Kartice):
                  <span class="font-weight-bold">{{
                    $options.filters.money(tipCard, 100, currency)
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row class="pt-0 mt-2 pb-0 mb-0">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <div class="pt-0 mt-0 pb-0 mb-0">
                  Napojnice (Ukupno):
                  <span class="font-weight-bold">{{
                    $options.filters.money(tipsTotal, 100, currency)
                  }}</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-row class="pl-3 pt-0 mt-2 pb-0 mb-0">
            <v-col class="pt-0 mt-0 pb-0 mb-0">
              <div class="pt-0 mt-0 pb-0 mb-0">
                Ukupno novčanica u blagajni:
                <span class="font-weight-bold">{{
                  $options.filters.money(
                    availableFunds + depositAmt + previousAmt + tipCash,
                    100,
                    currency
                  )
                }}</span>
              </div>
              <div class="font-italic">
                Utržak (Novčanice)
                {{ $options.filters.money(availableFunds, 100, currency) }}
                + Polog na blagajni:
                {{ $options.filters.money(depositAmt, 100, currency) }}
                + Ostatak utržka:
                {{ $options.filters.money(previousAmt, 100, currency) }}
                + Napojnice:
                {{ $options.filters.money(tipCash, 100, currency) }}
              </div>
            </v-col>
          </v-row>

          <v-row class="pl-3 pt-0 mt-2 pb-2 mb-0">
            <v-col class="pt-0 mt-0 pb-0 mb-0">
              <div class="pt-0 mt-0 pb-0 mb-0">
                Datum zadnjeg zaključka:
                <span class="font-weight-bold">{{
                  lastBalanceTipeStamp | fullDateTime
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-row>
        <v-divider class="mt-2 pb-2"></v-divider>
        <v-row class="pt-0 mt-0 pb-0 mb-0">
          <div style="padding-left: 12px; padding-top: 0px; padding-right: 5px">
            Iznos novca za polaganje u banku:
          </div>
          <div style="margin-top: -7px !important; max-width: 15% !important">
            <quantity-input
              dense
              hide-details
              v-model="withdrawAmount"
              suffix="€"
            ></quantity-input>
          </div>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>
<script>
import state from '@/state'
import { df } from '@/plugins/firebase'
import { toISOLocal } from '@/plugins/utils'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import QuantityInput from '@/components/QuantityInput.vue'

export default {
  name: 'CloseBalance',
  mixins: [applicationSettings],
  components: { QuantityInput },
  data: function () {
    return {
      cashLeft: 0,
      availableFunds: 0,
      cardFunds: 0,
      otherFunds: 0,
      depositAmt: 0,
      maxHoldingAmt: 0,
      lastBalanceTipeStamp: 0,
      receiptPrint: true,
      currency: 'EUR',
      withdrawAmount: 0,
      previousAmt: 0,
      tips: [],
      tipCash: 0,
      tipCard: 0,
      tipsTotal: 0
    }
  },
  computed: {
    dateNow () {
      return Date.now() / 1000
    },
    lastBalance () {
      return new Date(toISOLocal(new Date(this.lastBalanceTipeStamp * 1000)).substr(0, 10)).toLocaleDateString('hr-HR')
    }
  },
  watch: {
    receiptPrint (val) {
      this.$emit('receiptPrint', val)
    }
  },
  mounted () {
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }
    const listener = df.doc(`cash_registers/${state.getCashRegister().id}`).onSnapshot(doc => {
      this.resetValues()
      const document = doc.data()
      if (!document) {
        this.resetValues()
        return
      }
      this.availableFunds = document.available_funds || 0
      this.cardFunds = document.card_funds || 0

      var previousFunds = document.previous_funds ? document.previous_funds : 0
      this.withdrawAmount = ((this.availableFunds / 100) + (previousFunds / 100)).toFixed(2) || 0
      this.otherFunds = document.other_funds || 0
      this.depositAmt = document.deposit?.amt || 0
      this.previousAmt = document.previous_funds || 0
      this.maxHoldingAmt = document.max_holding || 0
      this.cashLeft = this.maxHoldingAmt - this.depositAmt - this.availableFunds
      this.lastBalanceTipeStamp = document.last_balance_timestamp
      this.getTips()
    })
    this.listeners.push(listener)
  },
  methods: {
    getTips () {
      this.tips = []
      const query = df.collection('tips')
        .where('register_id', '==', state.getCashRegister().id)
        .where('created', '>', this.lastBalanceTipeStamp)

      query
        .onSnapshot((doc) => {
          this.tips = []

          var tipCash = 0
          var tipCard = 0
          var tipsTotal = 0

          doc.docs.forEach((rec) => {
            var tip = rec.data()

            tipsTotal += tip.tip_amount

            if (tip.tip_payment_method === 'NOVČANICE') {
              tipCash += tip.tip_amount
            }

            if (tip.tip_payment_method === 'KARTICA') {
              tipCard += tip.tip_amount
            }
            this.tips.push(tip)
          })

          this.tipCard = tipCard
          this.tipCash = tipCash
          this.tipsTotal = tipsTotal

          if (doc.docs.length === 0) {
            this.tips = []
          }
        }
        )
    },
    getBalanceAmounts () {
      var amount = {
        withdrawAmount: this.withdrawAmount,
        availableFunds: this.availableFunds,
        depositAmount: this.depositAmt,
        previousAmount: this.previousAmt
      }
      return amount
    },
    resetReceiptPrint () {
      this.receiptPrint = true
    },
    resetValues () {
      this.cashLeft = 0
      this.availableFunds = 0
      this.depositAmt = 0
      this.maxHoldingAmt = 0
    }
  }
}
</script>
