<template>
  <panel-rows
    justify-end
    :style="`margin-left: ${margin}`"
    :categories="categories"
    :selectedPrinter="selectedPrinter"
    @updateItem="updateTileData"
  />
</template>

<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import PanelRows from '@/modules/cash-register/components/PanelRows'

export default {
  script: [
    { type: 'text/javascript', src: 'https://cdn.jsdelivr.net/gh/SheetJS/js-codepage/dist/cptable.js', async: true, body: false }
  ],
  inject: ['showMsgBox', 'confirm', 'confirmClose', 'showMsgBoxHtml'],
  data () {
    return {
      rows: [],
      category: null,
      categories: [],
      categoryItems: [],
      moreCategories: [],
      listeners: [],
      loaded: false,
      sideMenuMini: true,
      margin: '56px',
      selectedPrinter: {}
    }
  },
  components: {
    PanelRows
  },
  computed: {
    isSeller () {
      return state.getUser()?.roles?.PRODAVAČ
    },
    isAdmin () {
      return state.getUser()?.roles?.SUPERADMIN || state.getUser()?.roles?.ADMIN
    }
  },
  methods: {
    handleKeyEvents (e) {
    },
    getCategories () {
      const listener = df.doc(`company_categories/${state.getCurrentCompany().id}`)
        .onSnapshot(doc => {
          if (doc && doc.data() && doc.data().categories && Object.keys(doc.data().categories).length > 0) {
            this.categories = Object.keys(doc.data().categories).map(key => {
              return { ...doc.data().categories[key], [`hover-${doc.data().categories[key].id}`]: false }
            })
            this.categories.push({ id: '5692439e-4269-4fd9-875e-71e0e08a4e99', name: 'Meni', picture: '', status: 'OK' })
            this.categories.sort((a, b) => a.name > b.name ? 1 : -1)
          }
        })

      this.listeners.push(listener)
    },
    async updateTileData (tile, rows) {
      const docRef = df.doc(`cash_registers/${state.getCashRegister().id}/gui_configuration_rows/row${tile.row_index}`)
      try {
        await docRef.set(
          {
            cols: rows[tile.row_index].cols
          }
        )
        console.info('Tile successfully updated.')
      } catch (err) {
        console.error('Error updating tile: ', err)
      }
    }
  },
  created () {
    this.margin = state.getMargin()
    this.selectedPrinter = state.getSelectedPrinter()
  },
  mounted () {
    this.getCategories()

    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    EventBus.$on('set-selected-printer', (printer) => {
      this.selectedPrinter = printer
    })

    if (!this.isSeller && !this.isAdmin) {
      this.showMsgBoxHtml({ text: this.$t('$vuetify.errors.isNotSeller'), actions: ['cancel'], cancelBtnText: this.$t('$vuetify.close'), color: 'error' })
    }
    if (!this.isSeller && this.isAdmin) {
      df.doc(`users/${state.getUser()?.id}`)
        .get()
        .then((user) => {
          if (user && user.exists) {
            state.setUser(user.data())
          }

          if (!user.roles?.PRODAVAČ) {
            this.confirm({
              title: this.$t('$vuetify.addSellerRole'),
              message: this.$t('$vuetify.errors.notSellerButSuperuser'),
              options: {
                toolbar: true,
                width: 410,
                confirmText: this.$t('$vuetify.addRole'),
                cancelText: this.$t('$vuetify.close')
              }
            }).then(resp => {
              if (resp) {
                this.confirmClose()
                this.$router.push({
                  name: 'pointOfSale.users',
                  params: { companyId: state.getCurrentCompany().id, locationId: state.getPointOfSale().id }
                })
                setTimeout(() => {
                  EventBus.$emit('add-role', state.getUser())
                }, 150)
              }
            })
          }
        })
    }
  }
}
</script>
