<template>
  <div>
    <v-dialog
      v-model="dialog"
      v-if="dialog"
      persistent
      :max-width="width"
      @keydown.esc="close"
      content-class="my-custom-dialog"
    >
      <v-card tile color="white">
        <v-card-title>
          <h2 class="text--secondary">
            {{
              this.deliveryService !== undefined
                ? $options.filters.capitalize(this.deliveryService)
                : "Odaberite dostavnu službu"
            }}
          </h2>
          <v-spacer></v-spacer>
          <v-icon large @click="close">mdi-close</v-icon>
        </v-card-title>
      </v-card>
      <v-card-text class="white">
        <v-container>
          <v-row v-if="deliveryService === undefined" class="panel-row">
            <v-col
              v-for="(delivery, idxc) in deliveries"
              :key="idxc"
              :cols="12"
              class="mb-1 pb-0 mt-0 pt-0"
            >
              <v-btn
                style="border-radius: 12px"
                block
                tile
                depressed
                min-height="6rem"
                color="#295586"
                class="mb-0 pb-0"
                return-object
                @click="setDelivery(delivery.value)"
              >
                <span class="white--text tile btn-lg text-xs-center">{{
                  delivery.name
                }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="deliveryService" class="panel-row">
            <v-col
              v-for="(payment, idxc) in payments"
              :key="idxc"
              :cols="12"
              class="mb-1 pb-0 mt-0 pt-0"
            >
              <v-btn
                style="border-radius: 12px"
                block
                tile
                depressed
                min-height="6rem"
                color="#295586"
                class="mb-0 pb-0"
                return-object
                @click="finish(payment.value)"
              >
                <span class="white--text tile btn-lg text-xs-center">{{
                  payment.name
                }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-dialog>
  </div>
</template>

<script>
import onScreenKeyboard from '@/mixins/onScreenKeyboard'
import EventBus from '@/plugins/event-bus'
// import { df } from '@/plugins/firebase'
// import { v4 as uuidv4 } from 'uuid'
// import state from '@/state'

export default {
  mixins: [onScreenKeyboard],
  data () {
    return {
      dialog: false,
      language: 'hr',
      note: '',
      deliveryService: undefined,
      payments: [
        { name: 'Gotovinski', value: 'G' },
        { name: 'Bezgotovinski', value: 'O' }
      ],
      deliveries: [
        { name: 'Wolt', value: 'wolt' },
        { name: 'Glovo', value: 'glovo' },
        { name: 'Bolt', value: 'bolt' }
      ]
    }
  },

  methods: {
    setDelivery (deliveryService) {
      this.deliveryService = deliveryService
    },
    open (deliveryService) {
      this.dialog = true
      this.deliveryService = deliveryService
    },
    close () {
      this.dialog = false
      this.deliveryService = undefined
    },
    async finish (payment) {
      EventBus.$emit('deliveryFinishSimplePayment', this.deliveryService, payment)
      this.close()
    }
  }
}
</script>
<style scoped>
@media only screen and (max-height: 899px) {
  #container {
    max-height: 193px;
  }
  span.tile {
    display: block;
    white-space: normal;
    /* max-width: 180px; */
  }
}

@media only screen and (min-height: 900px) {
  #container {
    max-height: 440px;
  }
  span.tile {
    display: block;
    white-space: normal;
    /* max-width: 120px; */
  }
}
.btn-lg {
  font-size: 1.5rem;
  font-weight: 700;
}
.v-dialog__content >>> .my-custom-dialog {
  border-radius: 25px !important;
}
</style>
