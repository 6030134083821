<template>
  <div>
    <cash-register v-if="registerType === 'cash'"></cash-register>
    <panel-register v-if="registerType === 'panel'"></panel-register>
    <panel-register-wide
      v-if="registerType === 'ai-caffe-wide'"
    ></panel-register-wide>
    <bottom-up-register
      v-if="registerType === 'bottom-up'"
    ></bottom-up-register>
    <crud-register v-if="registerType === 'ai-crud'"></crud-register>
  </div>
</template>

<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import registerCommons from '@/mixins/registerCommons'
import CashRegister from '@/modules/cash-register/CashRegister'
import PanelRegister from '@/modules/cash-register/PanelRegister'
import PanelRegisterWide from '@/modules/cash-register/templates/PanelRegisterWide'
import BottomUpRegister from '@/modules/cash-register/templates/BottomUpRegister'
import CrudRegister from '@/modules/cash-register/templates/CrudRegister'
import { loadScript } from 'vue-plugin-load-script'
import { printerInit } from '@/libs/bixolon/printer'
import { initPrintManager } from '@/libs/jsPrintManager/utils'
import { clone } from '@/plugins/utils'
export default {
  components: {
    CashRegister, PanelRegister, PanelRegisterWide, BottomUpRegister, CrudRegister
  },
  mixins: [registerCommons],
  data () {
    return {
      registerType: null,
      selectedPrinter: null,
      allPrinters: [],
      classicPrinters: [
        { name: 'POS printer 58mm (T58)', printer_name: 'T58', id: 'T58', library: 'NONE', width: 'T58' },
        { name: 'POS printer 80mm (T80)', printer_name: 'T80', id: 'T80', library: 'NONE', width: 'T80' },
        { name: 'Klasični printer (A4)', printer_name: 'A4', id: 'A4', library: 'NONE', width: 'A4' }
      ]
    }
  },
  methods: {
    async setRegisterTemplate () {
      state.resetRegisterType()
      const register = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      if (register && register.data()) {
        const doc = register.data()
        if (doc && doc.register_template) {
          state.setRegisterType(doc.register_template)
        } else {
          state.setRegisterType('ai-crud')
        }
      } else {
        state.setRegisterType('ai-crud')
      }
      // HARDCODED FOR DEVELOPMENT //
      // this.registerType = 'crud'
      this.registerType = state.getRegisterType()
    },
    async setupPrinter () {
      const selectedPrinter = await df.doc(`location_printers/${state.getPointOfSale().id}/printers/${state.getCashRegister().printer}`).get()
      this.selectedPrinter = selectedPrinter.data()
      if (this.selectedPrinter && this.selectedPrinter.library) {
        switch (this.selectedPrinter.library) {
          case 'UNIVERSAL':
          // Printer koristi jsPrintManager library
            state.setPrinterLib('UNIVERSAL')
            break
          case 'BIXOLON':
          // Printer koristi bixolon library
            state.setPrinterLib('BIXOLON80')
            break
          case 'NONE':
          // Printer ne koristi nikakav vanjski library (A4, T80, T58)
            state.setPrinterLib('NONE')
            break
          default:
            break
        }
      } else if (['A4', 'T80', 'T58'].includes(state.getCashRegister().printer)) {
        state.setPrinterLib('NONE')
        const classicPrinter = this.classicPrinters.find((printer) => printer.printer_name === state.getCashRegister().printer)
        this.selectedPrinter = classicPrinter
      } else {
      // Printer iz stare konfiguracije nema unesen library
      // Podrazumijeva se da koristi bixolon library
        state.setPrinterLib('BIXOLON80')
      }
      state.setSelectedPrinter(this.selectedPrinter)

      EventBus.$emit('set-selected-printer', this.selectedPrinter)
      this.initSelectedPrinter()
    },
    initSelectedPrinter () {
      if (state.getPrinterLib() === 'BIXOLON80') {
        const issueID = 1
        printerInit(issueID)
        state.setPosPrinterSettings(2)
      } else if (state.getPrinterLib() === 'UNIVERSAL') {
        state.setPosPrinterSettings('A')
        initPrintManager()
      }
    },
    async getAllPrinters () {
      const printers = []
      const query = df.collection(`location_printers/${state.getPointOfSale().id}/printers`)
      query.get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((item) => {
              printers.push(item.data())
            })
            this.allPrinters = clone([...printers, ...this.classicPrinters].sort((a, b) => a.name.localeCompare(b.name)))
            state.setInstalledPrinters(this.allPrinters)
          }
        })
    }
  },
  async created () {
    this.setRegisterTemplate()
    this.setupPrinter()

    const templateConfig = await this.getTemplateConfiguration('bottom-up')
    state.setTemplateConfig(templateConfig)

    await this.getAllPrinters()

    EventBus.$on('change-register-template', () => {
      this.registerType = state.getRegisterType()
    })
  },

  async mounted () {
    loadScript('https://firebasestorage.googleapis.com/v0/b/ai-fisk-stage/o/javascript%2Fcptable.js?alt=media&token=2bbbaafa-5866-4acd-8e7f-7ad119399288')
      .then(() => {
        loadScript('https://firebasestorage.googleapis.com/v0/b/ai-fisk-stage/o/javascript%2Fcputils.js?alt=media&token=aa7abbec-5afa-43d1-b052-e0df13527090')
          .then(() => {
            loadScript('https://firebasestorage.googleapis.com/v0/b/ai-fisk-stage/o/javascript%2FJSESCPOSBuilder.js?alt=media&token=711f55f8-3128-473c-9e83-52c472ac0a42')
              .catch(() => {
                console.error('JSESCPOSBuilder.js failed to load.')
              })
          })
          .catch(() => {
            console.error('cputils.js failed to load.')
          })
      })
      .catch(() => {
        console.error('cptable.js failed to load.')
      })
  }
}
</script>
