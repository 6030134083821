<template>
  <v-form>
    <v-card flat class="product-container">
      <v-card-text>
        <v-row>
          <v-col cols="9" md="6" lg="5" xl="4">
            <v-menu
              offset-y
              v-model="dateSearchMenu"
              :close-on-content-click="false"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-on="on"
                  v-bind="attrs"
                  clearable
                  @click:clear="getData(true)"
                  :value="closingBalanceStructure.view"
                  :label="$t('$vuetify.cashRegister.closingBalanceSearch')"
                  dense
                  autocomplete="off"
                  prepend-icon="mdi-clock-time-four-outline"
                  @click:prepend="dateSearchMenu = !dateSearchMenu"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="closingBalanceDate"
                locale="hr"
                no-title
                scrollable
                @input="dateSearchMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-expansion-panels v-if="balances && balances.length > 0">
          <v-expansion-panel v-for="(item, i) in balances" :key="i">
            <v-expansion-panel-header>
              <v-row>
                <v-col>
                  <span>Blagajnu zaključio: </span>
                  <div class="mt-2 font-weight-bold">
                    {{
                      $options.filters.capitalize(
                        item.balanceClosingRequestedBy
                      )
                    }}
                  </div>
                </v-col>
                <v-col>
                  <span>Dana: </span
                  ><span class="font-weight-bold">{{
                    item.to | fullDateTime
                  }}</span>
                </v-col>
                <v-col>
                  <span>Utržak: </span
                  ><span class="font-weight-bold">{{
                    item.to > 1672527599
                      ? $options.filters.money(item.available_funds, 100, "EUR")
                      : $options.filters.money(item.available_funds, 100, "HRK")
                  }}</span>
                </v-col>
                <v-col cols="1">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @keypress.enter.stop=""
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item
                        v-for="(it, index) in item.options"
                        :key="index"
                        style="cursor: pointer"
                        @click="`${it.action(item.printRequestData)}`"
                        @keypress.enter.prevent="
                          `${it.action(item.printRequestData)}`
                        "
                      >
                        <v-list-item-title>{{ it.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <!-- <span>Preuzmi </span
                  ><a
                    href=""
                    @click.prevent.stop="
                      downloadClosingBalance(item.printRequestData)
                    "
                    >zaključak</a
                  > -->
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- <v-row>
                <v-col>
                  <div>
                    Najveća količina novca na blagajni u trenutku izdavanja zaključka: <span class="font-weight-bold">{{item.maxHoldingAmt  | money(100, 'EUR')}}</span>
                  </div>
                </v-col>
              </v-row> -->
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <div class="pt-0 mt-0 pb-0 mb-0">
                    Novčanice:
                    <span class="font-weight-bold">{{
                      item.to > 1672527599
                        ? $options.filters.money(
                            item.available_funds,
                            100,
                            "EUR"
                          )
                        : $options.filters.money(
                            item.available_funds,
                            100,
                            "HRK"
                          )
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <div class="pt-0 mt-0 pb-0 mb-0">
                    Kartice:
                    <span class="font-weight-bold">{{
                      item.to > 1672527599
                        ? $options.filters.money(item.card_funds, 100, "EUR")
                        : $options.filters.money(item.card_funds, 100, "HRK")
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <div class="pt-0 mt-0 pb-0 mb-0">
                    Ostalo:
                    <span class="font-weight-bold">{{
                      item.to > 1672527599
                        ? $options.filters.money(item.other_funds, 100, "EUR")
                        : $options.filters.money(item.other_funds, 100, "HRK")
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <div class="pt-0 mt-0 pb-0 mb-0">
                    Polog na blagajni u trenutku izdavanja zaključka:
                    <span class="font-weight-bold">{{
                      item.to > 1672527599
                        ? $options.filters.money(item.depositAmt, 100, "EUR")
                        : $options.filters.money(item.depositAmt, 100, "HRK")
                    }}</span>
                  </div>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col>
                  <div>
                    Ukupan preostali iznos u trenutku izdavanja zaključka: <span class="font-weight-bold">{{item.cashLeft  | money(100, currency)}}</span>
                  </div>
                </v-col>
              </v-row> -->
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <div class="pt-0 mt-0 pb-0 mb-0">
                    Datum prethodnog zaključka:
                    <span class="font-weight-bold">{{
                      item.from | fullDateTime
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-divider class="pb-2 mt-2"></v-divider>
              <!-- <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <div class="pt-0 mt-0 pb-0 mb-0">
                    Iznos novca za polaganje u banku:
                    <span class="font-weight-bold"
                      >{{
                        item.balance_data && item.balance_data.withdraw_amount
                          ? $options.filters.money(
                              item.balance_data.withdraw_amount,
                              100,
                              currency
                            )
                          : $options.filters.money(0, 1, currency)
                      }}
                    </span>
                  </div>
                </v-col>
              </v-row> -->

              <!-- <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <div class="pt-0 mt-0 pb-0 mb-0">
                    Zaključak blagajne:
                    <span class="font-weight-bold"
                      >{{
                        item.balance_data &&
                        item.balance_data.closing_balance_amount
                          ? $options.filters.money(
                              item.balance_data.closing_balance_amount,
                              100,
                              currency
                            )
                          : $options.filters.money(0, 1, currency)
                      }}
                    </span>
                  </div>
                </v-col>
              </v-row> -->

              <v-row
                v-if="
                  item.balance_data &&
                  item.balance_data.incoming_previous_amount &&
                  item.balance_data.incoming_previous_amount > 0
                "
                class="pt-0 mt-0 pb-0 mb-0"
              >
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <div class="pt-0 mt-0 pb-0 mb-0">
                    Ostatak utržka:
                    <span class="font-weight-bold">{{
                      item.balance_data &&
                      item.balance_data.incoming_previous_amount
                        ? $options.filters.money(
                            item.balance_data.incoming_previous_amount,
                            100,
                            currency
                          )
                        : $options.filters.money(0, 1, currency)
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <div class="pt-0 mt-0 pb-0 mb-0">
                    Prethodni ostatak utržka:
                    <span class="font-weight-bold">{{
                      item.balance_data &&
                      item.balance_data.current_previous_amount
                        ? $options.filters.money(
                            item.balance_data.current_previous_amount,
                            100,
                            currency
                          )
                        : $options.filters.money(0, 1, currency)
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <div class="pt-0 mt-0 pb-0 mb-0">
                    Novi ostatak utržka:
                    <span class="font-weight-bold">{{
                      item.balance_data &&
                      item.balance_data.total_previous_amount
                        ? $options.filters.money(
                            item.balance_data.total_previous_amount,
                            100,
                            currency
                          )
                        : $options.filters.money(0, 1, currency)
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-2 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <div class="pt-0 mt-0 pb-0 mb-0">
                    Iznos novca položenog u banku:
                    <span class="font-weight-bold">{{
                      item.balance_data && item.balance_data.withdraw_amount
                        ? $options.filters.money(
                            item.balance_data.withdraw_amount,
                            100,
                            currency
                          )
                        : $options.filters.money(0, 1, currency)
                    }}</span>
                  </div>
                  <div class="font-italic">
                    Utržak (Novčanice)
                    {{
                      $options.filters.money(
                        item.utrzakNovcanice,
                        100,
                        currency
                      )
                    }}
                    + Prethodni ostatak utržka:
                    {{
                      $options.filters.money(
                        item.prethodniOstatak,
                        100,
                        currency
                      )
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-row v-else>
          <p class="text--secondary" style="margin-top: 50px">
            Ne postoje zaključci po odabranim kriterijama
          </p>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>
<script>
import { toISOLocal, duck } from '@/plugins/utils'
import state from '@/state'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import { v4 as uuidv4 } from 'uuid'
import { auth, df } from '@/plugins/firebase'
import EventBus from '@/plugins/event-bus'
import { closingBalance } from '@/libs/closingBalance/closingBalance.js'

export default {
  name: 'ClosingBalanceList',
  inject: ['showMsgBox', 'openPdfPrint'],
  mixins: [applicationSettings],
  data: function () {
    return {
      selectedDateStartTime: 0,
      selectedDateEndTime: 0,
      balances: [],
      closingBalanceDate: '',
      dateSearchMenu: false,
      currency: 'EUR',
      selectedPrinter: {}
    }
  },
  computed: {
    closingBalanceStructure () {
      if (!this.closingBalanceDate) return {}

      const dateWords = this.closingBalanceDate.split('-')

      return {
        view: new Date(
          toISOLocal(
            new Date(new Date(this.closingBalanceDate).getTime())
          ).substr(0, 10)
        ).toLocaleDateString('hr-HR'),
        from: parseInt(
          new Date(dateWords[0], (parseInt(dateWords[1]) - 1).toString(), dateWords[2], 0, 0, 0).getTime() / 1000
        ),
        to: parseInt(
          new Date(dateWords[0], (parseInt(dateWords[1]) - 1).toString(), dateWords[2], 23, 59, 0).getTime() / 1000
        )
      }
    }
  },
  watch: {
    closingBalanceStructure: {
      deep: true,
      handler: function () {
        this.getData()
      }
    }
  },
  beforeDestroy () {
    this.detachListeners()
  },
  mounted () {
    this.selectedPrinter = state.getSelectedPrinter()
    EventBus.$on('set-selected-printer', (printer) => {
      this.selectedPrinter = printer
    })
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }
    this.getData()
  },
  methods: {
    getData (reset = false) {
      let query = df
        .collection(`cash_registers/${state.getCashRegister().id}/balances`)
        .orderBy('to', 'desc')
        .limit(10)

      if (!duck(this.closingBalanceStructure, {}) && !reset) {
        query = query
          .where('to', '>=', this.closingBalanceStructure.from)
          .where('to', '<=', this.closingBalanceStructure.to)
      }

      this.$emit('setPrintState', true)
      query
        .get()
        .then((doc) => {
          const document = doc
          this.balances = []
          if (!document || document.empty) return
          document.docs.forEach((doc) => {
            const docData = doc.data()
            if (!docData) return
            docData.balanceClosingRequestedBy = `${
              docData.requested_by?.name || ''
            } ${docData.requested_by?.surname || ''}`
            docData.available_funds =
              docData.cash_register?.available_funds || 0
            docData.other_funds =
              docData.cash_register?.other_funds || 0
            docData.card_funds = docData.cash_register?.card_funds || 0
            docData.cashLeft =
              (docData.cash_register?.max_holding_amt || 0) -
              docData.available_funds -
              (docData.deposit?.amt || 0)
            docData.depositAmt = docData.deposit?.amt || 0
            docData.maxHoldingAmt = docData.cash_register?.max_holding_amt || 0
            docData.withdrawAmount = docData.balance_data?.withdraw_amount || 0

            if (docData.withdrawAmount === 0) {
              docData.utrzakNovcanice = 0
              docData.prethodniOstatak = 0
            }

            docData.prethodniOstatak = docData.balance_data &&
            docData.balance_data.current_previous_amount && docData.withdrawAmount > 0
              ? docData.balance_data.current_previous_amount : 0

            docData.utrzakNovcanice = docData.available_funds

            if (docData.withdrawAmount <= docData.available_funds) {
              docData.utrzakNovcanice = docData.withdrawAmount
              docData.prethodniOstatak = 0
            }

            if (docData.withdrawAmount > docData.available_funds) {
              docData.prethodniOstatak = docData.withdrawAmount - docData.available_funds
            }

            if (docData.cash_register) {
              docData.printRequestData = {
                action: {
                  operation: 'get',
                  entity: 'cashier_balance',
                  resource_id: docData.id,
                  params: {
                    cash_register_id: docData.cash_register.id,
                    company_id: state.getCurrentCompany().id,
                    print_receipts: true,
                    print_size: state.getPrinter()
                  }
                }
              }
            }

            docData.options = [{ title: 'Preuzmi zaključak', action: this.downloadClosingBalance },
              { title: 'Isprintaj zaključak', action: this.printClosingBalance }
            ]
            this.balances.push(docData)
          })
        })
        .finally(() => {
          this.$emit('setPrintState', false)
        })
    },
    printClosingBalance (payload = undefined) {
      this.$emit('setPrintState', true)
      if (['T58', 'T80', 'A4'].includes(this.selectedPrinter.width)) {
        this.showMsgBox({
          text: 'Nije odabran printer',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      } else {
        this.printLocal(payload.action.resource_id)
      }
    },
    downloadClosingBalance (payload = undefined) {
      if (!payload) {
        this.showMsgBox({
          text: 'Ne postoje podaci o zaključku',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
        return
      }
      try {
        const reqId = uuidv4()
        payload.action.params = {
          ...payload.action.params,
          print_size: state.getPrinter()
        }
        this.$emit('setPrintState', true)

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'document',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .catch((err) => {
            this.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })
          })
        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot((doc) => {
          const data = doc.data()
          if (data) {
            unsubscribe()
            this.$emit('setPrintState', false)

            if (!data.body || !data.body.document) {
              this.showMsgBox({
                text: this.$t('$vuetify.errors.nullClosingBalance'),
                actions: ['cancel'],
                cancelBtnText: this.$t('$vuetify.close'),
                color: 'error'
              })
              return
            }
            this.openPdfPrint(data.body.document)
          }
        })
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      }
    },
    async printLocal (balanceId) {
      const response = await df.doc(`cash_registers/${state.getCashRegister().id}/balances/${balanceId}`).get()
      if (response && response.data()) {
        var closeBalance = response.data()
        closingBalance(closeBalance, this.selectedPrinter)
        this.$emit('setPrintState', false)
      }
    }
  }
}
</script>
<style scoped>
.product-container {
  height: 50vh; /* limit it to the size of your window */
  overflow-y: auto; /* add scroll when necessary */
}
</style>
