import state from '@/state'
import { df } from '@/plugins/firebase'
import EventBus from '@/plugins/event-bus'
import defaultTemplateConfiguration from '@/assets/templates/defaultTemplateConfiguration.json'

export default {
  methods: {
    async emptyTable (table) {
      const docRef = df.doc(`location_orders/${state.getPointOfSale().id}/tables/${table.id}`)
      try {
        await docRef.set(
          {
            id: table.id,
            name: table.name,
            number: table.number,
            items: [],
            persistent: table.persistent,
            color: 'primary',
            locked: false,
            locked_by: '',
            mark_for_delete: true,
            update_time: 0,
            user_id: '',
            user_name: ''
          }
        )
        console.info('Table successfully emptied.')
      } catch (err) {
        console.error('Error saving table: ', err)
      }
    },
    async deleteTable (table) {
      const docRef = df.doc(`location_orders/${state.getPointOfSale().id}/tables/${table.id}`)
      try {
        await docRef.delete()
        console.info('Table successfully deleted.')
      } catch (err) {
        console.error('Error deleting table: ', err)
      }
    },

    async getTemplateConfiguration (templateName) {
      const templateConfig = await df.doc(`cash_registers/${state.getCashRegister().id}/register_template_options/${templateName}`).get()
      if (templateConfig && templateConfig.data()) {
        const doc = templateConfig.data()
        if (doc) {
          return doc
        } else {
          return null
        }
      } else {
        // TODO: defaultna konfiguracija ide ovdje
        return defaultTemplateConfiguration
      }
    }
  },
  mounted () {
    EventBus.$on('empty-table', async (table) => {
      await this.emptyTable(table)
    })

    EventBus.$on('delete-table', async (table) => {
      await this.deleteTable(table)
    })
  }
}
