<template>
  <div>
    <v-dialog
      v-model="dialogOpen"
      v-if="dialogOpen"
      content-class="my-custom-dialog"
      persistent
      scrollable
      max-width="60%"
      @keydown.esc="close"
    >
      <v-card tile color="white">
        <v-card-title>
          <h2 class="text--secondary">
            {{ $t("$vuetify.cashRegister.search") }}
          </h2>
          <v-spacer></v-spacer>
          <v-icon large @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="white">
          <v-progress-linear v-if="!loaded" indeterminate></v-progress-linear>
          <v-container class="panel-container">
            <v-row class="panel-row">
              <v-col cols="12">
                <v-text-field
                  autofocus
                  ref="defInput"
                  v-model="searchText"
                  label="Pretraži artikle po nazivu ili šifri"
                  data-layout="normalAduro"
                  append-icon="mdi-magnify"
                  clearable
                  @click="showKbd"
                  @keydown.enter="searchProducts()"
                  @click:append="searchProducts()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="panel-row">
              <v-col
                v-for="(item, idxc) in cols"
                :key="idxc"
                :cols="4"
                class="panel-cell"
                align="center"
              >
                <v-btn
                  style="border-radius: 12px; display: inline-block"
                  block
                  tile
                  depressed
                  min-height="6rem"
                  :color="item.color"
                  class=""
                  return-object
                  @click="onCellClick(item)"
                >
                  <span class="white--text tile">{{ item.label }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container
      class="kbd"
      v-if="!$vuetify.breakpoint.smAndDown"
      ref="keyboard"
      v-show="oskVisible"
      :style="{ 'max-width': width }"
    >
      <div class="kbd-header"></div>
      <vue-touch-keyboard
        :options="oskOptions"
        :layout="layout"
        :cancel="hideKbd"
        :accept="accept"
        :input="input"
      />
    </v-container>
  </div>
</template>

<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import { clone } from '@/plugins/utils'
import onScreenKeyboard from '@/mixins/onScreenKeyboard'
import { collection, query, getDocs, where, limit } from 'firebase/firestore'
import triGram from '@/mixins/trigram'

export default {
  mixins: [onScreenKeyboard, triGram],
  data () {
    return {
      dialogOpen: true,
      products: [],
      cols: [],
      searchText: '',
      priceListItems: [],
      loaded: true,
      documents: [],
      items: [],
      menuItems: [],
      currency: 'EUR'
    }
  },
  watch: {
    searchText (nv) {
      if (!nv || nv === null || nv === '') {
        this.priceListItems = []
        this.cols = []
        this.$forceUpdate()
      }
    }
  },
  methods: {
    onCellClick (i) {
      const item = this.cols.find(it => it.id === i.id)
      this.$emit('addProduct', item)
      this.$emit('closeSearchItemsDialog')
    },

    close () {
      this.$emit('closeSearchItemsDialog')
    },

    async searchProducts () {
      this.loaded = false
      this.priceListItems = []
      this.cols = []

      if (!this.searchText || this.searchText === '' || this.searchText === null) {
        this.$forceUpdate()
        this.loaded = true
        return
      }

      var trigramObject = this.triGram(this.searchText)
      var items = await this.getItems(trigramObject, 'items')
      var menuItems = await this.getItems(trigramObject, 'menu')

      if (menuItems && menuItems.length > 0) {
        items.push(...menuItems)
      }

      if (items && items.length > 0) {
        items.forEach(product => {
          if (product.warehouse_units && product.warehouse_units.units) {
            Object.keys(product.warehouse_units.units).forEach(key => {
              if (product.warehouse_units.units[key].prices && product.warehouse_units.units[key].prices[this.currency].active === true) {
                let newProduct = {}
                newProduct = clone(product)
                newProduct.prices = product.warehouse_units.units[key].prices

                var stUnit = product.warehouse_units.units[key].storage_unit
                if (stUnit === '.kom') {
                  stUnit = ''
                }
                if (product.warehouse_units.units[key].base_unit === true) {
                  stUnit = product.selling_unit
                }
                newProduct.name = product.name + ' ' + stUnit
                newProduct.id = newProduct.id + '.' + product.warehouse_units.units[key].id
                this.cols.push({ type: newProduct.type, color: '#2C5A90', id: newProduct.id, label: newProduct.name })
              }
            })
          } else {
            this.cols.push({ type: product.type, color: '#2C5A90', id: product.id, label: product.name })
          }
        })
      }

      this.loaded = true
    },

    async getItems (triGramObject = undefined, type = 'items') {
      var searchCollection = 'location_pricelist'
      if (type === 'menu') {
        searchCollection = 'location_menu_pricelist'
      }
      const searchConstraints = []
      if (triGramObject) {
        Object.keys(triGramObject).forEach(name =>
          searchConstraints.push(where(`meta.${name}`, '==', true))
        )
      }

      searchConstraints.push(limit(20))

      searchConstraints.push(where('status', '==', 'OK'))
      searchConstraints.push(where('active', '==', true))

      const constraints = [
        collection(df, `${searchCollection}/${state.getPointOfSale().id}/items`),
        ...searchConstraints
      ]

      const q = query.apply(this, constraints)

      const querySnapshot = await getDocs(q)

      const results = []
      querySnapshot.forEach(doc => {
        var item = doc.data()
        if (type === 'menu') {
          item.type = 'menu-product'
        }
        results.push({ _id: doc.id, ...item })
      }
      )

      return results
    }
  },
  mounted () {
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }

    this.setEnterAction(this.searchProducts)
    this.setPosition(this.$refs.keyboard, '217px', '502px')
    const ref = this.$refs.defInput.$refs.input
    this.showKbdOnMount(ref, 'normalAduro')
  }
}
</script>

<style scoped>
.v-dialog__content >>> .my-custom-dialog {
  position: absolute;
  top: 50px;
  border-radius: 25px;
}
.panel-container {
  margin-top: 1rem;
}
.text-wrap {
  width: min-content;
  margin: auto;
}
.panel-cell {
  padding: 0 1px 1px 0;
}
.active {
  background-color: var(--v-accent-darken3);
  color: white;
}
.label {
  font-size: 0.6rem;
  color: white;
}
span.tile {
  display: block;
  white-space: normal;
  max-width: 330px;
  font-weight: 700;
  font-size: 1.4rem;
}
</style>
