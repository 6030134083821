<template>
  <v-dialog
    v-model="dialogOpen"
    v-if="dialogOpen"
    content-class="my-custom-dialog"
    transition="dialog-top-transition"
    persistent
    max-width="630"
    @keydown.esc="close"
  >
    <!-- BUG: if you put the border around the card, the border itself won't be visible, but the bottom of the card will not be transparent -->
    <v-card tile color="white" style="border: 1px solid white">
      <v-form ref="addTable" @submit.prevent="addTable">
        <v-card-title>
          <h2>Dodaj stol</h2>
        </v-card-title>
        <v-card-text class="white">
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="newTable.name"
                  placeholder="Naziv"
                  :rules="[rules.req, rules.nameValid]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  align-self="end"
                  v-model="persist"
                  label="Zadrži stol"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="white">
          <v-spacer></v-spacer>
          <v-btn class="white--text okButton" height="45" type="submit">
            Dodaj stol
          </v-btn>
          <v-btn class="white--text okButton" height="45" @click="close">
            Odustani
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/plugins/rules'
export default {
  inject: ['showMsgBox'],
  data () {
    return {
      dialogOpen: true,
      name: '',
      persist: false,
      newTable: {},
      rules: {
        req: rules.req(),
        nameValid: rules.nameValid()
      }
    }
  },
  props: {
    table: {
      type: Object,
      default: () => {}
    },
    tables: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    close () {
      this.$emit('closeAddTableDialog')
    },
    addTable () {
      if (!this.$refs.addTable.validate()) return
      if (this.isUnique()) {
        this.$emit('addTable', this.newTable, this.persist)
      } else {
        this.showMsgBox({ text: `Već postoji stol s imenom ${this.newTable.name}. Odaberi jedinstveno ime.`, actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
      }
    },
    isUnique () {
      return !this.tables.some(t => t.name === this.newTable.name)
    }
  },

  created () {
    this.newTable = { ...this.table }
  }
}
</script>

<style>
</style>
