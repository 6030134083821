<template>
  <div>
    <v-row v-if="showComponent" id="container" style="margin-top: 1.2rem">
      <v-col
        xl="3"
        lg="4"
        md="6"
        sm="12"
        xs="12"
        v-for="(table, idxc) in selectedTables"
        :key="idxc"
        class="panel-cell"
        align="center"
      >
        <div
          :ref="table.id"
          :key="timerCount"
          :class="
            showWaitingTime(table.update_time, table.id, true) < 1800000
              ? 'notification-green'
              : showWaitingTime(table.update_time, table.id, true) < 3600000
              ? 'notification-yellow'
              : 'notification-red'
          "
        >
          {{ showWaitingTime(table.update_time, table.id) }}
        </div>
        <v-btn
          :style="
            tableLocked(table)
              ? 'background-color: lightgrey'
              : `background-color: ${table.color}`
          "
          block
          tile
          depressed
          min-height="3.5rem"
          :color="table.color"
          return-object
          @click="onTableCellClick(table)"
        >
          <span class="white--text tile btn-md">{{ table.name }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <tables-dialog
      v-if="addTableDialogOpen"
      :availableTables="availableTables"
      @addTable="onAddTable"
      @selectTable="onSelectTable"
      @saveTable="saveTable"
      @deleteTable="onDeleteTable"
      @closeTablesDialog="onCloseTablesDialog"
    ></tables-dialog>
  </div>
</template>

<script>

import rules from '@/plugins/rules'
import state from '@/state'
import { df } from '@/plugins/firebase'
import { toISOLocal, clone } from '@/plugins/utils'
import TablesDialog from '@/modules/cash-register/components/dialogs/TablesDialog.vue'
import { printOrder } from '@/libs/bixolon/order'
import Table from '@/model/Table'
import { v4 as uuidv4 } from 'uuid'
import EventBus from '@/plugins/event-bus'

export default {
  components: {
    TablesDialog
  },
  inject: ['showMsgBox', 'confirm', 'confirmClose', 'showLoader', 'hideLoader', 'openPdfPrint'],
  data: function () {
    return {
      showComponent: false,
      data: {},
      selectedPrinter: null,
      timerCount: 0,
      user: undefined,
      manager: false,
      userId: undefined,
      r1DialogOpen: false,
      addTableDialogOpen: false,
      note: '',
      languages: [],
      language: undefined,
      receiptNumber: 1,
      bill_sequence: 0,
      offer_sequence: 0,
      last_receipt: '',
      receiptType: '',
      issueDate: '',
      minIssueDate: '',
      maxIssueDate: '',
      rules: {
        req: rules.req(),
        oib: rules.oib(),
        gt: rules.gt,
        arrReq: rules.arrReq()
      },
      listeners: [],
      type: '',
      paymentMethod: '',
      editMode: false,
      ordersView: false,
      selectedTable: {},
      selectedTables: [],
      availableTables: [],
      // destinationTable: {},
      initialTable: {},
      printers: [],
      orgUnits: [],
      selectedCard: undefined,
      tableNameSuffix: 1,
      templateConfig: {
        tables: {
          show: false
        },
        toolbar: {
          buttons: [],
          submenu_options: []
        }
      },
      receiptNote: null
    }
  },
  computed: {
  },

  watch: {
    editMode: {
      handler (nv, ov) {
      }
    },
    selectedTable: {
      immediate: true,
      deep: true,
      handler (newValue, _) {
        EventBus.$emit('update-table-selection', newValue)
      }
    },
    selectedTables: {
      handler (selectedTables) {
        clearInterval(this._updateTimer)
        if (selectedTables.length) {
          this._updateTimer = setInterval(() => this.timerCount++, 1000)
        }
      },
      immediate: true
    },
    beforeUnmount () {
      clearInterval(this._updateTimer)
    },
    // Vue 2.x equivalent of beforeUnmount
    beforeDestroy () {
      clearInterval(this._updateTimer)
    }
  },
  methods: {
    showWaitingTime (updateDate, tableId, mode = false) {
      const diff = new Date() - new Date(updateDate)
      const twoD = x => `${Math.floor(x)}`.padStart(2, '0')
      const HH = twoD((diff / (60 * 60 * 1000)) % 24)
      const mm = twoD((diff / (60 * 1000)) % 60)
      const ss = twoD((diff / 1000) % 60)
      if (mode) return diff
      else { return `${HH}:${mm}:${ss}` }
    },

    // From split receipt
    async onCreateNewTable (sourceTable, destinationTable) {
      this.selectedTable = clone(sourceTable)
      this.destinationTable = clone(destinationTable)

      await this.updateTable(this.selectedTable)
      await this.addSplitTable(this.destinationTable, this.selectedTable)

      this.selectedTable = new Table()
      EventBus.$emit('clear-selection')
    },

    async onAddItemsToTable (sourceTable, targetTable) {
      const srcTbl = this.selectedTables.find(t => t.id === sourceTable.id)
      const tgtTbl = this.selectedTables.find(t => t.id === targetTable.id)

      srcTbl.items = []
      tgtTbl.items = []

      sourceTable.items.forEach(item => {
        srcTbl.items.push(item)
      })
      targetTable.items.forEach(item => {
        tgtTbl.items.push(item)
      })

      await this.updateTable(srcTbl)
      await this.updateTable(tgtTbl)

      this.onNewOrder()
    },
    tableLocked (table) {
      return !this.editMode && table.locked && table.locked_by !== state.getUser().id
    },
    switchLocks (newSelectedTable, oldSelectedTable) {
      this.lock(newSelectedTable)

      if (oldSelectedTable.id) {
        this.unlock(oldSelectedTable)
      } else {
        this.selectedTable = newSelectedTable
      }
    },
    async lock (newSelectedTable) {
      const me = this

      const newUpdateArgs = { locked: true, color: '#c02dd8', locked_by: state.getUser().id }
      df.doc(`location_orders/${state.getPointOfSale().id}/tables/${newSelectedTable.id}`).update(newUpdateArgs)
        .then(() => {
        }).catch((err) => {
          console.error(err)
          me.showMsgBox({ text: 'Pogreška prilikom zaključavanja', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        })
      this.selectedTable = newSelectedTable

      EventBus.$emit('select-table', newSelectedTable) // to RegisterInvoice, onSelectTable. Includes recalculate
      // this.$emit('selectTable', newSelectedTable)
    },
    async unlock (oldSelectedTable) {
      const me = this

      const oldUpdateArgs = { locked: false, color: 'primary' }
      df.doc(`location_orders/${state.getPointOfSale().id}/tables/${oldSelectedTable.id}`).update(oldUpdateArgs)
        .then(() => {
        })
        .catch((err) => {
          console.error(err)
          me.showMsgBox({ text: 'Pogreška prilikom zaključavanja', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        })
    },
    onOpenTablesDialog () {
      if (!this.orderHasChanged()) {
        this.addTableDialogOpen = true
      } else {
        this.confirm({
          title: this.$t('$vuetify.tables.note'),
          message: this.$t('$vuetify.tables.noteMessage'),
          options: {
            toolbar: true,
            width: 410,
            confirmText: this.$t('$vuetify.tables.continue'),
            cancelText: this.$t('$vuetify.tables.cancel')
          }
        }).then(async (resp) => {
          if (resp) {
            this.addTableDialogOpen = true
          }
          this.confirmClose()
        })
      }
    },
    onCloseTablesDialog () {
      this.addTableDialogOpen = false
    },
    onAddTable (table) {
      if (this.selectedTable.id) {
        // Stol je odabran, ne spremati
        this.selectedTable = table
        this.initialTable = clone(this.selectedTable)
        this.clearOrderFromScreen()
        EventBus.$emit('select-table', table) // to RegisterInvoice, onSelectTable. Includes recalculate
        // this.$emit('selectTable', table)
        // this.$emit('recalculate')
      } else {
        // Stol nije odabran
        if (this.selectedTable.number !== 0) {
          const oldTb = clone(table)
          const newTb = clone(this.selectedTable)
          this.switchLocks(oldTb, newTb)
          this.initialTable = clone(newTb)
        } else {
          this.selectedTable = table
          this.data.items.forEach(item => {
            this.selectedTable.items.push(item)
          })
          this.initialTable = clone(this.selectedTable)
          EventBus.$emit('select-table', table) // to RegisterInvoice, onSelectTable. Includes recalculate
          // this.$emit('selectTable', table)
        }
        this.onSaveItemsToTable()
      }
    },
    onSelectTable (table) {
      if (this.data && this.data.items && this.data.items.length > 0) {
        this.selectedTable.items = [...this.data.items]
      }

      if (table.number === this.selectedTable.number) {
        this.selectedTable = table
        this.data.items = [...this.selectedTable.items]
        this.initialTable = clone(table)

        EventBus.$emit('select-table', table) // to RegisterInvoice, onSelectTable. Includes recalculate

        // this.$emit('selectTable', table) // PanelRows -> RegisterInvoice
        // this.$emit('recalculate') // PanelRows
      } else {
        const oldTb = clone(table)
        const newTb = clone(this.selectedTable)
        this.switchLocks(oldTb, newTb)
        this.initialTable = clone(table)

        EventBus.$emit('select-table', table) // to RegisterInvoice, onSelectTable. Includes recalculate

        // this.$emit('selectTable', table) // PanelRows
        // this.$emit('recalculate') // PanelRows
      }
    },

    getOrder () {
      const orderId = uuidv4()
      const orderItems = []

      this.selectedTable.items.forEach((item, idx) => {
        let found = false
        if (this.selectedTable.items[idx].notes) {
          if (this.selectedTable.items[idx].notes[this.selectedTable.items[idx].notes.length - 1].currentNote) {
            this.selectedTable.items[idx].notes[this.selectedTable.items[idx].notes.length - 1].orderId = orderId
            this.selectedTable.items[idx].notes[this.selectedTable.items[idx].notes.length - 1].currentNote = false
          }
        }

        if (this.initialTable && this.initialTable.items) {
          this.initialTable.items.forEach(initItem => {
            if (item.id === initItem.id) {
              found = true
              if (item.quantity !== initItem.quantity) {
                const diff = item.quantity - initItem.quantity
                const itemClone = clone(item)
                itemClone.quantity = diff

                if (item.newKeys && initItem.newKeys) {
                  const oldKeys = []
                  Object.keys(item.newKeys).forEach(nKey => {
                    Object.keys(initItem.newKeys).forEach(iKey => {
                      if (nKey === iKey) {
                        oldKeys.push(nKey)
                      }
                    })
                  })

                  if (oldKeys && oldKeys.length > 0) {
                    oldKeys.forEach(key => {
                      delete itemClone.choosenProductOrder[key]
                      delete itemClone.choosenTagOrder[key]
                    })
                  }
                }
                orderItems.push(itemClone)
              }
            }
          })
        }

        if (!found) {
          const itemClone = clone(item)
          orderItems.push(itemClone)
        }
      })

      const order = {
        id: orderId,
        order_number: this.selectedTable.orders && this.selectedTable.orders.length > 0 ? this.selectedTable.orders.length + 1 : 1,
        added_by_name: state.getCashRegisterUser() && state.getCashRegisterUser().name ? state.getCashRegisterUser().name + ' ' + state.getCashRegisterUser().surname : state.getUser().name + ' ' + state.getUser().surname,
        added_by_id: state.getCashRegisterUser() && state.getCashRegisterUser().id ? state.getCashRegisterUser().id : state.getUser().id,
        order_status: 'ORDERED',
        order_items: orderItems
      }

      return order
    },

    async onSaveItemsToTable () {
      if (this.data.items.length === 0) return
      this.showLoader('Spremam ' + this.selectedTable.name, { additionalTextTimer: 3500, additionalText: 'Oprostite što mi treba malo dulje, vaš internet se usporio.' })
      this.selectedTable.items = [...this.data.items]
      this.selectedTable.locked = true
      this.selectedTable.color = '#c02dd8'
      this.selectedTable.locked_by = state.getUser().id

      const order = this.getOrder()

      EventBus.$emit('send-notes', null, order) // to RegisterInvoice, onSendNotes
      // this.$emit('sendNotes', null, order)

      if (this.selectedTable.orders && this.selectedTable.orders.length > 0) {
        this.selectedTable.orders.push(order)
      } else {
        this.selectedTable.orders = [order]
      }

      this.saveItemsToDatabase(this.selectedTable, this.selectedTable.persistent)
      this.clearOrderFromScreen()
      if (!this.selectedTables.some(tbl => tbl.id === this.selectedTable.id)) {
        this.selectedTables.push(this.selectedTable)
      }

      // Dohvati prvi put
      let lastOrder = await this.getLastOrderFromDb(this.selectedTable.id, order.id)

      // Ako nema, dohvacaj 10 puta dok ne dobijes
      if (!lastOrder) {
        for (let i = 1; i <= 10; i++) {
          lastOrder = await this.getOrderWithTimeout(this.selectedTable.id, order.id)
          if (lastOrder !== undefined) break
        }
      }

      // Ako si uspio dohvatiti isprintaj sve i ocisti
      if (lastOrder) {
        this.prepareOrderForPrint(lastOrder, this.selectedTable)
        this.initialTable = clone(this.selectedTable)

        EventBus.$emit('recalculate') // to RegisterInvoice, recalculate
        this.onNewOrder()
      }

      if (!lastOrder) {
        this.confirm({
          title: 'Pogreška prilikom spremanja',
          message: 'Spremanje narudžbe nije uspjelo. Molimo ponovite.',
          options: {
            toolbar: true,
            width: 410,
            confirmText: 'Nastavi'
          }
        }).then((resp) => {
        })
      }

      EventBus.$emit('reset-notes') // to RegisterInvoice, onResetNotes
    },
    getOrderWithTimeout (tableId, orderId) {
      setTimeout(() => {
        return this.getLastOrderFromDb(tableId, orderId)
      }, 500)
    },
    async getLastOrderFromDb (tableId, orderId) {
      let dbOrder

      await df.doc(`location_orders/${state.getPointOfSale().id}/tables/${tableId}`)
        .get()
        .then((tableData) => {
          const tb = tableData.data()
          if (tb && tb.orders && tb.orders.length > 0) {
            tb.orders.forEach((or, index) => {
              if (or.id === orderId) {
                dbOrder = tb.orders[index]
              }
            })
          }
        })
      return dbOrder
    },
    async prepareOrderForPrint (order, table) {
      const itemsInOrgUnits = {}
      if (order && order.order_items && order.order_items.length > 0) {
        order.order_items.forEach(item => {
          if (item.organizational_unit_id && item.organizational_unit_id !== '') {
            if (!itemsInOrgUnits[item.organizational_unit_id]) {
              itemsInOrgUnits[item.organizational_unit_id] = []
            }
            itemsInOrgUnits[item.organizational_unit_id].push(item)
          }

          if (item.choosenProductOrder) {
            Object.keys(item.choosenProductOrder).forEach(key => {
              if (item.choosenProductOrder[key] && item.choosenProductOrder[key].length > 0) {
                item.choosenProductOrder[key].forEach(product => {
                  if (product && product.productItems) {
                    product.productItems.forEach(it => {
                      if (it.org_units && it.org_units[state.getPointOfSale().id]) {
                        if (item.choosenTagOrder[key]) {
                          it.tags = item.choosenTagOrder[key]
                        }
                        it.menuId = key
                        it.menuData = {
                          productName: product.productName,
                          id: item.id,
                          name: item.name
                        }

                        if (!itemsInOrgUnits[it.org_units[state.getPointOfSale().id]]) {
                          itemsInOrgUnits[it.org_units[state.getPointOfSale().id]] = []
                        }

                        itemsInOrgUnits[it.org_units[state.getPointOfSale().id]].push(it)
                      }
                    })
                  }
                })
              }
            })
          }
        })

        var otherUnits = []
        Object.keys(itemsInOrgUnits).forEach(unitId => {
          this.orgUnits.forEach(orgUnit => {
            if (orgUnit.id === unitId) {
              otherUnits.push(orgUnit)
            }
          })
        })

        Object.keys(itemsInOrgUnits).forEach(unitId => {
          this.orgUnits.forEach(orgUnit => {
            if (orgUnit.id === unitId) {
              this.printers.forEach(printer => {
                // TODO maknuti ovu provjeru sa name, to je izmjena u id
                if (printer.id === orgUnit.printer || printer.name === orgUnit.printer) {
                  this.printAndSaveItems(itemsInOrgUnits[unitId], printer, orgUnit, table, order, otherUnits)
                }
              })
            }
          })
        })
      }
    },
    async imidiatePrintPrepare (items) {
      const itemsInOrgUnits = {}
      if (items && items.length > 0) {
        items.forEach(item => {
          if (item.organizational_unit_id && item.organizational_unit_id !== '') {
            if (!itemsInOrgUnits[item.organizational_unit_id]) {
              itemsInOrgUnits[item.organizational_unit_id] = []
            }
            itemsInOrgUnits[item.organizational_unit_id].push(item)
          }

          if (item.choosenProductOrder) {
            Object.keys(item.choosenProductOrder).forEach(key => {
              if (item.choosenProductOrder[key] && item.choosenProductOrder[key].length > 0) {
                item.choosenProductOrder[key].forEach(product => {
                  if (product && product.productItems) {
                    product.productItems.forEach(it => {
                      if (it.org_units && it.org_units[state.getPointOfSale().id]) {
                        if (item.choosenTagOrder[key]) {
                          it.tags = item.choosenTagOrder[key]
                        }
                        it.menuId = key
                        it.menuData = {
                          id: item.id,
                          name: item.name
                        }

                        if (!itemsInOrgUnits[it.org_units[state.getPointOfSale().id]]) {
                          itemsInOrgUnits[it.org_units[state.getPointOfSale().id]] = []
                        }

                        itemsInOrgUnits[it.org_units[state.getPointOfSale().id]].push(it)
                      }
                    })
                  }
                })
              }
            })
          }
        })

        var otherUnits = []
        Object.keys(itemsInOrgUnits).forEach(unitId => {
          this.orgUnits.forEach(orgUnit => {
            if (orgUnit.id === unitId) {
              otherUnits.push(orgUnit)
            }
          })
        })

        Object.keys(itemsInOrgUnits).forEach(unitId => {
          this.orgUnits.forEach(orgUnit => {
            if (orgUnit.id === unitId) {
              this.printers.forEach(printer => {
                // TODO maknuti ovu provjeru sa name, to je izmjena u id
                if (printer.id === orgUnit.printer || printer.name === orgUnit.printer) {
                  this.imidiatePrint(itemsInOrgUnits[unitId], printer, orgUnit, otherUnits)
                }
              })
            }
          })
        })
      }
    },
    async printAndSaveItems (items, printer, orgUnit, table, order, otherUnits) {
      const orgUnitOrderId = uuidv4()
      const docRef = df.doc(`location_units/${state.getPointOfSale().id}/units/${orgUnit.id}/orders/${orgUnitOrderId}`)

      let note = null
      if (order.notes) {
        note = order.notes.find(n => n.unitId === orgUnit.id)
      }

      try {
        await docRef.set(
          {
            id: orgUnitOrderId,
            name: table && table.name ? table.name : 'Za van',
            number: order.order_number,
            order_added_by: state.getCashRegisterUser() ? state.getCashRegisterUser().name + ' ' + state.getCashRegisterUser().surname : state.getUser().name + ' ' + state.getUser().surname,
            items: items,
            printer_address: printer.ip_address
          }
        )
      } catch (err) {
        console.error('Error saving order: ', err)
      }

      state.setPosPrinterSettings(2)
      printOrder(items, printer, table, order, orgUnit, otherUnits, note, this.hideLoader)
    },
    async imidiatePrint (items, printer, orgUnit, otherUnits) {
      state.setPosPrinterSettings(2)
      printOrder(items, printer, undefined, undefined, orgUnit, otherUnits, undefined, this.hideLoader)
    },
    saveItemsToDatabase (table, persist) {
      this.saveTable(table, persist)
    },
    orderHasChanged () {
      if (this.selectedTable.id && this.data.items && this.data.items.length > 0) {
        let itemCountDifferent = false
        const lengthDifferent = this.selectedTable.items.length !== this.data.items.length
        for (let i = 0; i < this.selectedTable.items.length; i++) {
          const tableItem = this.selectedTable.items[i]
          const cartItem = this.data.items.find(i => i.id === tableItem.id)
          if (tableItem && cartItem && tableItem.quantity !== cartItem.quantity) {
            itemCountDifferent = true
            break
          }
        }
        return itemCountDifferent || lengthDifferent
      }
    },
    onTableCellClick (table) {
      if ((!this.selectedTable.id && this.data.items && this.data.items.length > 0) || this.orderHasChanged()) {
        this.confirm({
          title: this.$t('$vuetify.tables.note'),
          message: this.$t('$vuetify.tables.noteMessage'),
          options: {
            toolbar: true,
            width: 410,
            confirmText: this.$t('$vuetify.tables.continue'),
            cancelText: this.$t('$vuetify.tables.cancel')
          }
        }).then(async (resp) => {
          if (resp) {
            if (table.receiptNote && table.receiptNote.note !== '') {
              this.receiptNote = { note: table.receiptNote.note }
              EventBus.$emit('save-receipt-note', this.receiptNote)
            }
            this.selectTable(table)
          }
          this.confirmClose()
        })
      } else {
        if (table.locked && table.locked_by !== state.getUser().id) {
          this.confirm({
            title: this.$t('$vuetify.tables.unlockTitle'),
            message: `${table.name} ${this.$t('$vuetify.tables.unlockMessageOther')}`,
            options: {
              toolbar: true,
              width: 410,
              confirmText: this.$t('$vuetify.yes'),
              cancelText: this.$t('$vuetify.no')
            }
          }).then(async (resp) => {
            if (resp) {
              if (this.selectedTable.number > 0) {
                df.doc(`location_orders/${state.getPointOfSale().id}/tables/${this.selectedTable.id}`).update({ locked: false, color: 'primary', locked_by: state.getUser().id })
              }

              this.selectedTable = table
              if (table.receiptNote && table.receiptNote.note !== '') {
                this.receiptNote = { note: table.receiptNote.note }
              }
              EventBus.$emit('clear-invoice', table)
              df.doc(`location_orders/${state.getPointOfSale().id}/tables/${table.id}`).update({ locked: true, color: '#c02dd8', locked_by: state.getUser().id })
                .catch((err) => {
                  console.error(err)
                  this.showMsgBox({ text: `Pogreška prilikom otključavanja: ${err}`, actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
                })
            }
            this.confirmClose()
          })
        } else if (!this.editMode) {
          if (table.receiptNote && table.receiptNote.note !== '') {
            this.receiptNote = { note: table.receiptNote.note }
            EventBus.$emit('save-receipt-note', this.receiptNote)
          }
          this.selectTable(table)
        }
      }
    },
    selectTable (table) {
      const tb = clone(table)
      this.onSelectTable(tb)
      EventBus.$emit('recalculate')
    },
    async onNewOrder () {
      if (this.selectedTable.id && !this.orderHasChanged()) {
        await this.createNewOrder(true)
      }

      if ((!this.selectedTable.id && this.data.items && this.data.items.length > 0) || this.orderHasChanged()) {
        this.confirm({
          title: this.$t('$vuetify.tables.note'),
          message: this.$t('$vuetify.tables.noteMessage'),
          options: {
            toolbar: true,
            width: 410,
            confirmText: this.$t('$vuetify.tables.continue'),
            cancelText: this.$t('$vuetify.tables.cancel')
          }
        }).then(async (resp) => {
          if (resp) {
            await this.createNewOrder(false)
          }
          this.confirmClose()
        })
      }

      this.hideLoader()
    },
    clearOrderFromScreen () {
      this.data.items = []
      this.receiptNote = null
      EventBus.$emit('save-receipt-note', null)
    },
    async createNewOrder (tableSelected) {
      if (tableSelected) {
        await df.doc(`location_orders/${state.getPointOfSale().id}/tables/${this.selectedTable.id}`).update({ locked: false, color: 'primary' })
      }
      this.selectedTable = new Table()
      this.clearOrderFromScreen()
      EventBus.$emit('select-table', this.selectedTable)
    },
    onClearOrder () {
      this.confirm({
        title: 'Ukloni narudžbu',
        message: `${this.$t('$vuetify.tables.clearOrder')} ${this.selectedTable.number}?</br></br> ${this.$t('$vuetify.tables.note')} </br> Svako brisanje narudžbe će biti zabilježeno.`,
        options: {
          toolbar: true,
          width: 410,
          confirmText: this.$t('$vuetify.yes'),
          cancelText: this.$t('$vuetify.no')
        }
      }).then(async (resp) => {
        if (resp) {
          this.confirmClose()
          this.removeOrderFromTable()
        }
      })
    },
    async removeOrderFromTable () {
      // this.selectedTable.items = []
      this.initialTable.items = []
      const tblIndex = this.selectedTables.findIndex((tbl) => tbl.number === this.selectedTable.number)
      if (tblIndex > -1) {
        this.selectedTable.items = []
        this.selectedTable.locked = false
        this.selectedTable.locked_by = ''
        this.selectedTables.splice(tblIndex, 1)
        this.selectedTable.orders = []
        this.onEmptyTable(this.selectedTable)
      }
      if (!this.selectedTable.persistent) {
        this.onDeleteTable(this.selectedTable)
      }

      this.selectedTable = new Table()
      EventBus.$emit('clear-selection')
    },
    onR1DialogClose () {
      this.r1DialogOpen = false
    },

    getTables () {
      const query = df.collection(`location_orders/${state.getPointOfSale().id}/tables`).orderBy('number')
      const listener = query.onSnapshot((doc) => {
        const tmpSelectedTables = [...this.selectedTables]
        doc.docChanges().forEach((change) => {
          const table = change.doc.data()
          if (change.type === 'added') {
            if (table && table.items && table.items.length > 0 && ((this.manager) || (table.user_id && table.user_id === this.userId))) {
              this.selectedTables.push(table)
            }
          } else if (change.type === 'modified') {
            this.selectedTables = []
            if (table.locked_by !== state.getUser().id) {
              EventBus.$emit('clear-invoice', new Table()) // to RegisterInvoice, onClearInvoice
              // this.$emit('clearInvoice', new Table())
            }
            doc.docs.forEach((rec) => {
              let modTbl = rec.data()
              if (rec.data().id === table.id) {
                modTbl = table
              }
              if (modTbl && modTbl.items && modTbl.items.length > 0 && ((this.manager) || (modTbl.user_id && modTbl.user_id === this.userId))) {
                this.selectedTables.push(modTbl)
              }
            })
          }
        })

        if (this.selectedTables.length === 0) {
          this.selectedTables = [...tmpSelectedTables]
        }

        this.availableTables = []
        doc.docs.forEach((rec) => {
          const table = rec.data()
          const availableIndex = this.availableTables.findIndex((tbl) => tbl.id === table.id)
          if (availableIndex > -1) {
            this.availableTables.splice(availableIndex, 1, table)
          } else {
            this.availableTables.push(table)
          }
        })

        EventBus.$emit('set-available-tables', this.availableTables)
      })

      this.listeners.push(listener)
    },

    async updateTable (table) {
      const tbl = this.selectedTables.find(t => t.id === table.id)
      if (tbl) {
        var receiptNote = clone(this.receiptNote)
        this.receiptNote = null

        df.doc(`location_orders/${state.getPointOfSale().id}/tables/${tbl.id}`).update({
          items: table.items,
          user_id: this.userId,
          user_name: this.user.name + ' ' + this.user.surname,
          update_time: Date.now(),
          receiptNote: receiptNote || ''
        })
          .then(() => {
            console.info('Table succesfully updated.')
          })
          .catch(() => {
            console.error('Table has not been updated succesfully.')
          })
      }
    },

    async addSplitTable (table, sourceTable) {
      const numberOfTables = this.selectedTables.filter(t => t.name.startsWith(this.selectedTable.name))
      this.tableNameSuffix = numberOfTables.length + 1
      const tbl = {
        color: table.color,
        id: uuidv4(),
        locked: false,
        locked_by: state.getUser().id,
        number: this.getCurrentTableNumber() + 1,
        name: `${sourceTable.name}/${this.tableNameSuffix}`,
        persistent: false,
        items: table.items,
        user_id: this.userId,
        user_name: this.user.name + ' ' + this.user.surname,
        update_time: Date.now()
      }
      const docRef = df.collection(`location_orders/${state.getPointOfSale().id}/tables`)

      try {
        await docRef.doc(tbl.id).set(tbl)
        console.info('Table successfully saved.')
      } catch (err) {
        console.error('Error saving table: ', err)
      }
    },

    getCurrentTableNumber () {
      if (this.availableTables.length === 0) {
        return 0
      }
      return Math.max(...this.availableTables.map(table => table.number))
    },

    async saveTable (table, persist) {
      let tblId, persistent, items
      if (table.id && table.id === this.selectedTable.id) {
        tblId = table.id
      } else {
        tblId = uuidv4()
        table.id = tblId
      }
      if (persist !== undefined) { persistent = persist } else { persistent = this.selectedTable.persistent }
      if (table.items.length > 0) { items = [...table.items] } else { items = [] }
      const docRef = df.doc(`location_orders/${state.getPointOfSale().id}/tables/${tblId}`)

      var receiptNote = clone(this.receiptNote)
      this.receiptNote = null

      try {
        await docRef.set(
          {
            id: tblId,
            name: table.name,
            number: table.number,
            items: items,
            persistent: persistent,
            color: table.color ? table.color : '',
            locked: table.locked ? table.locked : false,
            locked_by: state.getUser().id,
            orders: table.orders && table.orders.length > 0 ? table.orders : [],
            user_id: this.userId,
            user_name: this.user.name + ' ' + this.user.surname,
            update_time: Date.now(),
            receiptNote: receiptNote || ''
          }
        )
        console.info('Table successfully saved.')
      } catch (err) {
        console.error('Error saving table: ', err)
      }
    },

    onEmptyTable (table) {
      EventBus.$emit('empty-table', table)
    },

    onDeleteTable (table) {
      if (table.id === this.selectedTable.id) {
        this.selectedTable = new Table()
      }
      EventBus.$emit('delete-table', table)
    },

    setDefaults () {
      this.languages = [
        { name: this.$t('$vuetify.receiptLanguages.hr'), value: 'hr' },
        { name: this.$t('$vuetify.receiptLanguages.en'), value: 'en' }
      ]
      this.language = this.languages[0]
      this.maxIssueDate = toISOLocal(new Date())
      this.issueDate = ''
    },

    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },

    dateTimeToUnixTimestamp (dt) {
      return Math.floor(new Date(toISOLocal(dt)).getTime() / 1000)
    },

    setupTableContainer () {
      const ele = document.getElementById('container')
      if (ele) {
        let pos = { top: 0, left: 0, x: 0, y: 0 }
        const mouseDownHandler = function (e) {
          ele.style.cursor = 'grabbing'
          ele.style.userSelect = 'none'
          pos = {
            // The current scroll
            left: ele.scrollLeft,
            top: ele.scrollTop,
            // Get the current mouse position
            x: e.clientX,
            y: e.clientY
          }
          const mouseMoveHandler = function (e) {
            // How far the mouse has been moved
            const dx = e.clientX - pos.x
            const dy = e.clientY - pos.y

            // Scroll the element
            ele.scrollTop = pos.top - dy
            ele.scrollLeft = pos.left - dx
          }
          const mouseUpHandler = function () {
            document.removeEventListener('pointermove', mouseMoveHandler)
            document.removeEventListener('pointerup', mouseUpHandler)

            ele.style.cursor = 'grab'
            ele.style.removeProperty('user-select')
          }

          document.addEventListener('pointermove', mouseMoveHandler)
          document.addEventListener('pointerup', mouseUpHandler)
        }

        document.addEventListener('pointerdown', mouseDownHandler)
      }
    },
    resetTables () {
      const interval = setInterval(() => {
        if (this.selectedTables.length > 0) {
          clearInterval(interval)
          if (this.selectedTable.number === 0) {
            this.selectedTables.forEach((t) => {
              if (t.locked && t.locked_by === state.getUser().id) {
                df.doc(`location_orders/${state.getPointOfSale().id}/tables/${t.id}`).update({ color: 'primary', locked: false })
                  .catch((err) => {
                    console.error(err)
                    this.showMsgBox({ text: `Pogreška prilikom resetiranja: ${err}`, actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
                  })
              }
            })
          }
        }
      }, 100)
    }
  },

  created () {
    const interval = setInterval(() => {
      if (state.getTemplateConfig()) {
        this.templateConfig = state.getTemplateConfig()
        this.showComponent = this.templateConfig.tables && this.templateConfig.tables.show
        clearInterval(interval)
      }
    }, 100)

    EventBus.$on('cart-has-changed', (data) => {
      this.data = clone(data)
    })

    this.user = state.getCashRegisterUser().id ? state.getCashRegisterUser() : state.getUser()
    this.userId = this.user.id
    this.manager = !!(this.user.roles && this.user.roles.VODITELJ && this.user.roles.VODITELJ === true)
    this.selectedTable = new Table()
    this.getTables()
    this.resetTables()
  },

  mounted () {
    EventBus.$on('save-receipt-note', (receiptNote) => {
      this.receiptNote = receiptNote
    })
    this.data.currency = 'EUR'

    this.selectedCard = undefined
    this.setupTableContainer()

    // Events from RegisterTableActions
    EventBus.$on('on-open-tables-dialog', () => { this.onOpenTablesDialog() })
    EventBus.$on('on-new-order', () => { this.onNewOrder() })
    EventBus.$on('on-clear-order', () => { this.onClearOrder() })
    EventBus.$on('on-save-items-to-table', () => { this.onSaveItemsToTable() })
    // End events from RegisterTableActions

    EventBus.$on('on-create-new-table', async (sourceTable, destinationTable) => { await this.onCreateNewTable(sourceTable, destinationTable) })
    EventBus.$on('on-add-items-to-table', async (sourceTable, targetTable) => { await this.onAddItemsToTable(sourceTable, targetTable) })

    EventBus.$on('clear-reload-tables', () => {
      this.selectedTables = []
      this.getTables()
      this.selectedTable = new Table()
    })

    EventBus.$on('clear-table', () => {
      this.removeOrderFromTable()
    })

    EventBus.$on('set-mode', (editMode) => {
      this.editMode = editMode
    })

    EventBus.$on('set-orders-view', (ordersView) => {
      this.ordersView = ordersView
    })

    this.setDefaults()
    this.minIssueDate = toISOLocal(new Date())
    this.listeners.push(
      df
        .doc(`cash_registers/${state.getCashRegister().id}`)
        .onSnapshot((doc) => {
          if (doc && doc.data()) {
            this.offer_sequence = doc.data().offer_sequence
            this.bill_sequence = doc.data().bill_sequence
            this.last_receipt = doc.data().last_receipt_issued_timestamp
            if (this.last_receipt) {
              this.minIssueDate = toISOLocal(
                new Date(this.last_receipt * 1000)
              )
            } else {
              this.minIssueDate = toISOLocal(new Date())
            }
          }
        })
    )

    this.orgUnits = []
    let query = df.collection(`location_units/${state.getPointOfSale().id}/units`)
    let listener = query.onSnapshot((doc) => {
      doc.docs.forEach((rec) => {
        this.orgUnits.push(rec.data())
      })
    })
    this.listeners.push(listener)

    this.printers = []
    query = df.collection(`location_printers/${state.getPointOfSale().id}/printers`)
    listener = query.onSnapshot((doc) => {
      doc.docs.forEach((rec) => {
        this.printers.push(rec.data())
      })
    })

    this.listeners.push(listener)
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  }
}
</script>

<style scoped>
#container {
  overflow-x: hidden;
  overflow-y: auto;
  cursor: grab;
  touch-action: none;
  /* border: 1px solid#fd0000; */
  margin-top: 12px;
}

@media only screen and (max-height: 899px) {
  #container {
    max-height: 193px;
  }
  span.tile {
    display: block;
    white-space: normal;
    max-width: 180px;
  }
}

@media only screen and (min-height: 900px) {
  #container {
    max-height: 440px;
  }
  span.tile {
    display: block;
    white-space: normal;
    max-width: 120px;
  }
}
.panel-cell {
  padding: 0 2px 2px 0;
}
.label {
  font-size: 0.6rem;
  color: white;
  min-width: 90px;
}
.text-wrap {
  width: min-content;
  margin: auto;
}
.disable-events {
  pointer-events: none;
  background-color: #e0e0e0;
}

.accent-btn {
  animation: glowing 700ms infinite;
  font-weight: 700;
}
.darkblue {
  background-color: #2c5a90;
}
.btn-lg {
  font-size: 1rem;
  font-weight: 700;
}
.btn-md {
  font-size: 0.8rem;
  font-weight: 700;
}
.btn-sm {
  font-size: 0.7rem;
  font-weight: 700;
}
.btn-purple {
  color: white;
  background-color: #b10090;
}
.notification-green {
  font-size: 12px;
  background-color: #8ff4a2;
  font-weight: bold;
}
.notification-yellow {
  font-size: 12px;
  background-color: #edf68b;
  font-weight: bold;
}
.notification-red {
  font-size: 12px;
  background-color: #e898a0;
  color: white;
  font-weight: bold;
}
</style>
