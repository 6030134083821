export default class Table {
  // eslint-disable-next-line space-before-function-paren
  constructor() {
    this.id = ''
    this.name = ''
    this.number = 0
    this.items = []
    this.color = 'primary'
    this.locked = 'false'
    this.locked_by = ''
    this.persistent = true
    this.selected = false
    this.mark_for_delete = false
  }
}
