var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showToolbar)?_c('v-row',{staticClass:"mb-1"},[_vm._l((_vm.sortedButtons),function(btn,i){return _c('v-col',{key:i,staticStyle:{"max-height":"3.5rem","margin":"0","padding":"0 1px 1px 0"},attrs:{"cols":"2"}},[(!btn.submenu)?_c('v-btn',{attrs:{"block":"","tile":"","depressed":"","color":btn.background_color,"height":"3.4rem"},on:{"click":function($event){return _vm.onToolbarClick(btn.action)}}},[_c('v-icon',{attrs:{"color":btn.foreground_color}},[_vm._v(_vm._s(btn.icon))])],1):_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:['white--text', { purple: true }],style:(_vm.items && _vm.items.length > 0
              ? { 'font-weight': 'bold' }
              : {
                  'background-color': '#E0E0E0 !important',
                  'font-weight': 'bold',
                }),attrs:{"block":"","tile":"","depressed":"","color":"white","width":"100%","height":"3.4rem","icon":"","disabled":_vm.items && _vm.items.length > 0 ? false : true}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(btn.label)+" ")])]}}],null,true)},[_c('v-list',_vm._l((btn.submenu),function(menu,idx){return _c('v-list-item',{key:idx,on:{"click":function($event){return _vm.menuPaymentClick(menu, btn.label)}}},[(menu.type === 'G' || menu.type === 'V')?_c('v-list-item-title',[_vm._v(_vm._s(menu.text))]):_c('v-list-item-title',[_vm._v(_vm._s(menu.text)+" ")])],1)}),1)],1)],1)}),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasSubmenu),expression:"hasSubmenu"}],staticStyle:{"max-height":"3.5rem","margin":"0","padding":"0 1px 1px 0"},attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"bottom":"","left":"","flat":"","rounded":"false","nudge-bottom":"60px","min-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","tile":"","depressed":"","color":"purple","width":"100%","height":"3.4rem"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3370954506)},[_c('v-list',_vm._l((_vm.sortedSubmenu),function(item,s){return _c('div',{key:s},[_c('v-list-item',{on:{"click":function($event){return _vm.onToolbarClick(item.action)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.label)+" ")])],1)],1)}),0)],1),_c('search-items-dialog-new',{ref:"searchItemsDialog"}),_c('receipts-dialog-new',{ref:"receiptsDialog"}),_c('close-balance',{ref:"closeBalance"}),_c('split-receipt-dialog',{ref:"splitReceiptDialog",attrs:{"selectedTable":_vm.selectedTable,"availableTables":_vm.availableTables},on:{"createNewTable":_vm.onCreateNewTable,"addItemsToTable":_vm.onAddItemsToTable}}),_c('switch-table-by-pin',{ref:"pinSwitch"}),_c('add-receipt-note-dialog',{ref:"addReceiptNoteDialog"})],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }