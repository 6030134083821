<template>
  <v-dialog v-model="visible" v-if="receipt" persistent scrollable width="400">
    <v-form ref="statusToUnpaidForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto py-3 pa-2"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="mb-2">
            <h3 class="text--secondary">Status u neplaćeno</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <label>Prebaci status u Neplaćeno ?</label>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" @click="close">
            {{ $t("$vuetify.no") }}
          </v-btn>
          <v-btn
            class="white--text okButton"
            :loading="submitting"
            :disabled="submitting"
            @click="submit"
          >
            {{ $t("$vuetify.yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
// import rules from '@/plugins/rules'
import { clone } from '@/plugins/utils'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import { v4 as uuidv4 } from 'uuid'
export default {
  inject: ['showMsgBox'],
  data: function () {
    return {
      visible: false,
      submitting: false,
      receipt: undefined
    }
  },
  computed: {},
  methods: {
    open (receipt) {
      if (!receipt) return
      this.visible = true
      this.receipt = clone(receipt)
    },
    close () {
      this.visible = false
    },
    getCurrentTimePart () {
      return new Date().toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      })
    },
    submit () {
      if (!this.$refs.statusToUnpaidForm.validate()) return
      try {
        const paymetDate = (new Date().getTime() / 1000).toFixed(0)
        const reqId = uuidv4()
        const payload = {
          action: {
            operation: 'update',
            entity: 'receipt',
            resource_id: this.receipt.id,
            params: {
              company_id: state.getCurrentCompany().id,
              status: 'UNPAID',
              date: parseInt(paymetDate)
            }
          }
        }

        this.submitting = true
        df.doc(`request/${reqId}`)
          .set({
            msg_id: reqId,
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'receipt',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(() => {
            this.$emit('receiptEdit', this.receipt)
            this.close()
          })
          .catch((error) => {
            // console.error('Error writing document: ', error)
            throw new Error(error)
          })
          .finally(() => {
            this.submitting = false
          })
      } catch (err) {
        this.showMsgBox({
          text: err,
          actions: ['cancel'],
          cancelBtnText: 'OK'
        })
      }
    }
  }
}
</script>
