<template>
  <v-dialog
    v-model="dialogOpen"
    v-if="dialogOpen"
    content-class="my-custom-dialog"
    transition="dialog-top-transition"
    persistent
    max-width="600"
    @keydown.esc="close"
  >
    <v-card tile color="white" style="border: 1px solid white">
      <v-card-title>
        <h2 class="text--secondary">
          {{ $t("$vuetify.formDetails.changeReceiptType") }}
        </h2>
        <v-spacer></v-spacer>
        <v-icon large @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-actions>
        <v-container>
          <v-row v-if="receipt && receipt.type && receipt.type.flag === 'R'">
            <v-col> OIB: {{ receipt.type.payer_oib }} </v-col>
          </v-row>
          <v-row
            v-if="receipt && receipt.type && receipt.type.flag === 'R'"
            style="margin-top: 0"
          >
            <v-col>
              Kupac: {{ receipt.type.payer_name.toUpperCase() }},
              {{ receipt.type.payer_city.toUpperCase() }},
              {{ receipt.type.payer_country.toUpperCase() }}
              <v-tooltip bottom>
                <template #activator="{ on: on }">
                  <v-icon v-on="on" class="ml-2" @click="changeToR1"
                    >mdi-pencil</v-icon
                  >
                </template>
                Promijeni
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group v-model="receiptType" @change="changeReceiptType">
                <v-radio label="R1" value="R" />
                <v-radio label="Bez oznake" value="BO" />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <account-details-dialog-new ref="accountDetailsDialog" />
  </v-dialog>
</template>

<script>
import state from '@/state'
import { df } from '@/plugins/firebase'
import AccountDetailsDialogNew from '@/modules/cash-register/components/dialogs/AccountDetailsDialogNew.vue'

export default {
  components: {
    AccountDetailsDialogNew
  },
  data () {
    return {
      dialogOpen: false,
      receipt: {},
      receiptType: null
    }
  },
  methods: {
    open (receipt) {
      this.receipt = receipt
      this.receiptType = receipt.type.flag
      this.dialogOpen = true
    },

    close () {
      this.dialogOpen = false
    },

    changeReceiptType () {
      switch (this.receipt.type.flag) {
        case 'BO': // From BO to R
          this.changeToR1()
          break
        case 'R': // From R to BO
          this.changeToBO()
          break
      }
      this.close()
    },

    changeToR1 () {
      this.$refs.accountDetailsDialog.open(this.receipt)
      this.close()
    },

    async changeToBO () {
      if (this.receipt.type.flag === 'BO') return
      const me = this

      df.doc(`receipts/${state.getCurrentCompany().id}/receipts/${this.receipt.id}`).update({
        type: {
          flag: 'BO'
        }
      })
        .then(() => {
          console.info('Receipt succesfully updated')
          this.close()
        })
        .catch(() => {
          me.showMsgBox({ text: 'Pogreška prilikom zapisa podataka', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        })
    }
  }

}
</script>
