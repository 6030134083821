<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="40%"
    max-height="100%"
    style="z-index: 6"
  >
    <v-form ref="chooseMenuOptions" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2
              class="text--secondary"
              v-if="item !== undefined && item.name !== undefined"
            >
              {{ $options.filters.capitalize(item.name) }}
            </h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container v-if="item !== undefined && item.products !== undefined">
            <div v-for="product in item.products" :key="product.id">
              <v-card v-if="product !== undefined" class="mb-5">
                <v-card-text>
                  <v-flex class="mb-1">
                    <v-row>
                      <v-col cols="12">
                        <h3 class="text--secondary pl-2">
                          {{ $options.filters.capitalize(product.name) }}
                        </h3>
                      </v-col>
                    </v-row>
                  </v-flex>
                  <v-flex v-if="product.type === 'manC'">
                    <v-row>
                      <v-col
                        class="ctrl-box"
                        cols="auto"
                        v-for="item in product.product_items"
                        :key="product.id + item.company_item.id"
                      >
                        <div @click="onClick(item, 'choosen')">
                          <v-checkbox
                            class="my-label"
                            :label="item.company_item.name"
                            v-model="item.choosen"
                            @click="onClick(item, 'choosen')"
                          >
                          </v-checkbox>
                        </div>
                      </v-col>
                    </v-row>
                  </v-flex>
                  <v-flex v-if="product.type === 'optC'">
                    <v-row class="pl-5">
                      <v-col
                        class="ctrl-box"
                        cols="auto"
                        v-for="item in product.product_items"
                        :key="product.id + item.company_item.id"
                      >
                        <div @click="onClick(item, 'default')">
                          <v-checkbox
                            class="my-label"
                            :label="item.company_item.name"
                            v-model="item.choosen"
                            @click="onClick(item, 'default')"
                          >
                          </v-checkbox>
                        </div>
                      </v-col>
                    </v-row>
                  </v-flex>
                  <v-flex v-if="product.type === 'manR'">
                    <v-radio-group v-model="product.radioGroup">
                      <v-row class="pl-5">
                        <v-col
                          class="ctrl-box"
                          cols="auto"
                          v-for="item in product.product_items"
                          :key="product.id + item.company_item.id"
                        >
                          <div>
                            <v-radio
                              class="my-label"
                              :value="item.company_item.id"
                              :label="item.company_item.name"
                            ></v-radio>
                          </div>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-flex>
                  <v-flex v-if="product.type === 'optR'">
                    <v-radio-group v-model="product.radioGroup">
                      <v-row class="pl-5">
                        <v-col
                          class="ctrl-box"
                          cols="auto"
                          v-for="item in product.product_items"
                          :key="product.id + item.company_item.id"
                        >
                          <div @click="onClick(item, 'item.company_item.name')">
                            <v-radio
                              class="my-label"
                              :value="item.company_item.id"
                              :label="item.company_item.name"
                            ></v-radio>
                          </div>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-flex>
                </v-card-text>
              </v-card>
            </div>
            <div v-for="tag in item.tags" :key="tag.id">
              <v-card v-if="tag !== undefined" class="mb-5">
                <v-card-text>
                  <v-flex class="mb-1">
                    <v-row>
                      <v-col cols="12">
                        <h3 class="text--secondary pl-2">
                          {{ $options.filters.capitalize(tag.name) }}
                        </h3>
                      </v-col>
                    </v-row>
                  </v-flex>
                  <v-flex v-if="tag.type === 'manC'">
                    <v-row class="pl-5">
                      <v-col
                        class="ctrl-box"
                        color="primary"
                        cols="auto"
                        v-for="item in tag.tag_texts"
                        :key="tag.id + item.id"
                      >
                        <div @click="onClick(item, 'default')">
                          <v-checkbox
                            class="my-label"
                            :label="item.text"
                            v-model="item.choosen"
                            :rules="[rules.req]"
                            @click="onClick(item, 'default')"
                          >
                          </v-checkbox>
                        </div>
                      </v-col>
                    </v-row>
                  </v-flex>
                  <v-flex v-if="tag.type === 'optC'">
                    <v-row class="pl-5">
                      <v-col
                        class="ctrl-box"
                        color="primary"
                        cols="auto"
                        v-for="item in tag.tag_texts"
                        :key="tag.id + item.id"
                      >
                        <div @click="onClick(item, 'default')">
                          <v-checkbox
                            class="my-label"
                            :label="item.text"
                            v-model="item.choosen"
                            @click="onClick(item, 'default')"
                          >
                          </v-checkbox>
                        </div>
                      </v-col>
                    </v-row>
                  </v-flex>
                  <v-flex v-if="tag.type === 'manR'">
                    <v-radio-group v-model="tag.radioGroup">
                      <v-row class="pl-5">
                        <v-col
                          class="ctrl-box"
                          cols="auto"
                          v-for="item in tag.tag_texts"
                          :key="tag.id + item.id"
                        >
                          <v-radio
                            class="my-label"
                            :value="item.id"
                            :label="item.text"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-flex>
                  <v-flex v-if="tag.type === 'optR'">
                    <v-radio-group v-model="tag.radioGroup">
                      <v-row class="pl-5">
                        <v-col
                          class="ctrl-box"
                          cols="auto"
                          v-for="item in tag.tag_texts"
                          :key="tag.id + item.id"
                        >
                          <v-radio
                            class="my-label"
                            :value="item.id"
                            :label="item.text"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-flex>
                </v-card-text>
              </v-card>
            </div>
            <div v-for="option in item.options" :key="option.id">
              <v-card v-if="option !== undefined">
                <v-card-text>
                  <v-flex class="mb-1">
                    <v-row>
                      <v-col cols="12">
                        <h3 class="text--secondary pl-2">
                          {{ $options.filters.capitalize(option.name) }}
                        </h3>
                      </v-col>
                    </v-row>
                  </v-flex>
                  <v-flex v-if="option.type === 'manC'">
                    <v-row class="pl-5">
                      <v-col
                        class="ctrl-box"
                        cols="auto"
                        v-for="item in option.option_items"
                        :key="option.id + item.company_item.id"
                      >
                        <div @click="onClick(item, 'choosen')">
                          <v-checkbox
                            class="my-label"
                            :label="showLabel(item)"
                            v-model="item.choosen"
                            :value="item.default"
                            @click="onClick(item, 'choosen')"
                          >
                          </v-checkbox>
                        </div>
                      </v-col>
                    </v-row>
                  </v-flex>
                  <v-flex v-if="option.type === 'optC'">
                    <v-row class="pl-5">
                      <v-col
                        class="ctrl-box"
                        cols="auto"
                        v-for="item in option.option_items"
                        :key="option.id + item.company_item.id"
                      >
                        <div @click="onClick(item, 'choosen')">
                          <v-checkbox
                            class="my-label"
                            :label="showLabel(item)"
                            v-model="item.choosen"
                            :value="item.default"
                            @click="onClick(item, 'choosen')"
                          >
                          </v-checkbox>
                        </div>
                      </v-col>
                    </v-row>
                  </v-flex>
                  <v-flex v-if="option.type === 'manR'">
                    <v-radio-group v-model="option.radioGroup">
                      <v-row class="pl-5">
                        <v-col
                          class="ctrl-box"
                          cols="auto"
                          v-for="item in option.option_items"
                          :key="option.id + item.company_item.id"
                        >
                          <v-radio
                            class="my-label"
                            :value="item.company_item.id"
                            :label="showLabel(item)"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-flex>
                  <v-flex v-if="option.type === 'optR'">
                    <v-radio-group v-model="option.radioGroup">
                      <v-row class="pl-5">
                        <v-col
                          class="ctrl-box"
                          cols="auto"
                          v-for="item in option.option_items"
                          :key="option.id + item.company_item.id"
                        >
                          <v-radio
                            class="my-label"
                            :name="option.radioGroup"
                            :value="item.company_item.id"
                            :label="showLabel(item)"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-flex>
                </v-card-text>
              </v-card>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Spremi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
// import { df } from '@/plugins/firebase'
// import state from '@/state'
import rules from '@/plugins/rules'
// import { clone } from '@/plugins/utils'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'AddProductToMenu',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    name: undefined,
    existing: false,
    submitting: false,
    currency: undefined,
    rules: {
      req: rules.req('Required field')
    },
    edit: false,
    item: undefined
  }),
  computed: {
  },
  methods: {
    showLabel (item) {
      // return (item.company_item.name + ' ' + this.$options.filters.money(parseFloat(item.prices[this.currency].price / (1 + item.prices[this.currency].taxes[0].rate / 10000)), 100, this.currency))

      return (item.company_item.name + ' ' + this.$options.filters.money(parseFloat(item.prices[this.currency].price), 100, this.currency))
    },
    findType (type) {
      switch (type) {
        case 'manC':
          return 'Checkbox mandatory'
        case 'optC':
          return 'Checkbox optional'
        case 'manR':
          return 'Radio mandatory'
        case 'optR':
          return 'Radio optional'
      }
    },
    show (item) {
    },
    async open (item, currency = 'EUR') {
      currency = 'HRK'
      if (Date.now() / 1000 > 1672527599) {
        currency = 'EUR'
      }
      this.visible = true
      this.item = item
      this.currency = currency

      if (this.item.products !== undefined && this.item.products.length > 0) {
        this.item.products.forEach(product => {
          if (product.type === 'manR' || product.type === 'optR') {
            product.radioGroup = ''
          }

          product.product_items.forEach(prItem => {
            prItem.choosen = prItem.default

            if (prItem.choosen === true && (product.type === 'manR' || product.type === 'optR')) {
              product.radioGroup = prItem.company_item.id
            }
          })
        })
      }

      if (this.item.tags !== undefined && this.item.tags.length > 0) {
        this.item.tags.forEach(tag => {
          if (tag.type === 'manR' || tag.type === 'optR') {
            tag.radioGroup = ''
          }

          tag.tag_texts.forEach(tgItem => {
            tgItem.choosen = tgItem.default

            if (tgItem.choosen === true && (tag.type === 'manR' || tag.type === 'optR')) {
              tag.radioGroup = tgItem.id
            }
          })
        })
      }

      if (this.item.options !== undefined && this.item.options.length > 0) {
        this.item.options.forEach(option => {
          if (option.type === 'manR' || option.type === 'optR') {
            option.radioGroup = ''
          }

          option.option_items.forEach(opItem => {
            opItem.choosen = opItem.default

            if (opItem.choosen === true && (option.type === 'manR' || option.type === 'optR')) {
              option.radioGroup = opItem.company_item.id
            }
          })
        })
      }
    },
    close () {
      this.$emit('close')
      this.visible = false
    },
    onClick (item, prop) {
      item[prop] = !item[prop]
      this.$forceUpdate()
    },
    submit () {
      // if (!this.$refs.addProductToMenuForm.validate()) return

      // Checkiraj da li je za svaki product mandatory checkbox odabran
      const products = this.item.products
      const choosenProductOrder = []

      let everythingOk = true
      products.forEach(product => {
        if (product.type === 'manC') {
          let foundChoosen = false
          product.product_items.forEach(prItem => {
            if (prItem.choosen) {
              foundChoosen = true
            }
          })

          if (!foundChoosen) {
            everythingOk = false
            this.showMsgBox({
              text: 'Nije odabran obavezan parametar stavke ' + product.name,
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })

            // eslint-disable-next-line no-useless-return
            return
          }
        }

        // Prodji kroz sve produkte i dodaj ih u narudzbu
        const productItems = []
        product.product_items.forEach(item => {
          if (product.type === 'manC' || product.type === 'optC') {
            if (item.choosen === true) {
              productItems.push(item.company_item)
            }
          }

          if (product.type === 'manR' || product.type === 'optR') {
            const compItem = product.radioGroup
            if (item.company_item.id === compItem) {
              productItems.push(item.company_item)
            }
          }
        })

        choosenProductOrder.push({
          productName: product.name,
          productItems: productItems
        })
      })

      // Checkiraj da li je za svaki tag mandatory checkbox odabran
      const choosenTagOrder = []
      const tags = this.item.tags
      tags.forEach(tag => {
        if (tag.type === 'manC') {
          let foundChoosen = false
          tag.tag_texts.forEach(tgItem => {
            if (tgItem.choosen) {
              foundChoosen = true
            }
          })

          if (!foundChoosen) {
            everythingOk = false

            this.showMsgBox({
              text: 'Nije odabran obavezan parametar stavke ' + tag.name,
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })

            // eslint-disable-next-line no-useless-return
            return
          }
        }

        // Prodji kroz sve tagove i dodaj ih u narudzbu
        const tagItems = []

        tag.tag_texts.forEach(item => {
          if (tag.type === 'manC' || tag.type === 'optC') {
            if (item.choosen === true) {
              tagItems.push(item.text)
            }
          }

          if (tag.type === 'manR' || tag.type === 'optR') {
            const compItem = tag.radioGroup
            if (item.id === compItem) {
              tagItems.push(item.text)
            }
          }
        })

        choosenTagOrder.push({
          tagName: tag.name,
          tagItems: tagItems
        })
      })

      // Checkiraj da li je za svaki option mandatory checkbox odabran
      const options = this.item.options
      if (options && options.length > 0) {
        options.forEach(option => {
          if (option.type === 'manC') {
            let foundChoosen = false
            option.option_items.forEach(opItem => {
              if (opItem.choosen) {
                foundChoosen = true
              }
            })

            if (!foundChoosen) {
              everythingOk = false
              this.showMsgBox({
                text: 'Nije odabran obavezan parametar stavke ' + option.name,
                actions: ['cancel'],
                cancelBtnText: this.$t('$vuetify.close'),
                color: 'error'
              })

              // eslint-disable-next-line no-useless-return
              return
            }
          }

          // Prodji kroz sve opcije i dodaj ih u narudzbu
          const optionItems = []
          option.option_items.forEach(item => {
            if (option.type === 'manC' || option.type === 'optC') {
              if (item.choosen === true) {
                optionItems.push({
                  item: item.company_item,
                  prices: item.prices
                })
              }
            }

            if (option.type === 'manR' || option.type === 'optR') {
              const compItem = option.radioGroup
              if (item.company_item.id === compItem) {
                optionItems.push({
                  item: item.company_item,
                  prices: item.prices
                })
              }
            }
          })

          optionItems.forEach(item => {
            item.item.prices = item.prices

            this.$emit('add', item.item)
          })
        })
      }

      if (everythingOk) {
        const id = uuidv4()

        this.item.storage_unit = 'komad'
        this.item.selling_unit = 'komad'

        if (this.item.choosenProductOrder) {
          this.item.choosenProductOrder[id] = choosenProductOrder
        } else {
          this.item.choosenProductOrder = {}
          this.item.choosenProductOrder[id] = choosenProductOrder
        }

        if (this.item.choosenTagOrder) {
          this.item.choosenTagOrder[id] = choosenTagOrder
        } else {
          this.item.choosenTagOrder = {}
          this.item.choosenTagOrder[id] = choosenTagOrder
        }

        this.$emit('add', this.item)
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.compact-form {
  transform: scale(0.875);
}

.my-label .v-label {
  font-size: 12px;
}

::v-deep .my-label .v-label {
  font-size: 12px;
}
.ctrl-box {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 0.3rem;
  margin-bottom: 0.3rem;
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
}
.ctrl-box div {
  height: 50px;
  display: flex;
  align-items: center;
}
</style>
