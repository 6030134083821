<template>
  <v-dialog
    v-model="dialogOpen"
    v-if="dialogOpen"
    content-class="my-custom-dialog"
    persistent
    scrollable
    max-width="800"
    @keydown.esc="close"
  >
    <v-card tile color="white">
      <v-card-title
        class="pb-4"
        style="position: sticky; border-bottom: 1px solid lightgrey"
      >
        <h2>Napomene</h2>
        <v-spacer></v-spacer>
        <v-icon class="mt-1" large @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-actions>
        <v-tabs v-model="tab">
          <v-tab>Napomena</v-tab>
          <v-tab>Povijest</v-tab>
          <v-spacer></v-spacer>
          <div class="mt-1">
            <v-btn
              class="button-more mr-1"
              elevation="0"
              :disabled="minusDisabled"
              @click="removeNote"
              >-</v-btn
            >
            <v-btn
              class="button-more"
              elevation="0"
              :disabled="plusDisabled"
              @click="addNote"
              >+</v-btn
            >
          </div>
          <v-tab-item
            ><v-container>
              <div v-for="(n, index) in notes" :key="index">
                <v-row>
                  <v-card
                    class="mt-2"
                    style="border-radius: 5px 5px 0 0"
                    outlined
                    width="100%"
                  >
                    <v-card-actions>
                      <v-col class="ctrl-col" cols="12">
                        <v-row>
                          <v-col
                            v-for="(ou, indexOu) in notes[index].destinations"
                            :key="indexOu"
                            class="d-flex align-center justify-center"
                            cols="4"
                          >
                            <v-checkbox
                              v-model="ou.isSelected"
                              :label="ou.name"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-card-actions>
                  </v-card>
                </v-row>
                <v-row>
                  <v-card
                    style="border-radius: 0 0 5px 5px; margin-bottom: 1rem"
                    outlined
                    width="100%"
                  >
                    <v-card-actions>
                      <v-col class="textarea-col" cols="12">
                        <v-textarea
                          v-model="notes[index].text"
                          autofocus
                          auto-grow
                          rows="2"
                          label="Unesi napomenu"
                        ></v-textarea>
                      </v-col>
                    </v-card-actions>
                  </v-card>
                </v-row>
              </div>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <v-row>
                <v-card class="mt-2" outlined width="100%">
                  <v-col cols="12">
                    <v-data-table
                      :items="previousNotes"
                      :headers="headers"
                      hide-default-footer
                    ></v-data-table>
                  </v-col>
                </v-card>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-card-actions> </v-card
  ></v-dialog>
</template>

<script>
import { df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import state from '@/state'

export default {
  data () {
    return {
      dialogOpen: false,
      tab: null,
      notes: [],
      note: {},
      organizationUnits: [],
      unitMessages: [],
      unitMessage: {},
      previousNotes: [],
      headers: []
    }
  },
  computed: {
    minusDisabled () {
      let lastOneStanding = true
      if (this.notes.length > 0 && this.notes[0].text !== '') {
        lastOneStanding = false
      }
      return this.notes.length === 1 && lastOneStanding
    },
    plusDisabled () {
      const currentNoteIndex = this.notes.length - 1
      let nextOneDisabled = true
      if (currentNoteIndex >= 0 && this.notes[currentNoteIndex].text.length > 0 && this.notes[currentNoteIndex].destinations.some(d => d.isSelected)) {
        nextOneDisabled = false
      }
      return this.notes.length === 3 || nextOneDisabled
    }
  },
  methods: {
    getHeaders () {
      this.headers = []
      this.headers.push(
        {
          text: 'Napomena',
          align: 'start',
          value: 'note.message'
        },
        {
          text: 'Ustrojstvena jedinica',
          align: 'start',
          value: 'note.unitName'
        },
        {
          text: 'Dodao',
          align: 'start',
          value: 'order.added_by_name'
        },
        {
          text: 'Status',
          align: 'start',
          value: 'order.order_status'
        }
      )
    },
    async open (table) {
      const locationId = state.getPointOfSale().id
      await this.gelLocationUnits(locationId)
      this.getHeaders()
      this.getNotesForTable(table.id)
      this.dialogOpen = true
    },
    sendNotes (order) {
      this.notes.forEach(note => {
        note.destinations.forEach(destination => {
          if (destination.isSelected) {
            const unitMessage = this.unitMessages.find(m => m.unitId === destination.id)
            if (unitMessage) {
              const concatenatedMessage = unitMessage.message + ', ' + note.text
              unitMessage.message = concatenatedMessage
            } else {
              this.unitMessages.push({
                unitId: destination.id,
                unitName: destination.name,
                message: note.text,
                printer: destination.printer
              })
            }
          }
        })
      })
      if (this.unitMessages && this.unitMessages.length > 0) {
        order.notes = this.unitMessages
      }
    },
    resetNotes () {
      this.notes = []
      this.unitMessages = []
      const locationId = state.getPointOfSale().id
      this.gelLocationUnits(locationId)
      this.addNote()
    },
    close () {
      const currentNoteIndex = this.notes.length - 1
      let closeDisabled = true
      if (currentNoteIndex >= 0 && this.notes[currentNoteIndex].text.length > 0 && this.notes[currentNoteIndex].destinations.some(d => d.isSelected)) {
        closeDisabled = false
      }
      if (this.notes[currentNoteIndex].text.length === 0 && !this.notes[currentNoteIndex].destinations.some(d => d.isSelected)) {
        closeDisabled = false
      }
      if (closeDisabled) { return }
      this.dialogOpen = false
    },
    addNote () {
      this.notes.push({ text: '', destinations: clone(this.organizationUnits) })
    },
    removeNote () {
      if (this.notes.length > 1) {
        this.notes.pop()
      } else {
        this.reset()
      }
    },
    reset () {
      this.notes[0].text = ''
      this.notes[0].destinations.forEach(item => {
        item.isSelected = false
      })
    },
    async gelLocationUnits (id) {
      if (this.organizationUnits.length > 0) return
      await df.collection(`location_units/${id}/units`).get()
        .then((units) => {
          if (units.docs && !units.docs.empty) {
            units.docs.forEach(unit => {
              const organizationUnit = unit.data()
              organizationUnit.isSelected = false
              this.organizationUnits.push(organizationUnit)
            })
          }
        })
      if (this.notes.length === 0) {
        this.notes.push({ text: '', destinations: clone(this.organizationUnits) })
      }
    },
    async getNotesForTable (tableId) {
      this.previousNotes = []
      if (tableId && tableId !== '') {
        const tbl = await df.doc(`location_orders/${state.getPointOfSale().id}/tables/${tableId}`).get()
        if (tbl && tbl.data()) {
          const table = tbl.data()
          if (table.orders) {
            const orders = clone(table.orders)
            orders.sort((a, b) => a.order_number > b.order_number ? -1 : 1)
            orders.forEach(order => {
              if (order.notes) {
                order.notes.forEach(note => {
                  this.previousNotes.push({ note: note, order: order })
                })
              }
            })
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.v-dialog__content >>> .my-custom-dialog {
  position: absolute;
  top: 50px;
}
.ctrl-col {
  padding-top: 0;
  padding-bottom: 0;
}
.textarea-col {
  padding-top: 0;
  padding-bottom: 0;
}
.button-more {
  font-size: 2rem;
}
</style>
